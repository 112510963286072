// import React, { useState, useEffect, useCallback } from "react";
// import SelectDatePickerCustom from "../SelectDateRange/SelectDatePickerCustom";
// import DaySelectionWeekDays from "../../daySelectionWeekDays/DaySelectionWeekDays";
// import { Button } from "@shopify/polaris";
// import { BgThemeButton } from "../../UI/Buttons";
// import SelectDateRange from "../SelectDateRange/SelectDateRange";
// import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
// import styles from "./inventorySliderComponents.module.scss";

// const InventoryDripFeed = ({
//   categories,
//   currentDate,
//   baseUrl,
//   property_id,
//   userToken,
//   setLoader,
//   channels,
//   setShow,
//   disabledSelectChannelOptions,
//   defaultSelectedChannel,
//   fetchInfo,
//   uniqueChannelId,
//   activeApp,
//   viewType,
//   room_id,
//   channel_id,
// }) => {
//   useEffect(() => {
//     if (defaultSelectedChannel && defaultSelectedChannel.length > 0) {
//       setSelectedChannel(defaultSelectedChannel);
//     }
//   }, [defaultSelectedChannel]);

//   const fetchInventoryData = async () => {
//     if (activeApp === "pms") {
//       fetchInfo(property_id?.id, uniqueChannelId);
//     } else if (activeApp === "channel_manager") {
//       viewType === "channel_view"
//         ? fetchInfo(property_id?.id, room_id, viewType)
//         : fetchInfo(property_id?.id, channel_id, viewType);
//     } else {
//     }
//   };

//   const [dates, setDates] = useState({ from_date: "", to_date: "" });

//   const [days, setDays] = useState({});

//   const handleDaysChange = useCallback((data) => {
//     setDays(data);
//   }, []);

//   const [inventoryData, setInventoryData] = useState([]);

//   const handleCheckboxChange = async (event) => {
//     if (dates.from_date === "" || dates.to_date === "") {
//       return showErrorToast("Please select a date range");
//     }

//     const { value, checked } = event.target;
//     const roomId = parseInt(value);

//     if (checked) {
//       try {
//         // Call the API to fetch available rooms for the selected room_id
//         const response = await fetch(
//           `${baseUrl}/api/v1/check/availability/${property_id?.id}?check_in=${dates.from_date}&check_out=${dates.to_date}&room_id=${roomId}`,{
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${userToken}`,
//             }
//           }
//         );
//         const data = await response.json();

//         if (data && data.available_rooms !== undefined) {
//           setInventoryData([
//             ...inventoryData,
//             {
//               room_id: roomId,
//               available_rooms: "",
//               backend_available_rooms: data.available_rooms, // Save available rooms from API
//             },
//           ]);
//         } else {
//           showErrorToast("Failed to fetch available rooms. Try again.");
//         }
//       } catch (error) {
//         showErrorToast("Error fetching available rooms. Check your network.");
//       }
//     } else {
//       // Remove the item from inventoryData if unchecked
//       setInventoryData(
//         inventoryData.filter((item) => item.room_id !== roomId)
//       );
//     }
//   };

//   const handleInputChange = (event, roomId) => {
//     const { value } = event.target;

//     setInventoryData(
//       inventoryData.map((item) => {
//         if (item.room_id === roomId) {
//           const backendAvailable = item.backend_available_rooms || 0;
//           if (value > backendAvailable) {
//             showErrorToast(`Input exceeds available rooms: ${backendAvailable}`);
//             return { ...item, available_rooms: backendAvailable };
//           }
//           return { ...item, available_rooms: parseInt(value) || "" };
//         }
//         return item;
//       })
//     );
//   };

//   const [selectedChannel, setSelectedChannel] = useState([]);

//   const handleCheckboxChangeChannel = (event) => {
//     const { value, checked } = event.target;
//     const channel_id = value;
//     const channel_name = event.target.dataset.name;

//     setSelectedChannel((prevSelectedChannels) => {
//       if (checked) {
//         // Check if the channel is already selected
//         const isAlreadySelected = prevSelectedChannels.some(
//           (channel) => +channel.channel_id === +channel_id
//         );

//         if (!isAlreadySelected) {
//           // If not already selected, add to the array
//           return [...prevSelectedChannels, { channel_id, channel_name }];
//         }
//       } else {
//         // If unchecked, remove from the array
//         return prevSelectedChannels.filter(
//           (channel) => +channel.channel_id !== +channel_id
//         );
//       }

//       // If already selected and checked, return the current state without changes
//       return prevSelectedChannels;
//     });
//   };

//   const updateStayRestrictions = async () => {
//     // Validation checks
//     if (selectedChannel.length === 0) {
//       showErrorToast("Please select at least one channel.");
//       return;
//     }

//     if (inventoryData.length === 0) {
//       showErrorToast("Please add inventory data.");
//       return;
//     }

//     for (let item of inventoryData) {
//       if (!item.available_rooms || item.available_rooms === "") {
//         showErrorToast(
//           "Please ensure all selected rooms have available rooms specified."
//         );
//         return;
//       }
//     }

//     if (!dates || !dates.from_date || !dates.to_date) {
//       showErrorToast("Please select both 'from' and 'to' dates.");
//       return;
//     }

//     if (Object.keys(days).length === 0) {
//       showErrorToast("Please select at least one day.");
//       return;
//     }

//     const postData = {
//       from_date: dates.from_date,
//       to_date: dates.to_date,
//       channels: selectedChannel,
//       status: "Open",
//       inventory_data: inventoryData,
//       ...days,
//     };

//     console.log(postData, "post data");

//     try {
//       setLoader(true);

//       const response = await fetch(
//         `${baseUrl}/api/v1/update/inventory/${property_id?.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//           method: "POST",
//           body: JSON.stringify(postData),
//         }
//       );

//       const data = await response.json();
//       if (data.success) {
//         showSuccessToast(data.message);
//         setShow(false);
//         fetchInventoryData();
//       } else {
//         showErrorToast(data.message || "Failed to update stay restrictions.");
//       }
//     } catch (error) {
//       console.error("An error occurred:", error);
//       showErrorToast("An error occurred while updating stay restrictions.");
//     } finally {
//       setLoader(false);
//     }
//   };

//   const handleCloseBookingSlider = () => {
//     setShow(false);
//     setLoader(true);
//     setTimeout(() => {
//       setLoader(false);
//     }, [1000]);
//   };

//   return (
//     <>
//       <div className={styles.container}>
//         <div style={{ width: "100%" }}>
//           <div style={{ paddingBottom: "5px" }}>Select Date Range</div>
//           <SelectDateRange
//             dataFromChild={(from_date, to_date) => {
//               setDates({
//                 from_date: from_date,
//                 to_date: to_date,
//               });
//             }}
//             minDate={new Date(currentDate)}
//           />
//         </div>
//         <hr />
//         <div>
//           <DaySelectionWeekDays onSaveDates={handleDaysChange} />
//         </div>

//         <hr />

//         <div>
//           <div className={styles.headings}>Select Room Categories</div>

//           {categories.map((room) => {
//             const inventoryItem = inventoryData.find(
//               (item) => item.room_id === room.id
//             );

//             return (
//               <div
//                 key={room.id}
//                 className="flex justify_content_between"
//                 style={{ margin: "5px 0" }}
//               >
//                 <div className={styles.checkboxContainer}>
//                   <input
//                     type="checkbox"
//                     name="update_type"
//                     onChange={handleCheckboxChange}
//                     value={room.id}
//                     checked={!!inventoryItem}
//                     style={{ marginRight: "10px" }}
//                     id={room.id + "-" + room.room_name}
//                   />
//                   <label htmlFor={room.id + "-" + room.room_name}>
//                     {room.room_name}
//                   </label>
//                 </div>

//                 {inventoryItem && (
//                   <input
//                     type="number"
//                     value={inventoryItem.available_rooms}
//                     onChange={(e) => handleInputChange(e, room.id)}
//                     className={styles.inputRoomData}
//                   />
//                 )}
//               </div>
//             );
//           })}
//         </div>

//         <hr />

//         <div>
//           <div>
//             <div className={styles.headings}>Select Channels</div>

//             <div
//               style={{
//                 display: "flex",
//                 gap: "10px",
//                 color: "#333",
//                 fontFamily: "Inter",
//                 fontSize: "14px",
//                 fontWeight: "400",
//                 flexDirection: "column",
//               }}
//             >
//               {channels &&
//                 channels.length > 0 &&
//                 channels.map((room) => (
//                   <div
//                     key={room.channel_id}
//                     className={styles.checkboxContainer}
//                   >
//                     <input
//                       type="checkbox"
//                       value={room.channel_id}
//                       data-name={room.channel_name}
//                       onChange={handleCheckboxChangeChannel}
//                       style={{ marginRight: "10px" }}
//                       disabled={disabledSelectChannelOptions}
//                       checked={selectedChannel.some(
//                         (selected) =>
//                           String(selected.channel_id) ===
//                           String(room.channel_id)
//                       )}
//                       id={room.channel_id + "-" + room.channel_name}
//                     />
//                     <label htmlFor={room.channel_id + "-" + room.channel_name}>
//                       {room.channel_name}
//                     </label>
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         className={`flex align_items_center flex_gap_20 ${styles.buttonContainer}`}
//       >
//         <BgThemeButton
//           type="submit"
//           onClick={updateStayRestrictions}
//           children={"Update"}
//         />

//         <Button onClick={handleCloseBookingSlider}>Cancel</Button>
//       </div>
//     </>
//   );
// };

// export default InventoryDripFeed;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button } from "@shopify/polaris";
import { BgThemeButton } from "../../UI/Buttons";
import SelectDateRange from "../SelectDateRange/SelectDateRange";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import styles from "./inventorySliderComponents.module.scss";


const InventoryDripFeed = ({
  categories,
  currentDate,
  baseUrl,
  property_id,
  userToken,
  setLoader,
  channels,
  setShow,
  disabledSelectChannelOptions,
  defaultSelectedChannel,
  fetchInfo,
  uniqueChannelId,
  activeApp,
  viewType,
  room_id,
  channel_id,
  propertyCurrentDate,
}) => {
  useEffect(() => {
    if (defaultSelectedChannel && defaultSelectedChannel.length > 0) {
      setSelectedChannel(defaultSelectedChannel);
    }
  }, [defaultSelectedChannel]);

  const fetchInventoryData = async () => {
    if (activeApp === "pms") {
      fetchInfo(property_id?.id, uniqueChannelId);
    } else if (activeApp === "channel_manager") {
      viewType === "channel_view"
        ? fetchInfo(property_id?.id, room_id, viewType)
        : fetchInfo(property_id?.id, channel_id, viewType);
    } else {
    }
  };

  const [dates, setDates] = useState({ from_date: "", to_date: "" });

  const [days, setDays] = useState({});

  const handleDaysChange = useCallback((data) => {
    setDays(data);
  }, []);

  const [inventoryData, setInventoryData] = useState([]);

  const handleCheckboxChange = async (event) => {
    if (dates.from_date === "" || dates.to_date === "") {
      return showErrorToast("Please select a date range");
    }

    const { value, checked } = event.target;
    const roomId = parseInt(value);

    if (checked) {
      //   try {
      //     // Call the API to fetch available rooms for the selected room_id
      //     const response = await fetch(
      //       `${baseUrl}/api/v1/check/availability/${property_id?.id}?check_in=${dates.from_date}&check_out=${dates.to_date}&room_id=${roomId}`,{
      //         headers: {
      //           "Content-Type": "application/json",
      //           Authorization: `Bearer ${userToken}`,
      //         }
      //       }
      //     );
      //     const data = await response.json();

      //     if (data && data.available_rooms !== undefined) {
      setInventoryData([
        ...inventoryData,
        {
          room_id: roomId,
          available_rooms: "",
          //   backend_available_rooms: data.available_rooms,
        },
      ]);
      //     } else {
      //       showErrorToast("Failed to fetch available rooms. Try again.");
      //     }
      //   } catch (error) {
      //     showErrorToast("Error fetching available rooms. Check your network.");
      //   }
    } else {
      // Remove the item from inventoryData if unchecked
      setInventoryData(inventoryData.filter((item) => item.room_id !== roomId));
    }
  };

  const handleInputChange = (event, roomId) => {
    const { value } = event.target;

    setInventoryData(
      inventoryData.map((item) => {
        if (item.room_id === roomId) {
          const backendAvailable = item.backend_available_rooms || 0;
          if (value > backendAvailable) {
            showErrorToast(
              `Input exceeds available rooms: ${backendAvailable}`
            );
            return { ...item, available_rooms: backendAvailable };
          }
          return { ...item, available_rooms: parseInt(value) || "" };
        }
        return item;
      })
    );
  };

  const [selectedChannel, setSelectedChannel] = useState([]);

  const handleCheckboxChangeChannel = (event) => {
    const { value, checked } = event.target;
    const channel_id = value;
    const channel_name = event.target.dataset.name;

    setSelectedChannel((prevSelectedChannels) => {
      if (checked) {
        // Check if the channel is already selected
        const isAlreadySelected = prevSelectedChannels.some(
          (channel) => +channel.channel_id === +channel_id
        );

        if (!isAlreadySelected) {
          // If not already selected, add to the array
          return [...prevSelectedChannels, { channel_id, channel_name }];
        }
      } else {
        // If unchecked, remove from the array
        return prevSelectedChannels.filter(
          (channel) => +channel.channel_id !== +channel_id
        );
      }

      // If already selected and checked, return the current state without changes
      return prevSelectedChannels;
    });
  };
  const inputRef = useRef(null);

  const updateStayRestrictions = async () => {
    // Validation checks
    if (selectedChannel.length === 0) {
      showErrorToast("Please select at least one channel.");
      return;
    }

    if (inventoryData.length === 0) {
      showErrorToast("Please add inventory data.");
      return;
    }

    // for (let item of inventoryData) {
    //   if (!item.available_rooms || item.available_rooms === "") {
    //     showErrorToast(
    //       "Please ensure all selected rooms have available rooms specified."
    //     );
    //     return;
    //   }
    // }
    if (inventoryData.length === 0) {
      return showErrorToast("Please Select Rooms");
    }

    // if (!dates || !dates.from_date || !dates.to_date) {
    //   showErrorToast("Please select both 'from' and 'to' dates.");
    //   return;
    // }

    // if (Object.keys(days).length === 0) {
    //   showErrorToast("Please select at least one day.");
    //   return;
    // }

    if (!inputRef.current?.value) {
      return showErrorToast("Please enter number of rooms");
    }

    const postData = {
      start_date: dates.from_date,
      end_date: dates.to_date,
      channel_id: selectedChannel[0]?.channel_id,
      is_drip_feed_active: 1,
      drip_feed_rooms: inputRef?.current?.value,
      roomIds: inventoryData.map((item) => item.room_id),
    };

    // console.log("postData", postData);

    try {
      setLoader(true);

      const response = await fetch(
        `${baseUrl}/api/v1/update/drip-feed/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setShow(false);
        fetchInventoryData();
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  const handleCloseBookingSlider = () => {
    setShow(false);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  };

  return (
    <>
      <div className={styles.container}>
        <div style={{ width: "100%" }}>
          <div style={{ paddingBottom: "5px" }}>Select Date Range</div>
          <SelectDateRange
            dataFromChild={(from_date, to_date) => {
              setDates({
                from_date: from_date,
                to_date: to_date,
              });
            }}
            minDate={new Date(propertyCurrentDate)}
          />
        </div>
        {/* <hr /> */}
        <div>
          {/* <DaySelectionWeekDays onSaveDates={handleDaysChange} /> */}
        </div>

        <hr />

        <div>
          <div className={styles.headings}>Select Room Categories</div>

          {categories.map((room) => {
            const inventoryItem = inventoryData.find(
              (item) => item.room_id === room.id
            );

            return (
              <div
                key={room.id}
                className="flex justify_content_between"
                style={{ margin: "5px 0" }}
              >
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    name="update_type"
                    onChange={handleCheckboxChange}
                    value={room.id}
                    checked={!!inventoryItem}
                    style={{ marginRight: "10px" }}
                    id={room.id + "-" + room.room_name}
                  />
                  <label htmlFor={room.id + "-" + room.room_name}>
                    {room.room_name}
                  </label>
                </div>

                {/* {inventoryItem && (
                  <input
                    type="number"
                    value={inventoryItem.available_rooms}
                    onChange={(e) => handleInputChange(e, room.id)}
                    className={styles.inputRoomData}
                  />
                )} */}
              </div>
            );
          })}
        </div>

        <hr />

        <div>
          <div className={styles.headings}>No. of Rooms for Drip Feed</div>
          <div>
            <input
              required={true}
              label={"No of Rooms"}
              size={"small"}
              type={"number"}
              ref={inputRef}
              className={styles.dripFeedRooms}
            />
          </div>
        </div>

        <hr />
        <div>
          <div>
            <div className={styles.headings}>Select Channels</div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                color: "#333",
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "400",
                flexDirection: "column",
              }}
            >
              {channels &&
                channels.length > 0 &&
                channels.map((room) => (
                  <div
                    key={room.channel_id}
                    className={styles.checkboxContainer}
                  >
                    <input
                      type="checkbox"
                      value={room.channel_id}
                      data-name={room.channel_name}
                      onChange={handleCheckboxChangeChannel}
                      style={{ marginRight: "10px" }}
                      disabled={disabledSelectChannelOptions}
                      checked={selectedChannel.some(
                        (selected) =>
                          String(selected.channel_id) ===
                          String(room.channel_id)
                      )}
                      id={room.channel_id + "-" + room.channel_name}
                    />
                    <label htmlFor={room.channel_id + "-" + room.channel_name}>
                      {room.channel_name}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`flex align_items_center flex_gap_20 ${styles.buttonContainer}`}
      >
        <BgThemeButton
          type="submit"
          onClick={updateStayRestrictions}
          children={"Update"}
        />

        <Button onClick={handleCloseBookingSlider}>Cancel</Button>
      </div>
    </>
  );
};

export default InventoryDripFeed;
