import React, { useEffect } from "react";
import GoBackButtonCustom from "../../../../Components/GoBackButtonCustom/GoBackButtonCustom";
import {
  LinkButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../../Components/NewCustomComp/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { SvgCancel, SvgDelete, SvgEdit } from "../../../../assets/svgIcons";
import { PrimaryInput } from "../../../../Components/NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import {
  capitalizeFirstLetter,
  formatIndianCurrency,
  getBaseUrl,
} from "../../../../utils/utils";
import { useSelector } from "react-redux";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";

const HousekeepingLaundryRates = () => {
  // Global
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const property_id = useSelector((state) => state.property.value)?.id;
  // Global End

  // All States
  const [type, setType] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [order, setOrder] = React.useState([]);
  const [addItems, setAddItems] = React.useState({
    item_name: "",
    category: "",
    washing: null,
    ironing: null,
    dry_cleaning: null,
    wash_iron: null,
    id: null,
  });
  // All States End

  // All Api's

  // Listing Api
  const fetchInfo = async (propertyId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/laundry/rates/${propertyId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setOrder(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Listing Api End

  // Single Data Api
  const fetchSingleInfo = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/laundry/rates/${property_id}?id=${id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setShow(true);
        setType("update-items");
        setAddItems({
          item_name: data?.data?.item_name,
          category: data?.data?.category,
          washing: data?.data?.washing,
          ironing: data?.data?.ironing,
          dry_cleaning: data?.data?.dry_cleaning,
          wash_iron: data?.data?.wash_iron,
          id: data?.data?.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Single Data Api End

  // Add Api
  const handleSubmit = async () => {
    const formdata = new FormData();
    formdata.append("item_name", addItems.item_name);
    formdata.append("category", addItems.category);
    formdata.append("washing", addItems.washing);
    formdata.append("ironing", addItems.ironing);
    formdata.append("dry_cleaning", addItems.dry_cleaning);
    formdata.append("wash_iron", addItems.wash_iron);
    // formdata.append("file", fileInput.files[0], "[PROXY]");

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/laundry/rates/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setType("");
        setShow(false);
        fetchInfo(property_id);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Add Api End

  // Update Api
  const handleUpdate = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: addItems.id,
      item_name: addItems.item_name,
      category: addItems.category,
      washing: addItems.washing,
      ironing: addItems.ironing,
      dry_cleaning: addItems.dry_cleaning,
      wash_iron: addItems.wash_iron,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/laundry/rates/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Updated Successfully");
        setType("");
        setShow(false);
        fetchInfo(property_id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Update Api End

  // Delete Api
  const handleDelete = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      ids: [id],
    });
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/laundry/rates/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Deleted Successfully");
        fetchInfo(property_id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Delete Api End

  // All Api's End

  //   Functions
  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id);
    }
  }, [property_id]);

  const handleAddItemsChange = (e) => {
    const { name, value } = e.target;
    setAddItems({
      ...addItems,
      [name]: value,
    });
  };

  const handleAddItemsCategoryChange = (e) => {
    const { value } = e.target;
    setAddItems({
      ...addItems,
      category: value,
    });
  };
  // Functions End

  // Console Log
  console.log("order", order);
  console.log("addItems", addItems);

  // Console Log End
  return (
    <div className="main_container">
      <div className="flex justify_content_between">
        <GoBackButtonCustom onClick={() => navigate(-1)} buttonNextText={"Laundry Rates"} />

        <div className="flex flex_gap_10">
          <LinkButton
            icon={
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_13476_1614"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="18"
                  height="19"
                >
                  <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_13476_1614)">
                  <path
                    d="M6 14L9 11.7125L12 14L10.875 10.2875L13.875 8.15H10.2L9 4.25L7.8 8.15H4.125L7.125 10.2875L6 14ZM9 17C7.9625 17 6.9875 16.8031 6.075 16.4094C5.1625 16.0156 4.36875 15.4813 3.69375 14.8063C3.01875 14.1313 2.48438 13.3375 2.09063 12.425C1.69687 11.5125 1.5 10.5375 1.5 9.5C1.5 8.4625 1.69687 7.4875 2.09063 6.575C2.48438 5.6625 3.01875 4.86875 3.69375 4.19375C4.36875 3.51875 5.1625 2.98438 6.075 2.59063C6.9875 2.19687 7.9625 2 9 2C10.0375 2 11.0125 2.19687 11.925 2.59063C12.8375 2.98438 13.6313 3.51875 14.3063 4.19375C14.9813 4.86875 15.5156 5.6625 15.9094 6.575C16.3031 7.4875 16.5 8.4625 16.5 9.5C16.5 10.5375 16.3031 11.5125 15.9094 12.425C15.5156 13.3375 14.9813 14.1313 14.3063 14.8063C13.6313 15.4813 12.8375 16.0156 11.925 16.4094C11.0125 16.8031 10.0375 17 9 17ZM9 15.5C10.675 15.5 12.0938 14.9187 13.2563 13.7563C14.4187 12.5938 15 11.175 15 9.5C15 7.825 14.4187 6.40625 13.2563 5.24375C12.0938 4.08125 10.675 3.5 9 3.5C7.325 3.5 5.90625 4.08125 4.74375 5.24375C3.58125 6.40625 3 7.825 3 9.5C3 11.175 3.58125 12.5938 4.74375 13.7563C5.90625 14.9187 7.325 15.5 9 15.5Z"
                    fill="#3968ED"
                  />
                </g>
              </svg>
            }
            isIconLeft={true}
            title={"Express"}
            size="medium"
            onClick={() => {
              setShow(true);
              setType("express");
            }}
          />
          <SecondaryButton
            title={"Import Rates"}
            onClick={() => {
              setShow(true);
              setType("import-rates");
            }}
            size="medium"
          />

          <PrimaryButton
            title={"Add Items"}
            onClick={() => {
              setShow(true);
              setType("add-items");
            }}
            size="medium"
          />
        </div>
      </div>

      <div className="white_wrapper mt-10">
        <CustomShopifyIndexTable
          headings={[
            "Item Name",
            "Category",
            "Washing",
            "Ironing",
            "Dry Cleaning",
            "Washing & Ironing",
            "Actions",
          ]}
          allItems={order}
          selectedItems={[]}
          setSelectedItems={setOrder}
          showCheckbox={false}
        >
          {order &&
            order.length > 0 &&
            order.map((item, index) => (
              <CustomShopifyIndexTableRow key={index}>
                <CustomShopifyIndexTableCell>
                  {item?.item_name}
                </CustomShopifyIndexTableCell>
                <CustomShopifyIndexTableCell>
                  {capitalizeFirstLetter(item?.category)}
                </CustomShopifyIndexTableCell>
                <CustomShopifyIndexTableCell>
                  {formatIndianCurrency(item?.washing)}
                </CustomShopifyIndexTableCell>
                <CustomShopifyIndexTableCell>
                  {formatIndianCurrency(item?.ironing)}
                </CustomShopifyIndexTableCell>
                <CustomShopifyIndexTableCell>
                  {formatIndianCurrency(item?.dry_cleaning)}
                </CustomShopifyIndexTableCell>
                <CustomShopifyIndexTableCell>
                  {formatIndianCurrency(item?.wash_iron)}
                </CustomShopifyIndexTableCell>
                <CustomShopifyIndexTableCell>
                  <div className="flex flex_gap_10 align_items_center">
                    <span onClick={() => fetchSingleInfo(item?.id)}>
                      <SvgEdit />
                    </span>
                    <span onClick={() => handleDelete(item?.id)}>
                      <SvgDelete />
                    </span>
                  </div>
                </CustomShopifyIndexTableCell>
              </CustomShopifyIndexTableRow>
            ))}
        </CustomShopifyIndexTable>
      </div>

      {/* Add Items Popup */}
      {show && (type === "add-items" || type === "update-items") && (
        <div className="bg-backdrop">
          <div style={{ minWidth: "500px" }} className="white_wrapper">
            <div className="flex justify_content_between align_items_center">
              <h4 className="headings">
                {type === "add-items" ? "Add Item" : "Update Item"}
              </h4>
              <span
                onClick={() => {
                  setShow(false);
                  setType("");
                }}
                className="flex align_items_center pointer"
              >
                <SvgCancel color={"#AAAAAA"} width={"18px"} height={"18px"} />
              </span>
            </div>

            <div className="flex flex_gap_10">
              <PrimaryInput
                label={"Item Name"}
                name={"item_name"}
                value={addItems.item_name}
                onChange={handleAddItemsChange}
                size={"medium"}
              />
              <SelectBasicWithSelectTag
                value={addItems.category}
                label={"Category"}
                options={[
                  { label: "Select Option", value: "" },
                  { label: "Men", value: "men" },
                  { label: "Women", value: "women" },
                  { label: "Kids", value: "kids" },
                  { label: "Others", value: "others" },
                ]}
                onChange={handleAddItemsCategoryChange}
                size={"medium"}
              />
            </div>
            <div className="pb-5 mt-10 border_bottom">
              <h6 style={{ color: "#888", fontSize: "12px" }}>PRICING</h6>
            </div>
            <div className="flex flex_gap_10 mt-10">
              <PrimaryInput
                type={"number"}
                name={"washing"}
                value={addItems.washing}
                label={"Washing"}
                size={"medium"}
                onChange={handleAddItemsChange}
              />
              <PrimaryInput
                type={"number"}
                name={"ironing"}
                value={addItems.ironing}
                label={"Ironing"}
                size={"medium"}
                onChange={handleAddItemsChange}
              />
              <PrimaryInput
                type={"number"}
                name={"wash_iron"}
                value={addItems.wash_iron}
                label={"Washing & Ironing"}
                size={"medium"}
                onChange={handleAddItemsChange}
              />
              <PrimaryInput
                type={"number"}
                name={"dry_cleaning"}
                value={addItems.dry_cleaning}
                label={"Dry Clean"}
                size={"medium"}
                onChange={handleAddItemsChange}
              />
            </div>

            <div className="flex flex_gap_10 mt-10">
              <PrimaryButton
                onClick={type === "update-items" ? handleUpdate : handleSubmit}
                title={type === "update-items" ? "Update" : "Add"}
                size={"medium"}
              />
              <SecondaryButton title={"Cancel"} size={"medium"} />
            </div>
          </div>
        </div>
      )}
      {/* Add Items Popup End */}

      {/* Express Popup */}
      {show && type === "express" && (
        <div className="bg-backdrop">
          <div style={{ minWidth: "300px" }} className="white_wrapper">
            <div className="flex justify_content_between align_items_center">
              <h4 className="headings">Express Rates</h4>
              <span
                onClick={() => {
                  setShow(false);
                  setType("");
                }}
                className="flex align_items_center pointer"
              >
                <SvgCancel color={"#AAAAAA"} width={"18px"} height={"18px"} />
              </span>
            </div>

            <div className="flex flex_gap_10 align_items_center mt-10">
              <label className="global_label">Increase Rates From%</label>
              <PrimaryInput type={"number"} name={""} size={"medium"} />
            </div>
          </div>

          <div></div>
        </div>
      )}
      {/* Express Popup End */}
    </div>
  );
};

export default HousekeepingLaundryRates;
