import React from "react";
import { PrimaryInput } from "../../../Components/NewCustomComp/Inputs/Inputs";
import styles from "./promotionalDates.module.scss";

const Triggers = ({formData , setFormData}) => {
  console.log(formData ,"fromData")

  const handleRadioChange = (trigger, isVisible) => {
    setFormData((prev) => ({
      ...prev,
      triggers: {
        ...prev.triggers,
        [trigger]: { ...prev.triggers[trigger], active: isVisible },
      },
    }));
  };

  const handleInputChange = (trigger, key, value) => {
    setFormData((prev) => ({
      ...prev,
      triggers: {
        ...prev.triggers,
        [trigger]: { ...prev.triggers[trigger], [key]: value },
      },
    }));
  };

  return (
    <div className={styles.container}>
      {/* Occupancy */}
      <div>
        <div className="flex justify_content_between align_items_center">
          <span className={styles.date_type}>Occupancy %</span>
          <span className="flex flex_gap_10">
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="occupancy"
                onChange={() => handleRadioChange("occupancy", true)}
                checked={formData?.triggers?.occupancy.active}
              />
              Yes
            </label>
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="occupancy"
                onChange={() => handleRadioChange("occupancy", false)}
                checked={!formData?.triggers?.occupancy?.active}
              />
              No
            </label>
          </span>
        </div>
        <hr className="mt-10 mb-10"/>
        {formData?.triggers?.occupancy?.active && (
          <div className="flex flex_gap_10 mb-10">
            <div className="w-50">
              <PrimaryInput
                size={"medium"}
                label={"From"}
                required={true}
                onChange={(e) =>
                  handleInputChange("occupancy", "from", e.target.value)
                }
                value={formData?.triggers?.occupancy?.from || ""}
              />
            </div>
            <div className="w-50">
              <PrimaryInput
                size={"medium"}
                label={"To"}
                value={formData?.triggers?.occupancy?.to || ""}
                required={true}
                onChange={(e) =>
                  handleInputChange("occupancy", "to", e.target.value)
                }
              />
            </div>
          </div>
        )}
       
      </div>

      {/* Revenue */}
      <div>
        <div className="flex justify_content_between align_center">
          <span className={styles.date_type}>Revenue</span>
          <span className="flex flex_gap_10" >
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="revenue"
                onChange={() => handleRadioChange("revenue", true)}
                checked={formData?.triggers?.revenue?.active}
              />
              Yes
            </label>
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="revenue"
                onChange={() => handleRadioChange("revenue", false)}
                checked={!formData?.triggers?.revenue?.active}
              />
              No
            </label>
          </span>
        </div>
        <hr className="mt-10 mb-10"/>
        {formData?.triggers?.revenue?.active && (
          <div className="flex flex_gap_10 mb-10">
            <div className="w-100">
              <PrimaryInput
                size={"medium"}
                value={formData?.triggers?.revenue?.limit || ""}
                label={"Revenue Limit"}
                required={true}
                onChange={(e) =>
                  handleInputChange("revenue", "limit", e.target.value)
                }
              />
            </div>
          </div>
        )}
      </div>

      {/* Revenue TGT */}
      <div>
        <div className="flex justify_content_between align_items_center">
          <span className={styles.date_type}>Revenue TGT %</span>
          <span className="flex flex_gap_10">
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="revenueTgt"
                onChange={() => handleRadioChange("revenueTgt", true)}
                checked={formData?.triggers?.revenueTgt?.active}

              />
              Yes
            </label>
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="revenueTgt"
                onChange={() => handleRadioChange("revenueTgt", false)}
                checked={!formData?.triggers?.revenueTgt?.active}
              />
              No
            </label>
          </span>
        </div>
        <hr className="mt-10 mb-10"/>
        {formData?.triggers?.revenueTgt?.active && (
          <div className="flex flex_gap_10 mb-10">
            <div className="w-100">
              <PrimaryInput
                size={"medium"}
                label={"Property Revenue Tgt.%"}
                value={formData?.triggers?.revenueTgt?.percentage || ""}
                required={true}
                onChange={(e) =>
                  handleInputChange("revenueTgt", "percentage", e.target.value)
                }
              />
            </div>
          </div>
        )}
      </div>

      {/* Comp. Rates */}
      <div>
        <div className="flex justify_content_between align_items_center">
          <span className={styles.date_type}>Comp. Rates</span>
          <span className="flex flex_gap_10">
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="compRates"
                onChange={() => handleRadioChange("compRates", true)}
                checked={formData?.triggers?.compRates?.active}
              />
              Yes
            </label>
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="compRates"
                onChange={() => handleRadioChange("compRates", false)}
                checked={!formData?.triggers?.compRates?.active}
              />
              No
            </label>
          </span>
        </div>
        <hr className="mt-10 mb-10"/>
        {formData?.triggers?.compRates?.active && (
          <div className="flex flex_gap_10 mb-10">
            <div className="w-50">
              <PrimaryInput
                size={"medium"}
                label={"From"}
                required={true}
                onChange={(e) =>
                  handleInputChange("compRates", "from", e.target.value)
                }
                value={formData?.triggers?.compRates?.from || ""}
              />
            </div>
            <div className="w-50">
              <PrimaryInput
                size={"medium"}
                label={"To"}
                value={formData?.triggers?.compRates?.to || ""}
                required={true}
                onChange={(e) =>
                  handleInputChange("compRates", "to", e.target.value)
                }
              />
            </div>
          </div>
        )}
      </div>

      {/* Usage */}
      <div>
        <div className="flex justify_content_between align_items_center">
          <span className={styles.date_type} >Usage</span>
          <span className="flex flex_gap_10">
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="usage"
                onChange={() => handleRadioChange("usage", true)}
                checked = {formData?.triggers?.usage?.active}
              />
              Yes
            </label>
            <label className={styles.date_type_end}>
              <input
                type="radio"
                name="usage"
                onChange={() => handleRadioChange("usage", false)}
                checked = {!formData?.triggers?.usage?.active}
              />
              No
            </label>
          </span>
        </div>
        <hr className="mt-10 mb-10"/>
        {formData?.triggers?.usage?.active && (
          <div className="flex flex_gap_10">
            <div className="w-100">
              <PrimaryInput
                size={"medium"}
                label={"Total Usage Count"}
                required={true}
                value={formData?.triggers?.usage?.total || ""}
                onChange={(e) =>
                  handleInputChange("usage", "total", e.target.value)
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Triggers;
