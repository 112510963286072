import React, { useEffect, useState } from "react";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchOrAdd } from "../../NewCustomComp/Search/Search";
import { PrimaryInput } from "../../NewCustomComp/Inputs/Inputs";
import { Textarea } from "../../NewCustomComp/Testarea/Textarea";
import { SelectBasicWithSelectTag } from "../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";

const PrimaryGuestDetails = ({
  guestDetails,
  setGuestDetails,
  errors,
  setErrors,
  hideTopCard,
}) => {
  const [showSalutation, setShowSalutation] = useState(true);
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();
  // get existing customers details

  const [customers, setCustomers] = useState([]);

  const fetchInfo = async (propertyId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/get/guests/customer/${propertyId}`;
    if (!propertyId) {
      navigate("/login");
    }
    try {
      // setLoader(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setCustomers(data.guests);
      // setLoader(false);
    } catch (error) {
      // setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchSalutations();
    }
  }, [property_id]);

  const [showSalutationPopup, setShowSalutationPopup] = useState(false);

  const [salutations, setSalutations] = useState([]);
  const salutationsOptions = [
    { value: "", label: "Select" }, // Default option
    ...salutations.map((item) => ({
      value: item.name,
      label: item.name,
    })),
  ];

  const fetchSalutations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSalutations(data.data);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
  };

  const handleSalutationChange = (e) => {
    const { name, value } = e.target;

    if (value === "other") {
      setShowSalutationPopup(true);
    } else {
      setGuestDetails({
        ...guestDetails,
        salutation: e.target.value,
      });
      setShowSalutationPopup(false);
    }
  };

  const [popUpSalutation, setPopUpSalutation] = useState("");

  const handleGuestDetails = (e) => {
    setGuestDetails({
      ...guestDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleGuestNameFromSearch = (id) => {
    const getDetails = customers.find((item) => +item.id === +id);
    if (getDetails) {
      setShowSalutation(false);
      setGuestDetails((prevDetails) => ({
        ...prevDetails,
        salutation: "",
        name: getDetails?.name || "",
        email: getDetails?.email || "",
        phone: getDetails?.phone || "",
        remarks: "",
      }));
      return;
    } else {
      setShowSalutation(true);
      setGuestDetails((prevDetails) => ({
        ...prevDetails,
        name: id,
        salutation: "",
        email: "",
        phone: "",
        remarks: "",
      }));
      return;
    }
  };

  return (
    <div>
      {!hideTopCard && (
        <>
          <div className="create_booking_primary_guest_details_container">
            <h4>Primary Guest Details</h4>
            <div className="flex flex_gap_10 align_items_center">
              <div className="w-40">
                <div className="flex flex_gap_5 align_items_end">
                  {showSalutation && (
                    <>
                      <SelectBasicWithSelectTag
                        required={true}
                        size={"medium"}
                        options={salutationsOptions}
                        label={"Name"}
                        id="salutation"
                        name="salutation"
                        value={guestDetails.salutation}
                        onChange={handleSalutationChange}
                        className="create_booking_primary_guest_details_input"
                        style={{
                          width: "50px",
                          background: errors.guest_name ? "#FFEAEA" : "",
                          border: errors.guest_name ? "1px solid #E03838" : "",
                        }}
                      />

                      {showSalutationPopup && (
                        <PrimaryInput
                          type="text"
                          required
                          className="create_booking_primary_guest_details_input"
                          name="salutation"
                          value={guestDetails.salutation}
                          onChange={handleGuestDetails}
                          style={{ width: "100px" }}
                          size={"medium"}
                        />
                      )}
                    </>
                  )}

                  <SearchOrAdd
                    size={"medium"}
                    options={customers}
                    optionKeyName={"name"}
                    selectedKeyName={"id"}
                    required={true}
                    label={showSalutation ? "" : "Name"}
                    handleOptionChange={handleGuestNameFromSearch}
                    style={{
                      background: errors.guest_name ? "#FFEAEA" : "",
                      border: errors.guest_name ? "1px solid #E03838" : "",
                    }}
                    defaultSelected={guestDetails.name}
                    pl
                  />
                </div>
              </div>
              <div className="w-30 ">
                <PrimaryInput
                  label={"Phone"}
                  value={guestDetails.phone}
                  name={"phone"}
                  onChange={handleGuestDetails}
                  type={"number"}
                  size={"medium"}
                />
              </div>

              <div className="w-30  " style={{ verticalAlign: "end" }}>
                <PrimaryInput
                  label={"Email"}
                  value={guestDetails.email}
                  name={"email"}
                  onChange={handleGuestDetails}
                  type={"email"}
                  size={"medium"}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <Textarea
          label={"Remarks"}
          size={"medium"}
          value={guestDetails.remarks}
          name={"remarks"}
          onChange={handleGuestDetails}
        />
      </div>
    </div>
  );
};

export default PrimaryGuestDetails;
