import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../../../Components/NewCustomComp/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatIndianCurrency, getBaseUrl } from "../../../../utils/utils";
import { getDecryptedData } from "../../../../utils/encryptStorage";
import Loader from "../../../../Components/UI/Loader/Loader";
import NoDataFound from "../../../../Components/CustomComponents/NoDataFound";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { SvgDelete, SvgEdit } from "../../../../assets/svgIcons";
import ConfirmPopup from "../../../../Components/NewCustomComp/confirmPopup/ConfirmPopup";
import HouseKeepingStatusUpdateSwitch from "./HouseKeepingStatusUpdateSwitch";

const HousekeepingLostFound = () => {
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData("encryptToken");
  const baseUrl = getBaseUrl();
  const navigate = useNavigate();

  const [pageInfo, setPageInfo] = useState({
    data: [],
    Loading: false,
    deleteId: null,
  });

  const fetchData = async () => {
    setPageInfo({ ...pageInfo, Loading: true });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/lost-and-found/${property_id?.id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        setPageInfo({ ...pageInfo, data: result.data, Loading: false });
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchData();
    }
  }, [property_id]);

  const handleDeleteClick = (id) => {
    setPageInfo((prev) => ({ ...prev, deleteId: id }));
  };

  const handleConfirmClick = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const deleteId = pageInfo?.deleteId;
    const raw = JSON.stringify({
      ids: [deleteId],
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/lost-and-found/${property_id?.id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        setPageInfo((prev) => ({
          ...prev,
          data: prev.data.filter((item) => +item.id !== +deleteId),
          deleteId: null,
        }));
        // fetchData();
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  console.log(pageInfo ,"hii")
  return (
    <div>
     <div className="mb-10 flex justify_content_end">
     <PrimaryButton
        size={"medium"}
        title={"Create Service "}
        onClick={() => navigate("/housekeeping/services/lost-found/create")}
      />
     </div>

      {pageInfo?.Loading ? (
        <Loader />
      ) : (
        <div>
          {pageInfo?.data && pageInfo?.data.length > 0 ? (
            <>
              <CustomShopifyIndexTable
                headings={["Service Id", "Date & Time","Booking ID" ,"Article","Approx Value","Status" ,""]}
              >
                {pageInfo?.data.map((item) => (
                  <CustomShopifyIndexTableRow key={item.id}>
                    <CustomShopifyIndexTableCell>
                      # {item?.id} {item.is_important ? <span style={{color:"red" ,fontSize:"25px"}}>.</span> : null}
                    </CustomShopifyIndexTableCell>

                    <CustomShopifyIndexTableCell>
                      {item?.lost_date}  {item?.lost_time}
                    </CustomShopifyIndexTableCell>

                    <CustomShopifyIndexTableCell>
                      {item?.unique_booking_id} 
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item?.article} 
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {formatIndianCurrency(item?.approx_value)} 
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                     <HouseKeepingStatusUpdateSwitch
                      onStatusChange={fetchData}
                      currentStatus={item.status}
                      options={["lost", "found", "returned"]} 
                      apiUrl={`lost-and-found`}   
                      item={item}     
                      propertyId={property_id?.id}            
                     />
                    </CustomShopifyIndexTableCell>



                    <CustomShopifyIndexTableCell>
                      <span className="flex flex_gap_10">
                        <span onClick={() => navigate(`/housekeeping/services/lost-found/edit/${item.id}`) }>
                          <SvgEdit />
                        </span>
                        <span onClick={() => handleDeleteClick(item.id)}>
                          <SvgDelete
                            
                          />
                        </span>
                      </span>
                    </CustomShopifyIndexTableCell>
                  </CustomShopifyIndexTableRow>
                ))}
              </CustomShopifyIndexTable>
            </>
          ) : (
            <NoDataFound />
          )}

          {pageInfo.deleteId && (
            <ConfirmPopup
              heading={"Are you sure?"}
              subHeading={"You won't be able to revert this!"}
              handleConfirmClick={handleConfirmClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HousekeepingLostFound;
