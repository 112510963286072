import React, { useEffect } from "react";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../Components/NewCustomComp/Buttons/Buttons";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { formatIndianCurrency, getBaseUrl } from "../../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import UpliftMenuButton from "../../../../Components/NewCustomComp/UpliftMenuButton/UpliftMenuButton";
import { useSelector } from "react-redux";
import { showSuccessToast } from "../../../../assets/toastUtils";
import HouseKeepingStatusUpdateSwitch from "../housekeepingLostAndFound/HouseKeepingStatusUpdateSwitch";

const HousekeepingLaundry = () => {
  // Global
  const baseUrl = getBaseUrl();
  const navigate = useNavigate();
  const property_id = useSelector((state) => state.property.value)?.id;

  // Global End

  // All States
  const [servicesList, setServicesList] = React.useState([]);
  // All States End

  // All Api's

  // List Service Api
  const listServicesApi = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/service/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setServicesList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Api
  const handleServiceDelete = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      ids: [id],
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/service/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Deleted Successfully");
        listServicesApi(property_id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Delete Api End

  // All Api's End

  //   Functions

  const handleUpliftMenuClick = (option, id) => {
    switch (option) {
      case "View Details":
        navigate(`/housekeeping/services/laundry/view/${id}`);
        // fetchSingleAgentData(id);
        break;

      case "Edit":
        navigate(`/housekeeping/services/laundry/edit/${id}`);
        break;

      case "Delete":
        handleServiceDelete(id);
        break;
    }
  };

  useEffect(() => {
    if (property_id) {
      listServicesApi();
    }
  }, [property_id]);

  // Functions End
  // Console Log
  console.log("servicesList", servicesList);
  // Console Log End

  return (
    <div className="pl-10">
      {/* Heading & Buttons */}
      <div className="flex justify_content_between align_items_center">
        <h2 className="headings">Laundry</h2>
        <div className="flex flex_gap_10">
          <SecondaryButton
            size="medium"
            onClick={() => navigate("/housekeeping/services/laundry/rates")}
            title={"Rates"}
          />
          <PrimaryButton
            size="medium"
            onClick={() => navigate("/housekeeping/services/laundry/create")}
            title={"Create Services"}
          />
        </div>
      </div>
      {/* Heading & Buttons End */}

      {/* Laundry Listing Table */}
      <div className="mt-10">
        <CustomShopifyIndexTable
          headings={[
            "Service ID",
            "Room No.",
            "Assigned To",
            "Total Amount",
            "Status",
            "Settlement Status",
            "",
          ]}
        >
          {servicesList &&
            servicesList.length > 0 &&
            servicesList.map((item, index) => (
              <CustomShopifyIndexTableRow key={index}>
                <CustomShopifyIndexTableCell>
                  <Link style={{ color: "#3968ED" }}>
                    # {item?.unique_service_id}
                  </Link>
                </CustomShopifyIndexTableCell>

                <CustomShopifyIndexTableCell>
                  {item?.room_no}
                </CustomShopifyIndexTableCell>

                {/* <CustomShopifyIndexTableCell>
                  {item?.plan}
                </CustomShopifyIndexTableCell> */}

                <CustomShopifyIndexTableCell>
                  {item?.assign_name}
                </CustomShopifyIndexTableCell>

                <CustomShopifyIndexTableCell>
                  {formatIndianCurrency(item?.grand_total)}
                </CustomShopifyIndexTableCell>

                <CustomShopifyIndexTableCell>
                  {/* {item?.status} */}
                  <HouseKeepingStatusUpdateSwitch
                    currentStatus={item?.status}
                    onStatusChange={listServicesApi}
                    propertyId={property_id}
                    item={item}
                    apiUrl={"service"}
                    options={[
                      "no action",
                      "in progress",
                      "delivered",
                      "canceled",
                    ]}
                  />
                </CustomShopifyIndexTableCell>

                <CustomShopifyIndexTableCell>
                  {item?.settlement_status}
                </CustomShopifyIndexTableCell>

                <CustomShopifyIndexTableCell>
                  <UpliftMenuButton
                    handleUpliftMenuClick={handleUpliftMenuClick}
                    menuOptions={["View Details", "Edit", "Delete"]}
                    id={item?.id}
                  />
                </CustomShopifyIndexTableCell>
              </CustomShopifyIndexTableRow>
            ))}
        </CustomShopifyIndexTable>
      </div>
      {/* Laundry Listing Table End */}
    </div>
  );
};

export default HousekeepingLaundry;
