import React, { useEffect } from "react";
import styles from "../HousekeepingStyles.module.scss";
import { SelectBasicWithSelectTag } from "../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { capitalizeFirstLetter, getBaseUrl } from "../../../utils/utils";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { useSelector } from "react-redux";
import { PrimaryButton } from "../../../Components/NewCustomComp/Buttons/Buttons";
import { showSuccessToast } from "../../../assets/toastUtils";

const HousekeepingDashboardAssignRooms = ({ heading, dashboardData, type,refreshDashboard }) => {
  // All Hooks
  const baseUrl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value)?.id;
  // All Hooks End

  // All States
  const [staffList, setStaffList] = React.useState([]);
  const [roomsData, setRoomsData] = React.useState([]);
  const [selectedRooms, setSelectedRooms] = React.useState([]);
  const [cleanedRooms, setCleanedRooms] = React.useState([]);
  const [firstSelectedStaffId, setFirstSelectedStaffId] = React.useState(null);
  const [status, setStatus] = React.useState("");

  // All States End

  // All Api's

  // Listing Staff
  const fetchStaff = async (propertyId) => {
    try {
      const response = await fetch(
        // /api/v1/account/get-house-keeping/10
        `${baseUrl}/api/v1/account/get-house-keeping/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setStaffList(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Listing Staff End

  //   Listing Under Cleaning
  const fetchRoomUnderCleaning = async (dayTypeFilter) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/get/room/under/cleaning/${property_id}?dayTypeFilter=${dayTypeFilter}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setRoomsData(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // Listing Under Cleaning End

  //   Handle Update Assign Room Status
  const handleUpdateAssignRoomStatus = async (status) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const data = roomsData.flatMap((room) =>
      room.housekeepings.map((housekeeping) => ({
        room_id: housekeeping?.room_id,
        room_no: housekeeping?.room_no,
        id: housekeeping?.id,
        housekeepingStaff_id: housekeeping?.housekeepingStaff_id
      }))
    );

    
    const apiRoomData = data
      .filter((room) => selectedRooms.includes(room.id)) // Filter rooms based on selectedRooms
      .map((room) => ({
        room_id: room.room_id,
        room_no: room.room_no,
        id: room.id
      })); // Map to the required structure

      console.log("apiRoomData",apiRoomData)


    const raw = JSON.stringify({
      // housekeepingStaff_id: 31,
      status: status,
      housekeeping: apiRoomData,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        // `${baseUrl}/api/v1/check/housekeeping/room/status/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Updated Successfully");
        fetchRoomUnderCleaning(90);
        fetchCleanedRooms();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  
  const handleUpdateDirtyAssignRoomStatus = async (status) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const data = dashboardData?.current_dirty_rooms?.flatMap((room) =>
      room.housekeepings.map((housekeeping) => ({
        room_id: housekeeping.room_id,
        room_no: housekeeping.room_no,
        id: housekeeping.id,
      }))
    );
    const apiRoomData = data
      .filter((room) => selectedRooms.includes(room.id)) // Filter rooms based on selectedRooms
      .map((room) => ({
        room_id: room.room_id,
        room_no: room.room_no,
      })); // Map to the required structure

    const raw = JSON.stringify({
      housekeepingStaff_id: firstSelectedStaffId,
      status: status,
      housekeeping: apiRoomData,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/create/housekeeping/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Updated Successfully");
        fetchRoomUnderCleaning(90);
        refreshDashboard(90);
        fetchCleanedRooms();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // Handle Update Assign Room Status End

  // Listing Clean Room
  const fetchCleanedRooms = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/get/cleaned/room/${property_id}`,
        requestOptions
      );
      const data = await response.json();

      if (data.success) {
        console.log(data)
        setCleanedRooms(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // Listing Clean Room End

  // All Api's End

  // All Functions

  useEffect(() => {
    if (property_id) {
      fetchStaff(property_id);
      if (type === "cleaning") {
        fetchRoomUnderCleaning(+90);
      }
      if (type === "cleaned") {
        fetchCleanedRooms();
      }
    }
  }, [property_id]);

  const assignedToOptions = [
    { label: "Assign To", value: "" },
    ...staffList.map((staff) => ({
      label: staff.name,
      value: staff.id,
    })),
  ];

  // const handleSelectRooms = (id, room_no) => {
  //   const roomData = { room_id: +id, room_no };

  //   setSelectedRooms((prev) => {
  //     const exists = prev.find((room) => room.room_id === roomData.room_id);

  //     if (exists) {
  //       // Remove the room if already selected
  //       return prev.filter((room) => room.room_id !== roomData.room_id);
  //     } else {
  //       // Add the room if not already selected
  //       return [...prev, roomData];
  //     }
  //   });
  // };

  const handleSelectRooms = (id) => {
    const value = +id;
    setSelectedRooms((prev) => {
      if (prev.includes(value)) {
        return prev.filter((id) => id !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  // State for storing the housekeepingStaff_id of the first selected room

  const handleSelectAllRooms = (e) => {
    const { checked } = e.target;

    if (checked) {
      const allRoomIds = roomsData.flatMap((room) =>
        room.housekeepings.map((housekeeping) => housekeeping.id)
      );
      setSelectedRooms(allRoomIds);
    } else {
      setSelectedRooms([]);
    }
    // const allRoomIds = roomsData.flatMap((room) =>
    //   room.housekeepings.map((housekeeping) => housekeeping.id)
    // );

    // if (selectedRooms.length === allRoomIds.length) {
    //   setSelectedRooms([]);
    // } else {
    //   setSelectedRooms(allRoomIds);
    // }
  };

  // All Functions End

  // Console.log
  console.log("roomsData", roomsData);
  console.log("selectedRooms", selectedRooms);
  console.log("cleanedRooms", cleanedRooms);

  return (
    <div>
      <h4 className="headings">{heading}</h4>

      {type === "dirty" && (
        <div>
          {/* Assign / Edit */}
          <div className="flex flex_gap_10 mt-10">
            <div className="flex align_items_center flex_gap_5">
              <input type="checkbox" /> Select All
            </div>
            <div className="w-20">
              <SelectBasicWithSelectTag
                options={assignedToOptions}
                size={"medium"}
                value={firstSelectedStaffId}
                onChange={(e) => setFirstSelectedStaffId(e.target.value)}
              />
            </div>
            <div className="w-20">
              <SelectBasicWithSelectTag
                options={[
                  { label: "Assign For", value: "" },
                  { label: "Cleaning", value: "cleaning" },
                  { label: "Inspect", value: "inspect" },
                  { label: "Dirty", value: "dirty" },
                  { label: "Touch Up", value: "touchup" },
                ]}
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                size={"medium"}
              />
            </div>
            <PrimaryButton onClick={()=>handleUpdateDirtyAssignRoomStatus(status)}  title={"Assign"} size="medium" />
          </div>
          {/* Assign / Edit */}

          <div className={`${styles.room_box} mt-10`}>
            {dashboardData?.current_dirty_rooms?.map((room, index) => (
              <div className={styles.room_box_container} key={index}>
                <h4>{room?.room_name}</h4>

                <div className={`${styles.room_box_row}`}>
                  {room?.housekeepings.length > 0 ?
                    room?.housekeepings?.map((roomDetails, innerIndex) => (
                      <div
                        onClick={() =>
                          handleSelectRooms(roomDetails?.id)
                        }
                        key={innerIndex}
                        className={`${styles.room_box_dirty} ${
                          selectedRooms &&
                          selectedRooms.length > 0 &&
                          selectedRooms.includes(roomDetails?.id)
                            ? styles.selected
                            : ""
                        }`}
                      >
                        {roomDetails.room_no}
                        <h6>{capitalizeFirstLetter(roomDetails?.status)}</h6>
                      </div>
                    )) : "No Dirty Rooms To Be Found"}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {type === "cleaning" && (
        <div>
          {/* Assign / Edit */}
          <div className="flex flex_gap_15 mt-10">
            <div className="flex align_items_center flex_gap_5">
              <input
                checked={
                  selectedRooms.length ===
                  roomsData.flatMap((room) =>
                    room.housekeepings.map((housekeeping) => housekeeping.id)
                  ).length
                }
                onChange={handleSelectAllRooms}
                type="checkbox"
              />{" "}
              Select All
            </div>

            <div className="w-20">
              <PrimaryButton
                onClick={() =>
                  // setStatus("clean"),
                  handleUpdateAssignRoomStatus("Cleaned")
                }
                title={"Mark as Clean"}
                size="medium"
              />
            </div>
          </div>
          {/* Assign / Edit */}

          <div className={`${styles.room_box} mt-10`}>
            {roomsData?.map((room, index) => (
              <div className={styles.room_box_container} key={index}>
                <h4>{room?.room_name}</h4>

                <div className={`${styles.room_box_row}`}>
                  {room?.housekeepings.length > 0 ?
                    room?.housekeepings?.map((roomDetails, innerIndex) => (
                      <div
                        onClick={() => handleSelectRooms(roomDetails?.id)}
                        key={innerIndex}
                        className={`${styles.room_box_occupied} ${
                          selectedRooms &&
                          selectedRooms.length > 0 &&
                          selectedRooms.includes(roomDetails?.id)
                            ? styles.selected
                            : ""
                        }`}
                      >
                        <h5>{roomDetails.room_no}</h5>
                        <h6>{capitalizeFirstLetter(roomDetails?.status)}</h6>
                      </div>
                    )):"No Rooms To Be In Cleaning Process"}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {type === "cleaned" && (
        <div>
          {/* Assign / Edit */}
          <div className="flex flex_gap_15 mt-10">
            {/* <div className="flex align_items_center flex_gap_5">
              <input
                type="checkbox"
              />{" "}
              Select All
            </div> */}

            {/* <div className="w-20">
              <PrimaryButton
                onClick={() => handleUpdateAssignRoomStatus("Cleaning")}
                title={"Mark as Inspected"}
                size="medium"
              />
            </div> */}
          </div>
          {/* Assign / Edit */}

          <div className={`${styles.room_box} mt-10`}>
            {cleanedRooms?.map((room, index) => (
              <div className={styles.room_box_container} key={index}>
                <h4>{room?.room_name}</h4>

                <div className={`${styles.room_box_row}`}>
                  {room?.housekeepings.length > 0 ?
                    room?.housekeepings?.map((roomDetails, innerIndex) => (
                      <div
                        onClick={() => handleSelectRooms(roomDetails?.id)}
                        key={innerIndex}
                        className={`${styles.room_box_cleaned} ${
                          selectedRooms &&
                          selectedRooms.length > 0 &&
                          selectedRooms.includes(roomDetails?.id)
                            ? styles.selected
                            : ""
                        }`}
                      >
                        <h5>{roomDetails.room_no}</h5>
                        <h6>{capitalizeFirstLetter(roomDetails?.status)}</h6>
                      </div>
                    )):"No Cleaned Rooms"}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HousekeepingDashboardAssignRooms;
