import React, { useEffect } from "react";
import styles from "../HousekeepingStyles.module.scss";
import HousekeepingDashboardAssignRooms from "./HousekeepingDashboardAssignRooms";
import { getBaseUrl } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../../utils/encryptStorage";

const HousekeepingManagerDashboard = () => {
  // All Hooks
  const baseUrl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value)?.id;
  // All Hooks End

  // All States
  const [dashboard, setDashboard] = React.useState([]);
  const [staffs, setStaffs] = React.useState([]);
  // All States End

  // All Api's

  //   Dashboard Api
  const dashboardApi = async (dayTypeFilter) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/dashboard/${property_id}?dayTypeFilter=${dayTypeFilter}`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "success") {
        setDashboard(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  //   Dashboard Api End



  // All Api's End

  // All Functions
  useEffect(() => {
    if (property_id) {
      dashboardApi(+90);

    }
  }, [property_id]);

  // All Functions End

  // Console.logs
  console.log("dashboard", dashboard);
  return (
    <div>
      <div className="card-3d">
        <div className="flex align_items_center justify_content_between">
          <h4 className="headings">Rooms Status</h4>
          <div>
            Total Rooms: <span>{}</span>
          </div>
        </div>
        <div className="border p-15 border_radius_8 mt-10">
          <div className="flex flex_gap_10">
            <div className="w-25">
              <div
                className={`${styles.clean} text-center py-10 w-100 border_radius_4`}
              >
                Clean
              </div>
              <div className="w-100 text-center">{dashboard?.clean}</div>
            </div>
            <div className="w-25">
              <div
                className={`${styles.dirty} text-center py-10 w-100 border_radius_4`}
              >
                Dirty
              </div>
              <div className="w-100 text-center"> {dashboard?.dirty}</div>
            </div>
            <div className="w-25">
              <div
                className={`${styles.occupied} text-center py-10 w-100 border_radius_4`}
              >
                Occupied
              </div>
              <div className="w-100 text-center"> {dashboard?.occupied}</div>
            </div>
            <div className="w-25">
              <div
                className={`${styles.out_of_order} text-center py-10 w-100 border_radius_4`}
              >
                Out Of Order
              </div>
              <div className="w-100 text-center"> {dashboard.pickeup}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <HousekeepingDashboardAssignRooms type={"dirty"} dashboardData={dashboard} heading={"Rooms For Cleaning"} refreshDashboard={dashboardApi} />
      </div>
      <div className="mt-20">
        <HousekeepingDashboardAssignRooms type={"cleaning"} dashboardData={dashboard} heading={"Rooms Under Cleaning/Touch-Up"} />
      </div>
      <div className="mt-20">
        <HousekeepingDashboardAssignRooms type={"cleaned"} dashboardData={dashboard} heading={"Cleaned Rooms"} />
      </div>
    </div>
  );
};

export default HousekeepingManagerDashboard;
