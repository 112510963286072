import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import styles from "./MultiDatePicker.module.scss";
import "./styles.css";
import { formatDateYYYYMMDD } from "../../../utils/utils";

const MultiDatePicker = ({
  onDatesChange = (dates) => console.log("Selected dates:", dates),
  defaultDates = [],
  min,
  max,
  datesFromApi = [],
  deletePromotionEntities,
}) => {
  const [dates, setDates] = useState([]);


  // Initialize dates only once with defaultDates
  useEffect(() => {
    if (defaultDates.length > 0) {
      const uniqueDefaultDates = Array.from(
        new Set(defaultDates.map((date) => new Date(date).toISOString()))
      ).map((isoDate) => new Date(isoDate));

      const currentDatesSet = new Set(dates.map((date) => date.toISOString()));
      const isDifferent = uniqueDefaultDates.some(
        (date) => !currentDatesSet.has(date.toISOString())
      );

      if (isDifferent) {
        setDates(uniqueDefaultDates);
        onDatesChange(
          uniqueDefaultDates.map((date) => formatDateYYYYMMDD(date))
        );
      }
    }
  }, [defaultDates, dates, onDatesChange]);

  // Handle date selection
  const handleDatesChange = (newDates) => {
    const uniqueDates = Array.from(
      new Set(newDates.map((date) => new Date(date).toISOString()))
    ).map((isoDate) => new Date(isoDate));

    setDates(uniqueDates);
    const formattedDates = uniqueDates.map((date) => formatDateYYYYMMDD(date));

    onDatesChange(formattedDates);
  };

  const removeDate = async (dateToRemove) => {
    const updatedDates = dates.filter(
      (date) => date.toISOString() !== dateToRemove.toISOString()
    );
    const formattedDates = updatedDates.map((date) => formatDateYYYYMMDD(date));

    if (datesFromApi.length > 0) {
      const foundDate = datesFromApi.find(
        (apiDate) =>
          formatDateYYYYMMDD(apiDate.date) === formatDateYYYYMMDD(dateToRemove)
      );

      console.log("foundDate", foundDate);

      if (foundDate) {
        try {
          const response = await deletePromotionEntities(
            "blackout-dates",
            foundDate.id
          );

          if (response.success) {
            // Update state only if the API call is successful
            setDates(updatedDates);
            onDatesChange(formattedDates);
          } else {
            console.error("API response was not successful:", response);
          }
        } catch (error) {
          console.error("Error calling API:", error);
        }
      }
    } else {
      // If there are no datesFromApi, directly update the state
      setDates(updatedDates);
      onDatesChange(formattedDates);
    }
  };

 

  return (
    <div>
      <div style={{ fontSize: "14px", marginBottom: "3.5px" }}>
        Blackout Dates
      </div>
      <div className={styles.selectTagContainer}>
        <DatePicker
          multiple
          value={dates}
          onChange={handleDatesChange}
          className="blue"
          placeholder="Select multiple dates"
          minDate={min ? new Date(min) : null}
          maxDate={max ? new Date(max) : null}
        />
      </div>

      <div className={`${styles["selected-dates-container"]} mt-10`}>
        {dates.length > 0 ? (
          dates.map((date, index) => (
            <div className={styles["date-item"]} key={index}>
              <span>{formatDateYYYYMMDD(date)} </span>

              <button
                className={styles["remove-btn"]}
                onClick={() => removeDate(date)}
              >
                &times;
              </button>
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center", margin: "5px 0" }}>
            No dates selected
          </p>
        )}
      </div>
    </div>
  );
};

export default MultiDatePicker;
