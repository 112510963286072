import React, { useEffect } from "react";
import styles from "../HousekeepingStyles.module.scss";
import HousekeepingDashboardAssignRooms from "./HousekeepingDashboardAssignRooms";
import { getBaseUrl } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../../utils/encryptStorage";
import Loader from "../../../Components/UI/Loader/Loader";

const HousekeepingManagerDashboard = () => {
  // All Hooks
  const baseUrl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value)?.id;
  // All Hooks End

  // All States
  const [dashboard, setDashboard] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
const [count, setCount] = React.useState([]);
  // All States End

  // All Api's

  //   Dashboard Api
  const dashboardApi = async (dayTypeFilter) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/dashboard/${property_id}?dayTypeFilter=${dayTypeFilter}`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "success") {
        setDashboard(data);
        setLoader(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  //   Dashboard Api End

  // Room Count Api
const roomCount = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const response = await fetch(`${baseUrl}/api/v1/house-keeping/room-status/${property_id}`, requestOptions);
    const data = await response.json();
    if(data.success){
      setCount(data?.data)
    }
  } catch (error) {
    console.log("error", error);
  }
}
  // Room Count Api End

  // All Api's End

  // All Functions
  useEffect(() => {
    if (property_id) {
      dashboardApi(+90);
      roomCount();
    }
  }, [property_id]);

  // All Functions End

  // Console.logs
  console.log("count", count);
  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="card-3d">
            <div className="flex align_items_center justify_content_between">
              <h4 className="headings">Rooms Status</h4>
              <div>
                Total Rooms:{" "}
                <span>
                  {dashboard?.clean + dashboard?.dirty + dashboard?.occupied}
                </span>
              </div>
            </div>
            <div className="border p-15 border_radius_8 mt-10">
              <div className="flex flex_gap_10">
                <div className="w-25">
                  <div
                    className={`${styles.clean} text-center py-10 w-100 border_radius_4`}
                  >
                    Clean
                  </div>
                  <div className="w-100 text-center">{count?.Cleaned}</div>
                </div>
                <div className="w-25">
                  <div
                    className={`${styles.dirty} text-center py-10 w-100 border_radius_4`}
                  >
                    Dirty
                  </div>
                  <div className="w-100 text-center"> {count?.dirty}</div>
                </div>
                <div className="w-25">
                  <div
                    className={`${styles.occupied} text-center py-10 w-100 border_radius_4`}
                  >
                    Occupied
                  </div>
                  <div className="w-100 text-center">
                    {" "}
                    {count?.occupied}
                  </div>
                </div>
                <div className="w-25">
                  <div
                    className={`${styles.out_of_order} text-center py-10 w-100 border_radius_4`}
                  >
                    Under Cleaning
                  </div>
                  <div className="w-100 text-center"> {count.cleaning}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-20">
            <HousekeepingDashboardAssignRooms
              type={"dirty"}
              dashboardData={dashboard}
              heading={"Rooms For Cleaning"}
              refreshDashboard={dashboardApi}
            />
          </div>

          <div className="mt-20">
            <HousekeepingDashboardAssignRooms
              type={"cleaning"}
              dashboardData={dashboard}
              heading={"Rooms Under Cleaning/Touch-Up"}
            />
          </div>

          <div className="mt-20">
            <HousekeepingDashboardAssignRooms
              type={"cleaned"}
              dashboardData={dashboard}
              heading={"Cleaned Rooms"}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default HousekeepingManagerDashboard;
