import React, { useEffect, useRef, useState } from "react";
import styles from "./HouseKeepingStatusUpdateSwitch.module.scss";
import { SvgAccordion } from "../../../../assets/svgIcons";
import { getDecryptedData } from "../../../../utils/encryptStorage";
import { getBaseUrl } from "../../../../utils/utils";
import { showErrorToast, showSuccessToast } from "../../../../assets/toastUtils";
import { showErrorCustomToast } from "../../../../Components/CustomComponents/CustomToastify/ToastFunctions";

const HouseKeepingStatusUpdateSwitch = ({
  currentStatus = "lost",
  onStatusChange = () => {},
  options = [],
  apiUrl,
  item,
  propertyId,
}) => {
  const userToken = getDecryptedData("encryptToken");
  const baseUrl = getBaseUrl();
  const [status, setStatus] = useState(currentStatus);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setStatus(currentStatus?.replaceAll("_", " ")?.toLowerCase());
    setDropdownOpen(false);
  }, [currentStatus]);

  const handleStatusChange = async (newStatus) => {
    if (apiUrl && item) {
      updateStatus(item, newStatus);
    } else {
      onStatusChange && onStatusChange(newStatus);
    }
  };

  const getBackgroundColor = () => {
    switch (status.toLowerCase()) {
      // lost and found
      case "lost":
        return "#BD941B";
      case "found":
        return "#3968ED";
      case "returned":
        return "#15AA12";
      // lost and found end

      // laundry start

      case "no action":
        return "#888888";
      case "in progress":
        return "#3968ED";
      case "delivered":
        return "#15AA12";
      case "cancelled":
        return "#E03838";

      // laundry start end

      default:
        return "#d4d4d4"; // Default gray
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const updateStatus = async (item, status) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const formData = {
      ...item,
      status: status.toLowerCase().replaceAll(" ", "_"),
    };

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/${apiUrl}/${propertyId}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
       showSuccessToast("Status Updated Successfully")
        onStatusChange && onStatusChange(status);
      }
    } catch (error) {
      console.error("API Error:", error.message);
      showErrorToast("Failed to update status");
    }
  };

  return (
    <div className={styles.container} ref={dropdownRef}>
      <div
        onClick={() => {
          if (
            status.toLowerCase() !== "returned" &&
            status.toLowerCase() !== "delivered"
          ) {
            setDropdownOpen(!isDropdownOpen);
          }
        }}
        className={`${styles.toggleButton} flex align_items_center flex_gap_5`}
        style={{ backgroundColor: getBackgroundColor() }}
      >
        {status}
        {(status.toLowerCase() !== "returned" &&
          status.toLowerCase() !== "delivered") && (
            <span
              className="flex align_items_center"
              style={isDropdownOpen ? { transform: "rotate(180deg)" } : {}}
            >
              <SvgAccordion color="#fff" />
            </span>
          )}
      </div>

      {/* Dropdown Options */}
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          {options.map((option) => (
            <div
              key={option}
              onClick={() =>
                (status.toLowerCase() !== "returned" ||
                  status.toLowerCase() !== "delivered") &&
                handleStatusChange(option)
              }
              className={`${styles.option} ${
                status.toLowerCase() === option.toLowerCase()
                  ? styles.active
                  : ""
              }`}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HouseKeepingStatusUpdateSwitch;
