import React from 'react'
import NavTabs from '../../../Components/NewCustomComp/NavTabs/NavTabs'
import { Outlet } from 'react-router-dom'

const HousekeepingServicesPage = () => {

    const mainData=[
        {label:"Laundry",to:"laundry"},
        {label:"Lost & Found",to:"lost-found"},
        {label:"Mini Bar",to:"mini-bar"},
    ]
  return (
    <div className='main_container'>
      <NavTabs mainData={mainData}/>

      <div className='mt-10'>
        <Outlet/>
      </div>
    </div>
  )
}

export default HousekeepingServicesPage
