import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import React, { useState } from "react";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { SvgGetInfo } from "../../assets/svgIcons";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
  getDateByMonth,
} from "../../utils/utils";

const ReportCancelTable = ({
  departureReport,
  getSingleBookingDataHandler,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const [informationPopup, setInformationPopup] = useState(false);

  const extractedData = departureReport?.map((item) => ({
    unique_booking_id: item?.unique_booking_id,
    guest_name: item?.guest[0]?.guest_name,
    updated_at: item?.updated_at,
    check_in: item?.check_in,
    check_out: item?.check_out,
    room_type_name: item?.items[0]?.room_type_name,
    no_nights: item?.items[0]?.no_nights,
    status: item?.status?.replace(/_/g, " "),
  }));

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Name",
      updated_at: "Cancel Date",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      room_type_name: "Room category",
      no_nights: "No. Of Nights",
      status: "Status",
    },
  ];
  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>
          <span
            className="pointer"
            style={{
              color: "rgba(57, 104, 237, 1)",
            }}
            onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
          >
            #{order.unique_booking_id || ""}
          </span>
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.guest[0]?.guest_name}
        </CustomReportTableCell>
        <CustomReportTableCell>
         
          {getDateByMonth(order.created_at)} 
        </CustomReportTableCell>
        <CustomReportTableCell>
          {
            getDateByMonth(order.check_in)
          }
        </CustomReportTableCell>
        <CustomReportTableCell>
        {getDateByMonth(order.check_out)}
          {/* {getDateByMonth(order?.updated_at?.slice(0, 10))} */}
        </CustomReportTableCell>
        <CustomReportTableCell>
        {order?.items.map((item, index) => (
            <React.Fragment key={index}>
              {item.room_type_name} {index < order?.items.length - 1 && ", "}
            </React.Fragment>
          ))}
        </CustomReportTableCell>
        <CustomReportTableCell>
        {order?.items[0]?.no_nights}
        </CustomReportTableCell>
        {/* <CustomReportTableCell>
          {order?.items[0]?.no_nights}
        </CustomReportTableCell> */}
        <CustomReportTableCell>{formatIndianCurrency(order?.balance_amount)}</CustomReportTableCell>

        <CustomReportTableCell>
          <StatusUpdate status={order.status} /> {}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      

      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "balance_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div" id="receipt">
          <div
            className="flex flex_gap_5 "
            id="pdf"
            style={{ alignItems: "unset" }}
          >
            <div className="room-type-heading">Booking Cancellation Report</div>
            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
              id="ignoreFromPrint"
            >
              <SvgGetInfo />
            </div>
          </div>
          {departureReport?.length > 0 && (
            <div className="flex flex_gap_15" id="ignoreFromPrint">
              {/* <DownloadPdfTables
                tableData={extractedData}
                tableNames={names}
                downloadFileName={"cancellation_report"}
              /> */}
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={`cancellation_report`}
                ignoreFromPrint={"ignoreFromPrint"}
                pdfOrientation={"landscape"}
                longPdf={true}
              />
              {/* <DownloadCsvTables tableData={extractedData} names={names} />
               */}
              <DownloadCSVButton
                downloadFileName={"cancellation_report"}
                itemToDownloadID={"receipt"}
              />
            </div>
          )}
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>
            <CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Booking Date",
                "Check In Date",
                "Check Out Date",
                "Room Type",
                "No Of Nights",
                "Balance",
                "Status",
              ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Booking Cancellation Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is the Booking Cancellation Report?",
                  answer: (
                    <p>
                      The <b>Booking Cancellation Report</b> provides a detailed
                      overview of all bookings that have been canceled within a
                      selected date range. This report is essential for tracking
                      and analyzing cancellations, helping to identify trends,
                      potential causes, and opportunities to minimize future
                      cancellations.
                    </p>
                  ),
                },
                {
                  question: "Why is the Booking Cancellation Report Important?",
                  answer: (
                    <div>
                      <p>
                        The Booking Cancellation Report plays a crucial role in managing
                        your hotel’s performance and optimizing operations.
                        Here’s why it's important:
                      </p>

                      <ul>
                        <li>
                          <b>Understand Guest Behavior: </b>Cancellations
                          directly impact occupancy and revenue, so staying
                          informed helps adjust forecasts more accurately.
                        </li>
                        <li>
                          <b>Improve Revenue Forecasting: </b>By identifying
                          patterns, such as high cancellation rates during
                          specific periods, you can take proactive measures to
                          reduce them.
                        </li>
                        <li>
                          <b>Minimize Future Cancellations: </b>Prepare for
                          special requests or VIP guests in advance, enhancing
                          the guest experience.
                        </li>
                        <li>
                          <b>Resource Optimization: </b>Knowing in advance which
                          rooms will become available can help reallocate
                          resources and open up booking opportunities for other
                          guests.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportCancelTable;
