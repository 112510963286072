import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./promotionsDiscounts.module.scss";
import { SvgCampaign, SvgInfo, SvgPlus } from "../../assets/svgIcons";
import { getDecryptedData } from "../../utils/encryptStorage";
import { useSelector } from "react-redux";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import Pagination from "../../Components/NewCustomComp/pagination/Pagination";
import NoDataFound from "../../Components/CustomComponents/NoDataFound";
import UpliftMenuButton from "../../Components/NewCustomComp/UpliftMenuButton/UpliftMenuButton";
import ConfirmPopup from "../../Components/NewCustomComp/confirmPopup/ConfirmPopup";
import { showSuccessToast } from "../../assets/toastUtils";
import { formatDateYYYYMMDD, formatIndianCurrency } from "../../utils/utils";

const PromotionsDiscounts = () => {
  const userToken = getDecryptedData("encodedToken");
  const property_id = useSelector((state) => state.property.value)?.id;
  const navigate = useNavigate();

  const options = [
    {
      label: "Basic Promotion",
      link: "/promotions/discounts/create-promotion?type=basic-promotion",
      info: "Offer basic discounts to attract a broader audience.",
    },
    {
      label: "Last Minute Deal",
      link: "/promotions/discounts/create-promotion?type=last-minute-deal",
      info: "Encourage last-minute bookings with special discounts.",
    },
    {
      label: "Early Bird Offer",
      link: "/promotions/discounts/create-promotion?type=early-bird-offer",
      info: "Reward customers who book well in advance.",
    },
    {
      label: "Extend Stay Rewards",
      link: "/promotions/discounts/create-promotion?type=extend-stay-rewards",
      info: "Provide incentives for guests who extend their stay.",
    },
    {
      label: "Coupon Discount",
      link: "/promotions/discounts/create-promotion?type=coupon-discount",
      info: "Allow customers to redeem coupons for exclusive discounts.",
    },
  ];

  const [data, setData] = useState("");

  const fetchPromotions = async (page) => {
    const url = page
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/get-promotion/${property_id}?page=${page}`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/get-promotion/${property_id}`;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();

      if (data.success) {
        setData(data.data);
      } else {
        setData("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchPromotions();
    }
  }, [property_id]);

  const handlePageChange = (page) => {
    fetchPromotions(page);
  };

  const menuOptions = ["Edit", "Delete"];

  const [deleteId, setDeleteId] = useState("");

  const handleUpliftMenuClick = (option, id) => {
    if (option === "Edit") {
      const type = data.data.find((item) => item.id === id).promotion_type;
      navigate(
        `/promotions/discounts/edit-promotion/${id}?type=${type.replaceAll(
          "_",
          "-"
        )}`
      );
      return;
    }
    if (option === "Delete") {
      setDeleteId(id);
      return;
    }
  };

  const handleDeletePromotion = async () => {
    const raw = JSON.stringify({
      ids: [deleteId],
    });
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/delete/promotion/${property_id}`;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();

      if (data.success) {
        fetchPromotions();
        showSuccessToast(data.message);
        setDeleteId("");
      }
    } catch (error) {
      console.log(error);
      showSuccessToast(error.message);
    }
  };
  function getPromotionCounts(promotions, promotionType) {
    let totalPromotions = 0;
    let activePromotions = 0;

    promotions.forEach((promotion) => {
      if (promotion.promotion_type === promotionType) {
        totalPromotions++;
        if (promotion.is_active === 1) {
          activePromotions++;
        }
      }
    });

    return {
      total: totalPromotions,
      active: activePromotions,
    };
  }

  return (
    <div className="">
      <div className={`${styles.promotionsContainer} mt-10 mb-10`}>
        {options.map((item, index) => (
          <div key={index} className={styles.promotionCard}>
            <div className={styles.promotionHeader}>
              <SvgCampaign />

              <span>{item.label}</span>

              <div className={styles.iconContainer}>
                <SvgInfo />
                <span
                  className={
                    index === options.length - 1
                      ? styles.tooltipLast
                      : styles.tooltip
                  }
                >
                  {item.info}
                </span>
              </div>
            </div>
            <hr className="mt-10 mb-10" />
            <div
              className={`${styles.promotionFooter} flex align_items_center flex_gap_10`}
            >
              <span>
                Total:{" "}
                {data ?
                  getPromotionCounts(
                    data?.data,
                    item.label.toLowerCase().replaceAll(" ", "_")
                  ).total : 0}
              </span>
              <span>
                Active:{" "}
                {data ?
                  getPromotionCounts(
                    data?.data,
                    item.label.toLowerCase().replaceAll(" ", "_")
                  ).active : 0}
              </span>
              <button
                className={`${styles.createButton} flex align_items_center flex_gap_5`}
                onClick={() => navigate(item.link)}
              >
                <SvgPlus /> Create
              </button>
            </div>
          </div>
        ))}
      </div>

      {data && data?.data?.length > 0 ? (
        <>
          <CustomShopifyIndexTable
            headings={[
              "Name",
              "Type",
              "Start Date",
              "End Date",
              "Discounts",
              "Status",
              "",
            ]}
          >
            {data &&
              data?.data?.map((item, index) => (
                <CustomShopifyIndexTableRow id={item.id} key={index}>
                  <CustomShopifyIndexTableCell>
                    <span className="capitalize">{item.promotion_name}</span>
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    {item.promotion_type.replaceAll("_", " ")}
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    {item.start_date}
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    {item.end_date}
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    {item.promotion_mode === "percentage"
                      ? `${item.promotion_amount}%`
                      : formatIndianCurrency(item.promotion_amount)}
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    {item.is_active ? "Active" : "Inactive"}
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    <UpliftMenuButton
                      menuOptions={menuOptions}
                      handleUpliftMenuClick={handleUpliftMenuClick}
                      id={item?.id}
                    />
                  </CustomShopifyIndexTableCell>
                </CustomShopifyIndexTableRow>
              ))}
          </CustomShopifyIndexTable>
          <div style={{ width: "fit-content", margin: "10px auto 0 auto" }}>
            <Pagination
              type={"advance"}
              currentPage={data?.current_page}
              totalPages={data.last_page}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <NoDataFound />
      )}

      {deleteId && (
        <ConfirmPopup
          heading={"Are you Sure ?"}
          subHeading={"You want to delete this promotion"}
          handleConfirmClick={handleDeletePromotion}
        />
      )}
    </div>
  );
};

export default PromotionsDiscounts;
