import React from "react";
import DateRangePicker from "../../../Components/NewCustomComp/DateRangePicker/DateRangePicker";
import { PrimaryInput } from "../../../Components/NewCustomComp/Inputs/Inputs";

const LastMinuteDealPromotionDates = ({ fromData, setFromData }) => {
console.log(fromData ,"fromData")
  const handleDateChange = (start, end) => {
    setFromData({
      ...fromData,
      promotion_wise_conditions: {
        ...fromData.promotion_wise_conditions,
        "last_minute_deal": {
          ...fromData.promotion_wise_conditions?.["last_minute_deal"],
          check_in_start_date: start,
          check_in_end_date: end,
        },
      },
    });
  };

  const handleInputChange = (e) => {
    setFromData({
      ...fromData,
      promotion_wise_conditions: {
        ...fromData.promotion_wise_conditions,
        "last_minute_deal": {
          ...fromData.promotion_wise_conditions?.["last_minute_deal"],
          minimum_lead_time : e.target.value,
        },
      },
    });
  };

  return (
    <div>
      <hr className="mt-10 mb-10" />

      <h2
        className="mb-10"
        style={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "700",
          marginBottom: "10px",
        }}
      >
        Advance Booking Period
      </h2>
      <div className="flex flex_gap_10 align_items_center">
        <div className="w-50">
          <DateRangePicker
            size={"medium"}
            label={"Check-In Date / Range "}
            required={true}
            onChange={handleDateChange}
            defaultDate={{
                startDate: fromData.promotion_wise_conditions?.["last_minute_deal"].check_in_start_date,
                endDate:   fromData.promotion_wise_conditions?.["last_minute_deal"].check_in_end_date
            }}

          />
        </div>

        <div className="w-50">
          <PrimaryInput
            name={"minimum_lead_time"}
            size={"medium"}
            label={"Minimum Lead Time"}
            required={true}
            onChange={handleInputChange}
            value={
              fromData.promotion_wise_conditions?.["last_minute_deal"]
                ?.minimum_lead_time || ""
            }
          />
        </div>
      </div>
    </div>
  );
};

export default LastMinuteDealPromotionDates;
