import React, { useEffect, useState } from "react";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../Components/NewCustomComp/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getBaseUrl, getDateAndMonth } from "../../../utils/utils";
import styles from "../HousekeepingStyles.module.scss";
import { getDecryptedData } from "../../../utils/encryptStorage";
import SliderComponentSkeleton from "../../../Components/NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";
import AddToRoomSliderComponent from "./AddToRoomSliderComponent";

const HousekeepingMiniBar = () => {
  const navigate = useNavigate();
  const property_id = useSelector((state) => state.property.value)?.id;
  const baseUrl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");

  const [miniBarDetails, setMiniDetails] = useState([]);

  const fetchMiniBarDetails = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-assign/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setMiniDetails(data.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (property_id) {
      fetchMiniBarDetails();
    }
  }, [property_id]);

  const [showSlider, setShowSlider] = useState(false);

  const handleShowModal = (item) => {
    setShowSlider(item);
  };

  return (
    <div>
      <div className="flex justify_content_between mb-10 align_items_center">
        <div className="room-type-heading">Mini Bar</div>
        <SecondaryButton
          size={"medium"}
          title={"Items"}
          onClick={() => {
            navigate("/housekeeping/services/mini-bar/items");
          }}
        />
      </div>

      <div className={styles.white_wrapper}>
        <div className={styles.room_card_wrapper}>
          {miniBarDetails &&
            miniBarDetails.length > 0 &&
            miniBarDetails.map((items, index) => (
              <div className={styles.row_child} key={index}>
                <div className="flex align_items_center justify_content_between">
                  <h4>{items?.Category}</h4>
                </div>
                <div className={`flex flex_gap_10 mt-10`}>
                  {items?.Rooms &&
                    items?.Rooms.map((item, innerIndex) => {
                      if (item.MiniBarAssigned) {
                        return (
                          <div
                            key={innerIndex}
                            className={`${styles.room_view_card} 
                            ${item?.RoomStatus === "dirty" && styles.dirtyRoom} 
                            ${
                              item?.RoomStatus === "occupied" &&
                              styles.occupiedRoom
                            } 
                            ${
                              item?.RoomStatus === "clean" && styles.cleanRoom
                            }`}
                          >
                            <div className="flex justify_content_between">
                              {item?.RoomNo}
                            </div>

                            <div>
                              <div className="flex flex_gap_5">
                                <h6>
                                  {item.ReservationDetails &&
                                    item.ReservationDetails.length > 0 &&
                                    getDateAndMonth(
                                      item.ReservationDetails[0]?.CheckIn
                                    )}
                                </h6>
                                {item.ReservationDetails &&
                                  item.ReservationDetails.length > 0 && (
                                    <span>-</span>
                                  )}
                                <h6>
                                  {item.ReservationDetails &&
                                    item.ReservationDetails.length > 0 &&
                                    getDateAndMonth(
                                      item.ReservationDetails[0]?.CheckOut
                                    )}
                                </h6>
                              </div>

                              <h6>
                                {item.ReservationDetails &&
                                  item.ReservationDetails.length > 0 &&
                                  item.ReservationDetails[0]?.GuestName}
                              </h6>
                            </div>

                            <div
                              className={styles.viewDetails}
                              onClick={() => handleShowModal(item)}
                            >
                              view details
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              </div>
            ))}
        </div>
      </div>

      {showSlider && (
        <SliderComponentSkeleton
          title={"Mini Bar"}
          show={showSlider}
          setShow={setShowSlider}
        >
          <AddToRoomSliderComponent
           roomData={showSlider}
           userToken={userToken}
           property_id={property_id}
           baseUrl={baseUrl}
          
          
          />
        </SliderComponentSkeleton>
      )}
    </div>
  );
};

export default HousekeepingMiniBar;
