// Polaris Imports
import { ChoiceList, Text } from "@shopify/polaris";
import BarCharts from "../BarCharts";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import { Chart, ArcElement } from "chart.js";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import LineChartDashboard from "../LineChartsDashboard";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";

import { permission_inner_lists } from "../../assets/constant";
import DonutCustomChart from "../CustomComponents/Charts/DonutCustomChart";
import ReportPaymentReconciliation from "./ReportPaymentReconciliation";
import CustomRoomChoiceSelect from "../CustomComponents/CustomRoomChoiceSelect/CustomRoomChoiceSelect";
import ReportPaymentTaxReport from "./ReportPaymentTaxReport";
import ReportPaymentInvoice from "./ReportPaymentInvoice";
import ReportPaymentCommission from "./ReportPaymentCommission";
import ReportPaymentFoodSaleReport from "./ReportPaymentFoodSaleReport";
import ReportAgentSaleReport from "./ReportAgentSaleReport";
import ReportItemizedFoodSaleReport from "./ReportItemizedFoodSaleReport";
import VerticalInputSelectCustomPermissions from "../CustomComponents/VerticalInputSelectCustomPermissions";
import ReportCityLedgerReport from "./ReportCityLedgerReport";
import ReportCashierFinance from "./ReportCashierFinance";
import ReportMonthlySalesReport from "./ReportMonthlySalesReport";
import ReportSaleSummeryReport from "./ReportSaleSummeryReport";
import ReportOutlet from "./ReportOutlet";
import { SelectBasic } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import ReportAccountBalanceTable from "./ReportAccountBalanceTable";
import ReportRoomAssignment from "./ReportRoomAssignment";
import ReportPaymentDailyRevenue from "./ReportPaymentDailyRevenue";
import ReportAgentBusinessReport from "./ReportAgentBusinessReport";
import ReportGstComplianceReport from "./ReportGstComplianceReport";
import ReportConsolidatedBusiness from "./ReportConsolidatedBusiness";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { getDecryptedData } from "../../utils/encryptStorage";
import ReportOtaBusinessReport from "./ReportOtaBusinessReport";
import SidebarInnerListWithMultiplePermissions from "../CustomComponents/SidebarInnerListWithMultiplePermissions";
import {
  calculateToDateMinus,
  calculateToDatePlus,
  getCurrentPropertyDateHandler,
} from "../../utils/utils";
import { SearchOrAdd } from "../NewCustomComp/Search/Search";

Chart.register(ArcElement);

const FinanceReports = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [showBookingDetails, setShowBookingDetails] = useState(false);

  const userToken = getDecryptedData("encryptToken");

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  const property_id = useSelector((state) => state.property.value);
  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const accordRef = useRef(null);

  const toggleAccord2 = () => {
    setIsOpen2(!isOpen2);
    // setIsOpen1(false);
    // setIsOpen(false);
  };
  const toggleAccord3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen(false);
  };

  const [allRoomTypes, setAllRoomTypes] = useState([]);

  const fetchAllRoomTypes = async (propertyId) => {
    try {
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);
      setSelectedRoomType([data.rooms[0].id]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const [bookingDetail, setBookingDetail] = useState(null);
  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);

  const getSingleBookingDataHandler = (bookingId) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetail(data);
          // setChargesDetails(data?.charges);
          // setMasterFolioDetails(data?.masterFolio);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const [selectedRoomType, setSelectedRoomType] = useState([1]);
  const [selectedBalanceType, setSelectedBalanceType] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([]);

  const handleRoomTypeStatus = useCallback(
    (value) => setSelectedRoomType(value),
    []
  );
  const handleBalanceTypeStatus = useCallback(
    (value) => setSelectedBalanceType(value),
    []
  );
  const handleBookingStatus = useCallback(
    (value) => setSelectedBookingStatus(value),
    []
  );

  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 5);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });
  const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
    from_date: currentDate,
    to_date: calculateToDate(currentDate),
  });
  const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
    from_date: currentDate,
    to_date: calculateToDate(currentDate),
  });

  const sendingData = {
    comparisonFrom: comparisonStartDateRange,
    comparisonTo: comparisonEndDateRange,
    roomType: selectedRoomType,
    source: selectedBookingStatus,
  };

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  useEffect(() => {
    if (property_id) {
      fetchAllRoomTypes(property_id.id);
    }
  }, [property_id]);

  const [bookingId, setBookingId] = useState("");

  const [openAccordion, setOpenAccordion] = useState("first");

  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const reportFilterOptions = [
    { label: "Select Option" },
    // {
    //   label: "Property Occupancy Report",
    //   value: "daily_finance_report",
    //   permission: ["full_access_analytics", "read_finance_report"],

    // },
    {
      label: "Detailed Business Report",
      value: "sale_summery_report",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    {
      label: "Consolidated Agent Business Report",
      value: "agent_business_report",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    {
      label: "Consolidated Business Report",
      value: "consolidated_business_report",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    {
      label: "GST Compliance Report",
      value: "gst_compliance_report",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    {
      label: "Payment Reconciliation",
      value: "payment_reconciliation",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    {
      label: "Account Balance",
      value: "account_balance",
      permission: ["full_access_analytics", "read_finance_report"],
    },

    {
      label: "Payout / Commission Report",
      value: "commission_report",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    {
      label: "Invoice Report",
      value: "invoice_report",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    // {
    //   label: "Room Assignment",
    //   value: "room_assignment",
    //   permission: "daily_activity_room_assignment",
    // },

    // {
    //   label: "Daily Revenue Report",
    //   value: "revenue_report",
    //   permission: "daily_activity_revenue_report",
    // },
    // {
    //   label: "Payment Ledger Report",
    //   value: "payment_ledger_report",
    //   permission: "finance_payment_ledger_report",
    // },
    // {
    //   label: "Tax Report",
    //   value: "tax_report",
    //   permission: "finance_tax_report",
    // },
    // {
    //   label: "Food Sale Report",
    //   value: "food_sale_report",
    //   permission: "finance_food_sale_report",
    // },
    // {
    //   label: "Agent Sale Report",
    //   value: "agent_sale_report",
    //   permission: "finance_agent_sale_report",
    // },
    // {
    //   label: "Itemized Sale Report",
    //   value: "itemized_sale_report",
    //   permission: "finance_itemized_sale_report",
    // },
    {
      label: "City Ledger Report",
      value: "city_ledger_report",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    {
      label: "OTA Business Report",
      value: "ota_business_report",
      permission: ["full_access_analytics", "read_finance_report"],
    },
    // {
    //   label: "Cashier Report",
    //   value: "cashier_report",
    //   permission: "finance_cashier_report",
    // },
    // {
    //   label: "Monthly Sales Report",
    //   value: "month_wise_sale_report",
    //   permission: "finance_wise_sale_report",
    // },

    // {
    //   label: "Outlet Report",
    //   value: "outlet_report",
    //   permission: "finance_wise_sale_report",
    // },
  ];

  const invoiceBookingFilters = [
    { label: "Select Option", value: "" },
    { label: "Booking Date", value: "booking" },
    { label: "Check In Date", value: "check_in_out" },
  ];
  const invoiceSettlementFilters = [
    { label: "Select Option", value: "" },
    { label: "Settlement Date", value: "booking" },
    { label: "Check In Date", value: "check_in_out" },
  ];

  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [fullData, setFullData] = useState([]);
  const [orders, setOrders] = useState([]);

  const [settlementOrders, setSettlementOrders] = useState([]);

  const [filterName, setFilterName] = useState("sale_summery_report");
  const [bookingNewFilter, setBookingNewFilter] = useState("");
  const [dailyFinanceDate, setDailyFinanceDate] = useState(currentDate);

  const polarisData = [
    {
      data: fullData.comparison_from || [],
      name: "2022",
      color: "lightseagreen",
    },
    {
      data: fullData.comparison_to || [],
      name: "2023",
      color: "lightgreen",
    },
  ];

  const [donutChart, setDonutChart] = useState([]);

  useEffect(() => {
    if (fullData?.data && fullData?.data.length > 0) {
      setDonutChart(fullData?.data);
    } else {
    }
  }, [fullData]);

  const generateYearOptions = (currentYear) => {
    const numYears = 3;
    const startYear = currentYear - numYears;
    const endYear = currentYear;

    const years = [];
    years.push({ label: "Select a year", value: "" });
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  };

  // Example usage:
  const currentYear = new Date().getFullYear();
  const yearOptions = generateYearOptions(currentYear);

  const [urlFilter, setUrlFilter] = useState("occupancy");

  const handleUrlInnerFilter = useCallback(
    (e) => {
      if (e === "payment_ledger_report") {
        setUrlFilter("payment");
      } else {
        setUrlFilter("occupancy");
      }
    },
    [urlFilter]
  );

  const fetchInfo = async (PropertyId) => {
    setLoader(true);

    if (
      filterName === "payment_ledger_report" &&
      (!paymentOptions || paymentOptions.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/property-occupancy-report/${PropertyId}?date=${dailyFinanceDate}`,
      {
        method: "GET",
        // body: JSON.stringify({
        //   date: dailyFinanceDate,
        //   from_date: selectedDatesRange.from_date,
        //   to_date: selectedDatesRange.to_date,
        //   payment_of: selectedPaymentStatus,
        //   payment_mode: selectedBookingStatus,
        // }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.success) {
          setLoader(false);

          // {
          //   filterName === "payment_ledger_report" &&
          //   setOrders(data.payment_ledger_data);
          // }
          setOrders(data.payment_ledger_data || []);
          setFullData(data);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
      });
  };

  const [paymentReconciliationError, setPaymentReconciliationError] = useState({
    paymentOption: false,
  });

  const fetchPaymentReconciliation = async (propertyId, roomIds) => {
    try {
      if (paymentOptions.length <= 0) {
        showErrorToast("Please select payment options.");
        setErrorWithTimeout(
          setPaymentReconciliationError,
          "paymentOption",
          3000
        );
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/payment-reconciliation/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&payment_options=${paymentOptions}&booking_filter=${bookingNewFilter}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   // payment_options: paymentOptions,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setSettlementOrders(data.foodData);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchConsolidatedBusinessReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/consolidated-business-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setSettlementOrders(data.foodData);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [paymentError, setPaymentError] = useState({ paymentMode: false });
  const fetchPaymentReconciliationNew = async (propertyId) => {
    try {
      // setChartData([]);
      setLoader(true);
      if (paymentOptions.length <= 0) {
        showErrorToast("Please select payment options.");
        setErrorWithTimeout(setPaymentError, "paymentMode", 3000);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/payment-reconciliation-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            payment_type: paymentOptions,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        const filterData = data.data.filter(
          (item) => item.payment_mode === "Null"
        );
        console.log(filterData, "filterData");
        setOrders(data.data);
        setSettlementOrders(data.foodData);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchPaymentRevenueFetch = async (propertyId, roomIds) => {
    try {
      // setChartData([]);
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/revenue-report/${propertyId}?from_date=${selectedDatesRange.from_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);

        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchAgentBusinessReport = async (propertyId, roomIds) => {
    try {
      // setChartData([]);
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent-bussiness-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);

        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchGstComplianceReport = async (propertyId, roomIds) => {
    try {
      // setChartData([]);
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/gst-compliance/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data.data);
        setFullData(data.data);
        // showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);

        setOrders(data.data);
        showErrorToast(data.message);
      }

      if (data.error) {
        setOrders([]);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchAccountBalanceHandler = async (propertyId, roomIds) => {
    // if (!selectedRoomType || selectedRoomType.length === 0) {
    //   showErrorToast("Please select the Room Type first!");
    //   return;
    // }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    setLoader(true);

    try {
      // setChartData([]);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/account-balance-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        // showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchRoomAssignmentHandler = async (propertyId, roomIds) => {
    // if (!selectedRoomType || selectedRoomType.length === 0) {
    //   showErrorToast('Please select the Room Type first!');
    //   return;
    // }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    try {
      // setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/room-assignment-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchPaymentTaxReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/tax-report/${propertyId}?from_date=${selectedDatesRange.from_date}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   // payment_options: paymentOptions,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        // setOrders(data.data);
        setSettlementOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchPaymentInvoiceReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/invoice-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&booking_source=${selectedBookingStatus}&booking_filter=${bookingNewFilter}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   // payment_options: paymentOptions,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchPaymentCommissionReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/commission-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&booking_filter=${bookingNewFilter}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   // payment_options: paymentOptions,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchPaymentFoodSalesReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/food-sale-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [cityLedgerError, setCityLedgerError] = useState({
    agent: false,
  });

  const fetchCityLedgerReport = async (propertyId, roomIds) => {
    if (
      !bookingNewFilter ||
      !selectedDatesRange.from_date ||
      !selectedDatesRange.to_date
    ) {
      showErrorToast("Please select all the filters");
      setErrorWithTimeout(setCityLedgerError, "agent", 3000);
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/city/ledger/report/${bookingNewFilter}/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.creditTransaction);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchOtaBusinessReport = async (propertyId, roomIds) => {
    // if (
    //   !bookingNewFilter ||
    //   !selectedDatesRange.from_date ||
    //   !selectedDatesRange.to_date
    // ) {
    //   showErrorToast("Please select all the filters");
    //   setErrorWithTimeout(setCityLedgerError, "agent", 3000);
    //   return;
    // }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/ota-business-report/1?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        // `${process.env.REACT_APP_BASE_URL}/api/v1/get/ota-business-report/1?from_date=2024-08-10&to_date=2024-10-27`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchSummarySaleReport = async (propertyId, roomIds) => {
    if (!selectedDatesRange.from_date || !selectedDatesRange.to_date) {
      showErrorToast("Please select all the filters");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/sale-summary-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,

        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setFullData(data);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchCashierReport = async (propertyId, roomIds) => {
    if (!selectedDatesRange.from_date || !selectedDatesRange.to_date) {
      showErrorToast("Please select all the filters");
      return;
    }
    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/food-sale-overview/4?from_date=2024-02-01&to_date=2024-04-30
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/food-sale-overview/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchMonthlySalesReport = async (propertyId, roomIds) => {
    if (!selectedDatesRange.from_date || !selectedDatesRange.to_date) {
      showErrorToast("Please select all the filters");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/sale-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const FetchItemizedSaleReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/itemised-food-sale/${propertyId}?from_date=${selectedDatesRange.from_date}&categories=${paymentOptions}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchPaymentAgentSalesReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent-sales/${propertyId}?from_date=${selectedDatesRange.from_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data?.data?.revenue);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [agentList, setAgentList] = useState([]);

  const fetchAgentList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAgentList(data.data);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentList();
    }
  }, [property_id]);

  const agentOptions = [
    { label: "Select Option", value: "" },
    ...agentList.map((agent) => ({
      label: agent.agent_name,
      value: agent.agent_information,
    })),
  ];

  const [menuItems, setMenuItems] = useState([]);

  const menuItemArray = menuItems?.map((roomType) => {
    return { label: roomType.item_category, value: roomType.item_category };
  });

  const fetchMenuItems = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menus/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      if (data.success) {
        setMenuItems(data.menu);

        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchMenuItems(property_id?.id);
    }
  }, [property_id]);

  const otherMenusRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [paymentOptions, setPaymentOptions] = useState([
    "cash",
    "bank_payment",
    "upi",
    "credit_card",
    "debit_card",
    "other"
]);
  

  const getDataFromCustomRoomChoiceSelectPaymentOptions = (data) => {
    setPaymentOptions(data);
  };

  const setErrorWithTimeout = (setterFunction, keyName, duration = 3000) => {
    setterFunction((prev) => ({ ...prev, [keyName]: true }));
    setTimeout(() => {
      setterFunction((prev) => ({ ...prev, [keyName]: false }));
    }, duration);
  };

  // Outlet Report
  const getOutletReport = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/xml");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const url = selectedStaff
      ? `https://dev.revcatalyst.in/api/v1/get/food-sale/order-by-report/${property_id?.id}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&order_by=${selectedStaff}`
      : `https://dev.revcatalyst.in/api/v1/get/food-sale/order-by-report/${property_id?.id}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`;
    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();

      if (result.success) {
        setOrders(result.data);
      } else {
        showErrorToast("No Data Found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // staff_list Api
  const [fetchedRoles, setFetchedRoles] = useState([]);

  const fetchRoles = async () => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/get-user/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoles(data.data);

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [selectedStaff, setSelectedStaff] = useState([]);
  const handleStaffSelect = (data) => {
    setSelectedStaff(data);
  };

  useEffect(() => {
    fetchRoles();
  }, [property_id]);

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  useEffect(() => {
    if (propertyCurrentDate) {
      setSelectedDatesRange({
        from_date: calculateToDateMinus(propertyCurrentDate, 30),
        to_date: propertyCurrentDate,
      });
    }
  }, [propertyCurrentDate]);

  //new code

  useEffect(() => {
    if (
      filterName &&
      selectedDatesRange.from_date &&
      selectedDatesRange.to_date &&
      property_id
    ) {
      filterName === "sale_summery_report" &&
        fetchSummarySaleReport(property_id.id);
      filterName === "consolidated_business_report" &&
        fetchConsolidatedBusinessReport(property_id.id);
    }
  }, [
    filterName,
    selectedDatesRange.from_date,
    selectedDatesRange.to_date,
    property_id,
  ]);

  return (
    <>
      <SidebarInnerListWithMultiplePermissions
        innerLists={permission_inner_lists}
        zIndex={20}
      />
      <section className="dashboard_page">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 0",
            }}
          >
            <div style={{ fontSize: "20px" }} className="report-chart-heading">
              Finance Reports
            </div>
          </div>
          <div>
            <div
              style={{
                background: "rgba(255, 255, 255, 1)",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="report-chart-heading">Filters</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{ width: "95%" }}
                    className="flex flex_gap_10 flex_wrap"
                  >
                    <div style={{ width: "150px" }}>
                      <VerticalInputSelectCustomPermissions
                        titleName={"Select Report"}
                        value={filterName}
                        defaultValue={"arrival"}
                        onChange={(e) => {
                          setFilterName(e.target.value);
                          handleUrlInnerFilter(e.target.value);
                          setSelectedRoomType([]);
                          setBookingDetails([]);
                          setSelectedBookingStatus([]);
                          setOrders([]);
                        }}
                        options={reportFilterOptions}
                      />
                    </div>
                    {filterName === "arrival" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange dataFromChild={dataFromChild} />
                        </div>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Room Type</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord2}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedRoomType.length} of{" "}
                                {allRoomTypes.length} SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen2 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={choiceListRoomsArray}
                                  selected={selectedRoomType}
                                  onChange={handleRoomTypeStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Not Checked-in",
                                      value: "not-Checked-in",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "departure" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange dataFromChild={dataFromChild} />
                        </div>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Room Type</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord2}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedRoomType.length} of{" "}
                                {allRoomTypes.length} SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen2 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={choiceListRoomsArray}
                                  selected={selectedRoomType}
                                  onChange={handleRoomTypeStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "in_house" && (
                      <>
                        <div style={{ width: "200px" }}>
                          {/* <div style={{ marginBottom: "5px" }}>
                                Date Range
                              </div> */}
                          <VerticalInputFieldCustom
                            titleName={"Check-In Date"}
                            type={"Date"}
                          />
                          {/* <SelectDateRange dataFromChild={dataFromChild} /> */}
                        </div>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Balance Due</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord2}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBalanceType.length} of {2} SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen2 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "Balance Due",
                                      value: "balance-due",
                                    },
                                    {
                                      label: "No Balance",
                                      value: "no-balance",
                                    },
                                  ]}
                                  selected={selectedBalanceType}
                                  onChange={handleBalanceTypeStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "daily_finance_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            type={"date"}
                            titleName={"Select Date"}
                            onChange={(e) =>
                              setDailyFinanceDate(e.target.value)
                            }
                            value={dailyFinanceDate}
                            name={"dailyFinanceDate"}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "account_balance" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange.from_date,
                              selectedDatesRange.to_date,
                            ]}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                      </>
                    )}
                    {filterName === "agent_business_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                      </>
                    )}
                    {filterName === "consolidated_business_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                      </>
                    )}
                    {filterName === "gst_compliance_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                      </>
                    )}
                    {filterName === "room_assignment" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "payment_reconciliation" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={paymentModeOptions}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelectPaymentOptions
                            }
                            selectedRoomsIds={paymentOptions}
                            heading="Payment Options"
                            showButtons={true}
                            error={paymentError.paymentMode}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "invoice_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Booking Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceBookingFilters}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Source</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 4 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${
                                  isOpen ? "accordImageRotate" : null
                                } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "Agent (B2B Partner)",
                                      value: "agent",
                                    },
                                    {
                                      label: "Walk In Bookings",
                                      value: "walk_in_bookings",
                                    },
                                    {
                                      label: "Front Desk",
                                      value: "front_desk",
                                    },
                                    {
                                      label: "Sales and Marketing",
                                      value: "sales_and_marketing",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "payment_ledger_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Settlement Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceSettlementFilters}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={paymentModeOptions}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelectPaymentOptions
                            }
                            selectedRoomsIds={paymentOptions}
                            heading="Payment Options"
                            showButtons={true}
                            error={paymentReconciliationError.paymentOption}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "tax_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                            // max={currentDate}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "commission_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>
                            Date Booked Date Range
                          </div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                            maxDate={new Date(currentDate)}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Booking Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceBookingFilters}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "food_sale_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                            // minDate={new Date(currentDate)}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Booking Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceBookingFilters}
                          />
                        </div> */}
                      </>
                    )}
                    {filterName === "city_ledger_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <SearchOrAdd
                            size={"medium"}
                            options={agentOptions}
                            optionKeyName={"label"}
                            selectedKeyName={"value"}
                            required={true}
                            label={"Search Agent"}
                            handleOptionChange={(e) => {
                              setBookingNewFilter(e);
                            }}
                            // style={{
                            //   background: errors.guest_name ? "#FFEAEA" : "",
                            //   border: errors.guest_name
                            //     ? "1px solid #E03838"
                            //     : "",
                            // }}
                            defaultSelected={
                              agentOptions?.find(
                                (opt) =>
                                  Number(opt?.value) ===
                                  Number(bookingNewFilter)
                              )?.label
                            }
                            // disabled={bookingDataArray.length > 0}
                          />
                        </div>
                        {/* <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Select Agent"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={agentOptions}
                            error={cityLedgerError.agent}
                          />
                        </div> */}
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange?.from_date,
                              selectedDatesRange?.to_date,
                            ]}
                            // minDate={new Date(currentDate)}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "ota_business_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={`
                              ${currentDate}
                              :
                              ${calculateToDate(currentDate)}
                              `}
                            // minDate={new Date(currentDate)}
                          />
                        </div>
                      </>
                    )}

                    {filterName === "cashier_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={`
                              ${currentDate}
                              :
                              ${calculateToDate(currentDate)}
                              `}
                            // minDate={new Date(currentDate)}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "month_wise_sale_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            maxDate={new Date()}
                            // minDate={new Date(currentDate)}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "agent_sale_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                            // max={currentDate}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Booking Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceBookingFilters}
                          />
                        </div> */}
                      </>
                    )}
                    {filterName === "itemized_sale_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                            max={currentDate}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={menuItemArray}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelectPaymentOptions
                            }
                            selectedRoomsIds={paymentOptions}
                            heading="Categories"
                            showButtons={true}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "outlet_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={`
                            ${currentDate}
                            :
                            ${calculateToDate(currentDate)}
                            `}
                            // minDate={new Date(currentDate)}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <>
                            <div style={{ marginBottom: "5px" }}>Staff</div>
                            <SelectBasic
                              emptyOption={"none"}
                              options={fetchedRoles}
                              handleSelectTagChange={handleStaffSelect}
                              optionKeyName={"name"}
                              showKeyName={"name"}
                              selectedKeyName={"name"}
                            />
                          </>
                        </div>
                      </>
                    )}
                    {filterName === "revenue_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                            max={currentDate}
                          />
                        </div>
                      </>
                    )}

                    {/* === new code === */}

                    {filterName === "sale_summery_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[
                              selectedDatesRange.from_date,
                              selectedDatesRange.to_date,
                            ]}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="flex flex_gap_10">
                    {filterName != "payment_ledger_report" &&
                      filterName != "invoice_report" &&
                      filterName != "tax_report" &&
                      filterName != "account_balance" &&
                      filterName != "agent_business_report" &&
                      filterName != "consolidated_business_report" &&
                      filterName != "gst_compliance_report" &&
                      filterName != "room_assignment" &&
                      filterName != "payment_reconciliation" &&
                      filterName != "revenue_report" &&
                      filterName != "commission_report" &&
                      filterName != "agent_sale_report" &&
                      filterName != "itemized_sale_report" &&
                      filterName != "sale_summery_report" &&
                      filterName != "city_ledger_report" &&
                      filterName != "ota_business_report" &&
                      filterName != "cashier_report" &&
                      filterName != "month_wise_sale_report" &&
                      filterName != "food_sale_report" &&
                      filterName !== "outlet_report" && (
                        <BgThemeButton
                          children={"Apply"}
                          loader={loader}
                          onClick={() => fetchInfo(property_id.id)}
                        />
                      )}

                    {filterName === "payment_ledger_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentReconciliation(property_id.id)
                        }
                      />
                    )}
                    {filterName === "revenue_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchPaymentRevenueFetch(property_id.id)}
                      />
                    )}
                    {filterName === "account_balance" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchAccountBalanceHandler(property_id.id)
                        }
                      />
                    )}
                    {filterName === "agent_business_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchAgentBusinessReport(property_id.id)}
                      />
                    )}
                    {filterName === "consolidated_business_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchConsolidatedBusinessReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "gst_compliance_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchGstComplianceReport(property_id.id)}
                      />
                    )}
                    {filterName === "room_assignment" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchRoomAssignmentHandler(property_id.id)
                        }
                      />
                    )}
                    {filterName === "payment_reconciliation" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentReconciliationNew(property_id.id)
                        }
                      />
                    )}
                    {filterName === "tax_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchPaymentTaxReport(property_id.id)}
                      />
                    )}

                    {filterName === "invoice_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentInvoiceReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "commission_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentCommissionReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "food_sale_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentFoodSalesReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "city_ledger_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchCityLedgerReport(property_id.id)}
                      />
                    )}
                    {filterName === "ota_business_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchOtaBusinessReport(property_id.id)}
                      />
                    )}

                    {filterName === "cashier_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchCashierReport(property_id.id)}
                      />
                    )}
                    {filterName === "month_wise_sale_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchMonthlySalesReport(property_id.id)}
                      />
                    )}
                    {filterName === "itemized_sale_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => FetchItemizedSaleReport(property_id.id)}
                      />
                    )}
                    {filterName === "agent_sale_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentAgentSalesReport(property_id.id)
                        }
                      />
                    )}

                    {filterName === "outlet_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        onClick={getOutletReport}
                      />
                    )}

                    {/* { new code   } */}

                    {filterName === "sale_summery_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchSummarySaleReport(property_id.id)}
                      />
                    )}
                    <BgOutlineThemeButton
                      onClick={() => {
                        setFilterName("");
                        setSelectedRoomType([]);
                        setBookingDetails([]);
                        setSelectedBookingStatus([]);
                        setOrders([]);
                      }}
                      type={"button"}
                      children={"Clear"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {filterName === "rev_par" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <LineChartDashboard
                  data={polarisData}
                  title={"Rev Par Insight"}
                />
              )}
            </>
          )}
          {filterName === "occupancy" && (
            <LineChartDashboard data={polarisData} title={"Occupancy"} />
          )}

          {filterName === "daily_finance_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <DonutCustomChart
                  data={donutChart || []}
                  title={"Property Occupancy Report"}
                  state={loader}
                />
              )}
            </>
          )}
          {filterName === "account_balance" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportAccountBalanceTable
                  departureReport={orders}
                  fullData={fullData}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "consolidated_business_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportConsolidatedBusiness
                  departureReport={orders}
                  fullData={fullData}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "agent_business_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportAgentBusinessReport
                  departureReport={orders}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "gst_compliance_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportGstComplianceReport
                  departureReport={orders}
                  fullData={fullData}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                  userToken={userToken}
                  property_id={property_id}
                />
              )}
            </>
          )}
          {filterName === "room_assignment" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportRoomAssignment
                  departureReport={orders}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "payment_reconciliation" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentReconciliation
                  departureReport={orders}
                  settlementOrders={settlementOrders}
                  loader={loader}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "revenue_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentDailyRevenue
                  departureReport={orders}
                  loader={loader}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}

          {filterName === "payment_ledger_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentReconciliation
                  departureReport={orders}
                  settlementOrders={settlementOrders}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                />
              )}
            </>
          )}
          {filterName === "invoice_report" && (
            <ReportPaymentInvoice
              departureReport={orders}
              settlementOrders={settlementOrders}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
            />
          )}
          {filterName === "tax_report" && (
            <ReportPaymentTaxReport
              departureReport={settlementOrders}
              settlementOrders={settlementOrders}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
            />
          )}
          {filterName === "commission_report" && (
            <ReportPaymentCommission
              departureReport={orders}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
            />
          )}
          {filterName === "food_sale_report" && (
            <ReportPaymentFoodSaleReport
              departureReport={orders}
              fullData={fullData}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
            />
          )}
          {filterName === "city_ledger_report" && (
            <ReportCityLedgerReport
              departureReport={orders}
              fullData={fullData}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
              property_id={property_id}
              userToken={userToken}
            />
          )}
          {filterName === "ota_business_report" && (
            <ReportOtaBusinessReport
              departureReport={orders}
              fullData={fullData}
              selectedDatesRange={selectedDatesRange}
              loader={loader}
              setLoader={setLoader}
              propertyId={property_id?.id}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
            />
          )}

          {filterName === "cashier_report" && (
            <ReportCashierFinance
              getSingleBookingDataHandler={getSingleBookingDataHandler}
              departureReport={orders}
              fullData={fullData?.data}
            />
          )}
          {filterName === "month_wise_sale_report" && (
            <ReportMonthlySalesReport
              departureReport={orders}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
              fullData={fullData?.data}
            />
          )}
          {filterName === "agent_sale_report" && (
            <ReportAgentSaleReport
              departureReport={orders}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
            />
          )}
          {filterName === "itemized_sale_report" && (
            <ReportItemizedFoodSaleReport
              departureReport={orders}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
            />
          )}
          {filterName === "outlet_report" && (
            <ReportOutlet
              data={orders}
              getSingleBookingDataHandler={getSingleBookingDataHandler}
            />
          )}

          {/*  new code   */}

          {filterName === "sale_summery_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportSaleSummeryReport
                  departureReport={orders}
                  fullData={fullData}
                  loader={loader}
                  getSingleBookingDataHandler={getSingleBookingDataHandler}
                  userToken={userToken}
                  property_id={property_id}
                />
              )}
            </>
          )}
        </div>

        {openSingleBookingDetails && (
          <BookingDetailSliderNew
            data={bookingDetail?.booking}
            chargesDetails={bookingDetail?.charges}
            masterFolioDetails={bookingDetail?.masterFolio}
            setShowBookingDetails={setOpenSingleBookingDetails}
            getSingleBookingDataHandler={getSingleBookingDataHandler}
          />
        )}
      </section>
    </>
  );
};

export default FinanceReports;
