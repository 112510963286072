import React, { useEffect, useState } from "react";
import NoDataFoundSmall from "../../../Components/CustomComponents/NoDataFoundSmall";
import {
  formatDateYYYYMMDD,
  getCurrentPropertyDateHandler,
  getDateByMonth,
} from "../../../utils/utils";
import styles from "./AddToRoomSliderComponent.module.scss";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import HouseKeepingMinibarAddItemToRoomPopup from "./HouseKeepingMinibarAddItemToRoomPopup";
import { SvgDelete } from "../../../assets/svgIcons";

const AddToRoomSliderComponent = ({
  roomData,
  userToken,
  property_id,
  baseUrl,
}) => {
  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  useEffect(() => {
    if (
      roomData &&
      roomData?.ReservationDetails &&
      roomData.ReservationDetails.length > 0
    ) {
      fetchMiniBarDetails(
        roomData.ReservationDetails[0].BookingID,
        roomData.RoomId,
        roomData.RoomNo
      );
    }
  }, [property_id, roomData]);

  const reservation = roomData && roomData.ReservationDetails[0];
  const checkInDate = reservation && new Date(reservation.CheckIn);
  const checkOutDate = reservation && new Date(reservation.CheckOut);
  const today = propertyCurrentDate && new Date(propertyCurrentDate);

  // Function to generate date range
  const getDateRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const dateRange = getDateRange(checkInDate, checkOutDate);

  //   fetch room details
  const [dateWiseData, setDateWiseDate] = useState([]);

  const fetchMiniBarDetails = async (booking_id, room_id, room_no) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-assign-items/${property_id}?booking_id=${booking_id}&room_id=${room_id}&room_no=${room_no}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setDateWiseDate(data.data);
      }
    } catch (error) {
      showErrorToast("something went wrong");
    }
  };

  console.log(dateWiseData, "hii");

  const [show, setShow] = useState(false);

  const handleAddClick = () => {
    setShow(true);
  };
  //   console.log

  const handleRemoveItem = async (item) => {
    const oldItems =
      dateWiseData[propertyCurrentDate]?.[0]?.mini_bar_service_items;

    const updatedItems = oldItems.filter((oldItem) => oldItem.id !== item.id);

    const subTotal = updatedItems.reduce(
        (acc, item) => acc + parseFloat(item.rate) * parseFloat(item.quantity),
        0
      );
    const tax_rate = 18;
    const tax_amount = (subTotal * tax_rate) / 100;
    const grand_total = subTotal + tax_amount;

    const postData = {
        id: dateWiseData?.[propertyCurrentDate]?.[0]?.id ,
        room_id: roomData?.RoomId,
        room_no: roomData?.ReservationDetails?.[0].RoomNo,
        booking_id: roomData?.ReservationDetails?.[0].BookingID,
        guest_id: roomData?.ReservationDetails?.[0].GuestID,
        items: updatedItems,
        sub_total: subTotal,
        total_discount_amount: 0,
        total_tax_amount: tax_amount,
        grand_total: grand_total,
        date: propertyCurrentDate,
      };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-assign-items/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchMiniBarDetails(
          roomData?.ReservationDetails?.[0].BookingID,
          roomData?.RoomId,
          roomData?.ReservationDetails?.[0].RoomNo
        );

        showSuccessToast(data.message);
      }
    } catch (error) {
    } finally {
      setShow(false);
    }
  };

  return (
    <div className="">
      {roomData && roomData?.ReservationDetails?.length === 0 ? (
        <NoDataFoundSmall />
      ) : (
        <div className={styles["slider-container"]}>
          {dateRange.map((date, index) => {
            const isDisabled = date > today; // Disable future dates
            const isCurrentDate =
              propertyCurrentDate &&
              date.toDateString() === today.toDateString();
            const formattedDate = formatDateYYYYMMDD(date);

            return (
              <div key={index}>
                <div
                  className={`flex justify_content_between align_items_center ${styles.accordBar}`}
                >
                  {getDateByMonth(date)}
                  <button
                    className={`${styles.addButton} ${
                      isCurrentDate && styles.active
                    }`}
                    disabled={!isCurrentDate}
                    onClick={handleAddClick}
                  >
                    +
                  </button>
                </div>
                {dateWiseData[formattedDate]?.[0]?.mini_bar_service_items
                  ?.length > 0 && (
                  <div className={styles.miniBarItems}>
                    {dateWiseData[
                      formattedDate
                    ]?.[0]?.mini_bar_service_items?.map((item, innerIndex) => (
                      <div
                        className={`flex justify_content_between ${styles.miniBarItemsChild}`}
                        key={innerIndex}
                      >
                        <div>{item.item_name}</div>
                        <div>Qty : {item.quantity}</div>
                        <button
                          disabled={isDisabled}
                          className={`pointer ${styles.miniBarItemsChildButton}`}
                          onClick={() => handleRemoveItem(item)}
                        >
                          <SvgDelete />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {show && (
        <HouseKeepingMinibarAddItemToRoomPopup
          reservationDetails={roomData?.ReservationDetails?.[0] || {}}
          data={
            dateWiseData?.[propertyCurrentDate]?.[0]?.mini_bar_service_items ||
            []
          }
          setShow={setShow}
          RoomId={roomData?.RoomId || ""}
          propertyCurrentDate={propertyCurrentDate}
          fetchMiniBarDetails={fetchMiniBarDetails}
          oldItemId={dateWiseData?.[propertyCurrentDate]?.[0]?.id || null}
        />
      )}
    </div>
  );
};

export default AddToRoomSliderComponent;
