import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GoBackButtonCustom from "../../../../Components/GoBackButtonCustom/GoBackButtonCustom";
import { PrimaryInput } from "../../../../Components/NewCustomComp/Inputs/Inputs";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../Components/NewCustomComp/Buttons/Buttons";
import { getBaseUrl } from "../../../../utils/utils";
import { showSuccessToast } from "../../../../assets/toastUtils";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../../../utils/encryptStorage";
import HouseKeepingStatusUpdateSwitch from "./HouseKeepingStatusUpdateSwitch";

const CreateLostFoundService = () => {
  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData("encryptToken");
  const baseUrl = getBaseUrl();
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchData = async (id) => {
    
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/lost-and-found/${property_id?.id}?id=${id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        // setPageInfo({ ...pageInfo, data: result.data, Loading: false });
        setFormData(result.data);
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  useEffect(()=>{
    if(id && property_id){
      fetchData(id);
    }
  } ,[id ,property_id])

  const [formData, setFormData] = useState({
    is_important: false,
    status: "lost",
    unique_booking_id: "",
    article: "",
    approx_value: "",
    lost_date: "",
    lost_time: "",
    lost_area: "",
    founder: "",
    found_date: "",
    found_time: "",
    found_area: "",
    return_date: "",
    return_person: "",
    authorized_by: "",
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const raw = JSON.stringify(formData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/lost-and-found/${property_id?.id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        showSuccessToast(
          id ? "Service Updated Successfully" : "Service Created Successfully"
        );
        navigate(-1);
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  const onStatusChange = (status) => {
    setFormData((prev) => ({
      ...prev,
      status: status,
    }));
  };
  console.log(formData, "fromData");

  return (
    <div className="main_container">
      <div className="flex justify_content_between align_items_center">
        <GoBackButtonCustom
          buttonNextText={id ? "Edit Service" : "Create Service"}
          onClick={() => navigate(-1)}
        />

        <div className="flex flex_gap_10 align_items_center">
          <label className="flex flex_gap_5 pointer align_items_center">
            Important
            <input
              type={"checkbox"}
              value={formData.is_important}
              name="is_important"
              onChange={handleChange}
              checked={formData.is_important ? true : false}
            />
          </label>

          <HouseKeepingStatusUpdateSwitch
            onStatusChange={onStatusChange}
            currentStatus={formData.status}
            options={["lost", "found"]}
            
          />
        </div>
      </div>

      <div>
        <div className="card-3d mt-10">
          <div className="card-3d-title">Lost Details</div>

          <div className="flex flex_gap_10 w-100">
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Booking Id"}
                type={"text"}
                name="unique_booking_id"
                value={formData.unique_booking_id}
                onChange={handleChange}
              />
            </div>
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Article"}
                type={"text"}
                name="article"
                value={formData.article}
                onChange={handleChange}
              />
            </div>
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Value"}
                type={"number"}
                name="approx_value"
                value={formData.approx_value}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex flex_gap_10 w-100 mt-10">
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Date"}
                type={"date"}
                name="lost_date"
                value={formData.lost_date}
                onChange={handleChange}
              />
            </div>
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Time"}
                type={"time"}
                name="lost_time"
                value={formData.lost_time}
                onChange={handleChange}
              />
            </div>
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Area"}
                type={"text"}
                name="lost_area"
                value={formData.lost_area}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="card-3d mt-20">
          <div className="card-3d-title">Found Details</div>

          <div className="w-100">
            <PrimaryInput
              size={"medium"}
              label={"Founder"}
              type={"text"}
              name="founder"
              value={formData.founder}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex_gap_10 w-100 mt-10">
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Date"}
                type={"date"}
                name="found_date"
                value={formData.found_date}
                onChange={handleChange}
              />
            </div>
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Time"}
                type={"time"}
                name="found_time"
                value={formData.found_time}
                onChange={handleChange}
              />
            </div>
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Area"}
                type={"text"}
                name="found_area"
                value={formData.found_area}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="card-3d mt-20">
          <div className="card-3d-title">Return Details</div>

          <div className="flex flex_gap_10">
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Date"}
                type={"date"}
                name="return_date"
                value={formData.return_date}
                onChange={handleChange}
              />
            </div>
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Person"}
                type={"text"}
                name="return_person"
                value={formData.return_person}
                onChange={handleChange}
              />
            </div>
            <div className="w-33">
              <PrimaryInput
                size={"medium"}
                label={"Authorized By"}
                type={"text"}
                name="authorized_by"
                value={formData.authorized_by}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="flex flex_gap_10 mt-20">
          <PrimaryButton
            size="medium"
            onClick={handleSubmit}
            title={id ? "Update" : "Create"}
          />
          <SecondaryButton
            size="medium"
            onClick={() => navigate(-1)}
            title={"Cancel"}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateLostFoundService;
