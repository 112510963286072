import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
  sortReportData,
} from "../../utils/utils";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import { SelectBasicWithSelectTag } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";

const ReportByRoomType = ({ reportData }) => {
  const [sortType, setSortType] = useState("");
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      room_type_name: "Room Category",
      booking_count: "Total Booking",
      total_sale: "Total Sale",
    },
  ];

  const sortedData = sortReportData(reportData, sortType);

  const rowMarkup =
    reportData &&
    sortedData?.map(
      ({ booking_count, room_type_name, total_sale, guest_state }, index) => {
        return (
          <CustomReportTableRow
            id={index}
            key={index}
            position={room_type_name}
          >
            <CustomReportTableCell>{room_type_name}</CustomReportTableCell>
            <CustomReportTableCell>{booking_count}</CustomReportTableCell>

            <CustomReportTableCell>
              {total_sale ? formatIndianCurrency(total_sale) : "0"}
            </CustomReportTableCell>
          </CustomReportTableRow>
        );
      }
    );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>

      <CustomReportTableCell>
        {calculateTotalWithReduce(reportData, "booking_count")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "total_sale")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );

  const sortingOptions = [
    { label: "select", value: "" },
    { label: "booking count(high to low)", value: "booking_count_high_to_low" },
    { label: "booking count(low to high)", value: "booking_count_low_to_high" },
    {
      label: "booking amount(high to low)",
      value: "booking_amount_high_to_low",
    },
    {
      label: "booking amount(low to high)",
      value: "booking_amount_low_to_high",
    },
  ];
  const handleSortChange = (e) => {
    setSortType(e.target.value);
  };
  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">
              Reservation By Room Categories
            </div>

            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>

          {reportData?.length > 0 && (
            <div className="flex flex_gap_15 align_items_end" id="pdf">
              <div
                className="flex flex_gap_5 align_items_center"
                style={{ width: "150px" }}
              >
                <span
                  className="w-30"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Sort By
                </span>
                <div className="w-70">
                  <SelectBasicWithSelectTag
                    size={"small"}
                    options={sortingOptions}
                    value={sortType}
                    onChange={handleSortChange}
                  />
                </div>
              </div>
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"Reservation By Room Categories"}
                ignoreFromPrint={"pdf"}
              />
              <DownloadCSVButton
                itemToDownloadID={"receipt"}
                downloadFileName={"Reservation By Room Categories"}
              />
            </div>
          )}
        </div>

        {reportData && reportData.length > 0 ? (
          <>
            <CustomReportTable
              headings={["Room Category", "Booking Count", "Total Sale"]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Reservation by Room Categories Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is Reservation by Room Categories Report?",
                  answer: (
                    <p>
                      The <b>Reservation by Room Categories</b> report provides
                      detailed insights into the number of reservations and
                      total sales for each type of room offered by the hotel. It
                      helps the hotel management understand which room
                      categories are most popular among guests and generate the
                      most revenue.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Reservation by Room Categories Report?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                          <b> Room Preference Insights:</b> This report helps
                          hotel management identify which room categories are in
                          high demand, enabling them to better meet guest
                          preferences and expectations.
                        </li>
                        <li>
                          <b>Revenue Optimization:</b> Understanding which rooms
                          generate the highest sales can guide pricing
                          strategies, room upgrades, and promotional offers for
                          specific categories to maximize revenue.
                        </li>
                        <li>
                          <b>Inventory Management:</b> The hotel can use this
                          data to manage room inventory effectively, ensuring
                          that high-demand room categories are available during
                          peak times.
                        </li>
                        <li>
                          <b> Marketing Strategies:</b> The report helps tailor
                          promotions or packages for popular room categories, or
                          alternatively, push promotions for lower-demand rooms
                          to balance occupancy across categories.
                        </li>
                        <li>
                          <b>Investment Decisions:</b> For hotels planning
                          renovations or expansions, this report provides data
                          on which room categories offer the best return on
                          investment, guiding resource allocation for
                          improvements.
                        </li>
                        {/* <li>
                          <b>Strategic Decision-Making: </b>The report informs
                          decisions on marketing strategies, commission
                          structures, and partnerships to enhance business
                          growth.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b> Understanding booking
                          trends through agents helps optimize inventory
                          management and resource allocation.
                        </li> */}
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportByRoomType;
