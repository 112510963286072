import React from "react";
import { PrimaryInput } from "../../../Components/NewCustomComp/Inputs/Inputs";
import ToggleSwitch from "../../../Components/NewCustomComp/Switch/ToggleSwitch";
import styles from "./basicDetails.module.scss";

const BasicDetails = ({
  formData,
  setFormData,
  handleInputChange,
  type,
  earlyBirdOfferType,
}) => {


  const handleChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value ? true : false,
    });
  };

  const handleInputChangeEarlyBird = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      promotion_wise_conditions: {
        ...prevState.promotion_wise_conditions,
        extend_stay_rewards: {
          ...prevState.promotion_wise_conditions.extend_stay_rewards,
          [field]: value,
        },
      },
    }));
  };

  return (
    <div>
      <PrimaryInput
        size="medium"
        required={true}
        label="Promotion Name"
        value={formData?.promotion_name}
        name="promotion_name"
        onChange={handleInputChange}
      />

      <div className="flex align_items_center justify_content_between mt-10">
        <span>
          Discount Type <sup style={{ color: "red" }}>*</sup>
        </span>
        <span className="flex align_items_end flex_gap_10 ">
          <label className="pointer">
            <input
              type="radio"
              name="promotion_mode"
              value="percentage"
              checked={formData?.promotion_mode === "percentage"}
              onChange={handleInputChange}
            />{" "}
            Percentage
          </label>
          <label className="pointer">
            <input
              type="radio"
              name="promotion_mode"
              value="fixed"
              checked={formData?.promotion_mode === "fixed"}
              onChange={handleInputChange}
            />{" "}
            Fixed
          </label>
        </span>
        <span>
          <PrimaryInput
            size="medium"
            name="promotion_amount"
            value={formData?.promotion_amount ? formData?.promotion_amount : ""}
            onChange={handleInputChange}
            type="number"
          />
        </span>
      </div>

      <div className="flex align_items_end flex_gap_10 mt-20">
        <div className="w-50 flex flex_gap_10  ">
          <div className="flex flex_gap_10 align_items_center ">
            <label style={{ fontSize: "14px" }}>Active</label>

            <ToggleSwitch
              isOn={formData?.is_active}
              size={"medium"}
              name={"is_active"}
              handleToggle={handleChange}
            />
          </div>

          <div className="flex flex_gap_10 align_items_center">
            <label style={{ fontSize: "14px" }}>Stackable</label>
            <ToggleSwitch
              isOn={formData?.is_stackable}
              size={"medium"}
              name={"is_stackable"}
              handleToggle={handleChange}
            />
          </div>
        </div>

        {type === "coupon-discount" && (
          <>
            <div className="w-50">
              <PrimaryInput
                name={"coupon_code"}
                size={"medium"}
                label={"Coupon Code"}
                value={formData?.coupon_code}
                onChange={handleInputChange}
              />
            </div>
          </>
        )}
      </div>

      {earlyBirdOfferType && <hr className="mt-20 mb-20" />}

      {earlyBirdOfferType === "discount" && (
        <div className="flex align_items_center justify_content_between">
          <div className="flex align_items_center flex_gap_5">
            <span className={styles.label}>
              Min Los <sup>*</sup>
            </span>
            <PrimaryInput
              size={"medium"}
              style={{ width: "50px" }}
              value={
                formData?.promotion_wise_conditions?.extend_stay_rewards
                  ?.min_los || ""
              }
              onChange={(e) => handleInputChangeEarlyBird("min_los", e.target.value)}
            />
          </div>
          <span className={styles.small_text}>
            *Set the Min LOS to 3 days or more for optimal results
          </span>
        </div>
      )}

      {/* ============================================ */}

      {earlyBirdOfferType === "free_nights" && (
        <div className={`${styles.label} flex flex_gap_20`}>
          <div className="flex align_items_center flex_gap_5">
            Stay For{" "}
            <PrimaryInput
              size={"medium"}
              style={{ width: "50px" }}
              value={
                formData?.promotion_wise_conditions?.extend_stay_rewards
                  ?.stay_for_nights || ""
              }
              onChange={(e) =>
                handleInputChangeEarlyBird("stay_for_nights", e.target.value)
              }
            />{" "}
            Nights <sup>*</sup>
          </div>
          <div className="flex align_items_center flex_gap_5">
            Pay For{" "}
            <PrimaryInput
              size={"medium"}
              style={{ width: "50px" }}
              value={
                formData?.promotion_wise_conditions?.extend_stay_rewards
                  ?.pay_for_nights || ""
              }
              onChange={(e) =>
                handleInputChangeEarlyBird("pay_for_nights", e.target.value)
              }
            />{" "}
            Nights <sup>*</sup>
          </div>
        </div>
      )}



    </div>
  );
};

export default BasicDetails;
