import React, { useEffect, useState } from "react";
import GoBackButtonCustom from "../../../Components/GoBackButtonCustom/GoBackButtonCustom";
import { useNavigate } from "react-router-dom";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useSelector } from "react-redux";
import Loader from "../../../Components/UI/Loader/Loader";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { getBaseUrl } from "../../../utils/utils";
import ToggleSwitch from "../../../Components/NewCustomComp/Switch/ToggleSwitch";
import { SvgDelete, SvgEdit } from "../../../assets/svgIcons";
import AddEditMiniBarItem from "./AddEditMiniBarItem";
import { PrimaryButton } from "../../../Components/NewCustomComp/Buttons/Buttons";
import ConfirmPopup from "../../../Components/NewCustomComp/confirmPopup/ConfirmPopup";
import NoDataFound from "../../../Components/CustomComponents/NoDataFound";

const MiniBarItems = () => {
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value)?.id;
  const [items, setItems] = useState([]);
  const [addItem, setAddItem] = useState(null);
  const [loader, setLoader] = useState(false);

  const fetchItemListing = async (id) => {
    const url = id
      ? `${baseUrl}/api/v1/house-keeping/mini-bar-item/${property_id}?id=${id}`
      : `${baseUrl}/api/v1/house-keeping/mini-bar-item/${property_id}`;
    setLoader(true);
    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        if (id) {
          setAddItem(data.data);
        } else {
          setItems(data.data);
        }
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchItemListing();
    }
  }, [property_id]);

  const handleAddItemClick = () => {
    setAddItem({
      item_name: "",
      category: "",
      rate: "",
      status: "",
      id: "",
    });
  };

  const [deletedId, setDeleteId] = useState(null);

  const deleteButtonClick = async (id) => {
    if (!deletedId) {
      setDeleteId(id);
      return;
    }

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-item/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "DELETE",
          body: JSON.stringify({
            ids: [deletedId],
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setDeleteId(null);
        fetchItemListing();
        showSuccessToast(data.message);
      }
    } catch (error) {
    } finally {
    }
  };

  const handleToggleChange = async (value, name, id) => {
    const data = items.find((item) => +item.id === +id);
    const editData = {
      ...data,
      status: value ? "active" : "inactive",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-item/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(editData),
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchItemListing();
        showSuccessToast(data.message);
      }
    } catch (error) {
      showErrorToast(
        error.message || "An error occurred while processing your request."
      );
    } finally {
    }
  };

  return (
    <div>
      <div className="flex justify_content_between  align_items_center">
        <GoBackButtonCustom
          buttonNextText={"Mini bar items"}
          onClick={() => navigate("/housekeeping/services/mini-bar")}
        />
        <PrimaryButton
          size="medium"
          title={"Add Item"}
          onClick={handleAddItemClick}
        />
      </div>

      <div className="mt-10">
        {loader ? (
          <Loader />
        ) : (
          <>
            {items.length > 0 ? (
              <>
                <CustomShopifyIndexTable
                  headings={["Name", "Category", "Price", ""]}
                >
                  {items.map((item) => (
                    <React.Fragment key={item.id}>
                      <CustomShopifyIndexTableRow>
                        <CustomShopifyIndexTableCell>
                          {item.item_name}
                        </CustomShopifyIndexTableCell>
                        <CustomShopifyIndexTableCell>
                          {item.category}
                        </CustomShopifyIndexTableCell>
                        <CustomShopifyIndexTableCell>
                          {item.rate}
                        </CustomShopifyIndexTableCell>
                        <CustomShopifyIndexTableCell>
                          <span className="flex flex_gap_10 align_items_center">
                            <ToggleSwitch
                              size="small"
                              isOn={item.status === "active" ? true : false}
                              handleToggle={handleToggleChange}
                              id={item.id}
                            />
                            <span
                              onClick={() => fetchItemListing(item.id)}
                              className="cursor_pointer"
                            >
                              <SvgEdit />
                            </span>

                            <span
                              onClick={() => deleteButtonClick(item.id)}
                              className="cursor_pointer"
                            >
                              <SvgDelete />
                            </span>
                          </span>
                        </CustomShopifyIndexTableCell>
                      </CustomShopifyIndexTableRow>
                    </React.Fragment>
                  ))}
                </CustomShopifyIndexTable>
              </>
            ) : (
              <NoDataFound />
            )}
          </>
        )}
      </div>

      {addItem && (
        <AddEditMiniBarItem
          setAddItem={setAddItem}
          addItem={addItem}
          baseUrl={baseUrl}
          property_id={property_id}
          userToken={userToken}
          fetchItemListing={fetchItemListing}
        />
      )}

      {deletedId && (
        <ConfirmPopup
          heading={"Delete"}
          subHeading={"Are you sure you want to delete this item?"}
          handleConfirmClick={deleteButtonClick}
          setShowPopup={setDeleteId}
        />
      )}
    </div>
  );
};

export default MiniBarItems;
