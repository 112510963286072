import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import Loader from "./UI/Loader/Loader";
import NoDataFound from "./CustomComponents/NoDataFound";
import { getDecryptedData } from "../utils/encryptStorage";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "./CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import Pagination from "./NewCustomComp/pagination/Pagination";
import ButtonToDownloadTablePDF from "./CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { SvgBooking } from "../assets/svgIcons";
import SliderComponentSkeleton from "./NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";
import CustomerPageSliderCustomerDetails from "./CustomComponents/CutomersPageComponents/CustomerPageSliderCustomerDetails";
import CustomPageSliderCustomersBookings from "./CustomComponents/CutomersPageComponents/CustomPageSliderCustomersBookings";
import ApiSearchInput from "./NewCustomComp/apiSearchInput/ApiSearchInput";
import {
  PrimaryButton,
  SecondaryButton,
} from "./NewCustomComp/Buttons/Buttons";
import AddCustomerPopup from "./CustomComponents/CutomersPageComponents/AddCustomerPopup";
import AgentCustomerCategories from "./AgentsCustomesCategories/AgentCustomerCategories";
import { formatIndianCurrency } from "../utils/utils";

const Customers = () => {
  const permission = localStorage.getItem("permissions");
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const handlePageChange = (page) => {
    if (page) {
      fetchInfo(property_id.id, page);
    }
  };

  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [orders, setOrders] = useState("");

  const [customerCategory, setCustomerCategory] = useState([]);

  const fetchICategory = async (propertyId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/customer/category/${propertyId}`;
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setCustomerCategory(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const fetchInfo = async (propertyId, page) => {
    const url = page
      ? `${process.env.REACT_APP_BASE_URL}/api/v1/get/guests/customer_list/${propertyId}?pagination=true&page=${page}`
      : `${process.env.REACT_APP_BASE_URL}/api/v1/get/guests/customer_list/${propertyId}?pagination=true&page=${page}`;
    if (!propertyId) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id && userToken) {
      fetchInfo(property_id?.id);
      fetchICategory(property_id?.id);
    }
  }, [property_id, userToken]);

  const rowMarkup =
    orders &&
    orders?.guests &&
    orders?.guests?.data?.map(
      ({
        id,
        guest_name,
        guest_phone,
        guest_email,
        guest_state,
        guest_pincode,
        category_name,
        customer_statistics,
        index,
      }) => (
        <CustomShopifyIndexTableRow id={id} key={id} position={index}>
          <CustomShopifyIndexTableCell>
            <div
              style={{
                margin: "7px 0",
                fontSize: "14px",
                fontWeight: "500",
                color: "#3968ed",
                textDecoration: "underline",
              }}
              onClick={() => handleOpenCustomerDetailsSlider(id, "details")}
              className="pointer"
            >
              {guest_name || "NA"}
            </div>
          </CustomShopifyIndexTableCell>

          <CustomShopifyIndexTableCell>
            <CustomCssComponentTableText
              innerText={guest_phone || "NA"}
            ></CustomCssComponentTableText>
          </CustomShopifyIndexTableCell>

          <CustomShopifyIndexTableCell>
            <div style={{ fontSize: "14px", fontWeight: "500" }}>
              {guest_email || "NA"}
            </div>
          </CustomShopifyIndexTableCell>

          <CustomShopifyIndexTableCell>
            <CustomCssComponentTableText
              innerText={guest_state || "NA"}
            ></CustomCssComponentTableText>
          </CustomShopifyIndexTableCell>

          <CustomShopifyIndexTableCell>
            <CustomCssComponentTableText
              innerText={guest_pincode || "NA"}
            ></CustomCssComponentTableText>
          </CustomShopifyIndexTableCell>
          <CustomShopifyIndexTableCell>
            {category_name || "NA"}
          </CustomShopifyIndexTableCell>

          <CustomShopifyIndexTableCell>
            <div style={{ fontSize: "14px", fontWeight: "500" }}>
              {customer_statistics?.total_checkins || "NA"}
            </div>
          </CustomShopifyIndexTableCell>
          <CustomShopifyIndexTableCell>
            <div style={{ fontSize: "14px", fontWeight: "500" }}>
              {customer_statistics?.total_revenue || "NA"}
            </div>
          </CustomShopifyIndexTableCell>
          <CustomShopifyIndexTableCell>
            <span
              onClick={() =>
                handleOpenCustomerDetailsSlider(id, "bookings", guest_name)
              }
            >
              <SvgBooking />
            </span>
          </CustomShopifyIndexTableCell>
        </CustomShopifyIndexTableRow>
      )
    );

  const [agentDetailsSlider, setAgentDetailsSlider] = useState(false);
  const [sliderData, setSliderData] = useState({
    customersDetails: {},
    bookingsDetails: [],
    customer_name: "",
  });
  const [bookingDetailsSlider, setBookingDetailsSlider] = useState(false);

  const handleOpenCustomerDetailsSlider = async (id, status, name) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/view/guest/${property_id?.id}?id=${id}&status=${status}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      if (status === "details") {
        setAgentDetailsSlider(true);
        setSliderData({
          customersDetails: data.data,
          bookingsDetails: [],
          customer_name: "",
        });
        return;
      }
      if (status === "bookings") {
        setBookingDetailsSlider(true);
        setSliderData({
          customersDetails: "",
          bookingsDetails: data.data,
          customer_name: name,
        });

        return;
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const handleSearch = async (value) => {
    if (!value) {
      fetchInfo(property_id?.id);
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/guests/${property_id?.id}?search=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [showAddCustomers, setShowAddCustomers] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  return (
    <>
      <div className="room-types-full-container" id="customers">
        <div className="room-types-full-container-div">
          <div className="room-type-heading flex justify_content_between align_items_center w-100">
            <span>Customers</span>

            <div className="flex flex_gap_10">
              {permission.includes("full_access_customer_management") && (
                <>
                  <ButtonToDownloadTablePDF
                    itemToDownloadID={"customers"}
                    longPdf={true}
                    downloadFileName={"customers"}
                  />

                  <SecondaryButton
                    size={"medium"}
                    title={"Categories"}
                    onClick={() => setShowCategory(true)}
                  />

                  <PrimaryButton
                    size={"medium"}
                    title={"Add Customers"}
                    onClick={() => setShowAddCustomers(true)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {loader ? (
          <Loader />
        ) : (
          <>
            <div
              className="mb-10 bg-white p-5 flex flex_gap_10"
              style={{ borderRadius: "6px" }}
            >
              <span>
                <span>
                  <b>{orders?.overall_statistics?.customers_count || 0}</b>
                </span>{" "}
                <span style={{ fontSize: "12px" }}>Total Customers </span>
              </span>

              <span>|</span>
              <span>
                <span>
                  <b>{formatIndianCurrency(orders?.overall_statistics?.total_revenue)}</b>
                </span>{" "}
                <span style={{ fontSize: "12px" }}>Total Assets </span>
              </span>

              <span style={{ margin: " 0 0 0 auto" }}>
                <ApiSearchInput
                  onSearch={handleSearch}
                  placeholder={"Customer Name/ Phone"}
                />
              </span>
            </div>
            {orders && orders?.guests && orders?.guests?.data?.length > 0 ? (
              <>
                <div className="mb-10">
                  <CustomShopifyIndexTable
                    showCheckbox={false}
                    headings={[
                      "Name",
                      "Phone",
                      "Email",
                      "State",
                      "Pincode",
                      "Category",
                      "Total Bookings",
                      "Total Assets",
                      "",
                    ]}
                  >
                    {rowMarkup}
                  </CustomShopifyIndexTable>
                </div>
                <div className="w-fit-content  mx-auto">
                  <Pagination
                    type={"advance"}
                    currentPage={orders.guests.current_page}
                    totalPages={orders.guests.last_page}
                    onPageChange={handlePageChange}
                  />
                </div>
              </>
            ) : (
              <NoDataFound />
            )}
          </>
        )}
      </div>

      {agentDetailsSlider && (
        <SliderComponentSkeleton
          show={agentDetailsSlider}
          setShow={setAgentDetailsSlider}
          smallTitle={`<span  style="font-weight: 600;">Details</span>`}
          title={sliderData?.customersDetails?.name}
        >
          <CustomerPageSliderCustomerDetails
            data={sliderData.customersDetails}
          />
        </SliderComponentSkeleton>
      )}

      {bookingDetailsSlider && (
        <SliderComponentSkeleton
          show={bookingDetailsSlider}
          setShow={setBookingDetailsSlider}
          smallTitle={`<span  style="font-weight: 600;">Bookings</span>`}
          title={sliderData.customer_name}
        >
          <CustomPageSliderCustomersBookings
            data={sliderData.bookingsDetails}
          />
        </SliderComponentSkeleton>
      )}

      {showAddCustomers && (
        <div className="global_popup_container_background">
          <div className="global_popup_container" style={{ width: "700px" }}>
            <AddCustomerPopup
              show={showAddCustomers}
              setShow={setShowAddCustomers}
              property_id={property_id}
            />
          </div>
        </div>
      )}

      {showCategory && (
        <div className="global_popup_container_background ">
          <div className="global_popup_container" style={{ width: "80%" }}>
            <AgentCustomerCategories
              categoriesData={customerCategory}
              setCategoriesData={setCustomerCategory}
              show={showCategory}
              setShow={setShowCategory}
              type={"customer"}
              
            />
          </div>
        </div>
      )}
    </>
  );
};
export default Customers;
