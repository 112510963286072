import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GoBackButtonCustom from "../../Components/GoBackButtonCustom/GoBackButtonCustom";
import styles from "./createPromotion.module.scss";
import BasicDetails from "./OffersComponent/BasicDetails";
import PromotionsDiscounts from "./PromotionsDiscounts";
import BlackOutOptions from "./OffersComponent/BlackOutOptions";
import PromotionDates from "./OffersComponent/PromotionDates";
import ApplyOn from "./OffersComponent/ApplyOn";
import Triggers from "./OffersComponent/Triggers";
import ApplicableOn from "./OffersComponent/ApplicableOn";
import ToggleSwitch from "../../Components/NewCustomComp/Switch/ToggleSwitch";
import ConditionBuilder from "./OffersComponent/ConditionBuilder";
import MultiDatePicker from "./OffersComponent/MultidatePicker";
import PromotionDatesSimplifies from "./OffersComponent/PromotionDatesSimplifies";
import { useSelector } from "react-redux";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../Components/NewCustomComp/Buttons/Buttons";
import { getDecryptedData } from "../../utils/encryptStorage";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import SelectionBoxExtendStayReward from "./OffersComponent/SelectionBoxExtendStayReward";

const CreatePromotionDiscounts = () => {
  function convertTimeToHHMMSS(time) {
    if (!time) return "";
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}:00`;
  }
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value)?.id;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const slug = queryParams.get("type");
  const promotion_type = slug
    ? slug.replaceAll("-", "_").replaceAll("=", " ")
    : "basic_promotion";

  const { id } = useParams();

  const fetchEditData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit-promotion/${id}/${property_id}`,
        requestOptions
      );
      const data = await response.json();

      if (data.success) {
        setFormData({
          promotion_type: promotion_type,
          promotion_name: data.data.promotion_name,
          promotion_mode: data.data.promotion_mode,
          promotion_amount: data.data.promotion_amount,
          is_active: data.data.is_active ? true : false,
          is_stackable: data.is_stackable ? true : false,

          coupon_code: data.data.coupon_code,
          start_date: data.data.start_date,
          end_date: data.data.end_date,
          start_time: data.data.start_time,
          end_time: data.data.end_time,
          is_trigger_active: data.data.is_trigger_active ? true : false,

          // channels
          selectedChannels: data?.data?.applications?.map(
            (item) => item?.applies_to
          ),
          selectedOTAs: data?.data?.applications?.find(
            (item) => item?.applies_to === "ota"
          )?.specific_ids,

          // meal plans

          selectedMealPlans: data?.data?.entities.find(
            (item) => item?.entity_type === "meal_plans"
          )?.entity_ids,
          triggers: data?.data?.trigger,

          ...(slug === "early-bird-offer" && {
            promotion_wise_conditions: data.data.promotion_wise_condition,
          }),
          ...(slug === "last-minute-deal" && {
            promotion_wise_conditions: data.data.promotion_wise_condition,
          }),
          ...(slug === "extend-stay-rewards" && {
            promotion_wise_conditions: data.data.promotion_wise_condition,
          }),
        });
        setConditions(data.data.conditions);
        setBlackoutDates(data.data.blackout_dates.map((item) => item.date));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id && property_id) {
      fetchEditData();
    }
  }, [id, property_id]);

  const [formData, setFormData] = React.useState({
    promotion_type: promotion_type,
    promotion_name: "",
    promotion_mode: "percentage",
    promotion_amount: 20,

    //   promotional dates
    // probational_dates_types: "check_in",
    // check_in_date: "",
    // end_date_check_in: false,
    // end_date_check_in_value: "",
    // booking_date: "",
    // end_date_booking: false,
    // end_date_booking_value: "",
    //  end promotional dates

    coupon_code: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    is_active: true,
    is_stackable: false,

    // channels
    selectedChannels: [],
    selectedOTAs: [],

    // channels

    // applicable on
    selectedRoomCategories: [],

    // triggers

    is_trigger_active: false,
    triggers: {
      occupancy: { active: false, from: "", to: "" },
      revenue: { active: false, limit: "" },
      revenueTgt: { active: false, percentage: "" },
      compRates: { active: false, from: "", to: "" },
      usage: { active: false, total: "" },
    },

    ...(slug === "early-bird-offer" && {
      promotion_wise_conditions: {
        early_bird_offer: {
          check_in_start_date: "",
          check_in_end_date: "",
          advance_booking_period: "",
        },
      },
    }),

    ...(slug === "last-minute-deal" && {
      promotion_wise_conditions: {
        last_minute_deal: {
          check_in_start_date: "",
          check_in_end_date: "",
          minimum_lead_time: "",
        },
      },
    }),

    ...(slug === "extend-stay-rewards" && {
      promotion_wise_conditions: {
        extend_stay_rewards: {
          type: "discount",
          min_los: "3",
        },
      },
    }),
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if(type==="number" &&  value<0){
      showErrorToast("Amount Should not be less than 0");
      return;
    }
    setFormData((prev) => {
      let newValue = type === "checkbox" ? checked : value;
      if (name === "promotion_amount" && prev.promotion_mode === "percentage") {
        newValue = Math.min(100, Number(newValue));
      }

  

      if (name === "promotion_mode") {
        return {
          ...prev,
          [name]: newValue,
          promotion_amount: "",
        };
      }

      return {
        ...prev,
        [name]: newValue,
      };
    });
  };

  const [conditions, setConditions] = React.useState([
    {
      field: "booking_date",
      operator: ">=",
      value: "",
      logical_operator: "AND",
    },
  ]);

  const [blackOutDates, setBlackoutDates] = React.useState([]);

  const handleDatesUpdate = (dates) => {
    setBlackoutDates(dates); // Update the parent state
  };

  const [selectedRoomCategories, setSelectedRoomCategories] = React.useState(
    []
  );
  const validatePromotionData = (formData, slug) => {
    if (!formData.promotion_name) return "Promotion name is required.";
    if (!formData.start_date) return "Start date is required.";
    if (!formData.end_date) return "End date is required.";
    if (!formData.start_time) return "Start time is required.";
    if (!formData.end_time) return "End time is required.";
    if (!formData.promotion_amount) return "Promotion amount is required.";

    // Type-specific validations
    switch (slug) {
      case "coupon-discount":
        if (!formData.coupon_code)
          return "Coupon code is required for coupon discount.";
        break;

      case "last-minute-deal":
        if (
          !formData.promotion_wise_conditions?.last_minute_deal
            ?.check_in_start_date
        )
          return "Check-in start date is required for last-minute deal.";
        if (
          !formData.promotion_wise_conditions?.last_minute_deal
            ?.check_in_end_date
        )
          return "Check-in end date is required for last-minute deal.";
        if (
          !formData.promotion_wise_conditions?.last_minute_deal
            ?.minimum_lead_time
        )
          return "Minimum lead time is required for last-minute deal.";
        break;

      case "early-bird-offer":
        if (
          !formData.promotion_wise_conditions?.early_bird_offer
            ?.check_in_start_date
        )
          return "Check-in start date is required for early bird offer.";
        if (
          !formData.promotion_wise_conditions?.early_bird_offer
            ?.check_in_end_date
        )
          return "Check-in end date is required for early bird offer.";
        if (
          !formData.promotion_wise_conditions?.early_bird_offer
            ?.advance_booking_period
        )
          return "Advance booking period is required for early bird offer.";
        break;

      case "extend-stay-rewards":
        const type =
          formData.promotion_wise_conditions?.extend_stay_rewards?.type;
        if (type === "discount") {
          if (!formData.promotion_wise_conditions?.extend_stay_rewards?.min_los)
            return "Minimum length of stay is required for extend stay reward (discount).";
        } else if (type === "free_nights") {
          if (
            !formData.promotion_wise_conditions?.extend_stay_rewards
              ?.stay_for_nights
          )
            return "Stay for nights is required for extend stay reward (free nights).";
          if (
            !formData.promotion_wise_conditions?.extend_stay_rewards
              ?.pay_for_nights
          )
            return "Pay for nights is required for extend stay reward (free nights).";
        } else {
          return "Invalid type for extend stay reward.";
        }
        break;

      case "basic-promotion":
        return null;

      default:
        return "Invalid promotion type.";
    }

    return null; // No errors
  };

  const handleCreatePromotions = async () => {
    const errorMessage = validatePromotionData(formData, slug);
    if (errorMessage) {
      showErrorToast(errorMessage);
      return;
    }
    const blackOutDatesArrayOfObjects = blackOutDates.map((item) => {
      return {
        date: item,
      };
    });

    const applicableOn = formData.selectedChannels.map((channel) => ({
      applies_to: channel,
      specific_ids: channel === "ota" ? formData.selectedOTAs : [""], // Conditional check for "ota"
    }));

    const entities = {
      entity_type: "meal_plans",
      entity_ids: selectedRoomCategories.flatMap((room) =>
        room.meal_plans.map((plan) => plan.meal_plan_id)
      ),
    };

    if (entities.entity_ids.length === 0) {
      showErrorToast("Please select at least one meal plan");
      return;
    }
    if (formData.selectedChannels.length === 0) {
      showErrorToast("Please select at least one channel");
      return;
    }

    const data = {
      promotion_type: formData.promotion_type,
      promotion_mode: formData.promotion_mode,
      promotion_name: formData.promotion_name,
      coupon_code: formData.coupon_code || "",
      start_date: formData.start_date,
      end_date: formData.end_date,
      start_time: formData.start_time
        ? convertTimeToHHMMSS(formData.start_time)
        : "",
      end_time: formData.end_time ? convertTimeToHHMMSS(formData.end_time) : "",
      promotion_amount: formData.promotion_amount,
      is_active: formData.is_active,
      is_stackable: formData.is_stackable,
      conditions: conditions,
      blackout_dates: blackOutDatesArrayOfObjects,
      is_trigger_active: formData.is_trigger_active,
      triggers: formData.triggers,
      applications: applicableOn,
      entities: [entities],
      ...(slug === "early-bird-offer" && {
        promotion_wise_conditions: formData.promotion_wise_conditions,
      }),
      ...(slug === "last-minute-deal" && {
        promotion_wise_conditions: formData.promotion_wise_conditions,
      }),
      ...(slug === "extend-stay-rewards" && {
        promotion_wise_conditions: formData.promotion_wise_conditions,
      }),
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/store/promotion/${property_id}`,
        requestOptions
      );
      const data = await response.json();

      if (data.success) {
        showSuccessToast(data.message);
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePromotions = async () => {
    const errorMessage = validatePromotionData(formData, slug);
    if (errorMessage) {
      showErrorToast(errorMessage);
      return;
    }

    const blackOutDatesArrayOfObjects = blackOutDates.map((item) => {
      return {
        date: item,
      };
    });

    const applicableOn = formData.selectedChannels.map((channel) => ({
      applies_to: channel,
      specific_ids: channel === "ota" ? formData.selectedOTAs : [""], // Conditional check for "ota"
    }));

    const entities = {
      entity_type: "meal_plans",
      entity_ids: selectedRoomCategories.flatMap((room) =>
        room.meal_plans.map((plan) => plan.meal_plan_id)
      ),
    };
    if (entities.entity_ids.length === 0) {
      showErrorToast("Please select at least one meal plan");
      return;
    }
    if (formData.selectedChannels.length === 0) {
      showErrorToast("Please select at least one channel");
      return;
    }

    const data = {
      promotion_type: formData.promotion_type,
      promotion_mode: formData.promotion_mode,
      promotion_name: formData.promotion_name,
      coupon_code: formData.coupon_code || "",
      start_date: formData.start_date,
      end_date: formData.end_date,
      start_time: formData.start_time
        ? convertTimeToHHMMSS(formData.start_time)
        : "",
      end_time: formData.end_time ? convertTimeToHHMMSS(formData.end_time) : "",
      promotion_amount: formData.promotion_amount,
      is_active: formData.is_active,
      is_stackable: formData.is_stackable,
      conditions: conditions,
      blackout_dates: blackOutDatesArrayOfObjects,
      is_trigger_active: formData.is_trigger_active,
      triggers: formData.triggers,
      applications: applicableOn,
      entities: [entities],
      ...(slug === "early-bird-offer" && {
        promotion_wise_conditions: formData.promotion_wise_conditions,
      }),
      ...(slug === "last-minute-deal" && {
        promotion_wise_conditions: formData.promotion_wise_conditions,
      }),
      ...(slug === "extend-stay-rewards" && {
        promotion_wise_conditions: formData.promotion_wise_conditions,
      }),
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/promotion/${id}/${property_id}`,
        requestOptions
      );
      const data = await response.json();

      if (data.success) {
        showSuccessToast(data.message);
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main_container">
      <div className="flex align_items_center  justify_content_between mb-10">
        <GoBackButtonCustom
          buttonNextText={id ? "Update Promotion" : "Create Promotion"}
          onClick={() => navigate(-1)}
        />

        <div className={styles.promotionName}>
          {promotion_type.replaceAll("_", " ")}
        </div>
      </div>

      <div className="flex flex_gap_10">
        <div className="left w-60">
          {slug === "extend-stay-rewards" && (
            <div className="mb-10">
              <SelectionBoxExtendStayReward
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          )}

          <div className="card-3d">
            <h3 className={styles.heading}>Basic Details</h3>
            <BasicDetails
              formData={formData}
              setFormData={setFormData}
              handleInputChange={handleInputChange}
              type={slug}
              earlyBirdOfferType={
                slug === "extend-stay-rewards"
                  ? formData?.promotion_wise_conditions["extend_stay_rewards"]
                      ?.type
                  : null
              }
            />
          </div>
          <div className="card-3d mt-10">
            <h3 className={styles.heading}>Promotion Dates</h3>
            <PromotionDatesSimplifies
              formData={formData}
              setFormData={setFormData}
              type={slug}
            />
          </div>

          <div className="card-3d mt-10">
            <h3 className={styles.heading}>Conditions Options</h3>
            <MultiDatePicker
              defaultDates={blackOutDates}
              onDatesChange={handleDatesUpdate}
              min={formData?.start_date}
              max={formData?.end_date}
            />
            <div className="mt-10">
              <ConditionBuilder
                conditions={conditions}
                setConditions={setConditions}
              />
            </div>
          </div>

          <div className="flex flex_gap_10 mt-20">
            <PrimaryButton
              size="medium"
              title={id ? "Update" : "Create"}
              onClick={id ? handleUpdatePromotions : handleCreatePromotions}
            />
            <SecondaryButton
              size="medium"
              title={"Discard"}
              onClick={() => navigate(-1)}
            />
          </div>
        </div>

        <div className="right w-40">
          <div className="card-3d">
            <h3 className={styles.heading}>Apply On</h3>
            <ApplyOn formData={formData} setFormData={setFormData} />
          </div>

          <div className="card-3d mt-10">
            <h3 className={styles.heading}>Applicable On</h3>
            <ApplicableOn
              selectedRoomCategories={selectedRoomCategories}
              setSelectedRoomCategories={setSelectedRoomCategories}
              property_id={property_id}
              selectMealPlansArray={formData?.selectedMealPlans}
            />
          </div>

          <div className="card-3d mt-10">
            <div className={`${styles.heading} flex flex_gap_10`}>
              Triggers
              <ToggleSwitch
                size="medium"
                isOn={formData?.is_trigger_active}
                name={"is_trigger_active"}
                handleToggle={(value, name) =>
                  setFormData((prev) => ({ ...prev, [name]: value }))
                }
              />
            </div>

            {formData?.is_trigger_active && (
              <Triggers formData={formData} setFormData={setFormData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePromotionDiscounts;
