import React, { useState } from "react";
import { PrimaryInput } from "../../../Components/NewCustomComp/Inputs/Inputs";
import ToggleSwitch from "../../../Components/NewCustomComp/Switch/ToggleSwitch";
import { SelectBasicWithSelectTag } from "../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../Components/NewCustomComp/Buttons/Buttons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";

const AddEditMiniBarItem = ({
  addItem,
  setAddItem,
  baseUrl,
  property_id,
  userToken,
  fetchItemListing,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToggleChange = (value) => {
    setAddItem((prevData) => ({
      ...prevData,
      status: value ? "active" : "inactive",
    }));
  };

  const categories = [
    { label: "select", value: "" },
    { label: "Beverages", value: "beverages" },
    { label: "Snacks", value: "snacks" },
    { label: "Confectionery", value: "confectionery" },
    { label: "Tobacco Products", value: "tobacco_products" },
    { label: "others", value: "others" },
  ];

  const handleAddItemClick = async () => {
    if(addItem.item_name === "" || addItem.category === "" || addItem.rate === "" || addItem.status === "") {
       showErrorToast("Please enter all required fields");
       return
    }
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-item/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(addItem),
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchItemListing();
        setAddItem(null);
        showSuccessToast(data.message);
      }
    } catch (error) {
      showErrorToast(
        error.message || "An error occurred while processing your request."
      );
    } finally {
    }
  };

  const handleCancelClick = () => {
    setAddItem(null);
  };
  return (
    <div className="global_popup_container_background">
      <div className="global_popup_container">
        <div className="global_popup_container_heading">
          {addItem.id ? "Edit Item" : "Add Item"}
        </div>

        <div className="mt-10 flex flex_gap_10">
          <div className="w-50">
            <PrimaryInput
              size={"medium"}
              label={"Item Name"}
              type={"text"}
              name={"item_name"}
              onChange={handleInputChange}
              value={addItem.item_name}
            />
          </div>
          <div className="w-50">
            <SelectBasicWithSelectTag
              options={categories}
              size={"medium"}
              label={"Item Category"}
              type={"text"}
              name={"category"}
              onChange={handleInputChange}
              value={addItem.category}
            />
          </div>
        </div>

        <div className="mt-10 flex flex_gap_10">
          <div className="w-50">
            <PrimaryInput
              size={"medium"}
              label={"Item Price"}
              type={"number"}
              name={"rate"}
              onChange={handleInputChange}
              value={addItem.rate}
            />
          </div>
          <div className="w-50 flex align_items_end flex_gap_10">
            <label>Status</label>
            <ToggleSwitch
              size="medium"
              label="Status"
              name="status"
              isOn={addItem.status === "active" ? true : false}
              handleToggle={handleToggleChange}
            />
          </div>
        </div>

        <div className="flex flex_gap_10 mt-10">
          <PrimaryButton
            size="medium"
            title={addItem?.id ? "Update" : "Add"}
            onClick={handleAddItemClick}
          />

          <SecondaryButton
            size="medium"
            onClick={handleCancelClick}
            title={"Cancel"}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditMiniBarItem;
