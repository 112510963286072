import React from "react";
import { showErrorToast } from "../../../assets/toastUtils";
import { PrimaryInput } from "../../../Components/NewCustomComp/Inputs/Inputs";
import EarlyBirdPromotionDates from "./EarlyBirdPromotionDates";
import LastMinuteDealPromotionDates from "./LastMinuteDealPromotionDates";

const PromotionDatesSimplifies = ({ formData, setFormData, type }) => {
  console.log("type", type);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "promotion_mode") {
      setFormData({
        ...formData,
        [name]: value,
        promotion_amount: 0,
      });
      return;
    }

    if (
      name === "promotion_amount" &&
      formData.promotion_mode === "percentage"
    ) {
      if (+value > 100) {
        return showErrorToast(
          "Percentage amount should be less than or equal to 100"
        );
      }
    }

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <div>
      <div className="flex flex_gap_10">
        <div className="w-half">
          <PrimaryInput
            label={"Start Date"}
            type="date"
            name="start_date"
            value={formData?.start_date}
            onChange={handleChange}
            size={"medium"}
            required={true}
            step={"2"}
          />
        </div>

        <div className="w-half">
          <PrimaryInput
            type="date"
            name="end_date"
            value={formData?.end_date}
            onChange={handleChange}
            size={"medium"}
            label={"End Date"}
            required={true}
          />
        </div>
      </div>

      <div className="flex flex_gap_10 mt-10">
        <div className="w-half">
          <PrimaryInput
            label={"Start Time"}
            size={"medium"}
            type="time"
            name="start_time"
            value={formData.start_time}
            onChange={handleChange}
            required={true}
          />
        </div>

        <div className="w-half">
          <PrimaryInput
            label={"End Time"}
            size={"medium"}
            type="time"
            name="end_time"
            value={formData.end_time}
            onChange={handleChange}
            required={true}
          />
        </div>
      </div>

      {type === "early-bird-offer" && (
        <EarlyBirdPromotionDates
          fromData={formData}
          setFromData={setFormData}
        />
      )}

      {type === "last-minute-deal" && (
        <LastMinuteDealPromotionDates
          fromData={formData}
          setFromData={setFormData}
        />
      )}
    </div>
  );
};

export default PromotionDatesSimplifies;
