import React, { useEffect } from "react";
import styles from "./PromotionsAddons.module.scss";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../Components/NewCustomComp/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { getBaseUrl } from "../../../utils/utils";
import { SvgDelete, SvgEdit } from "../../../assets/svgIcons";
import { showSuccessToast } from "../../../assets/toastUtils";
import ConfirmPopup from "../../../Components/NewCustomComp/confirmPopup/ConfirmPopup";
import NoDataFound from "../../../Components/CustomComponents/NoDataFound";
import Loader from "../../../Components/UI/Loader/Loader";

const PromotionsAddonsPage = () => {
  // Global Variables
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const propertyId = JSON.parse(localStorage.getItem("selectedProperty"))?.id;
  // const propertyId = 1;
  // End Global Variables

  // States
  const [addons, setAddons] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  // End States

  // All Api's

  const fetchAddons = async () => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/addons/offers/${propertyId}`,
        requestOptions
      );
      const data = await response.json();
      if (data?.success) {
        setAddons(data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const DeleteAddons = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      ids: [id],
    });
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/addons/offers/${propertyId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setDeleteId(null)
        showSuccessToast(data.message);
        fetchAddons();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // End All Api's

  useEffect(() => {
    if (propertyId) {
      fetchAddons();
    }
  }, [propertyId]);

  const [deleteId, setDeleteId] = React.useState(null);

  const handleDelete = () => {
    DeleteAddons(deleteId);
  };

  // console.log("addons", addons)

  return (
    <div>
      <div className="flex justify_content_end mt-10">
        <PrimaryButton
          title={"Create Addon"}
          size="medium"
          onClick={() => navigate("/promotions/add-ons/create")}
        />
      </div>

     {
      loader ? <Loader/> : (<>
      
      {addons && addons.length > 0 ? (
        <CustomShopifyIndexTable
          style={{ marginTop: "10px" }}
          headings={[
            "Add-on Name",
            "Price",
            "Tax",
            "Discount",
            "Status",
            "Action",
          ]}
        >
          {addons &&
            addons.length > 0 &&
            addons.map((item, index) => {
              // console.log(item)
              return (
                <CustomShopifyIndexTableRow key={index}>
                  <CustomShopifyIndexTableCell>
                    {item?.addon_name}
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    {item?.price}
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    {item?.addon_tax} %
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    {item?.is_discount_active
                      ? item?.discount_type === "percentage"
                        ? `${item?.discount} %`
                        : `₹ ${item?.discount}`
                      : "N/A"}
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    <div
                      className={`${
                        item.status === "active"
                          ? styles.active
                          : styles.inactive
                      } flex align_items_center justify_content_center`}
                    >
                      {item?.status}
                    </div>
                  </CustomShopifyIndexTableCell>
                  <CustomShopifyIndexTableCell>
                    <div className="flex align_items_center flex_gap_15">
                      <span
                        onClick={() =>
                          navigate(`/promotions/add-ons/edit/${item?.id}`)
                        }
                        className="flex align_items_center pointer"
                      >
                        <SvgEdit />
                      </span>
                      <span
                        onClick={() => {
                          setDeleteId(item?.id);
                        }}
                        className="flex align_items_center pointer"
                      >
                        <SvgDelete />
                      </span>
                    </div>
                  </CustomShopifyIndexTableCell>
                </CustomShopifyIndexTableRow>
              );
            })}
        </CustomShopifyIndexTable>
      ) : (
        <NoDataFound />
      )}
      
      
      
      
      
      
      
      </>)
     }

      {/* {addOffers && (
        <div className='bg_backdrop'> Add Offers Popup</div>
      )} */}

      {deleteId && (
        <ConfirmPopup
          showPopup={deleteId}
          heading={"Delete Addon"}
          subHeading={"Are you sure you want to delete this addon?"}
          setShowPopup={setDeleteId}
          handleConfirmClick={handleDelete}
        />
      )}
    </div>
  );
};

export default PromotionsAddonsPage;
