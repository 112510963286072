import React from "react";
import styles from "../../../OffersComponent/selectionBoxExtendStayReward.module.scss";

import {
  SvgLock,
  SvgLockTransparent,
  SvgPersons,
} from "../../../../../assets/svgIcons";

const AddonTypeSelectionBox = ({ setFormData, formData }) => {
  const handleTypeChange = (newType) => {
    setFormData((prevState) => ({
      ...prevState,
      price_type: newType,
    }));
  };

  const selectedOption = formData?.price_type;

  return (
    <div className={`${styles.container}`}>
      {/* Offer Discount Box */}
      <div
        className={`${styles.box} ${
          selectedOption === "fixed" ? styles.selected : ""
        } pointer`}
        onClick={() => handleTypeChange("fixed")}
      >
        <div className={styles.content}>
          <div className={styles.checkbox}>
            <input
              type="radio"
              id="discount"
              className="pointer"
              checked={selectedOption === "fixed"}
              readOnly
            />
            <span className={styles.customCheckbox}></span>
          </div>
          <label htmlFor="discount" className="pointer">
            {/* Offer Discount */}
            Fixed
          </label>
          <div
            className={`${styles.icon} ${
              selectedOption === "fixed" ? styles.selected : ""
            }`}
          >
            <SvgLockTransparent
              color={selectedOption === "fixed" ? "#3968ed" : ""}
            />
          </div>
        </div>
      </div>

      {/* Offer Free Nights Box */}
      <div
        className={`${styles.box} ${
          selectedOption === "adaptive" ? styles.selected : ""
        } pointer`}
        onClick={() => handleTypeChange("adaptive")}
      >
        <div className={styles.content}>
          <div className={styles.checkbox}>
            <input
              type="radio"
              id="free-nights"
              className="pointer"
              checked={selectedOption === "adaptive"}
              readOnly
            />
            <span className={styles.customCheckbox}></span>
          </div>
          <label htmlFor="free-nights" className="pointer">
            Adaptive Price
          </label>
          <div
            className={`${styles.icon} ${
              selectedOption === "adaptive" ? styles.selected : ""
            }`}
          >
            <SvgPersons
              color={selectedOption === "adaptive" ? "#3968ed" : ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddonTypeSelectionBox;
