import React, { useEffect } from "react";
import styles from "./ApplicableOn.module.scss";
import { getDecryptedData } from "../../../utils/encryptStorage";
import ToggleSwitch from "../../../Components/NewCustomComp/Switch/ToggleSwitch";

const ApplicableOn = ({
  property_id,
  selectedRoomCategories,
  setSelectedRoomCategories,
  selectMealPlansArray =[],
}) => {
  const userToken = getDecryptedData("encryptToken");
  const [roomCategories, setRoomCategories] = React.useState([]);

  const fetchRoomCategories = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get-room-with-plan/${property_id}`,
        requestOptions
      );
      const data = await response.json();

      if (data.success) {
        setRoomCategories(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isToggleOn =
    roomCategories.length > 0 &&
    roomCategories.every((mealPlan) => {
      const room = selectedRoomCategories.find(
        (room) => room.room_id === mealPlan.room_id
      );
      return room && room.meal_plans.length === mealPlan.meal_plans.length;
    });

  const handleToggleAll = () => {
    if (!isToggleOn) {
      const allData = roomCategories.map((mealPlan) => ({
        room_id: mealPlan.room_id,
        room_name: mealPlan.room_name,
        meal_plans: mealPlan.meal_plans.map((mealPlan) => ({
          meal_plan_id: mealPlan.meal_plan_id,
          meal_plan_type: mealPlan.meal_plan_type,
        })),
      }));
      setSelectedRoomCategories(allData);
    } else {
      setSelectedRoomCategories([]);
    }
  };

  const handleRoomToggle = (roomId, roomName, roomCategories) => {
    setSelectedRoomCategories((prev) => {
      const roomExists = prev.find((room) => room.room_id === roomId);

      if (roomExists) {
        const allroomCategoriesSelected =
          roomExists.meal_plans.length === roomCategories.length;
        if (allroomCategoriesSelected) {
          return prev.filter((room) => room.room_id !== roomId);
        } else {
          return prev.map((room) =>
            room.room_id === roomId
              ? {
                  ...room,
                  meal_plans: roomCategories.map((mealPlan) => ({
                    meal_plan_id: mealPlan.meal_plan_id,
                    meal_plan_type: mealPlan.meal_plan_type,
                  })),
                }
              : room
          );
        }
      } else {
        return [
          ...prev,
          {
            room_id: roomId,
            room_name: roomName,
            meal_plans: roomCategories.map((mealPlan) => ({
              meal_plan_id: mealPlan.meal_plan_id,
              meal_plan_type: mealPlan.meal_plan_type,
            })),
          },
        ];
      }
    });
  };

  const handleMealPlanToggle = (roomId, mealPlanId, mealPlanType) => {
    setSelectedRoomCategories((prev) => {
      const room = prev.find((r) => r.room_id === roomId);

      if (room) {
        const mealPlanExists = room.meal_plans.find(
          (mealPlan) => mealPlan.meal_plan_id === mealPlanId
        );

        // If the meal plan exists, remove it; otherwise, add it
        const updatedroomCategories = mealPlanExists
          ? room.meal_plans.filter(
              (mealPlan) => mealPlan.meal_plan_id !== mealPlanId
            )
          : [
              ...room.meal_plans,
              {
                meal_plan_id: mealPlanId,
                meal_plan_type: mealPlanType,
              },
            ];

        // If no meal plans are left, remove the room; otherwise, update it
        return updatedroomCategories.length
          ? prev.map((r) =>
              r.room_id === roomId
                ? { ...r, meal_plans: updatedroomCategories }
                : r
            )
          : prev.filter((r) => r.room_id !== roomId);
      } else {
        // Add the room if it's not in the selected data
        return [
          ...prev,
          {
            room_id: roomId,
            room_name: roomCategories.find((r) => r.room_id === roomId)
              ?.room_name,
            meal_plans: [
              {
                meal_plan_id: mealPlanId,
                meal_plan_type: mealPlanType,
              },
            ],
          },
        ];
      }
    });
  };

  useEffect(() => {
    if (property_id) {
      fetchRoomCategories();
    }
  }, [property_id]);

  

  useEffect(() => {
    if (selectMealPlansArray.length > 0 && roomCategories.length > 0) {
      const filteredRooms = roomCategories.map((room)=>{
        const filteredMealPlans = room.meal_plans?.filter(mealPlan => selectMealPlansArray.includes(mealPlan.meal_plan_id));
        return filteredMealPlans.length > 0 ? {...room, meal_plans: filteredMealPlans} : null
      })?.filter(room => room !== null)
      setSelectedRoomCategories(filteredRooms);
    }
  }, [selectMealPlansArray, roomCategories]);



  console.log("selectedRoomCategories", roomCategories);
  console.log("selectMealPlansArray", selectMealPlansArray);
  console.log("selectedRoomCategories", selectedRoomCategories);

 

  return (
    <div>
      <div>
        <div>
          <div className="flex align_items_center flex_gap_10 mt-10">
            <h3>All Romes & Rate Plans</h3>
            <ToggleSwitch
              handleToggle={handleToggleAll}
              isOn={isToggleOn}
              size="medium"
              name={"allRooms"}
            />
          </div>

          <div
            className={`${styles.mealPlanContainer} mt-10 overflowContainer`}
          >
            {roomCategories.map((mealPlan, i) => {
              const isRoomSelected = !!selectedRoomCategories.find(
                (room) => room.room_id === mealPlan.room_id
              );

              return (
                <div key={mealPlan.room_id}>
                  <div
                    className={`flex align_items_center flex_gap_10 ${
                      i > 0 && "mt-10"
                    }`}
                  >
                    <input
                      onChange={() =>
                        handleRoomToggle(
                          mealPlan.room_id,
                          mealPlan.room_name,
                          mealPlan.meal_plans
                        )
                      }
                      type="checkbox"
                      className={
                        selectedRoomCategories.find(
                          (room) => room.room_id === mealPlan.room_id
                        )?.meal_plans.length > 0
                          ? selectedRoomCategories.find(
                              (room) => room.room_id === mealPlan.room_id
                            )?.meal_plans.length === mealPlan.meal_plans.length
                            ? styles.checkbox
                            : styles.checkboxFull
                          : styles.checkboxFull
                      }
                      checked={isRoomSelected}
                    />
                    <h3>{mealPlan.room_name}</h3>
                  </div>
                  <div>
                    {mealPlan.meal_plans.map((data) => (
                      <div
                        className="flex align_items_center flex_gap_10 mt-10 pl-15"
                        key={data.meal_plan_id}
                      >
                        <input
                          className={styles.checkbox}
                          type="checkbox"
                          onChange={() =>
                            handleMealPlanToggle(
                              mealPlan.room_id,
                              data.meal_plan_id,
                              data.meal_plan_type
                            )
                          }
                          checked={
                            selectedRoomCategories
                              .find((room) => room.room_id === mealPlan.room_id)
                              ?.meal_plans.some(
                                (mealPlan) =>
                                  mealPlan.meal_plan_id === data.meal_plan_id
                              ) || false
                          }
                        />
                        <h3>{data.meal_plan_type}</h3>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicableOn;
