import React from "react";
import { useSelector } from "react-redux";
import { useCurrentTime, currentDateSingle } from "../../../utils/utils";

const DownloadCSVButton = ({ downloadFileName, itemToDownloadID }) => {
  const propertyName = useSelector(
    (state) => state.property.value
  ).property_name;
  const dateTime = `${currentDateSingle}_${useCurrentTime()}`;
  const reportPropertyDateTime = `${propertyName}_${dateTime}`;

  const handleDownloadCSV = () => {
    const container = document.getElementById(itemToDownloadID);
    if (!container) {
      console.error(`Container with ID ${itemToDownloadID} not found.`);
      return;
    }
  
    const table = container.querySelector("table");
    if (!table) {
      console.error("No table found inside the specified container.");
      return;
    }
  
    const rows = table.querySelectorAll("tr");
    const csv = [];
  
    rows.forEach((row) => {
      const cols = Array.from(row.querySelectorAll("th, td"));
      const rowCsv = [];
      cols.forEach((col) => {
        const colSpan = col.colSpan || 1;
  
        // Extract only the visible text content
        let cellValue = col.textContent.trim();
        cellValue = cellValue.replace(/₹/g, ""); // Remove currency symbols if present
  
        // Escape the cell value for CSV format
        cellValue = `"${cellValue.replace(/"/g, '""')}"`; // Wrap in double quotes and escape internal double quotes
  
        for (let i = 0; i < colSpan; i++) {
          rowCsv.push(cellValue || '""'); // Add escaped empty string for blank cells
        }
      });
      csv.push(rowCsv.join(",")); // Join columns with a comma
    });
  
    const csvString = csv.join("\n"); // Join rows with a new line
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${
      downloadFileName ? downloadFileName : "report"
    }_${reportPropertyDateTime}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };
  
  
  

  return (
    <div className="flex .align_items_center  flex_gap_5">
      <button
        onClick={handleDownloadCSV}
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
            fill="#3968ED"
          />
        </svg>
        {"  "}
        
        <span style={{ color: "#3968ED" ,fontWeight: "600" }}> CSV</span>
      </button>
    </div>
  );
};

export default DownloadCSVButton;
