import React from "react";
import NavTabs from "../../Components/NewCustomComp/NavTabs/NavTabs";
import { Outlet } from "react-router-dom";

const WebsitePageNavigation = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const mainData = [
    {
      label: "Blogs",
      to: "blogs",
      permission: ["full_access_website_management", "read_website_management"],
      check_permission: true,
    },
    {
      label: "Pages",
      to: "pages",
      permission: ["full_access_website_management", "read_website_management"],
      check_permission: true,
    },
    {
      label: "Menus Location",
      to: "menuLocation",
      permission: ["full_access_website_management", "read_website_management"],
      check_permission: true,
    },
    {
      label: "Menus",
      to: "menus",
      permission: ["full_access_website_management", "read_website_management"],
      check_permission: true,
    },
    // {
    //   label: "Discounts",
    //   to: "discounts",
    //   permission: ["full_access_website_management", "read_website_management"],
    //   check_permission: true,
    // },
    // {
    //   label: "Offers",
    //   to: "offers",
    //   permission: ["full_access_website_management", "read_website_management"],
    //   check_permission: true,
    // },
    {
      label: "Promotions",
      to: "promotions",
      permission: ["full_access_website_management", "read_website_management"],
      check_permission: true,
    },
    {
      label: "Packages",
      to: "packages",
      permission: ["full_access_website_management", "read_website_management"],
      check_permission: true,
    },
    {
      label: "Add-ons",
      to: "add-ons",
      permission: ["full_access_website_management", "read_website_management"],
      check_permission: true,
    },
  ];

  const filteredData = mainData
    .filter((item) => {
      if (!item.check_permission) return true;
      return item.permission.some((perm) => permissions?.includes(perm));
    })
    .map(({ label, to }) => ({ label, to }));
  return (
    <>
      <div className="main_container">
        <NavTabs mainData={filteredData} />
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default WebsitePageNavigation;
