import React, { useEffect } from "react";
import { PrimaryInput } from "../../../../Components/NewCustomComp/Inputs/Inputs";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../Components/NewCustomComp/Buttons/Buttons";
import {
  calculateTotalArrayObjectKey,
  formatIndianCurrency,
  getBaseUrl,
  performRounding,
} from "../../../../utils/utils";
import { SelectBasicWithSelectTag } from "../../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showSuccessToast } from "../../../../assets/toastUtils";

const GenerateBillComponent = ({ details, closePopup, refreshApi }) => {
  const baseUrl = getBaseUrl();
  const property_id = useSelector((state) => state.property.value)?.id;
  const [cityLedgerList, setCityLedgerList] = React.useState([]);
  const [selectedCityLedger, setSelectedCityLedger] = React.useState([]);
  const [formData, setFormData] = React.useState({
    payment_method: "",
    // change: "",
    payment_type: "",
    company_name: "",
    round_off: "",
    amount: "",
    tip: null,

    rounded_value: "",
    rounded_amount: details?.grand_total,
    city_ledger_id: "",
    totalLedgerBalance: "",
  });

  const handleRadioChange = (e) => {
    const { name, value } = e.target;

    if (name === "round_off") {
      const roundedGrandTotal = performRounding(details?.grand_total, value);

      setFormData((prev) => ({
        ...prev,
        rounded_value: roundedGrandTotal?.roundingDifference,
        rounded_amount: roundedGrandTotal?.roundedAmount,
      }));
      console.log(roundedGrandTotal, "roundedGrandTotal");
    }

    if (name === "city_ledger_id") {
      const cityLedgerDetails = cityLedgerList.find(
        (item) => item.id === +value
      );

      setSelectedCityLedger(cityLedgerDetails);

      setFormData((prev) => ({
        ...prev,
        [name]: value,
        company_name: cityLedgerDetails?.company_name,
      }));
    }

    if (name === "amount") {
      if (value > formData.rounded_amount) {
        return;
      }
      setFormData((prev) => ({
        ...prev,
      }));
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Submit Api

  const handleGenerateBill = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      payment_method: formData.payment_method,
      tip: formData.tip || 0,
      amount: formData.amount,
      // change: 0,
      payment_type: formData.payment_type,
      // company_name: formData.company_name,
      round_off: formData.rounded_value,
      
      ...(formData.payment_type === "city_ledger" && { company_name: selectedCityLedger?.agent_information })
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/service/payment/${details?.id}/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Bill Generated Successfully");
        closePopup(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Post To Room

  console.log("details", details);

  const handlePostToRoom = async () => {
    const formdata = new FormData();
    formdata.append("id", details?.id);
    formdata.append("folio_id", details?.folio_id);
    formdata.append("room_no", details?.room_no);
    
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${baseUrl}/api/v1/house-keeping/service/post-to-room/${property_id}`, requestOptions);
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Bill Generated Successfully");
        closePopup(false)
      }
    } catch (error) {
      console.log(error);
    }
  }

  // City Ledger List
  const fetchCityLedgerList = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/agents/${property_id}?pagination=false`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setCityLedgerList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cityLedgerListOptions = [
    { label: "Select", value: "" },
    ...cityLedgerList.map((item) => ({
      label: item?.company_name,
      value: item?.id,
    })),
  ];

  useEffect(() => {
    if (selectedCityLedger) {
      const ledgerBalance = calculateTotalArrayObjectKey(
        selectedCityLedger?.credit,
        "balance_amount"
      );

      setFormData((prev) => ({
        ...prev,
        totalLedgerBalance: ledgerBalance,
      }));
      console.log("ledgerBalance", ledgerBalance);
    }
  }, [selectedCityLedger]);

  useEffect(() => {
    if (property_id && formData.payment_type === "city_ledger") {
      fetchCityLedgerList();
    }
  }, [property_id, formData.payment_type]);

  console.log("formData", formData);
  console.log("selectedCityLedger", selectedCityLedger);
  return (
    <div style={{ minWidth: "900px" }} className="white_wrapper">
      <div className="flex justify_content_between align_items_center">
        <h4 className="headings">Generate Bill</h4>
        <div className="flex flex_gap_10">
          <div>
            Service Id :{" "}
            <span className="weight_700">#{details?.unique_service_id}</span>
          </div>
          <hr />
          <div>
            Room No. : <span className="weight_700">{details?.room_no}</span>
          </div>
          <hr />

          <div>
            Total Amt. :{" "}
            <span className="weight_700">
              {formatIndianCurrency(details?.grand_total)}
            </span>
          </div>
        </div>
      </div>

      {/* Settlement Section */}
      <div className="mt-10 pb-10 border_bottom">
        <label>Payment Mode</label>
        <div className="flex align_items_center flex_gap_10 mt-10">
          <div className="flex flex_gap_5 align_items_center">
            <input
              type="radio"
              name="payment_method"
              value="cash"
              onChange={handleRadioChange}
            />{" "}
            Cash
          </div>

          <div className="flex flex_gap_5 align_items_center">
            <input
              type="radio"
              name="payment_method"
              value="upi"
              onChange={handleRadioChange}
            />{" "}
            UPI
          </div>

          <div className="flex flex_gap_5 align_items_center">
            <input
              type="radio"
              name="payment_method"
              value="card"
              onChange={handleRadioChange}
            />{" "}
            Card
          </div>

          <div className="flex flex_gap_5 align_items_center">
            <input
              type="radio"
              name="payment_method"
              value="post_to_room"
              onChange={handleRadioChange}
            />{" "}
            Post To Room
          </div>
        </div>
      </div>

      {/* Payment Type Section */}
      <div className="mt-10 pb-10 border_bottom">
        <label>Payment Type</label>

        <div className="flex align_items_center flex_gap_10 mt-10">
          <div className="flex flex_gap_5 align_items_center">
            <input
              type="radio"
              name="payment_type"
              value="cash"
              onChange={handleRadioChange}
            />{" "}
            Cash / Advance Payment
          </div>

          <div className="flex flex_gap_5 align_items_center">
            <input
              type="radio"
              name="payment_type"
              value="city_ledger"
              onChange={handleRadioChange}
            />{" "}
            City Ledger
          </div>
        </div>
      </div>

      {/* Round Off Section */}
      <div className="mt-10 pb-10 border_bottom">
        <div>
          <label>Round Off</label>
          <div className="flex align_items_center flex_gap_10 mt-10">
            <div className="flex flex_gap_5 align_items_center">
              <input
                type="radio"
                name="round_off"
                value="roundUp"
                onChange={handleRadioChange}
              />{" "}
              Up
            </div>
            <div className="flex flex_gap_5 align_items_center">
              <input
                type="radio"
                name="round_off"
                value="roundDown"
                onChange={handleRadioChange}
              />{" "}
              Down
            </div>
          </div>
        </div>
      </div>

      {/* Add Payment Section */}
      <div className="flex flex_gap_10 mt-10">
        {formData.payment_type!=="city_ledger" && (
          <>
          {formData.payment_method !== "post_to_room" && (
            <>
              <PrimaryInput
                type={"number"}
                label={"Received Amt."}
                size={"medium"}
                name={"amount"}
                value={formData?.amount}
                onChange={handleRadioChange}
              />
              <PrimaryInput
                type={"number"}
                label={"Tip"}
                size="medium"
                name={"tip"}
                value={formData?.tip}
                onChange={handleRadioChange}
              />
            </>
          )}
          </>
        )}

        {formData.payment_type === "city_ledger" && (
          <>
            <SelectBasicWithSelectTag
              options={cityLedgerListOptions}
              label={`City Ledger ${
                formData.city_ledger_id ?`(Balance: ${formData.totalLedgerBalance})` : ""
              }`}
              size={"medium"}
              name={"city_ledger_id"}
              value={formData?.city_ledger_id}
              onChange={handleRadioChange}
            />
            <PrimaryInput
              type={"number"}
              label={"Received Amt."}
              size={"medium"}
              name={"amount"}
              value={formData?.amount}
              onChange={handleRadioChange}
            />
          </>
        )}

        <PrimaryInput
          type={"number"}
          label={"Change Amt."}
          size="medium"
          value={formData?.rounded_amount}
          disabled={true}
        />
      </div>

      <div className="flex flex_gap_10 mt-10">
        <SecondaryButton
          title={"Discard"}
          size="medium"
          onClick={() => closePopup(false)}
        />
        {formData.payment_method !== "post_to_room" && (
          <PrimaryButton
            size="medium"
            title={"Submit"}
            disabled={+formData?.rounded_amount !== +formData?.amount}
            onClick={handleGenerateBill}
          />
        )}
        {formData.payment_method === "post_to_room" && (
          <PrimaryButton
            size="medium"
            title={"Post"}
            onClick={handlePostToRoom}
          />
        )}
      </div>
    </div>
  );
};

export default GenerateBillComponent;
