import React, { useEffect } from "react";
import { PrimaryInput } from "../NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { showSuccessToast } from "../../assets/toastUtils";
import { PrimaryButton, SecondaryButton } from "../NewCustomComp/Buttons/Buttons";
import { useNavigate } from "react-router-dom";

const ChannelManagerSettings = () => {
    const propertyId = JSON.parse(localStorage.getItem("selectedProperty"))?.id;
    const navigate = useNavigate();

    const [channel, setChannel] = React.useState([]);
    const [formData, setFormData] = React.useState({
        channel_id: "",
        channel_manager_username: "",
        channel_manager_password: "",
        channel_manager_ID_Context: "",
    })


    const getChannels = async (propertyId) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/booking-engine/cm/settings/1`,
                requestOptions
            );
            const data = await response.json();
            if (data.success) {
                setChannel(data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    
    useEffect(() => {
        if (propertyId) {
            getChannels();
        }
    }, [propertyId]);

    const channelData = [
        { label: "select", value: "" },
        ...channel.map((item) => ({
            label: item?.channel_name,
            value: item?.id,
        }))
    ];

    const handleChannelChange = (e) => {

        const { name, value } = e.target
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }))

        const selected = channel.find((ch) => toString(ch.id) === toString(value));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            channel_id: formData.channel_id,
            channel_manager_username: formData.channel_manager_username,
            channel_manager_password: formData.channel_manager_password,
            channel_manager_ID_Context: formData.channel_manager_ID_Context
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/booking-engine/cm/settings/1`, requestOptions);
            const data = await response.json();
            if (data.success) {
                showSuccessToast(data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (


        <form className="m-0" onSubmit={handleUpdate}>
            <div className="profile_tab_form">
                <div className="flex flex_direction_col flex_gap_10 w-100">
                    <h3>Channel Manger Settings</h3>

                    <SelectBasicWithSelectTag
                        options={channelData}
                        label={"Channel Manager"}
                        size={"medium"}
                        onChange={(e) => handleChannelChange(e)}
                        name={"channel_id"}
                    />

                    <PrimaryInput
                        size={"medium"}
                        label={"Channel ID"}
                        value={formData.channel_id}
                        disabled={true}
                        onChange={(e) => handleChannelChange(e)}
                    />
                    <PrimaryInput
                        size={"medium"}
                        label={"User Name"}
                        name={"channel_manager_username"}
                        value={formData.channel_manager_username}
                        onChange={(e) => handleChannelChange(e)}
                    />
                    <PrimaryInput
                        size={"medium"}
                        label={"Password"}
                        name={"channel_manager_password"}
                        value={formData.channel_manager_password}
                        onChange={(e) => handleChannelChange(e)}
                    />
                    <PrimaryInput
                        size={"medium"}
                        label={"ID-Context"}
                        name={"channel_manager_ID_Context"}
                        value={formData.channel_manager_ID_Context}
                        onChange={(e) => handleChannelChange(e)}
                    />
                </div>
            </div>
            <div className="flex align_items_center flex_gap_10 mt-10">
                <PrimaryButton title={"Update"} size='medium' type={"submit"} />
                <SecondaryButton onClick={() => navigate(-1)} title={"Cancel"} size='medium' />
            </div>
        </form>


    );
};

export default ChannelManagerSettings;
