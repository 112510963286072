import React, { useEffect, useState } from "react";
import { getDecryptedData } from "../../utils/encryptStorage";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import styles from "./AgentCustomer.module.scss";
import {
  SvgEditWithText,
  SvgRates,
  SvgRatesWithText,
  SvgUpdateWithText,
} from "../../assets/svgIcons";
import { formatIndianCurrency } from "../../utils/utils";
import { PrimaryInput } from "../NewCustomComp/Inputs/Inputs";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import Loader from "../UI/Loader/Loader";

const AgentsCustomersBulkRateUpdate = () => {

  const [bulkRate, setBulkRate] = useState([]);
  const [activeRate, setActiveRate] = useState([]);
  const [editRate, setEditRate] = useState(false);
  const [loader ,setLoader] = useState(false)
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);

  const location = useLocation();
  const type = location.pathname.includes("agents") ? "agent" : "customer";
  const mapKey = type === "agent" ? "agent_rates": "rates";

  const { categoryId } = useParams();

  // Fetch Bulk Rate

  const fetchBulkRate = async (propertyId) => {
    setLoader(true)
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${type}/category/${propertyId}?with_rates=${categoryId}&id=${categoryId}`,
        requestOptions
      );
      const data = await response.json();

      if (data.success) {
        setBulkRate(data.data);
        setActiveRate(data.data[mapKey].map((rate) => rate?.room_name));
        if(data.data[mapKey].length > 0){
          setLoader(false)
        }
       
      }
     
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    if (property_id?.id) {
      fetchBulkRate(property_id?.id);
    }
  }, [property_id?.id]);

  // End Fetch Bulk Rate


  // Handle Edit Rate
  const handleEditRate = () => {
    setEditRate(true);
  };
  // End Handle Edit Rate

  const handleFilterChange = (e) => {
    const { value } = e.target;

    if (activeRate.includes(value)) {
      setActiveRate(activeRate.filter((rate) => rate !== value));
    } else {
      setActiveRate([...activeRate, value]);
    }
  };


//  Handle Rate Update onChange
const handleRateChange = (mapKey, rateIndex, roomRateIndex, field, value) => {
  setBulkRate((prevRates) => {
    // Ensure prevRates is an object or array
    const updatedRates = Array.isArray(prevRates)
      ? [...prevRates]
      : { ...prevRates };

    // Access the specific rate being edited
    const targetRate =
      updatedRates[mapKey]?.[rateIndex]?.room_rates?.[roomRateIndex]?.rates?.[0];

    if (targetRate) {
      // Update only the specific field
      targetRate[field] = value;
    }

    return updatedRates;
  });

};
function transformData(data) {
  const arrayKey = type === "agent" ? "agent_rates": "customer_rates";
  const result = {
    id: data.id,
    category_name: data.category_name,
    category_description: data.category_description,
    targeted_room_count: data.targeted_room_count,
    targeted_booking_amount: parseFloat(data.targeted_booking_amount),
    ...(type === "agent" && { category_discount_type: data.category_discount_type  }),
    [arrayKey]: [],
  };

  data[mapKey].forEach(room => {
    room.room_rates.forEach(ratePlan => {
      ratePlan.rates.forEach(rate => {
        result[arrayKey].push({
          room_id: rate.room_id,
          rate_plan_name: ratePlan.meal_plan_name,
          rate_plan_type: rate.rate_plan_type || "N/A", // Default to "N/A" if null
          rate_rack_price: parseFloat(rate.rate_rack_price),
          rate_bar_price: parseFloat(rate.rate_bar_price),
          extra_child: parseFloat(rate.extra_child),
          extra_adult: parseFloat(rate.extra_adult),
          extra_bed: parseFloat(rate.extra_bed),
          breakfast: parseFloat(rate.breakfast),
          lunch: parseFloat(rate.lunch),
          dinner: parseFloat(rate.dinner),
          mealplan_id: ratePlan.meal_plan_id,
          rate_plan_id: ratePlan.rate_plan_id,
        });
      });
    });
  });

  return result; 
}


  // Handle Rate Update
  const handleRateUpdate = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const roomRatesData = transformData(bulkRate)
    console.log(roomRatesData, "roomRatesData");

    const raw = JSON.stringify(roomRatesData);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/${type}/category/${property_id?.id}`, requestOptions);
      const data = await response.json();
      if (data.success) {
      setEditRate(false);
      showSuccessToast("Rates Updated Successfully");
      fetchBulkRate(property_id?.id);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  // End Handle Rate Update


  

  

  return (
    <div className="mt-15">
      <div className="flex flex_gap_10 align_items_center">
        <GoBackButtonCustom  onClick={() => window.history.back()}/>
        <span
          style={{ fontSize: "16px", fontWeight: "bold", marginBottom: "5px" }}
        >
          {type === "agent" ? "Agent" : "Customer"} Rates
        </span>
      </div>

      { 
        loader ? <Loader/>  : (
          <div className="mt-10 flex flex_gap_15">
        <div className={styles.rateUpdateFilter}>
          Filters
          {bulkRate[mapKey] &&
            bulkRate[mapKey]?.length > 0 &&
            bulkRate[mapKey]?.map((rate, index) => (
              <div key={index} className="flex flex_gap_10">
                <input
                  value={rate?.room_name}
                  type="checkbox"
                  checked={activeRate.includes(rate?.room_name)}
                  onChange={handleFilterChange}
                />
                <span>{rate?.room_name}</span>
              </div>
            ))}
        </div>

        {activeRate?.length > 0 && (
          <div className={styles.rateUpdateMain}>
            <div className={styles.rateUpdateHeader}>
              {/* <div>On Season-2024 - Standard</div> */}
              <div className="flex flex_gap_10 align_items_center">
                {!editRate ? (
                  <div className="pointer" onClick={handleEditRate}>
                    <SvgEditWithText />{" "}
                  </div>
                ) : (
                  <div className="pointer" onClick={handleRateUpdate}>
                    <SvgUpdateWithText />
                  </div>
                )}

                <div>
                  <SvgRatesWithText />
                </div>
              </div>
            </div>

            {bulkRate &&
              bulkRate[mapKey]?.length > 0 &&
              bulkRate[mapKey]?.map((rate, index) => (
                <>
                  {activeRate.includes(rate?.room_name) && (
                    <table className={styles.rateUpdateTable} key={index}>
                      <thead>
                        <tr>
                          <th>{rate?.room_name}</th>
                          <th>Base Rate</th>
                          <th>Extra Adult</th>
                          <th>Child with Bed</th>
                          <th>Extra Child</th>
                        </tr>
                      </thead>

                      <tbody>
                        {rate?.room_rates &&
                          rate?.room_rates.length > 0 &&
                          rate?.room_rates.map((roomRate, innerIndex) => {
                            return (
                              <tr key={innerIndex}>
                                <td>{roomRate?.meal_plan_name}</td>
                                <td>
                                  <PrimaryInput
                                    style={{ width: "60%" }}
                                    size={"small"}
                                    value={roomRate?.rates[0]?.rate_bar_price}
                                    disabled={!editRate}
                                    onChange={(e) =>
                                      handleRateChange(mapKey, index, innerIndex, "rate_bar_price", e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <PrimaryInput
                                    style={{ width: "60%" }}
                                    size={"small"}
                                    value={roomRate?.rates[0]?.extra_adult}
                                    disabled={!editRate}
                                    onChange={(e) =>
                                      handleRateChange(mapKey, index, innerIndex, "extra_adult", e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <PrimaryInput
                                    style={{ width: "60%" }}
                                    size={"small"}
                                    value={roomRate?.rates[0]?.extra_bed}
                                    disabled={!editRate}
                                    onChange={(e) =>
                                      handleRateChange(mapKey, index, innerIndex, "extra_bed", e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <PrimaryInput
                                    style={{ width: "60%" }}
                                    size={"small"}
                                    value={roomRate?.rates[0]?.extra_child}
                                    disabled={!editRate}
                                    onChange={(e) =>
                                      handleRateChange(mapKey, index, innerIndex, "extra_child", e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  )}
                </>
              ))}
          </div>
        )}
      </div>
        )
      }
    </div>
  );
};

export default AgentsCustomersBulkRateUpdate;
