import React, { useEffect, useState } from "react";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchOrAdd } from "../../NewCustomComp/Search/Search";
import { PrimaryInput } from "../../NewCustomComp/Inputs/Inputs";
import { Textarea } from "../../NewCustomComp/Testarea/Textarea";
import { SelectBasicWithSelectTag } from "../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { PrimaryButton } from "../../NewCustomComp/Buttons/Buttons";

const GuestDetailsNew = ({
  guestDetails,
  setGuestDetails,
  errors,
  setErrors,
  customers,
  handleAddClick,
  bookingDataArray,
}) => {
  const [showSalutation, setShowSalutation] = useState(true);
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();

  useEffect(() => {
    if (property_id) {
      fetchSalutations();
    }
  }, [property_id]);

  const [showSalutationPopup, setShowSalutationPopup] = useState(false);

  const [salutations, setSalutations] = useState([]);

  const salutationsOptions = [
    { value: "", label: "Select" }, // Default option
    ...salutations.map((item) => ({
      value: item.name,
      label: item.name,
    })),
  ];

  const fetchSalutations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSalutations(data.data);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
  };

  const handleSalutationChange = (e) => {
    const { name, value } = e.target;

    if (value === "other") {
      setShowSalutationPopup(true);
    } else {
      setGuestDetails({
        ...guestDetails,
        salutation: e.target.value,
      });
      setShowSalutationPopup(false);
    }
  };

  const [popUpSalutation, setPopUpSalutation] = useState("");

  const handleGuestDetails = (e) => {
    setGuestDetails({
      ...guestDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleGuestNameFromSearch = (id) => {
    const getDetails = customers.find((item) => +item.id === +id);

    if (getDetails) {
      setShowSalutation(false);
      setGuestDetails((prevDetails) => ({
        ...prevDetails,
        salutation: "",
        name: getDetails?.name || "",
        email: getDetails?.email || "",
        phone: getDetails?.phone || "",
        customer_id: id,
        customer_category_id: getDetails.category_id,
      }));
      return;
    } else {
      setShowSalutation(true);
      setGuestDetails((prevDetails) => ({
        ...prevDetails,
        name: id,
        salutation: "",
        email: "",
        phone: "",
        customer_id: "",
        customer_category_id: "",
      }));
      return;
    }
  };

  return (
    <div className="w-100">
      <div className="flex flex_gap_10 align_items_end">
        <div className="w-30">
          <div className="flex flex_gap_5 align_items_end">
            {showSalutation && (
              <>
                <SelectBasicWithSelectTag
                  required={true}
                  size={"medium"}
                  options={salutationsOptions}
                  label={"Guest's Name"}
                  id="salutation"
                  name="salutation"
                  value={guestDetails.salutation}
                  onChange={handleSalutationChange}
                  className="create_booking_primary_guest_details_input"
                  style={{
                    width: "50px",
                    background: errors.guest_name ? "#FFEAEA" : "",
                    border: errors.guest_name ? "1px solid #E03838" : "",
                  }}
                  disabled={bookingDataArray.length > 0}
                />

                {showSalutationPopup && (
                  <PrimaryInput
                    type="text"
                    required
                    className="create_booking_primary_guest_details_input"
                    name="salutation"
                    value={guestDetails.salutation}
                    onChange={handleGuestDetails}
                    style={{ width: "100px" }}
                    size={"medium"}
                  />
                )}
              </>
            )}

            <SearchOrAdd
              size={"medium"}
              options={customers}
              optionKeyName={"name"}
              selectedKeyName={"id"}
              required={true}
              label={showSalutation ? "" : "Name"}
              handleOptionChange={handleGuestNameFromSearch}
              style={{
                background: errors.guest_name ? "#FFEAEA" : "",
                border: errors.guest_name ? "1px solid #E03838" : "",
              }}
              defaultSelected={guestDetails.name}
              disabled={bookingDataArray.length > 0}
            />
          </div>
        </div>

        {guestDetails.customer_id && (
          <div className="w-10">
            <PrimaryInput
             label={`Customer's Category`}
             size={"medium"}
              disabled={true}
              value={
                customers.find(
                  (item) => +item.id === +guestDetails?.customer_id
                )?.category_name
              }
            />
          </div>
        )}

        <div className="w-30 ">
          <PrimaryInput
            label={"Phone"}
            value={guestDetails.phone}
            name={"phone"}
            onChange={handleGuestDetails}
            type={"number"}
            size={"medium"}
            disabled={bookingDataArray.length > 0}
          />
        </div>

        <div className="w-30" style={{ verticalAlign: "end" }}>
          <PrimaryInput
            label={"Email"}
            value={guestDetails.email}
            name={"email"}
            onChange={handleGuestDetails}
            type={"email"}
            size={"medium"}
            disabled={bookingDataArray.length > 0}
          />
        </div>
      </div>
    </div>
  );
};

export default GuestDetailsNew;
