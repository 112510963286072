import { useState, useEffect, useRef } from "react";
import "./BookingDetailsSliderNew.css";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  SettleBillButton,
  WithoutBgButtonBlue,
} from "../../UI/Buttons";
import UnderscoreRemoveCapitalize from "../UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../../assets/toastUtils";
import { showSuccessToast } from "../../../assets/toastUtils";
import VerticalTextAreaCustom from "../VerticalTextAreaCustom";
import { Button, Icon } from "@shopify/polaris";
import DeletePopupWithReasonBooking from "../DeletePopup/DeletePopupWithReasonBooking";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import BookingVoucher from "../BookingBillsVouchers/BookingVoucher";
import {
  formatAMPM,
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
  useCurrentTime,
} from "../../../utils/utils";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import { useNavigate } from "react-router-dom";
import HandleNaN from "../HandleNaN/HandleNaN";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import { getDecryptedData } from "../../../utils/encryptStorage";
import AssignRoomPopupNew from "../AssignRoomPopupNew/AssignRoomPopupNew";
const RoomKotDetailsSlider = ({
  kotList,
  data,
  setShowBookingDetails,
  chargesDetails,
  masterFolioDetails,
}) => {
  const navigate = useNavigate();
  const [openPrintPopup, setOpenPrintPopup] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [openMoreOptionsPopup, setOpenMoreOptionsPopup] = useState(false);
  const [openCheckedInMoreOptionsPopup, setOpenCheckedInMoreOptionsPopup] =
    useState(false);

  const currentTimeToBackend = useCurrentTime("24");

  const [selectedRooms, setSelectedRooms] = useState([]);

  const [openKotDetailsSlider, setOpenKotDetailsSlider] = useState(false);
  const [newOrderRoom, setNewOrderRoom] = useState(false);

  const [roomsData, setRoomsData] = useState([]);

  const fetchRoomsData = async (propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      setRoomsData(data.rooms);
      // setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  function handlePrintPopup() {
    setOpenPrintPopup(!openPrintPopup);
  }
  function handleEmailPopup() {
    setOpenEmailPopup(!openEmailPopup);
  }
  function handleMoreOptionsPopup() {
    setOpenMoreOptionsPopup(!openMoreOptionsPopup);
  }
  function handleCheckedInMoreOptionsPopup() {
    setOpenCheckedInMoreOptionsPopup(!openCheckedInMoreOptionsPopup);
  }
  const popupsRef = useRef(null);
  const bookingDetailsPopupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setOpenPrintPopup(false);
        setOpenEmailPopup(false);
        setOpenMoreOptionsPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        bookingDetailsPopupRef.current &&
        !bookingDetailsPopupRef.current.contains(event.target)
      ) {
        setShowBookingDetails(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const formattedDate = new Date(data?.created_at).toLocaleString("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  });
  // ............Cancel Booking.............
  const [loader, setLoader] = useState(true);
  const [cancelReason, setCancelReason] = useState("");
  const userToken = getDecryptedData('encryptToken');
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [bookings, setBookings] = useState([]);
  const [bookingOuterDetails, setBookingOuterDetails] = useState([]);
  const [cancelBookingId, setCancelBookingId] = useState("");
  const [dayTypeFilter, setDayTypeFilter] = useState("-1");
  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);
  const property_id = useSelector((state) => state.property.value);

  const fetchInfo = async (PropertyId, from_date, to_date) => {
    setLoader(true);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/${PropertyId}`,
      {
        method: "POST",
        body: JSON.stringify({
          booking_status: selectedBookingStatus,
          date_filters: selectedDateFilter,
          booking_id: bookingId,
          dayTypeFilter: dayTypeFilter,
          from_date: from_date,
          to_date: to_date,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const data = await response
      .json()
      .then((data) => {
        if (data.status) {
          setBookings(data.Bookings);
          setBookingOuterDetails(data);
          showSuccessToast(data.message);
          setLoader(false);
          // navigate("/bookings");
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
      });
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(
        property_id.id,
        selectedDatesRange.from_date,
        selectedDatesRange.to_date,
        selectedDateFilter,
        selectedBookingStatus,
        dayTypeFilter
      );
    }
  }, [property_id, selectedDateFilter, selectedBookingStatus, dayTypeFilter]);

  useEffect(() => {
    if (property_id) {
      fetchRoomsData(property_id.id);
    }
  }, [property_id]);

  const cancelBookingHandle = (bookingId) => {
    // e.preventDefault();
    // const credentials = { email: email, password: password };
    // http://127.0.0.1:8000/api/v1/get/booking/9a0783fb-213b-4f31-ad9d-a5605d8672a4
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/booking/cancelled/${bookingId}/${property_id?.id}?cancelReason=${cancelReason}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setLoader(false);
          fetchInfo(property_id.id);
          setShowModelMenuActive(false);
          window.location.reload();
        } else {
          // setSingleBookingDetail(data.booking);
          // setShowBookingDetails(true);
          // showSuccessToast("Data Fetched Successfully");
          showSuccessToast(data.message);
          setShowModelMenuActive(false);
          // setOpenSingleBookingDetails(!openSingleBookingDetails);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
    setShowBookingDetails(false);
  };

  function handleCancelBooking() {
    setShowModelMenuActive(true);
    setCancelBookingId(data?.id);
  }

  // ...................Remove Reservation..........
  const [reasonFromChild, setReasonFromChild] = useState("");
  const [uniqueBookingId, setUniqueBookingId] = useState(false);
  const [deleteWithReasonPopup, setDeleteWithReasonPopup] = useState(false);
  const [deleteKotId, setDeleteKotId] = useState("");
  function handleRemoveReservation() {
    setDeleteWithReasonPopup(true);
  }
  const handleDeleteWithReason = (reason) => {
    setReasonFromChild(reason);
  };

  // .........No show.........
  const [noShowData, setNoShowData] = useState();

  const handleNoShowData = (status, bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/booking_status/${bookingId}/${status}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      });
  };

  // ...............Assign Room....................
  const [clickedBookingId, setClickedBookingId] = useState("");
  const [shortBookingId, setShortBookingId] = useState("");
  const [showAssignRoomPopup, setShowAssignRoomPopup] = useState(false);
  const [assignRoomsData, setAssignRoomsData] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoooms, selectedRooooms] = useState({});
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const [selectedRoomsLabels, setSelectedRoomsLabels] = useState([]);
  const [selectedSiftKot, setSelectedShiftKot] = useState([]);

  const [folioId, setFolioId] = useState(null);
  const [folioIdUnique, setFolioIdUnique] = useState(null);

  const fetchFolioId = async (roomId, propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/room_details/${roomId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      //   setSelectedRoomGuestDetails(data.guestDetail);
      //   set_unique_booking_id(data.guestDetail.unique_booking_id);
      // setKots({
      //   ...kots,
      //   unique_booking_id: data.guestDetail.unique_booking_id,
      // })

      setFolioId(data?.guestDetail);
      setFolioIdUnique(data?.guestDetail?.folio_id);
      setLoader(false);
    } catch (error) {
      // setLoader(false);
      console.error(error);
    }
  };


  const handleRoomClick = (id, label) => {
    fetchFolioId(id, property_id?.id);
    if (selectedRooms.includes(id)) {
      setSelectedRooms([id]);
      setSelectedRoomsLabels([label]);
    } else {
      setSelectedRooms([id]);
      setSelectedRoomsLabels([label]);
    }
  };
  const handleCheckboxChange = (roomId, roomName, roomNoId, room_no) => {
    selectedRooooms((prevSelectedRooms) => {
      const newSelectedRooms = { ...prevSelectedRooms };
      const currentSelection = newSelectedRooms[roomId] || [];

      if (currentSelection.includes(room_no)) {
        newSelectedRooms[roomId] = currentSelection.filter(
          (id) => id !== room_no
        );
      } else {
        const roomCategory = assignRoomsData.find((room) => room.id === roomId);
        const maxRooms = parseInt(roomCategory.no_of_rooms, 10);

        if (currentSelection.length < maxRooms) {
          newSelectedRooms[roomId] = [...currentSelection, room_no];
        } else {
          showErrorToast(
            `Cannot select more than ${maxRooms} rooms for ${roomName}`
          );
          return prevSelectedRooms;
        }
      }
      return newSelectedRooms;
    });
  };

  const assignRoomsHandle = (e, bookingId) => {
    // every
    // some

    const checkMaxRooms =
      selectedData &&
      selectedData.every((room) => {
        return (
          room.maxRooms ===
          (room.selectedRoooms ? room.selectedRoooms.length : 0)
        );
      });

    if (!checkMaxRooms) {
      showErrorToast("Please assign the rooms first.");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/room/no/${data?.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          assign_room: selectedData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setShowBookingDetails(false);
          // setBookingDetails(data.booking);
          setShowInHouseCheckIn(false);
          setShowAssignRoomPopup(false);
          showSuccessToast(data.message);
          // setShowDashBoardCheckIn(false);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // const encrypt = (data) => {
  //   const encryptedData = CryptoJS.AES.encrypt(
  //     JSON.stringify(data),
  //     process.env.REACT_APP_SECRET_KEY
  //   ).toString();
  //   return encryptedData;
  // }
  const handleShift = (e, bookingId) => {
    // setNewOrderRoom(false)
    fetch(
      // shift kot  /kot/shift/{kot_id}/{property_id}
      `${process.env.REACT_APP_BASE_URL}/api/v1/restaurant/kot/shift/${selectedSiftKot?.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          room_no: selectedRooms?.toString(),
          folio_id: folioId?.folio_id,
          folio_no: folioId?.folio_no,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setShowBookingDetails(false);
          // setBookingDetails(data.booking);
          setShowInHouseCheckIn(false);
          setShowAssignRoomPopup(false);
          showSuccessToast(data.message);
          // setShowDashBoardCheckIn(false);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const fetchAssignRooms = async (bookingId, propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/check-in/getdata/${bookingId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setAssignRoomsData(data.availablerooms);
          setLoader(false);
          setShowAssignRoomPopup(true);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      setLoader(false);
      showErrorToast("Something went wrong...");
      console.error(error);
    }
  };

  const roomsOptions = [
    ...(roomsData.flatMap(
      (room) =>
        room.assigned_rooms_no
          .filter((assignedRoom) => assignedRoom?.status === "occupied")
          .filter(
            (assignedRoom) => assignedRoom?.room_no !== selectedSiftKot?.room_no
          )
          .map((room) => ({ label: room?.room_no, value: room?.room_no })) || []
    ) || []),
  ];
  useEffect(() => {
    const newData = assignRoomsData.map((room) => ({
      name: room.room_name,
      id: room.id,
      selectedRoooms: selectedRoooms[room.id] || [],
      maxRooms: parseInt(room.no_of_rooms, 10),
    }));
    setSelectedData(newData);
  }, [selectedRoooms, assignRoomsData]);

  // void reservation
  const handleCancelStatusButton = async () => {
    // console.log(data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/booking/${data?.id}/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify({
            type: reasonFromChild.reason,
            duplicate_booking_id: uniqueBookingId,
            original_booking_id: reasonFromChild.originalBookingId,
            remarks: reasonFromChild.otherReason,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (res.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        setDeleteWithReasonPopup(false);
        window.location.reload();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
    // setShowBookingDetails(false);
  };

  // Voucher

  const [iframeContent, setIframeContent] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);

  const handleSubmitPreviewVoucher = async (
    event,
    template,
    statuses,
    vouchertitle,
    booking_id
  ) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomVoucher&reservationId=${booking_id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((data) => {
        if (data) {
          setIframeContent(data);
          setShowIframePopup(true);
          // setShowBillPreview(true);
          // setIsLoading(false);
          // printIframe("receipt");
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // add payments

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    // { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "8%", value: "8" },
    { label: "12%", value: "12" },
    { label: "15%", value: "15" },
  ];
  const paymentTypeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash", value: "cash" },
    // { label: "City Ledger", value: "city_ledger" },
  ];

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    // { label: "Other", value: "other" },
  ];

  const currentDate = new Date().toISOString().split("T")[0];

  const [addChargesData, setAddChargesData] = useState({
    date: currentDate,
    booking_id: "",
    folio_id: "",
    property_id: "",
    notes: "",
    notes: "",
    payment_mode: "",
    payment_type: "",
    amount: "",
  });

  const [roomDetails, setRoomDetails] = useState([]);

  const [agentCreditLimit, setAgentCreditLimit] = useState("");

  useEffect(() => {
    // Convert input values to numbers
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    // Apply GST
    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);
    }

    let totalAmount = priceAfterDiscount + gstAmount;

    setAddChargesData({
      ...addChargesData,
      // subtotal: subtotal.toFixed(2),
      // net_total: totalAmount.toFixed(2),
      // discount_amount: discountAmount.toFixed(2),
      // tax_amount: gstAmount.toFixed(2),
    });
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  const setAddChargesHandleChange = (event) => {
    // if (event.target.name === "amount") {
    //   if (
    //     agentCreditLimit > 0 &&
    //     parseFloat(event.target.value) > agentCreditLimit
    //   ) {
    //     showErrorToast(
    //       "Amount should not be greater than Agent Balance Credit Limit"
    //     );
    //     return;
    //   }
    // }
    if (event.target.name === "company_name") {
      setAddChargesData({
        // ...addChargesData,
        amount: 0,
      });
    }

    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  const [addPaymentPopup, setAddPaymentPopup] = useState(false);

  const settlementHandleFolio = (event) => {
    event.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/payments/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          // folio_id: selectedFolio.id,
          property_id: property_id?.id,
          booking_id: data.id,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // fetchFolioDetails(property_id?.id);
          showSuccessToast(res.message);
          setAddChargesData({});
          setAddPaymentPopup(false);
        } else {
          showErrorToast(res.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // Add charges

  useEffect(() => {
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;

      let totalAmount = priceAfterDiscount + gstAmount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);

      // GST Inclusive Price * 100/(100 + GST Rate Percentage)
      subtotal = (priceAfterDiscount * 100) / (100 + tax_rate);

      let totalAmount = priceAfterDiscount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    }
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);
  const [addChargesPopup, setAddChargesPopup] = useState(false);

  const serviceOptions = [
    { label: "Select Option", value: "" },
    { label: "Room-Charge", value: "Room-Charge" },
    { label: "Food-Charge", value: "Food-Charge" },
    { label: "Housekeeping-Charge ", value: "Housekeeping-Charge" },
    { label: "Miscellaneous-Charge", value: "Miscellaneous-Charge" },
    { label: "Laundry-Charge", value: "Laundry-Charge" },
    // { label: "MISC", value: "misc" },
  ];


  const handleAddCharges = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/charges/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: masterFolioDetails.id,
          property_current_date:
            propertyCurrentDate + " " + currentTimeToBackend,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          showSuccessToast(res.message);
          setAddChargesPopup(false);
          // fetchFolioInfo(property_id?.id);
          setAddChargesData({
            charge_type: "",
            description: "",
            rate: "",
            quantity: "",
            net_total: "",
            subtotal: "",
            discount_amount: "",
            gst_type: "inclusive",
            discount_rate: "",
            tax_rate: "",
            tax_amount: "",
          });

          // checkFolioSummery(
          //   userToken,
          //   folioData?.id,
          //   property_id?.id,
          //   chargeAddedTransactions,
          //   true
          // );
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // print
  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };


  return (
    <div className="bg-backdrop">
      <div
        id="booking_details_slider_container_new"
        ref={bookingDetailsPopupRef}
      >
        <div class="popup" id="popup">
          <div className="d_flex booking_detail_popup_head">
            <div
              onClick={() => setShowBookingDetails(false)}
              className="close_popup"
            >
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                <path
                  d="M13 12.5L0.999999 0.500001"
                  stroke="#AAAAAA"
                  strokeWidth="1.2"
                />
              </svg>
            </div>
          </div>

          <div className="">
            <div className="boking_details_popup_overview ">
              <h3>Kot's Made</h3>
            </div>
            <div className="common_padding" style={{ paddingTop: "10px" }}>
              <div>
                {kotList?.length < 1 ? (
                  <>No Data.</>
                ) : (
                  <>
                    {kotList?.map((kot, index) => (
                      <div className="d_flex booking_detail_popup_inclusion_list">
                        <p>KOT- {kot?.order_no}</p>
                        <p>{formatIndianCurrency(kot?.total_amount)}</p>
                        <p>
                          <CheckWithoutBgUnderlineBtn
                            children={"Shift"}
                            onClick={() => [
                              setNewOrderRoom(true),
                              setSelectedShiftKot(kot),
                            ]}
                          />
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {showModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                <p style={{ marginBottom: "0" }}>
                  Do You Want To Cancel Booking?
                </p>
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <div className="reason-input-button">Enter Reason</div>
                    <div className="reason-input-button">
                      {/* {reason?.reason?.trim()?.length}/{wordLimit} */}
                    </div>
                  </div>
                  <div style={{ width: "100%", marginBottom: "10px" }}>
                    <VerticalTextAreaCustom
                      name={"cancelReason"}
                      className="textfield"
                      focused={false}
                      type="text"
                      value={cancelReason}
                      autoComplete="off"
                      onChange={(e) => setCancelReason(e.target.value)}
                      cols={9}
                    />
                  </div>
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                  >
                    Discard
                  </Button>
                  <Button
                    onClick={() => cancelBookingHandle(data?.id)}
                    destructive
                    type={"Button"}
                  >
                    Yes, Cancel
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}

        {openCheckedInMoreOptionsPopup && (
          <div className="more_options_popup" ref={popupsRef}>
            <button onClick={() => handleNoShowData("no_show", data?.id)}>
              No Show
            </button>
            <p>Add Add-on</p>
          </div>
        )}
        {/* {openPrintPopup && (
                    <div className="print_popup" ref={popupsRef}>
                        <p>Vouchers</p>
                        <p>GRC</p>
                        <p>Bill</p>
                    </div>
                )} */}

        {deleteWithReasonPopup && (
          <DeletePopupWithReasonBooking
            title={"Booking"}
            textAreaName={"reason_of_cancelation"}
            onDeleteWithReason={handleDeleteWithReason}
            wordLimit={50}
            deleteWithReasonPopup={deleteWithReasonPopup}
            setDeleteWithReasonPopup={setDeleteWithReasonPopup}
            deleteFunction={(data) => handleCancelStatusButton(data)}
            id={deleteKotId}
            btnText={"Yes, Delete"}
            type={"Button"}
            cancelText={"You Want To Delete This Booking?"}
          />
        )}
        {showAssignRoomPopup && (
          // <div className="bg-backdrop">
          //   <div
          //     style={{
          //       width: "500px",
          //       marginBottom: "20px",
          //       // height: "500px",
          //       // overflowY: "scroll",
          //     }}
          //     className="room-plan-add-model-container  overflow_x_scroll_y_hidden"
          //   >
          //     <GoBackButtonCustom
          //       buttonNextText={`Assign Rooms for Booking ID : ${clickedBookingId}`}
          //       onClick={() => [
          //         setShowAssignRoomPopup(false),
          //         setSelectedRoom(""),
          //       ]}
          //     />
          //     <div
          //       style={{
          //         display: "flex",
          //         justifyContent: "center",
          //         flexWrap: "wrap",
          //         flexDirection: "column",
          //       }}
          //     >
          //       {selectedData && selectedData.length > 0 ? (
          //         selectedData.map((data, index) => (
          //           <div
          //             key={index}
          //             className={`room-box ${
          //               selectedRoom === data.name ? "selected" : ""
          //             }`}
          //           >
          //             <div className="availableRoomsSelectBoxContainer">
          //               <div
          //                 className="availableRoomsSelectBox"
          //                 onClick={() =>
          //                   handleRoomClick(data.name, data.maxRooms)
          //                 }
          //               >
          //                 <div
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "space-between",
          //                     alignItems: "center",
          //                   }}
          //                 >
          //                   <span>{data.name}</span>
          //                   <span>Rooms:({data.maxRooms})</span>
          //                   <span
          //                     style={{
          //                       height: "20px",
          //                       width: "20px",
          //                     }}
          //                   >
          //                     <svg
          //                       viewBox="0 0 20 20"
          //                       class="Polaris-Icon__Svg"
          //                       focusable="false"
          //                       aria-hidden="true"
          //                     >
          //                       <path
          //                         fillRule="evenodd"
          //                         d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
          //                       ></path>
          //                     </svg>
          //                   </span>
          //                 </div>
          //               </div>
          //               {selectedRoom === data.name && (
          //                 <div className="checkbox-container">
          //                   {assignRoomsData
          //                     .find((room) => room.room_name === data.name)
          //                     .sorted_rooms.map((roomNo) => (
          //                       <div
          //                         className="flex flex-gap-10"
          //                         key={roomNo.id}
          //                       >
          //                         <input
          //                           style={{ marginRight: "5px" }}
          //                           type="checkbox"
          //                           checked={data.selectedRoooms.includes(
          //                             roomNo.room_no
          //                           )}
          //                           id={`room-${roomNo.room_no}`}
          //                           value={roomNo.room_no}
          //                           disabled={
          //                             roomNo.status === "occupied" ||
          //                             roomNo.status === "dirty" ||
          //                             roomNo.status === "out_of_order"
          //                           }
          //                           onChange={() =>
          //                             handleCheckboxChange(
          //                               roomNo.room_id,
          //                               data.name,
          //                               roomNo.id,
          //                               roomNo.room_no
          //                             )
          //                           }
          //                         />
          //                         <label htmlFor={`room-${roomNo.room_no}`}>
          //                           {`Room ${
          //                             roomNo.room_no
          //                           } (${roomNo.status.replace(/_/g, " ")})`}
          //                         </label>
          //                       </div>
          //                     ))}
          //                 </div>
          //               )}
          //             </div>
          //           </div>
          //         ))
          //       ) : (
          //         <>
          //           <div>No Rooms </div>
          //         </>
          //       )}
          //     </div>

          //     <div
          //       style={{
          //         display: "flex",
          //         justifyContent: "flex-start",
          //         alignItems: "center",
          //         gap: "10px",
          //       }}
          //     >
          //       <BgThemeButton
          //         onClick={(e) => assignRoomsHandle(e, data?.id)}
          //         children={"Assign"}
          //         type={"button"}
          //       />

          //       <BgOutlineThemeButton
          //         onClick={() => [
          //           setShowAssignRoomPopup(false),
          //           setSelectedRoom(""),
          //         ]}
          //         children={"Discard"}
          //       />
          //     </div>
          //   </div>
          // </div>

          <AssignRoomPopupNew
          mainData={data}
          setShowAssignRoomPopup={setShowAssignRoomPopup}
         
          
          />
        )}

        {iframeContent && showIframePopup && (
          <BookingVoucher
            setShowIframePopup={setShowIframePopup}
            iframeContent={iframeContent}
          />
        )}

        {/* add payments */}
        {addPaymentPopup && (
          <div className="bg-backdrop">
            <form
              onSubmit={settlementHandleFolio}
              style={{ width: "600px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Add Payments
                </div>
                <div
                  onClick={() => setAddPaymentPopup(!addPaymentPopup)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                  min={propertyCurrentDate}
                    required={true}
                    focused={false}
                    name="date"
                    type="date"
                    titleName="Date"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.date || currentDate}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={paymentTypeOptions}
                    type="text"
                    required={true}
                    focused={false}
                    name="payment_type"
                    titleName="Payment Type"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.payment_type}
                  />
                </div>
                <div className="form-container-div">
                  {addChargesData.payment_type === "cash" && (
                    <VerticalInputSelectCustomCopy
                      options={paymentModeOptions}
                      type="text"
                      required={true}
                      focused={false}
                      name="payment_mode"
                      titleName="Payment Mode"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.payment_mode}
                    />
                  )}
                  {/* {addChargesData.payment_type === "city_ledger" && (
                    <VerticalInputSelectCustomCopy
                      options={agentListOptions}
                      type="text"
                      required={true}
                      focused={false}
                      name="company_name"
                      titleName="Company Name"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.company_name}
                    />
                  )} */}
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="notes"
                    titleName="Notes"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.notes}
                  />
                </div>
                <div className="form-container-div">
                  {agentCreditLimit < 1 &&
                  addChargesData.payment_type === "city_ledger" ? (
                    <VerticalInputFieldCustom
                      type="number"
                      required={true}
                      focused={false}
                      disabled={true}
                      name="amount"
                      titleName="Amount"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.amount}
                    />
                  ) : (
                    <VerticalInputFieldCustom
                      type="number"
                      required={true}
                      focused={false}
                      // disabled={true}
                      name="amount"
                      titleName="Amount"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.amount}
                    />
                  )}
                </div>
              </div>
              {/* {addChargesData.payment_type === "city_ledger" && (
              <div className="form-container-div-full">
                <>
                  Balance Credit Limit:
                  <span>
                    {agentCreditLimit
                      ? formatIndianCurrency(agentCreditLimit)
                      : formatIndianCurrency(agentCreditLimit)}
                  </span>
                </>
              </div>
            )} */}

              {/* <div className="form-container-div-full">
                <>
                  Pending Balance:
                  <span>
                    {formatIndianCurrency(selectedFolio?.balance_amount)}
                  </span>
                </>
              </div> */}

              <div className="flex flex_gap_10">
                {/* {Number(selectedFolio?.balance_amount) ===
                Number(addChargesData.amount) ? ( */}
                <BgThemeButton children={"Add"} type={"submit"} />
                {/* ) : (
                  <BgThemeButtonDisabled children={"Add"} />
                )} */}

                <BgOutlineThemeButton
                  children={"Cancel"}
                  type={"button"}
                  onClick={() => setAddPaymentPopup(!addPaymentPopup)}
                />
              </div>
            </form>
          </div>
        )}

        {/* add charges */}
        {addChargesPopup && (
          <div className="bg-backdrop">
            <form
              onSubmit={handleAddCharges}
              style={{ width: "1000px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Add Charges
                </div>
                <div
                  onClick={() => setAddChargesPopup(!addChargesPopup)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    options={serviceOptions}
                    titleName="Service"
                    name="charge_type"
                    value={addChargesData.charge_type}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Description"
                    name="description"
                    type="text"
                    value={addChargesData.description}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Rate"
                    name="rate"
                    type="number"
                    value={addChargesData.rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Qty"
                    name="quantity"
                    type="number"
                    value={addChargesData.quantity}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Discount%"
                    name="discount_rate"
                    type="number"
                    value={addChargesData.discount_rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>

                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    options={gstTypeOptions}
                    required={true}
                    titleName="GST Type"
                    name="gst_type"
                    value={addChargesData.gst_type}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    required={true}
                    options={gstOptions}
                    titleName="GST%"
                    name="tax_rate"
                    value={addChargesData.tax_rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Subtotal"
                    name="sub_total"
                    disabled={true}
                    type="number"
                    value={addChargesData.subtotal}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div>
                Subtotal :{" "}
                {addChargesData.gst_type === "inclusive" ? (
                  // <HandleNaN
                  //   value={addChargesData.subtotal - addChargesData.tax_amount}
                  // />
                  <HandleNaN value={addChargesData.subtotal} />
                ) : (
                  <HandleNaN value={addChargesData.subtotal} />
                )}
              </div>

              <div>
                Tax Amount : <HandleNaN value={addChargesData.tax_amount} />
              </div>
              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Total Amount"
                    name="net_total"
                    type="number"
                    disabled={true}
                    value={
                      addChargesData.gst_type === "inclusive"
                        ? // ? addChargesData.net_total - addChargesData.tax_amount
                          addChargesData.net_total
                        : addChargesData.net_total
                    }
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div className="flex flex_gap_10">
                <BgThemeButton children={"Add"} type={"submit"} />
                <BgOutlineThemeButton
                  children={"Cancel"}
                  type={"button"}
                  onClick={() => setAddChargesPopup(!addChargesPopup)}
                />
              </div>
            </form>
          </div>
        )}

        {newOrderRoom && (
          <>
            <div className="bg-backdrop">
              <div
                onSubmit={(e) => e.preventDefault()}
                style={{ width: "702px" }}
                className="stock-add-model-container"
              >
                <div className="area_list_parent">
                  <div
                    className="add-ota-model-container-heading"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div>Rooms</div>
                    <div>
                      <>
                        {folioId && (
                          <>
                            <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Folio No: </strong>
                              {folioId?.folio_no}
                            </span>
                            {"  "}||{" "}
                            {/* <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Guest Name :</strong>{" "}
                              {folioId && folioId?.guest_name}
                            </span> */}
                            {/* <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Phone No. :</strong>{" "}
                              {folioId && folioId?.guest_phone}
                            </span> */}
                          </>
                        )}
                        <span style={{ fontWeight: "400", fontSize: "15px" }}>
                          KOT- {selectedSiftKot && selectedSiftKot?.order_no}
                        </span>
                        {"  "}||{" "}
                        <span style={{ fontWeight: "400", fontSize: "15px" }}>
                          {" "}
                          <strong>Room No. :</strong>{" "}
                          {selectedSiftKot && selectedSiftKot?.room_no}
                        </span>
                      </>
                      {/* {folioId && (
                          <>
                            <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Folio No: </strong>
                              {folioId?.folio_no}
                            </span>
                            {"  "}||{" "}
                            <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Guest Name :</strong>{" "}
                              {folioId && folioId?.guest_name}
                            </span>
                            {"  "}||{" "}
                            <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              {" "}
                              <strong>Phone No. :</strong>{" "}
                              {folioId && folioId?.guest_phone}
                            </span>
                          </>
                        )} */}
                    </div>
                  </div>
                  <div
                    className="flex flex_gap_10"
                    style={{ flexWrap: "wrap" }}
                  >
                    {roomsData && roomsData.length > 0 ? (
                      roomsOptions.map((data) => (
                        <div
                          className="area_container flex justify_content_between align_items_center"
                          key={data.id}
                          onClick={() =>
                            handleRoomClick(data.value, data.label)
                          }
                          style={{
                            backgroundColor: selectedRooms.includes(data.value)
                              ? "rgba(189, 148, 27, 1)"
                              : "rgba(21, 170, 18, 1)",
                          }}
                        >
                          {data.label}
                        </div>
                      ))
                    ) : (
                      <>No Available Rooms</>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    {!folioId ? (
                      <BgThemeButtonDisabled
                        onClick={() => [
                          // setNewOrderRoom(false),
                          // handleShift(property_id?.id, selectedSiftKot?.id),
                        ]}
                        type="submit"
                        children={"Shift"}
                      />
                    ) : (
                      <BgThemeButton
                        onClick={() => [
                          // setNewOrderRoom(false),
                          handleShift(property_id?.id, selectedSiftKot?.id),
                        ]}
                        type="submit"
                        children={"Shift"}
                      />
                    )}

                    <Button
                      onClick={() => [
                        setNewOrderRoom(false),
                        // setSelectedTables([]),
                        setSelectedRooms([]),
                        // setNewOrderPopup(false),
                        // setSelectedRoomsLabels([]),
                        // setSelectedTablesLabels([]),
                      ]}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <iframe
          srcDoc={iframeContent}
          id="receipt"
          style={{ display: "none" }}
          title="Receipt"
        />
      </div>
    </div>
  );
};
export default RoomKotDetailsSlider;
