import React from "react";
import CreateBookingInputBox from "./CreateBookingInputBox/CreateBookingInputBox";
import CreateBookingSelectBox from "./CreateBookingSelectBox/CreateBookingSelectBox";
import DateRangePickerNew from "./DateRangePickerNew/DateRangePickerNew";
import "./CreateBookingNewPage.css";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { useNavigate } from "react-router-dom";
import {
  calculateTotalArrayObjectKey,
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
  useCurrentTime,
} from "../../../utils/utils";
import CreateBookingSelectBoxOptionDisabled from "./createBookingSelectBoxOptionDisabled/CreateBookingSelectBoxOptionDisabled";
import CreateBookingCardNew from "./createBookingCard/CreateBookingCardNew";
import { getDecryptedData } from "../../../utils/encryptStorage";
import PrimaryGuestDetails from "./PrimaryGuestDetails";
import AddNewAgentPopup from "./AddNewAgentPopup";
import { SearchSelect } from "../../NewCustomComp/Search/Search";
import { SelectBasicWithSelectTag } from "../../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import DateRangePicker from "../../NewCustomComp/DateRangePicker/DateRangePicker";
import { PrimaryInput } from "../../NewCustomComp/Inputs/Inputs";
import styles from "./CreateBookingNewPage.module.scss";
import GuestDetailsNew from "./GuestDetailsNew";
import { Textarea } from "../../NewCustomComp/Testarea/Textarea";
const CreateBookingNewUi = () => {
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();

  const currentTimeToBackend = useCurrentTime("24");

  //..date range ....
  const currentDate = new Date().toISOString().split("T")[0];

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");

  const dataFromChild = (from_date, to_date) => {
    // setErrors((prev) => ({ ...prev, no_of_nights: false }));

    setCheckIn(from_date);
    setCheckOut(to_date);
    setBookingData((prevData) => ({
      ...prevData,
      check_in: from_date,
      check_out: to_date,
    }));
  };

  const BookingTypeOptions = [
    { label: "select", value: "" },
    { label: "Single", value: "single" },
    { label: "Multiple", value: "multiple" },
  ];
  // calculate the number of nights from check in check out

  const [bookingData, setBookingData] = useState({
    booking_type: "",
    available_rooms: "",
    extra_adult_allowed: "",
    check_out: null,
    check_in: null,
    no_of_nights: "",
    no_of_adults: "",
    extra_adults: "",
    no_of_child: "",
    no_of_rooms: "",
    room_category: "",
    room_id: "",
    booking_source: "",
    agent_id: "",
    discount: "",
    tax_type: "inclusive",
    tac_discount: "",
    selected_discount: "",
    room_plans: "",
    ota_source: "",
    agent_name: "",
  });

  useEffect(() => {
    const calculateNumberOfNights = () => {
      const checkIn = new Date(bookingData.check_in);
      const checkOut = new Date(bookingData.check_out);
      const timeDifference = checkOut.getTime() - checkIn.getTime();
      const numberOfNights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return numberOfNights;
    };

    const numberOfNightsForBooking = calculateNumberOfNights();

    setBookingData((prevData) => ({
      ...prevData,
      no_of_nights: numberOfNightsForBooking,
    }));
  }, [bookingData.check_in, bookingData.check_out]);

  //handle booking change
  const handleBookingTypeChange = (event) => {
    setErrors((prev) => ({ ...prev, booking_type: false }));
    setBookingData({
      ...bookingData,
      booking_type: event.target.value,
    });
  };

  //   ................Room Category Amd fetching room plans.....................

  const [RoomCategoryOptions, setRoomCategoryOptions] = useState([]);

  const fetchRoomTypeLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setRoomCategoryOptions(data?.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchRoomTypeLists();
    }
  }, [property_id]);

  const roomCategoryOptionsList = [
    { label: "select", value: "" },
    ...RoomCategoryOptions.map((item) => ({
      label: item.room_name,
      value: item.id,
    })),
  ];

  const handleRoomCategory = async (e) => {
    if (e.target.value === "") {
      setBookingData((perv) => ({
        ...perv,
        room_category: "",
        limits: "",
      }));
      setAvailableRooms("");

      return;
    }
    setBookingData((prev) => ({
      ...prev,
      no_of_rooms: "",
      no_of_adults: "",
      no_of_child: "",
      extra_adults: "",
    }));

    setErrors((prev) => ({ ...prev, room_category: false }));
    const id = e.target.value;
    const roomCategory = RoomCategoryOptions.find(
      (item) => +item.id == +id
    ).room_name;
    // api  for fetching the room plans

    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;
    // check availability api
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/availability/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}&room_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      const blockedRooms = data?.available_rooms_dates?.filter(
        (item) => item?.blocked !== 0
      );
      if (blockedRooms?.length > 0) {
        showErrorToast(
          `Room is blocked for selected dates :  ${blockedRooms
            .map((room) => room.date)
            .join(", ")}      `
        );
        return;
      } else {
        setAvailableRooms(data);
        setBookingData((prev) => ({
          ...prev,
          limits: data,
        }));

        //  fetch room plans
        fetchRoomRates(id, roomCategory);
      }

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRoomRates = async (id, roomCategory) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/rooms/rates/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }
      const data = await response.json();

      if (data.success === true) {
        setBookingData((prev) => ({
          ...prev,
          room_plans: data?.roomRates,
          room_category: roomCategory,
          room_id: id,
        }));
      }
    } catch (error) {
      console.error("Error fetching room plans:", error);
      showErrorToast("something went wrong please try again...");
    }
  };

  const [availableRooms, setAvailableRooms] = useState("");

  const handleInputChange = (e) => {
    const extraBeddingAllowed =
      availableRooms?.extra_bedding_allowed == "enabled" ? true : false;

    const { name, value } = e.target;

    if (name === "agent_id") {
      const findAgent = agentList.find(
        (item) => item.agent_information == value
      );

      setBookingData({
        ...bookingData,
        [name]: value,
        booking_source_name: findAgent?.agent_name,
      });
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
        tac_discount: 0,
      });
    }
  };
  const handleInputOtaChange = (e) => {
    setErrors((prev) => ({ ...prev, ota_source: false }));
    const { name, value } = e.target;
    setBookingData({
      ...bookingData,
      [name]: value,
    });
  };

  const [showNewAgentPopUp, setShowNewAgentPopUp] = useState(false);

  const handleInputSelectAgentChange = (e) => {
    setErrors((prev) => ({ ...prev, agent_id: false }));
    const { name, value } = e.target;
    if (e.target.value === "new_agent") {
      setShowNewAgentPopUp(true);
      setBookingData({
        ...bookingData,
        [name]: "",
        booking_source_name: "",
      });
      return;
    }

    const selectedAgent = agentList.find(
      (item) => +item.agent_information === +value
    );
    const agentName = selectedAgent ? selectedAgent.company_name : "";

    if (!agentName) {
      setBookingData({
        ...bookingData,
        [name]: "",
        booking_source_name: "",
      });
      return;
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
        booking_source_name: agentName,
      });
      return;
    }
  };

  const handleInputAgentNameChange = (e) => {
    setErrors((prev) => ({ ...prev, agent_name: false }));
    const { name, value } = e.target;
    setBookingData({
      ...bookingData,
      [name]: value,
    });
  };

  // booking submit handler
  const BookingSourceOptions = [
    { label: "select", value: "" },
    { label: "Walk In Booking", value: "walk in booking" },
    { label: "Front Desk", value: "front desk" },
    { label: "Sales and Marketing", value: "sales and marketing" },
    { label: "Agent (B2B) Partner", value: "agent" },
    { label: "OTA", value: "ota" },
  ];

  const handleBookingSourceChange = (e) => {
    setErrors((prev) => ({
      ...prev,
      booking_source: false,
      agent_id: false,
      agent_name: false,
    }));
    setBookingData({
      ...bookingData,
      booking_source: e.target.value,
      tac_discount: "",
      agent_id: "",
      agent_name: "",
      agentCategoryType: "",
      agent_information: "",
      agent_category: "",
      tac_discount: "",
    });
  };

  // for discount percentage
  const [discountOptions, setDiscountOptions] = useState([]);

  const fetchDiscountOptions = async () => {
    const checkIn = bookingData.check_in;
    const checkOut = bookingData.check_out;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/discount/${property_id?.id}?check_in=${checkIn}&check_out=${checkOut}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setDiscountOptions(data.discount);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching discount options:", error);
    }
  };

  useEffect(() => {
    if (bookingData.check_in && bookingData.check_out && property_id) {
      fetchDiscountOptions();
    }
  }, [
    bookingData.check_in,
    bookingData.check_out,
    property_id,
    checkIn,
    checkOut,
  ]);

  // handle click on Add button save booking data in Booking array
  const [bookingDataArray, setBookingDataArray] = useState([]);

  const [showDiscountSelect, setShowDiscountSelect] = useState(true);
  const handleDeleteBookingCard = (index) => {
    setBookingDataArray((prev) => prev.filter((_, i) => i !== index));
    setAdhocArray((prev) => prev.filter((_, i) => i !== index));
  };

  // ota sources
  const otaTypeOptions = [
    { label: "MakeMyTrip", value: "makemytrip" },
    { label: "GoIbibo", value: "goibibo" },
    { label: "Expedia", value: "expedia" },
    { label: "EaseMyTrip", value: "easemytrip" },
    { label: "ClearTrip", value: "cleartrip" },
    { label: "HappyEasyGo", value: "happyeasygo" },
    { label: "Travelguru", value: "travelguru" },
    { label: "Yatra", value: "yatra" },
    { label: "Agoda", value: "agoda" },
    { label: "Booking.com", value: "booking.com" },
  ];

  const [errors, setErrors] = useState({
    booking_type: false,
    no_of_nights: false,
    no_of_rooms: false,
    room_category: false,
    booking_source: false,
    agent_id: false,
    no_of_adults: false,
    discount_type: false,
    agent_id: false,
    agent_name: false,
    ota_source: false,
    agent_name: false,
    extra_adult: false,
    no_of_child: false,
    guest_name: false,
    sales_agent_name:false
  });

 

  const handleClickOnAddButton = () => {
    if (isDisabled()) {
      return;
    }
    const blockedRooms = availableRooms?.available_rooms_dates?.filter(
      (item) => item?.blocked !== 0
    );
    if (blockedRooms?.length > 0) {
      showErrorToast(
        `Room is blocked for selected dates :  ${blockedRooms
          .map((room) => room.date)
          .join(", ")}      `
      );
      return;
    }

    const hasSingleBooking = bookingDataArray.some(
      (item) => item.booking_type === "Single"
    );

    const hasSameRoomCategory = bookingDataArray.some(
      (item) => item.room_category === bookingData.room_category
    );
    if (hasSameRoomCategory) {
      showErrorToast("Same room category cannot be added twice");
      return;
    }

    if (hasSingleBooking) {
      showErrorToast("Only one booking can be created at a time");
    } else {
      setBookingDataArray((prevData) => [...prevData, bookingData]);
      setAvailableRooms({});
      setTimeout(() => {
        setBookingData((prevData) => ({
          ...prevData,
          no_of_rooms: "",
          no_of_adults: "",
          extra_adults: "",
          extra_adult: "",
          no_of_child: "",
          room_category: "",
          room_id: "",
        }));
      }, 500);
      // setDiscountOptions([]);
    }
  };

  const isDisabled = () => {
    setTimeout(() => {
      setErrors((prev) => ({
        ...prev,
        booking_type: false,
        no_of_nights: false,
        no_of_rooms: false,
        room_category: false,
        booking_source: false,
        agent_id: false,
        no_of_adults: false,
        discount_type: false,
        agent_name: false,
        ota_source: false,
        extra_adult: false,
        no_of_child: false,
        guest_name: false,
        sales_agent_name:false
      }));
    }, 5000);

    if (
      bookingData.booking_type === "" ||
      bookingData.no_of_nights === "" ||
      bookingData.no_of_rooms === "" ||
      bookingData.room_category === "" ||
      bookingData.booking_source === "" ||
      guestDetails.name === ""
    ) {
      showErrorToast("Please fill in all required fields.");
      if (bookingData.booking_type === "") {
        setErrors((prev) => ({ ...prev, booking_type: true }));
      }
      if (bookingData.no_of_nights === 0) {
        setErrors((prev) => ({ ...prev, no_of_nights: true }));
      }
      if (bookingData.room_category === "") {
        setErrors((prev) => ({ ...prev, room_category: true }));
      }
      if (bookingData.booking_source === "") {
        setErrors((prev) => ({ ...prev, booking_source: true }));
      }
      if (bookingData.no_of_rooms === "") {
        setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      }
      if (bookingData.no_of_adults === "") {
        setErrors((prev) => ({ ...prev, no_of_adults: true }));
      }
      if (!guestDetails.name) {
        setErrors((prev) => ({ ...prev, guest_name: true }));
      }

      return true;
    }
    if (
      bookingData?.booking_source === "sales and marketing" &&
      !bookingData?.sales_agent_name === ""
    ) {
      setErrors((prev) => ({ ...prev, sales_agent_name: true }));
      showErrorToast(
        "Please provide the Agent Name for Sales and Marketing bookings."
      );
      return true;
    }

    if (
      bookingData.booking_source === "agent" &&
      bookingData.agent_id === ""
    ) {
      setErrors((prev) => ({ ...prev, agent_id: true }));
      showErrorToast("Please Select Agent for B2B bookings.");
      return true;
    }

    if (bookingData.booking_source === "" && bookingData.ota_source === "") {
      setErrors((prev) => ({ ...prev, ota_source: true }));
      showErrorToast("Please provide the OTA Source for OTA bookings.");
      return true;
    }
    

    return false;
  };

  function calculateTotal(array, property) {
    // Check if the input is an array
    if (!Array.isArray(array)) {
      return 0;
    }

    return array.reduce((total, item) => {
      const value = parseFloat(item[property]) || 0; // Convert to number, default to 0 if NaN
      return total + value;
    }, 0);
  }

  const [guestDetails, setGuestDetails] = useState({
    salutation: "",
    name: "",
    email: "",
    phone: "",
    remarks: "",
  });

  // calculating the total of the booking arrays and saving them

  const sumSubTotal = calculateTotal(bookingDataArray, "subTotal") || 0;
  const sumGrandTotal = calculateTotal(bookingDataArray, "grandTotal");
  const sumDiscountAmount =
    calculateTotal(bookingDataArray, "discountAmount") || 0;

  const sumTaxAmount = calculateTotal(bookingDataArray, "taxAmount") || 0;

  // handle confirm booking button

  const isConfirmDisabled = () => {
    if (
      // guestDetails.salutation === "" ||
      guestDetails.name === ""
      // guestDetails.email === "" ||
      // guestDetails.phone === ""
    ) {
      setErrors((prev) => ({ ...prev, guest_name: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, guest_name: false }));
      }, 5000);
      showErrorToast("Please fill in all required fields.");
      return true;
    }
  };

  // on click of hold button show the hold modal

  const [showHoldPopup, setShowHoldPopup] = useState(false);

  const [holdBookingData, setHoldBookingData] = useState({
    hold_date: "",
    hold_time: "",
    hold_reason: "",
  });

  // function wrote  for showing the date format according to indian standard
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "dd/mm/yyyy";
    } else {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  };

  const [addOnsPaymentData, setAddOnsPaymentData] = useState({
    addOn: [],
    payments: [],
  });

  const sumPaidAmount =
    calculateTotal(addOnsPaymentData.payments, "amount") || 0;

  // add ons cost

  const sumSubtotalAddons =
    calculateTotal(addOnsPaymentData.addOn, "price") || 0;

  const sumGrandTotalAddons =
    calculateTotal(addOnsPaymentData.addOn, "total") || 0;

  const sumTaxAmountAddons =
    calculateTotal(addOnsPaymentData.addOn, "tax_amount") || 0;

  // new handler for room change
  const handleNumberOfRoomsChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
        no_of_adults: "",
        no_of_child: "",
        extra_adults: "",
      });
      setErrors((prev) => ({ ...prev, no_of_rooms: false }));
      return;
    }

    const selectedRooms = +value;
    const prevAvailableRooms = availableRooms?.available_rooms;

    if (selectedRooms <= 0) {
      showErrorToast("Please enter a valid number of rooms");
      setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      return;
    }

    if (selectedRooms > prevAvailableRooms) {
      showErrorToast(
        "Entered number cannot be greater than the number of available rooms"
      );
      setErrors((prev) => ({ ...prev, no_of_rooms: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_rooms: false }));
      }, 5000);
      return;
    }

    // Update the booking data with the new value
    setBookingData({
      ...bookingData,
      [name]: value,
      no_of_adults: "",
      no_of_child: "",
      extra_adults: "",
    });
    setErrors((prev) => ({ ...prev, no_of_rooms: false }));
  };

  // handle number of adult
  const handleNumberOfAdultChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
      return;
    }
    if (isNaN(value) || +value <= 0) {
      showErrorToast("Please enter a valid number of adults");
      setErrors((prev) => ({ ...prev, no_of_adults: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_adults: false }));
      }, 5000);

      return;
    }

    if (+value > availableRooms?.base_adult * bookingData.no_of_rooms) {
      showErrorToast(
        "The number of adults cannot be greater than the base occupancy of a room"
      );
      setErrors((prev) => ({ ...prev, no_of_adults: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_adults: false }));
      }, 5000);
      return;
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
    }
    setTimeout(() => {
      setErrors((prev) => ({ ...prev, no_of_adults: false }));
    }, 5000);
  };

  //handle number of extra adult change
  const handleNumberOfExtraAdultChange = (e) => {
    const { name, value } = e.target;

    // If the input field is cleared or contains a non-numeric value, update the booking data with an empty string
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, extra_adult: false }));
      return;
    }
    if (isNaN(value) || +value <= 0) {
      showErrorToast("Please enter a valid number of adults");
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);
      return;
    }

    if (availableRooms.extra_bedding !== "enabled") {
      showErrorToast("Extra Bedding not allowed for this room");
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);

      setBookingData({
        ...bookingData,
        [name]: 0,
      });
      return; // Return early if extra bedding is not allowed
    }
    if (bookingData.no_of_child !== "") {
      const backendMaxOccupancy =
        availableRooms?.max_adult * +bookingData.no_of_rooms;
      const frontEndMaxOccupancy =
        +bookingData.no_of_adults + +bookingData.no_of_child;
      const maxExtraAdultsAllowed =
        (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
        bookingData.no_of_rooms;
      if (+value > +maxExtraAdultsAllowed) {
        showErrorToast(
          "Max occupancy reached! please increase number of rooms"
        );
        setBookingData({
          ...bookingData,
          [name]: "", // Clear the input value
        });
        return;
      }
    }

    if (+value > availableRooms?.allowedExtraAdult * bookingData.no_of_rooms) {
      showErrorToast("Value cannot exceed the available extra adult per room");
      setErrors((prev) => ({ ...prev, extra_adult: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, extra_adult: false }));
      }, 5000);
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, extra_adult: false }));
    }
  };

  //handle number of child change

  const handleNumberOfChildChange = (e) => {
    const { name, value } = e.target;
    if (value === "" || isNaN(value)) {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
      setErrors((prev) => ({ ...prev, no_of_child: false }));
      return;
    }
    if (isNaN(value) || +value < 0) {
      showErrorToast("Please enter a valid number of children");
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_child: false }));
      }, 5000);
      setErrors((prev) => ({ ...prev, no_of_child: true }));
      return;
    }

    const childAllowed = availableRooms?.max_child;

    if (+value > childAllowed * +bookingData.no_of_rooms) {
      showErrorToast("Value cannot exceed the available child limit");
      setErrors((prev) => ({ ...prev, no_of_child: true }));
      setTimeout(() => {
        setErrors((prev) => ({ ...prev, no_of_child: false }));
      }, 5000);
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
      return;
    }

    const backendMaxOccupancy =
      availableRooms?.max_adult * +bookingData.no_of_rooms;
    const frontEndMaxOccupancy =
      +bookingData.no_of_adults + +bookingData.extra_adults;
    const maxChildAllowed =
      (backendMaxOccupancy - frontEndMaxOccupancy + 1) *
      bookingData.no_of_rooms;
    if (+value > +maxChildAllowed) {
      showErrorToast("Max occupancy reached! please increase number of rooms");
      setBookingData({
        ...bookingData,
        [name]: "", // Clear the input value
      });
      return;
    }

    setBookingData({
      ...bookingData,
      [name]: value,
    });
    setErrors((prev) => ({ ...prev, no_of_child: false }));
  };

  const handleGuestDetails = (e) => {
    setGuestDetails({
      ...guestDetails,
      [e.target.name]: e.target.value,
    });
  };

  // salutations

  const [showSalutationPopup, setShowSalutationPopup] = useState(false);

  const [salutations, setSalutations] = useState([]);

  const fetchSalutations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSalutations(data.data);
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
  };

  useEffect(() => {
    // fetchSalutations();
  }, []);

  const handleSalutationChange = (e) => {
    const { name, value } = e.target;

    if (value === "other") {
      setShowSalutationPopup(true);
    } else {
      setGuestDetails({
        ...guestDetails,
        salutation: e.target.value,
      });
      setShowSalutationPopup(false);
    }
  };

  const [popUpSalutation, setPopUpSalutation] = useState("");

  const handlePopupAddButtonClick = async (e) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/add`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            name: popUpSalutation,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setPopUpSalutation(false);
        setPopUpSalutation("");
        // fetchSalutations();
      } else if (data.message === "Unauthorised.") {
        navigate("/");
      } else {
        console.error("Failed to fetch salutations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching salutations:", error);
    }
    setGuestDetails({
      ...guestDetails,
      salutation: popUpSalutation,
    });
    setShowSalutationPopup(false);
  };
  const checkKeyValidity = (dataArray, keyName) => {
    for (const item of dataArray) {
      // Check if the key is empty or equal to 0
      if (!item[keyName] || item[keyName] === 0) {
        showErrorToast(`Invalid value for ${keyName.replace(/_/g, " ")}`);
        return true; // Stop further processing if an invalid value is found
      }
    }
  };


  

  const handleConfirmButtonClick = () => {
    if (isConfirmDisabled()) {
      showErrorToast("Please fill required Guest details.");
      return;
    }
    if (bookingDataArray.length === 0) {
      showErrorToast("Please add booking details");
      return;
    }

    if (checkKeyValidity(bookingDataArray, "no_of_adults")) {
      return;
    }
    if (checkKeyValidity(bookingDataArray, "no_of_rooms")) {
      return;
    }
    // Check child age for each booking pax
    for (const booking of bookingDataArray) {
      for (const pax of booking.booking_paxes) {
        if (pax.pax_type === "child" && !pax.child_age) {
          showErrorToast("Please enter child's age.");
          return;
        }
      }
    }

    function extractChildDetails(bookingPaxes) {
      const childPrices = [];
      const childAges = [];

      bookingPaxes.forEach((pax) => {
        if (pax.pax_type === "child") {
          childPrices.push(pax.price);
          childAges.push(pax.child_age);
        }
      });

      return [childPrices, childAges];
    }

    const roomInputData = bookingDataArray.map((item) => {
      const [child_price, child_age] = extractChildDetails(item.booking_paxes);
      return {
        room_id: item.room_id || "",
        room_type_name: item.room_category || "",
        room_plan_id: item.room_rate_details.rate_plan_id || "",
        room_plan: item.room_rate_details?.rate_plan_type || "",
        no_of_rooms: item.no_of_rooms || "",
        no_nights: item.no_of_nights || "",
        room_rate: item.room_rate_new,
        no_adult: item.no_of_adults || 0,
        no_child: item.no_of_child || 0,
        extra_adult: item.extra_adults || 0,
        discount_rate: item?.discount_type?.discount_amount || 0,
        discount_amount: item?.discountAmount || 0,
        tax_rate: item.tax_rate || 0,
        tax_amount: item.taxAmount || 0,
        room_total_amount: item.subTotal || 0,
        booking_paxes: item.booking_paxes,
        room_grand_total: item.grandTotal,
        ...(( !item.discount_type && item.tac_discount) && { tac_rate: item.tac_discount ,tac_amount : item?.discountAmount || 0  }),
        child_price: child_price,
        child_age: child_age,
      };
    });

    const addOns = addOnsPaymentData.addOn;

    const paymentData = addOnsPaymentData.payments;

    const postData = {
      status: "confirm",
      source: bookingData.booking_source,
      sales_agent: bookingData.agent_name,
      ota_source: bookingData.ota_source,
      booking_source_name:
        bookingData.booking_source === "agent"
          ? agentList.find((item) => item.id === bookingData.agent_id)
              ?.agent_name || null
          : bookingData.booking_source === "ota"
          ? bookingData.ota_source
          : bookingData.booking_source === "sales and marketing"
          ? bookingData.sales_agent_name
          : guestDetails.name || null,

      check_in: bookingData.check_in,
      check_out: bookingData.check_out,
      date: currentDate,
      agent_id: agentList.find((item) => +item.id === +bookingData.agent_id)?.agent_information || null,
      // agent_id: bookingData.agent_id,
      guest_name: guestDetails.salutation + " " + guestDetails.name,
      guest_email: guestDetails.email,
      guest_phone: guestDetails.phone,
      remarks: guestDetails.remarks,
      pincode: guestDetails.pincode || "",
      guest_city: guestDetails.city || "",
      guest_state: guestDetails.state || "",
      guest_address: guestDetails.address || "",
      gst_type: bookingData.tax_type || "",
      grand_total: sumGrandTotal,
      taxable_amount: sumSubTotal,
      total_tax_amount: sumTaxAmount,
      total_discount_amount: sumDiscountAmount,
      roomInputData: roomInputData,
      addOns: addOns,
      payments: paymentData,
      ...(( !bookingData.discount_type && bookingData.tac_discount) && { total_tac_amount: calculateTotalArrayObjectKey(bookingDataArray,"discountAmount")  || 0  }),

    };

    console.log(postData, "postData");

    // navigate("/bookings/createbooking/edit")

    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/store/new/bookings/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...postData,
            check_in: checkIn,
            check_out: checkOut,
            property_current_date:
              propertyCurrentDate + " " + currentTimeToBackend,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            showErrorToast("Error while creating booking");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success === true) {
            // navigate("/bookings")
            showSuccessToast("Booking Created Successfully");

            navigate(`/bookings/createbooking/edit/${data.booking_id}`);
          } else {
            showErrorToast("Error while creating booking");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function calculateDiscountAmountForBookingPaxes(bookingData) {
    let totalDiscountAmount = 0;
    // Loop through each booking in the array
    bookingData?.forEach((booking) => {
      // Loop through each booking pax in the booking
      booking?.booking_paxes?.forEach((pax) => {
        // Add the discount_amount of the booking pax to the totalDiscountAmount
        totalDiscountAmount += pax?.discount_amount;
      });
    });
    return totalDiscountAmount;
  }

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => {
          setPropertyCurrentDate(date);
          setHoldBookingData({
            hold_time: "",
            hold_date: date,
            hold_reason: "",
          });
        })
        .catch((error) => console.error(error));
    }
  }, [property_id]);

 

  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}`;
  }
  const holdBookingDataChange = (e) => {
    setHoldBookingData({
      ...holdBookingData,
      [e.target.name]: e.target.value,
    });
  };

  const holdBookingTimeDataChange = (e) => {
    const { name, value } = e.target;
    const propertyDate = propertyCurrentDate;
    const selectedDate = holdBookingData.hold_date;

    if (holdBookingData.hold_date === "") {
      showErrorToast("Please select hold date");
      return;
    }

    if (propertyCurrentDate === selectedDate) {
      const currentTime = getCurrentTime();
      const selectedTime = value;

      if (selectedTime > currentTime) {
        setHoldBookingData({
          ...holdBookingData,
          [name]: value,
        });
        return;
      } else {
        setHoldBookingData({
          ...holdBookingData,
          [name]: "",
        });
        showErrorToast("hold time should be greater than current time");
        return;
      }
    } else if (selectedDate > propertyDate) {
      setHoldBookingData({
        ...holdBookingData,
        [name]: value,
      });
      return;
    } else {
      setHoldBookingData({
        ...holdBookingData,
        [name]: "",
      });
      return;
    }
  };

  // hold booking data submit
  const handleAssignButtonClick = () => {
    if (holdBookingData.hold_date === "") {
      showErrorToast("please enter the hold date");
      return;
    }
    if (holdBookingData.hold_time === "") {
      showErrorToast("please enter the hold time");
      return;
    }
    if (holdBookingData.hold_reason === "") {
      showErrorToast("please enter reason for holding the booking");
      return;
    }
    if (checkKeyValidity(bookingDataArray, "no_of_adults")) {
      return;
    }
    if (checkKeyValidity(bookingDataArray, "no_of_rooms")) {
      return;
    }
    // Check child age for each booking pax
    for (const booking of bookingDataArray) {
      for (const pax of booking.booking_paxes) {
        if (pax.pax_type === "child" && !pax.child_age) {
          showErrorToast("Please enter child's age.");
          return;
        }
      }
    }

    function extractChildDetails(bookingPaxes) {
      const childPrices = [];
      const childAges = [];

      bookingPaxes.forEach((pax) => {
        if (pax.pax_type === "child") {
          childPrices.push(pax.price);
          childAges.push(pax.child_age);
        }
      });

      return [childPrices, childAges];
    }

    const roomInputData = bookingDataArray.map((item) => {
      const [child_price, child_age] = extractChildDetails(item.booking_paxes);
      return {
        room_id: item.room_id || "",
        room_type_name: item.room_category || "",
        room_plan_id: item.room_rate_details.rate_plan_id || "",
        room_plan: item.room_rate_details?.rate_plan_type || "",
        no_of_rooms: item.no_of_rooms || "",
        no_nights: item.no_of_nights || "",
        room_rate: item.room_rate_new,
        no_adult: item.no_of_adults || 0,
        no_child: item.no_of_child || 0,
        extra_adult: item.extra_adults || 0,
        discount_rate: item?.discount_type?.discount_amount || 0,
        discount_amount: item?.discountAmount || 0,
        tax_rate: item.tax_rate || 0,
        tax_amount: item.taxAmount || 0,
        room_total_amount: item.subTotal || 0,
        booking_paxes: item.booking_paxes,
        room_grand_total: item.grandTotal,
        child_price: child_price,
        child_age: child_age,
        ...(( !item.discount_type && item.tac_discount) && { tac_rate: item.tac_discount ,tac_amount : item?.discountAmount || 0  }),

      };
    });

    const addOns = addOnsPaymentData.addOn;

    const paymentData = addOnsPaymentData.payments;

    const postData = {
      status: "hold",
      hold_time: holdBookingData.hold_time,
      hold_date: holdBookingData.hold_date,
      hold_reason: holdBookingData.hold_reason,
      source: bookingData.booking_source,
      sales_agent: bookingData.agent_name,
      ota_source: bookingData.ota_source,

      booking_source_name:
        bookingData.booking_source === "agent"
          ? agentList.find((item) => item.id === bookingData.agent_id)
              ?.agent_name || null
          : bookingData.booking_source === "ota"
          ? bookingData.ota_source
          : bookingData.booking_source === "sales and marketing"
          ? bookingData.sales_agent_name
          : guestDetails.name || null,

      check_in: bookingData.check_in,
      check_out: bookingData.check_out,
      date: currentDate,
      agent_id: agentList.find((item) => +item.id === +bookingData.agent_id)?.agent_information || null,
      guest_name: guestDetails.salutation + " " + guestDetails.name,
      guest_email: guestDetails.email,
      guest_phone: guestDetails.phone,
      remarks: guestDetails.remarks,
      pincode: guestDetails.pincode || "",
      guest_city: guestDetails.city || "",
      guest_state: guestDetails.state || "",
      guest_address: guestDetails.address || "",
      gst_type: bookingData.tax_type || "",
      grand_total: sumGrandTotal,
      taxable_amount: sumSubTotal,
      total_tax_amount: sumTaxAmount,
      total_discount_amount: sumDiscountAmount,
      roomInputData: roomInputData,
      addOns: addOns,
      payments: paymentData,
      ...(( !bookingData.discount_type && bookingData.tac_discount) && { total_tac_amount: calculateTotalArrayObjectKey(bookingDataArray,"discountAmount")  || 0  }),

    };

    try {
      fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/bookings/store/new/bookings/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...postData,
            check_in: checkIn,
            check_out: checkOut,
            property_current_date:
              propertyCurrentDate + " " + currentTimeToBackend,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            showErrorToast("Error while creating booking");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success === true) {
            // navigate("/bookings")
            showSuccessToast("Booking Created Successfully");

            navigate(`/bookings/createbooking/edit/${data.booking_id}`);
          } else {
            showErrorToast("Error while creating booking");
          }
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // array for tracking the show adhoc room rates form child to show the discount
  const [adhocArray, setAdhocArray] = useState([]);

  const getAdhocArrayFromChild = (data, index) => {
    setAdhocArray((prev) => {
      const newArray = [...prev];
      newArray[index] = data;
      return newArray;
    });
  };

  // discount type

  const handleDiscountTypeChange = (e) => {
    const id = e?.target?.value;

    const discount = discountOptions?.find((item) => item?.id == id);
    setBookingData({
      ...bookingData,
      discount_type: discount,
    });
  };

  useEffect(() => {
    if (adhocArray.includes(true)) {
      setBookingData({
        ...bookingData,
        discount_type: null,
      });
    }
  }, [adhocArray, setBookingData]);

  // rates of customers and agents

  const [customersList, setCustomersList] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (property_id) {
        try {
          const response = await fetch(
            `${
              process.env.REACT_APP_BASE_URL
            }/api/v1/get/guests/customer_list/${
              property_id?.id
            }?pagination=${false}`,
            {
              method: "POST", // Changed to POST
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          if (!response.ok) {
            if (response.status === 401) {
              navigate("/");
            }
            throw new Error("Failed to fetch data");
          }

          const data = await response.json();
          setCustomersList(data.guests);
        } catch (error) {
          console.error("Error fetching customers:", error);
        }
      }
    };

    fetchCustomers();
  }, [property_id, userToken, navigate]);

  const [agentList, setAgentList] = useState([]);

  const fetchAgentList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAgentList(data.data);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentList();
    }
  }, [property_id]);

  const handleAgentChange = (value) => {
    if (value) {
      if (bookingData.booking_source === "agent") {
        setBookingData((prev) => ({
          ...prev,
          agent_id: value,
          category_id: agentList.find((item) => +item.id === +value)
            ?.agent_category,
          agentCategoryType: agentList.find((item) => +item.id === +value)
            ?.agent_cat_name,
          agentRateType: agentList.find((item) => +item.id === +value)
            ?.agent_cat_discount_type,
        }));
      }
    } else {
      setBookingData((prev) => ({
        ...prev,
        category_id: "",
        agent_id: "",
        agentCategoryType: "",
        agentRateType: "",
        tac_discount: "",
      }));
    }
  };

  // fetch tac discount

  // Tac discount
  const fetchTacDiscount = async () => {
    const agentId = agentList.find(
      (item) => +item.id === +bookingData.agent_id
    )?.agent_information;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/tac/discount/${agentId}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setBookingData({
        ...bookingData,
        tac_discount: data.tac_discount,
        agent_information: data.agent_information,
      });
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      bookingData?.agent_id &&
      bookingData?.agentRateType &&
      bookingData?.agentRateType === "tac"
    ) {
      fetchTacDiscount();
    } else {
      setBookingData((prev) => ({
        ...prev,
        tac_discount: "",
      }));
    }
  }, [bookingData?.agent_id, bookingData?.agentRateType]);

  //get the booked by name

  const getRatesInfo = (bookingData, guestDetails) => {
    if (
      bookingData?.booking_source === "agent" &&
      bookingData.agent_id &&
      bookingData?.agentRateType === "manual-rates"
    ) {
      return { ratesType: "agent", rateTypeId: bookingData.category_id };
    } else if (
      guestDetails?.customer_category_id &&
      guestDetails?.customer_id && !bookingData.agent_id 
    ) {
      return {
        ratesType: "customer",
        rateTypeId: guestDetails?.customer_category_id,
      };
    } else  {
      return { ratesType: "room", rateTypeId: null };
    }
  };

  const { ratesType, rateTypeId } = getRatesInfo(bookingData, guestDetails);

  console.log(bookingData, "bookingData");
  console.log(guestDetails, "guestDetails");
  console.log(agentList ,"hiiiiiii")

  return (
    <>
      <div
        className={`${styles.topContainer} card-3d mt-20`}
        style={{ background: "#fff" }}
      >
        {/* top box start */}
        <div className="flex align_items_end flex_gap_10">
          <div className="w-40 flex flex_gap_10 align_items_end">
            <div className="w-30">
              <SelectBasicWithSelectTag
                size={"medium"}
                label="Booking Type"
                value={bookingData.booking_type}
                onChange={handleBookingTypeChange}
                options={BookingTypeOptions}
                disabled={bookingDataArray.length > 0}
                error={errors.booking_type}
              />
            </div>
            <div
              className="w-40"
              onClick={() => setErrors({ no_of_nights: false })}
            >
              <DateRangePicker
                label={"Select Date Range"}
                size={"medium"}
                onChange={dataFromChild}
                minDate={new Date(propertyCurrentDate)}
                disabled={
                  bookingDataArray.length > 0 ||
                  bookingData.room_category !== ""
                }
                error={errors.no_of_nights}
                defaultDate={{
                  startDate: bookingData.check_in,
                  endDate: bookingData.check_out,
                }}
              />
            </div>
            <div className="w-30">
              <SelectBasicWithSelectTag
                size={"medium"}
                disabled={
                  bookingData.check_in && bookingData.check_out ? false : true
                }
                label="Room Category"
                value={bookingData.room_id}
                onChange={handleRoomCategory}
                options={roomCategoryOptionsList}
                error={errors.room_category}
              />
            </div>
          </div>

          <div className="w-30 flex flex_gap_10">
            <div className="w-10">
              <PrimaryInput
                size={"small"}
                label="No. of Nights"
                disabled={true}
                value={bookingData.no_of_nights}
                error={errors.no_of_nights}
              />
            </div>

            <div className="w-10">
              <PrimaryInput
                size={"small"}
                label="No. of Rooms"
                onChange={handleNumberOfRoomsChange}
                name="no_of_rooms"
                value={bookingData.no_of_rooms}
                error={errors.no_of_rooms}
                disabled={bookingData.room_category === ""}
              />
            </div>

            <div className="w-10">
              <PrimaryInput
                size={"small"}
                label="No. of Adults"
                onChange={handleNumberOfAdultChange}
                name="no_of_adults"
                value={bookingData.no_of_adults}
                error={errors.no_of_adults}
                disabled={bookingData.room_category === ""}
              />
            </div>

            <div className="w-10">
              <PrimaryInput
                size={"small"}
                alignment="vertical"
                label="Extra Adults"
                onChange={handleNumberOfExtraAdultChange}
                name="extra_adults"
                value={bookingData.extra_adults}
                error={errors.extra_adult}
                disabled={
                  bookingData.room_category === "" ||
                  bookingData.no_of_adults === ""
                }
              />
            </div>

            <div className="w-10">
              <PrimaryInput
                size={"small"}
                alignment="vertical"
                label="No. of Child"
                name="no_of_child"
                onChange={handleNumberOfChildChange}
                value={bookingData.no_of_child}
                error={errors.no_of_child}
                disabled={
                  bookingData.room_category === "" ||
                  bookingData.no_of_adults === ""
                }
              />
            </div>
          </div>
        </div>
        {/* top box end */}

        <div className={styles.availabilityContainer}>
          <div>
            {availableRooms.available_rooms !== undefined && (
              <p
                className="avail_room"
                style={{
                  fontSize: "12px",
                  backgroundColor:
                    availableRooms.available_rooms === 0
                      ? "rgb(255 0 0 / 18%)"
                      : "",
                  color: availableRooms.available_rooms === 0 ? "red" : "",
                }}
              >
                {availableRooms.available_rooms === 0
                  ? "Rooms not available"
                  : `Available Rooms: ${availableRooms.available_rooms}`}
              </p>
            )}

            {(availableRooms.base_adult !== undefined ||
              availableRooms.base_adult === "") && (
              <p style={{ fontSize: "10px" }}>
                Base Occupancy: {availableRooms.base_adult}
              </p>
            )}

            {(availableRooms.max_adult !== undefined ||
              availableRooms.max_adult === "") && (
              <p style={{ fontSize: "10px" }}>
                Max. Occupancy: {availableRooms.max_adult}
              </p>
            )}

            {(availableRooms.allowedExtraAdult !== undefined ||
              availableRooms.allowedExtraAdult === "") && (
              <p
                style={{
                  fontSize: "10px",
                  color: availableRooms.allowedExtraAdult ? "green" : "red",
                }}
              >
                Extra Adult Allowed:{" "}
                {availableRooms.allowedExtraAdult
                  ? `Enabled (${availableRooms.allowedExtraAdult})`
                  : "Disabled"}
              </p>
            )}

            {(availableRooms.max_child !== undefined ||
              availableRooms.max_child === "") && (
              <p style={{ fontSize: "10px" }}>
                Child Allowed: {availableRooms.max_child}
              </p>
            )}
            <></>
          </div>
        </div>

        <hr className="mt-10 mb-10" />
        {/* bottom box start */}
        <div className="flex flex_gap_10 align_items_end">
          <div>
            <SelectBasicWithSelectTag
              size={"medium"}
              label="Booking Source"
              value={bookingData?.booking_source}
              onChange={handleBookingSourceChange}
              options={BookingSourceOptions}
              disabled={bookingDataArray.length > 0}
              error={errors.booking_source}
            />
          </div>

          {bookingData.booking_source &&
            bookingData.booking_source == "agent" && (
              <>
                <div className="w-10">
                  <SearchSelect
                    size={"medium"}
                    label={"Select Agent"}
                    options={agentList}
                    optionKeyName={"agent_name"}
                    selectedKeyName={"id"}
                    handleOptionChange={handleAgentChange}
                    defaultSelected={bookingData.agent_id}
                    showKeyName={"agent_name"}
                    error={errors.agent_id}
                  />
                </div>

                <div className="w-10">
                  <PrimaryInput
                    label={"TAC Disc. %"}
                    size={"medium"}
                    type="number"
                    name="tac_discount"
                    disabled
                    value={bookingData?.tac_discount}
                  />
                </div>
                <div className="w-10">
                  <PrimaryInput
                    label={"Agent's Category"}
                    size={"medium"}
                    type={"text"}
                    disabled
                    value={bookingData?.agentCategoryType}
                  />
                </div>
              </>
            )}
          {bookingData.booking_source === "ota" && (
            <div className="w-10">
              <SelectBasicWithSelectTag
                size={"medium"}
                label={"Select OTA"}
                onChange={handleInputOtaChange}
                name="ota_source"
                value={bookingData.ota_source}
                options={otaTypeOptions}
                error={errors.ota_source}
              />
            </div>
          )}

          {bookingData.booking_source === "sales and marketing" && (
            <div className="w-10">
              <PrimaryInput
                label={"Sales Agent Name"}
                size={"medium"}
                type="text"
                name="sales_agent_name"
                onChange={(e) => handleInputChange(e, "sales_agent_name")}
                value={bookingData?.sales_agent_name}
                error={errors.sales_agent_name}
              />
            </div>
          )}

          <div>
            <BgThemeButton
              onClick={handleClickOnAddButton}
              type={"submit"}
              children={"Add Room"}
              disabled={
                availableRooms !== "" && availableRooms.available_rooms === 0
                  ? true
                  : false
              }
              style={{
                background:
                  availableRooms !== "" && availableRooms.available_rooms === 0
                    ? "#6483db"
                    : "",
                cursor:
                  availableRooms !== "" && availableRooms.available_rooms === 0
                    ? "not-allowed"
                    : "",
              }}
            />
          </div>
        </div>

        {/* bottom box end */}

        <hr className="mt-10 mb-10" />

        {/* guest details start  */}
        <GuestDetailsNew
          guestDetails={guestDetails}
          setGuestDetails={setGuestDetails}
          errors={errors}
          setErrors={setErrors}
          customers={customersList}
          bookingDataArray={bookingDataArray}
        />

        {/*  guest details end */}
      </div>

      <div className="flex flex_gap_20 mt-20">
        <div className="w-75">
          {bookingDataArray.length > 0 && (
            <>
              {bookingDataArray.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <CreateBookingCardNew
                      index={index}
                      singleBookingData={data}
                      setBookingDataArray={setBookingDataArray}
                      bookingDataArray={bookingDataArray}
                      handleDeleteBookingCard={handleDeleteBookingCard}
                      discount_type={bookingData.discount_type}
                      tax_type={bookingData.tax_type}
                      getAdhocArrayFromChild={getAdhocArrayFromChild}
                      adhocArray={adhocArray}
                      ratesType={ratesType}
                      rateTypeId={rateTypeId}
                      styles={styles}
                    />
                  </React.Fragment>
                );
              })}
            </>
          )}

          {/* guest details */}

          <div className="card-3d">
            {/* <PrimaryGuestDetails
              guestDetails={guestDetails}
              setGuestDetails={setGuestDetails}
              errors={errors}
              setErrors={setErrors}
              hideTopCard={true}
            /> */}

            <Textarea
              label={"Remarks"}
              size={"medium"}
              value={guestDetails.remarks}
              name={"remarks"}
              onChange={handleGuestDetails}
            />
          </div>
        </div>

        {/* total amount Card   */}

        <div className="card-3d w-25">
          {/* setting the discount type and tax type changing these will change the total amount */}
          {/* this select tag is sending id to handler and it sets discount type object in BookingData object */}
          <div
            style={{
              borderBottom: "0.5px solid #333",
            }}
            className="mb-10"
          >
            <div>
              {!adhocArray.includes(true) && (
                <>
                  <label>Disc Type</label> <br />
                  <select
                    onChange={handleDiscountTypeChange}
                    name="discount_id"
                    className="createBookingInputAndSelectTags"
                    style={{ marginTop: "5px", width: "150px" }}
                    value={bookingData?.discount_type?.id}
                  >
                    <option value="">Select</option>

                    {discountOptions && discountOptions.length > 0 ? (
                      discountOptions.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.discount_name} {item.discount_amount}
                        </option>
                      ))
                    ) : (
                      <option disabled>No discount added</option>
                    )}
                  </select>
                </>
              )}
            </div>
            <div className="mt-10 mb-10">
              <label className="Create_booking_label">Tax</label>
              <div className="flex gap_7">
                <label className="Create_booking_label flex gap_7">
                  <input
                    type="radio"
                    name="tax_type"
                    value="inclusive"
                    checked={bookingData.tax_type === "inclusive"}
                    onChange={handleInputChange}
                  ></input>
                  Inclusive
                </label>
                <label className="Create_booking_label flex gap_7">
                  <input
                    type="radio"
                    name="tax_type"
                    value={"exclusive"}
                    checked={bookingData.tax_type === "exclusive"}
                    onChange={handleInputChange}
                  ></input>
                  Exclusive
                </label>
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="crate_booking_page_total_amount_heading">
              <span>Subtotal</span>
              <span>
                {formatIndianCurrency(+sumSubTotal + +sumSubtotalAddons)}{" "}
              </span>
            </div>
            <div className="crate_booking_page_total_amount_heading">
              <span>Discount Amt.</span>
              <span>{formatIndianCurrency(+sumDiscountAmount)}</span>
            </div>

            <div className="crate_booking_page_total_amount_heading">
              <span>Tax Amt.</span>
              <span>
                {formatIndianCurrency(+sumTaxAmount + +sumTaxAmountAddons)}{" "}
              </span>
            </div>
            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#3968ED" }}
            >
              <span>Grand Total</span>
              <span>
                {formatIndianCurrency(+sumGrandTotal + +sumGrandTotalAddons)}
              </span>
            </div>

            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#15AA12" }}
            >
              <h4>Paid Amt.</h4>
              <h3>{formatIndianCurrency(sumPaidAmount)}</h3>
            </div>
            <div
              className="crate_booking_page_total_amount_heading"
              style={{ color: "#BD941B" }}
            >
              <h4>Due Amt.</h4>
              <h3>
                {formatIndianCurrency(
                  +sumGrandTotal + +sumGrandTotalAddons - sumPaidAmount
                )}
              </h3>
            </div>
          </div>

          <div className="flex flex_gap_10 mt-10">
            <BgThemeButton
              onClick={handleConfirmButtonClick}
              type={"submit"}
              children={"Confirm"}
              disabled={bookingDataArray.length < 1}
            />

            <BgThemeButton
              style={{
                background: "#BD941B",
                cursor: bookingDataArray.length < 1 ? "not-allowed" : "",
              }}
              onClick={() => setShowHoldPopup(true)}
              disabled={bookingDataArray.length < 1}
            >
              Hold
            </BgThemeButton>
          </div>

          {showHoldPopup && (
            <>
              <div
                className="createBookingTableNewPopupContainer"
                onClick={() => setShowHoldPopup(false)}
              >
                <div
                  className="createBookingTableNewPopupContent"
                  onClick={(e) => e.stopPropagation()}
                  style={{ width: "400px" }}
                >
                  <h3 style={{ textAlign: "center" }}>Hold Date & Time</h3>

                  <div className="d_flex gap_7" style={{ margin: "15px 0" }}>
                    <div style={{ width: "48%" }}>
                      <label>Select Date</label> <br />
                      <div style={{ position: "relative" }}>
                        <input
                          type="date"
                          className="addEvent-input"
                          min={propertyCurrentDate}
                          name="hold_date"
                          style={{ cursor: "pointer" }}
                          value={holdBookingData.hold_date}
                          onChange={holdBookingDataChange}
                          placeholder="Enter Date"
                        ></input>
                        <input
                          type="text"
                          className="addEvent-input"
                          name="hold_date"
                          value={formatDate(holdBookingData.hold_date) || ""}
                          onChange={holdBookingDataChange}
                          placeholder="dd/mm/yyyy"
                          style={{
                            position: "absolute",
                            width: "50%",
                            top: "2px",
                            left: "2px",
                            border: "none",
                            outline: "none",
                          }}
                        ></input>
                      </div>
                    </div>
                    <div style={{ width: "48%" }}>
                      <label>Select Time</label> <br />
                      {/* <select
                        className="addEvent-input"
                        name="hold_time"
                        value={holdBookingData.hold_time}
                        onChange={holdBookingDataChange}
                      >
                        {timeArray.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                      <input
                        type="time"
                        className="addEvent-input"
                        name="hold_time"
                        value={holdBookingData.hold_time}
                        onChange={holdBookingTimeDataChange}
                        min={getCurrentTime()}
                      />
                    </div>
                  </div>

                  <div>
                    <label>Reason</label> <br />
                    <textarea
                      className="addEvent-input"
                      name="hold_reason"
                      onChange={holdBookingDataChange}
                      style={{ width: "100%", resize: "none", padding: "5px" }}
                    />
                  </div>

                  <div className="createBookingTableNewPopupFooter">
                    <BgThemeButton
                      // type={"submit"}
                      children={"Assign"}
                      style={{ background: "#BD941B", width: "48%" }}
                      onClick={handleAssignButtonClick}
                    />
                    <BgOutlineThemeButton
                      //  type={"submit"}
                      children={"Discard"}
                      onClick={() => setShowHoldPopup(false)}
                      style={{ width: "48%" }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {showNewAgentPopUp && (
            <>
              <div className="global_popup_container_background">
                <div className="global_popup_container overflowContainer">
                  <AddNewAgentPopup
                    show={showNewAgentPopUp}
                    setShow={setShowNewAgentPopUp}
                    setBookingData={setBookingData}
                    fetchAgentList={fetchAgentList}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default CreateBookingNewUi;
