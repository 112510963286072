import { useState, useEffect, useRef } from "react";
import "./LeadDetailsSlider.css";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
  SettleBillButton,
  WithoutBgButtonBlue,
  WithoutBgButtonForRates,
} from "../../UI/Buttons";
import UnderscoreRemoveCapitalize from "../UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../../assets/toastUtils";
import { showSuccessToast } from "../../../assets/toastUtils";
import VerticalTextAreaCustom from "../VerticalTextAreaCustom";
import { Button, Icon } from "@shopify/polaris";
import DeletePopupWithReasonBooking from "../DeletePopup/DeletePopupWithReasonBooking";
import GoBackButtonCustom from "../../GoBackButtonCustom/GoBackButtonCustom";
import BookingVoucher from "../BookingBillsVouchers/BookingVoucher";
import {
  formatAMPM,
  formatIndianCurrency,
  getCurrentPropertyDateHandler,
} from "../../../utils/utils";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import VerticalInputFieldCustom from "../VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../VerticalInputSelectCustomCopy";
import { Link, useNavigate } from "react-router-dom";
import HandleNaN from "../HandleNaN/HandleNaN";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import BookingStatusUpdate from "../BookingStatusUpdate";
import { getDecryptedData } from "../../../utils/encryptStorage";
import AssignRoomPopupNew from "../AssignRoomPopupNew/AssignRoomPopupNew";
const LeadDetailsSlider = ({
  data,
  setShowBookingDetails,
  chargesDetails,
  masterFolioDetails,
  fetchInfo,
}) => {
  const navigate = useNavigate();
  const [openPrintPopup, setOpenPrintPopup] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [openMoreOptionsPopup, setOpenMoreOptionsPopup] = useState(false);
  const [openCheckedInMoreOptionsPopup, setOpenCheckedInMoreOptionsPopup] =
    useState(false);
  const [addLeadsPopUp, setAddLeadsPopUp] = useState(false);

  function handlePrintPopup() {
    setOpenPrintPopup(!openPrintPopup);
  }
  function handleEmailPopup() {
    setOpenEmailPopup(!openEmailPopup);
  }
  function handleMoreOptionsPopup() {
    setOpenMoreOptionsPopup(!openMoreOptionsPopup);
  }
  function handleCheckedInMoreOptionsPopup() {
    setOpenCheckedInMoreOptionsPopup(!openCheckedInMoreOptionsPopup);
  }
  const popupsRef = useRef(null);
  const bookingDetailsPopupRef = useRef(null);

  const [previewData, setPreviewData] = useState(data.lead_communication);

  console.log(previewData, "pdataaaa");

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupsRef.current && !popupsRef.current.contains(event.target)) {
        setOpenPrintPopup(false);
        setOpenEmailPopup(false);
        setOpenMoreOptionsPopup(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [communicationItems, setCommunicationItems] = useState(
    data.lead_communication
  );

  const handleConvertToBooking = (id) => {
    const updatedItems = communicationItems.map((item) =>
      item.id === id ? { ...item, status: "converted" } : item
    );
    setCommunicationItems(updatedItems);
  };

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (
  //       bookingDetailsPopupRef.current &&
  //       !bookingDetailsPopupRef.current.contains(event.target)
  //     ) {
  //       setShowBookingDetails(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, []);

  //   update fucntion

  const hadnleSubmitUpdateLead = (event, leadId) => {
    event.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v2/update/lead/${leadId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddLeadsPopUp(false);
          setShowBookingDetails(false);
          fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const formattedDate = new Date(data.created_at).toLocaleString("en-US", {
    dateStyle: "medium",
    timeStyle: "short",
  });
  // ............Cancel Booking.............
  const [loader, setLoader] = useState(true);
  const [deleteReason, setdeleteReason] = useState("");
  const userToken = getDecryptedData('encryptToken');
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [bookings, setBookings] = useState([]);
  const [bookingOuterDetails, setBookingOuterDetails] = useState([]);
  const [cancelBookingId, setCancelBookingId] = useState("");
  const [dayTypeFilter, setDayTypeFilter] = useState("-1");
  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);
  const property_id = useSelector((state) => state.property.value);

  //   const fetchInfo = async (PropertyId, from_date, to_date) => {
  //     setLoader(true);
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v2/get/leads/${PropertyId}`,
  //       {
  //         method: "POST",
  //         body: JSON.stringify({
  //           booking_status: selectedBookingStatus,
  //           date_filters: selectedDateFilter,
  //           booking_id: bookingId,
  //           dayTypeFilter: dayTypeFilter,
  //           from_date: from_date,
  //           to_date: to_date,
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const data = await response
  //       .json()
  //       .then((data) => {
  //         if (data.status) {
  //           setBookings(data.leads);
  //           setBookingOuterDetails(data);
  //           showSuccessToast(data.message);
  //           setLoader(false);
  //           // navigate("/bookings");
  //         } else {
  //           showErrorToast(data.message);
  //           setLoader(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         showErrorToast("An error occurred while processing your request.");
  //         setLoader(false);
  //       });
  //   };

  //   useEffect(() => {
  //     if (property_id) {
  //       fetchInfo(
  //         property_id.id,
  //         selectedDatesRange.from_date,
  //         selectedDatesRange.to_date,
  //         selectedDateFilter,
  //         selectedBookingStatus,
  //         dayTypeFilter
  //       );
  //     }
  //   }, [property_id, selectedDateFilter, selectedBookingStatus, dayTypeFilter]);

  const cancelBookingHandle = (LeadId) => {
    // e.preventDefault();
    // const credentials = { email: email, password: password };
    // http://127.0.0.1:8000/api/v1/get/booking/9a0783fb-213b-4f31-ad9d-a5605d8672a4
    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v2/lead/delete/${LeadId}/${property_id?.id}?deleteReason=${deleteReason}`,
      {
        method: "Delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setLoader(false);
          fetchInfo(property_id.id);
          setShowModelMenuActive(false);
          window.location.reload();
        } else {
          // setSingleBookingDetail(data.booking);
          // setShowBookingDetails(true);
          // showSuccessToast("Data Fetched Successfully");
          showSuccessToast(data.message);
          setShowModelMenuActive(false);
          // setOpenSingleBookingDetails(!openSingleBookingDetails);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
    setShowBookingDetails(false);
  };

  function handleCancelBooking() {
    setShowModelMenuActive(true);
    setCancelBookingId(data?.id);
  }

  // ...................Remove Reservation..........
  const [reasonFromChild, setReasonFromChild] = useState("");
  const [uniqueBookingId, setUniqueBookingId] = useState(false);
  const [deleteWithReasonPopup, setDeleteWithReasonPopup] = useState(false);
  const [deleteKotId, setDeleteKotId] = useState("");
  function handleRemoveReservation() {
    setDeleteWithReasonPopup(true);
  }
  const handleDeleteWithReason = (reason) => {
    setReasonFromChild(reason);
  };

  // .........No show.........
  const [noShowData, setNoShowData] = useState();

  const handleNoShowData = (status, bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/booking_status/${bookingId}/${status}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      });
  };

  // ...............Assign Room....................
  const [clickedBookingId, setClickedBookingId] = useState("");
  const [shortBookingId, setShortBookingId] = useState("");
  const [showAssignRoomPopup, setShowAssignRoomPopup] = useState(false);
  const [assignRoomsData, setAssignRoomsData] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRoooms, selectedRooooms] = useState({});
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const handleRoomClick = (roomId, maxRooms) => {
    setSelectedRoom((prev) => (prev === roomId ? "" : roomId));
  };
  const handleCheckboxChange = (roomId, roomName, roomNoId, room_no) => {
    selectedRooooms((prevSelectedRooms) => {
      const newSelectedRooms = { ...prevSelectedRooms };
      const currentSelection = newSelectedRooms[roomId] || [];

      if (currentSelection.includes(room_no)) {
        newSelectedRooms[roomId] = currentSelection.filter(
          (id) => id !== room_no
        );
      } else {
        const roomCategory = assignRoomsData.find((room) => room.id === roomId);
        const maxRooms = parseInt(roomCategory.no_of_rooms, 10);

        if (currentSelection.length < maxRooms) {
          newSelectedRooms[roomId] = [...currentSelection, room_no];
        } else {
          showErrorToast(
            `Cannot select more than ${maxRooms} rooms for ${roomName}`
          );
          return prevSelectedRooms;
        }
      }
      return newSelectedRooms;
    });
  };

  const assignRoomsHandle = (e, bookingId) => {
    // every
    // some

    const checkMaxRooms =
      selectedData &&
      selectedData.every((room) => {
        return (
          room.maxRooms ===
          (room.selectedRoooms ? room.selectedRoooms.length : 0)
        );
      });

    if (!checkMaxRooms) {
      showErrorToast("Please assign the rooms first.");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/room/no/${data?.id}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          assign_room: selectedData,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setShowBookingDetails(false);
          // setBookingDetails(data.booking);
          setShowInHouseCheckIn(false);
          setShowAssignRoomPopup(false);
          showSuccessToast(data.message);
          // setShowDashBoardCheckIn(false);
          window.location.reload();
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const fetchAssignRooms = async (bookingId, propertyId) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/check-in/getdata/${bookingId}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        if (data.success) {
          setAssignRoomsData(data.availablerooms);
          setLoader(false);
          setShowAssignRoomPopup(true);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      setLoader(false);
      showErrorToast("Something went wrongs...");
      console.error(error);
    }
  };
  useEffect(() => {
    const newData = assignRoomsData.map((room) => ({
      name: room.room_name,
      id: room.id,
      selectedRoooms: selectedRoooms[room.id] || [],
      maxRooms: parseInt(room.no_of_rooms, 10),
    }));
    setSelectedData(newData);
  }, [selectedRoooms, assignRoomsData]);

  // void reservation
  const handleCancelStatusButton = async () => {
    // console.log(data);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/delete/booking/${data?.id}/${property_id?.id}`,
        {
          method: "DELETE",
          body: JSON.stringify({
            type: reasonFromChild.reason,
            duplicate_booking_id: uniqueBookingId,
            original_booking_id: reasonFromChild.originalBookingId,
            remarks: reasonFromChild.otherReason,
            property_id: property_id?.id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const res = await response.json();
      if (res.success) {
        fetchInfo(property_id?.id);
        showSuccessToast(data.message);
        setDeleteWithReasonPopup(false);
        window.location.reload();
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
    // setShowBookingDetails(false);
  };

  // Voucher

  const [iframeContent, setIframeContent] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  const handleSubmitPreviewVoucher = async (id) => {
    const lead_id = data.id;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v2/lead/preview/${property_id?.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            lead_id: lead_id,
            property_id: property_id?.id,
            lead_communication_id: id,
          }),
        }
      );

      const data = await response.text();
      console.log(data, "previewData");
      if (data) {
        setIframeContent(data);
        setShowIframePopup(true);
        setIframeSrc(null);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentLists();
    }
  }, [property_id]);

  const [agentLists, setAgentLists] = useState([]);

  const fetchAgentLists = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAgentLists(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const agentListOptions = [
    {
      label: "Select option",
      value: "",
    },
    ...agentLists?.map((agent) => ({
      label: agent?.company_name,
      value: agent?.agent_information,
    })),
  ];

  // add payments

  const proofOptions = [
    { label: "Select option" },
    { label: "Aadhar Card", value: "aadhar" },
    { label: "PAN Card", value: "pan" },
    { label: "Passport", value: "passport" },
    { label: "Driving License", value: "driving_license" },
    // { label: "Other", value: "other" },
  ];

  const gstTypeOptions = [
    { label: "select Option", value: "" },
    { label: "Inclusive", value: "inclusive" },
    { label: "Exclusive", value: "exclusive" },
  ];

  const gstOptions = [
    { label: "Select Option", value: "" },
    { label: "5%", value: "5" },
    { label: "12%", value: "12" },
    { label: "18%", value: "18" },
  ];
  const paymentTypeOptions = [
    { label: "Select Option", value: "" },
    { label: "Cash", value: "cash" },
    { label: "City Ledger", value: "city_ledger" },
  ];

  const paymentModeOptions = [
    { label: "Select Option" },
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    // { label: "Other", value: "other" },
  ];

  const currentDate = new Date().toISOString().split("T")[0];

  const [addChargesData, setAddChargesData] = useState({
    name: data.name,
    email: data.email,
    phone: data.phone,
    lead_status: data.lead_status,
    source: data.source,
    propert_id: property_id,
    note: data.note,
  });

  const [roomDetails, setRoomDetails] = useState([]);

  const [agentCreditLimit, setAgentCreditLimit] = useState("");

  useEffect(() => {
    const selectedAgent = agentLists?.find(
      (agent) =>
        Number(agent?.agent_information) ===
        Number(addChargesData?.company_name)
    );

    if (selectedAgent && selectedAgent?.credit.length > 0) {
      const creditLimit = selectedAgent?.credit?.reduce(
        (acc, agent) => Number(acc) + Number(agent.balance_amount),
        0
      );
      setAgentCreditLimit(creditLimit);
    } else {
      setAgentCreditLimit(null);
    }

    setAddChargesData({
      ...addChargesData,
      amount: null,
    });
  }, [addChargesData.company_name, agentLists]);

  useEffect(() => {
    // Convert input values to numbers
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    // Apply GST
    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);
    }

    let totalAmount = priceAfterDiscount + gstAmount;

    setAddChargesData({
      ...addChargesData,
      // subtotal: subtotal.toFixed(2),
      // net_total: totalAmount.toFixed(2),
      // discount_amount: discountAmount.toFixed(2),
      // tax_amount: gstAmount.toFixed(2),
    });
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);

  const setAddChargesHandleChange = (event) => {
    console.log(event);

    // Update the state for all input fields
    setAddChargesData({
      ...addChargesData,
      [event.target.name]: event.target.value,
    });
  };

  const [propertyCurrentDate, setPropertyCurrentDate] = useState();

  useEffect(() => {
    if (property_id) {
      getCurrentPropertyDateHandler(property_id?.id, userToken)
        .then((date) => setPropertyCurrentDate(date))
        .catch((error) => console.error(error));
    }
  }, [property_id]);

  const [addPaymentPopup, setAddPaymentPopup] = useState(false);

  const settlementHandleFolio = (event) => {
    event.preventDefault();

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/payments/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: data.folioId,
          property_id: property_id?.id,
          booking_id: data.id,
          property_current_date: propertyCurrentDate,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // fetchFolioDetails(property_id?.id);
          showSuccessToast(res.message);
          setAddChargesData({});
          setAddPaymentPopup(false);
        } else {
          showErrorToast(res.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // Add charges

  useEffect(() => {
    const rate = parseFloat(addChargesData.rate);
    const quantity = parseFloat(addChargesData.quantity);
    const discount_rate = parseFloat(addChargesData.discount_rate);
    const tax_rate = parseFloat(addChargesData.tax_rate);

    let subtotal = rate * quantity;

    const discountAmount = (subtotal * discount_rate) / 100;
    const priceAfterDiscount = subtotal - discountAmount;

    let gstAmount = 0;

    if (addChargesData.gst_type === "exclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / 100;

      let totalAmount = priceAfterDiscount + gstAmount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    } else if (addChargesData.gst_type === "inclusive") {
      gstAmount = (priceAfterDiscount * tax_rate) / (100 + tax_rate);

      // GST Inclusive Price * 100/(100 + GST Rate Percentage)
      subtotal = (priceAfterDiscount * 100) / (100 + tax_rate);

      let totalAmount = priceAfterDiscount;

      setAddChargesData({
        ...addChargesData,
        subtotal: subtotal.toFixed(2),
        net_total: totalAmount.toFixed(2),
        discount_amount: discountAmount.toFixed(2),
        tax_amount: gstAmount.toFixed(2),
      });
    }
  }, [
    addChargesData.rate,
    addChargesData.quantity,
    addChargesData.discount_rate,
    addChargesData.gst_type,
    addChargesData.tax_rate,
    addChargesData.tax_amount,
  ]);
  const [addChargesPopup, setAddChargesPopup] = useState(false);

  const serviceOptions = [
    { label: "Select Option", value: "" },
    { label: "Room-Charge", value: "Room-Charge" },
    { label: "Food-Charge", value: "Food-Charge" },
    { label: "Housekeeping-Charge ", value: "Housekeeping-Charge" },
    { label: "Miscellaneous-Charge", value: "Miscellaneous-Charge" },
    { label: "Laundry-Charge", value: "Laundry-Charge" },
    // { label: "MISC", value: "misc" },
  ];

  const handleAddCharges = (event) => {
    event.preventDefault();
    // console.log({ ...addChargesData, folio_id: folioData.id });
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/add/folios/charges/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addChargesData,
          folio_id: masterFolioDetails.id,
          property_current_date: propertyCurrentDate,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          showSuccessToast(res.message);
          setAddChargesPopup(false);
          // fetchFolioInfo(property_id?.id);
          setAddChargesData({
            charge_type: "",
            description: "",
            rate: "",
            quantity: "",
            net_total: "",
            subtotal: "",
            discount_amount: "",
            gst_type: "inclusive",
            discount_rate: "",
            tax_rate: "",
            tax_amount: "",
          });

          // checkFolioSummery(
          //   userToken,
          //   folioData?.id,
          //   property_id?.id,
          //   chargeAddedTransactions,
          //   true
          // );
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // print
  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const handlePrintVoucher = async (
    event,
    template,
    statuses,
    vouchertitle,
    booking_id
  ) => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomVoucher&reservationId=${booking_id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((res) => {
        if (res) {
          setIframeContent(res);
          // setShowIframePopup(true);
          // setShowBillPreview(true);
          // setIsLoading(false);
          setTimeout(() => {
            printIframe("receipt");
          }, 500);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handlePrintBill = async (
    event,
    template,
    statuses,
    vouchertitle,
    booking_id
  ) => {
    fetch(
      // https://dev.revcatalyst.in/api/v1/layout/preview/4/bill?previewType=roomBillPreview&previewId=301
      `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${booking_id}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.text())
      .then((res) => {
        if (res) {
          setIframeContent(res);
          // setShowIframePopup(true);
          // setShowBillPreview(true);
          // setIsLoading(false);
          setTimeout(() => {
            printIframe("receipt");
          }, 500);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  console.log(data, "dtaaaaaaaa");

  return (
    <div className="bg-backdrop">
      <div
        id="booking_details_slider_container_new"
        ref={bookingDetailsPopupRef}
      >
        <div class="popup" id="popup">
          <div className="d_flex booking_detail_popup_head">
            <div
              onClick={() => setShowBookingDetails(false)}
              className="close_popup"
            >
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 12.5L13 0.5" stroke="#AAAAAA" strokeWidth="1.2" />
                <path
                  d="M13 12.5L0.999999 0.500001"
                  stroke="#AAAAAA"
                  strokeWidth="1.2"
                />
              </svg>
            </div>
            <div>
              <div className="d_flex booking_id">
                <p style={{ marginRight: "6px" }}> Lead Id :</p>

                <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                  #{data.unique_lead_id}
                </span>
              </div>
            </div>
          </div>
          {data?.lead_status === "converted" ? null : (
            <div className="form-container-div">
              <div className="save-btn form-container-div-half">
                <Link to={`/leads/createmessage/${data.id}`}>
                  <BgThemeButton
                    style={{  margin: "20px" }}
                    // onClick={(e) =>
                    //   handleSubmitRateAndDeal(
                    //     e,
                    //     "send"
                    //   )
                    // }
                    type="submit"
                    children={"Send Rates and Deals"}
                  />
                </Link>
              </div>
            </div>
          )}

          <div className="boking_details_popup_overview ">
            <h3>Overview</h3>
          </div>
          <div className="booking_details d_flex common_padding">
            <div className="booking_details_col">
              <div>
                <h3>Guest Name</h3>
                <p>{(data && data.name) || <div>N/A</div>}</p>
              </div>
              <div>
                <h3>Source</h3>
                {data && data.source
                  ? data.source
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())
                  : "NA"}
                {/* <p>{data && data.source}</p> */}
              </div>

              {/* <div>
                    <h3>Child w/o Bed</h3>
                    <p>0</p>
                  </div> */}
            </div>
            <div className="booking_details_col">
              <div>
                <h3>Phone</h3>
                <p>{(data && data.phone) || <p>N/A</p>}</p>
              </div>
              <div>
                <h3>Created On</h3>
                <p>
                  {" "}
                  {data && data.created_at ? (
                    new Date(data.created_at).toLocaleDateString()
                  ) : (
                    <div>N/A</div>
                  )}
                </p>
              </div>
            </div>
            <div className="booking_details_col">
              <div>
                <h3>Email</h3>
                <p>{(data && data.email) || 0}</p>
              </div>
              <div>
                <h3>Lead Status</h3>
                {data?.lead_status === "new_lead" && (
                  <p style={{ color: "#3968ED" }}>
                    <UnderscoreRemoveCapitalize text={data?.lead_status} />
                  </p>
                )}
                {data?.lead_status === "follow_up" && (
                  <p style={{ color: "#e03838" }}>
                    <UnderscoreRemoveCapitalize text={data?.lead_status} />
                  </p>
                )}
                {data?.lead_status === "converted" && (
                  <p style={{ color: "#888888" }}>
                    <UnderscoreRemoveCapitalize text={data?.lead_status} />
                  </p>
                )}
                {data?.lead_status === "dropped" && (
                  <p style={{ color: "#888888" }}>
                    <UnderscoreRemoveCapitalize text={data?.lead_status} />
                  </p>
                )}
              </div>

              {/* <div>
                                    <h3>Child with Bed</h3>
                                    <p>1</p>
                                </div> */}
            </div>
          </div>
          {data && data.note ? (
            <div className="notes" style={{ marginLeft: "20px" }}>
              <p className="Notes" style={{ color: "#666666" }}>
                <span style={{ fontWeight: "bold" }}>Notes : </span>
                {data && data.note}
              </p>
            </div>
          ) : null}
        </div>

        <div>
          <div>
            {data?.lead_status === "new_lead" ? (
              <></>
            ) : (
              // {data?.lead_status === "follow_up" || data?.lead_status === "dropped" || (
              <>
                <div
                  className="boking_details_popup_overview "
                  style={{ marginTop: "20px" }}
                >
                  <h3>Template sent</h3>
                </div>
                {/* {data?.lead_communication?.map((message, index) => (
  <div key={index} className="common_padding">
    <div className="d_flex booking_detail_popup_inclusion_list">
      <WithoutBgButtonForRates
      style={{fontSize: "14px"}}
        onClick={(e) => handleSubmitPreviewVoucher(message?.id)}
        children={"Rates & Availability"}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 12 10"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
              fill="#3968ED"
            />
            <path
              d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
              fill="#3968ED"
            />
          </svg>
        }
      />

      <div>
        <p style={{fontSize: "14px"}}>{message?.check_in}</p>
      </div>
      <div>
        {message?.status === "converted" ? (
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "12.1px",
              color: "#888888",
            }}
          >
            Booking Converted
          </p>
        ) : message?.status === "unconverted" ? (
          <p
            style={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "12.1px",
              color: "#888888",
              visibility: "hidden"
            }}
          >
           Booking Converted
          </p>

        ) : <Link to={`/leads/sentmessage/${message?.id}`}>
        <WithoutBgButtonForRates style={{fontSize: "14px"}} children={"Convert to Booking >"} />
      </Link>}

          
      </div>
    </div>
  </div>
))} */}

                {data?.lead_communication
                  ?.sort((a, b) => {
                    if (a.status === "converted" && b.status !== "converted") {
                      return -1;
                    }
                    if (a.status !== "converted" && b.status === "converted") {
                      return 1;
                    }
                    return 0;
                  })
                  .map((message, index) => (
                    <div key={index} className="common_padding">
                      <div className="d_flex booking_detail_popup_inclusion_list">
                        <WithoutBgButtonForRates
                          style={{ fontSize: "14px" }}
                          onClick={(e) =>
                            handleSubmitPreviewVoucher(message?.id)
                          }
                          children={"Rates & Availability"}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              viewBox="0 0 12 10"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.0673 4.80967C9.9757 2.07317 7.87646 0.826174 5.77812 0.935011C3.70396 1.04259 1.74696 2.47251 0.91742 4.82943L0.857422 4.9999L0.91742 5.17037C1.74696 7.52729 3.70396 8.9572 5.77812 9.06478C7.87646 9.17362 9.9757 7.92662 11.0673 5.19012L11.1431 4.9999L11.0673 4.80967ZM5.99609 7.56089C7.40656 7.56089 8.54996 6.41429 8.54996 4.9999C8.54996 3.5855 7.40656 2.43891 5.99609 2.43891C4.58563 2.43891 3.44222 3.5855 3.44222 4.9999C3.44222 6.41429 4.58563 7.56089 5.99609 7.56089Z"
                                fill="#3968ED"
                              />
                              <path
                                d="M7.52842 4.9999C7.52842 5.84853 6.84237 6.53649 5.99609 6.53649C5.14981 6.53649 4.46377 5.84853 4.46377 4.9999C4.46377 4.15126 5.14981 3.4633 5.99609 3.4633C6.84237 3.4633 7.52842 4.15126 7.52842 4.9999Z"
                                fill="#3968ED"
                              />
                            </svg>
                          }
                        />

                        <div>
                          <p style={{ fontSize: "14px" }}>
                            {message?.check_in}
                          </p>
                        </div>
                        <div>
                          {message?.status === "converted" ? (
                            <p
                              style={{
                                fontFamily: "Inter",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#888888",
                              }}
                            >
                              Booking Converted
                            </p>
                          ) : message?.status === "unconverted" ? (
                            <p
                              style={{
                                fontFamily: "Inter",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "#888888",
                                visibility: "hidden",
                              }}
                            >
                              Booking Converted
                            </p>
                          ) : (
                            <Link to={`/leads/sentmessage/${message?.id}`}>
                              <WithoutBgButtonForRates
                                style={{ fontSize: "14px" }}
                                children={"Convert to Booking >"}
                              />
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}

            {/* <div>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clipPath="url(#clip0_4924_7597)">
                                        <path
                                            d="M8.1651 0.77177L10.2286 2.8357L10.5727 2.49149C11.1424 1.92164 11.1424 0.997859 10.5727 0.428009C10.0028 -0.142019 9.07869 -0.142019 8.50879 0.428009L8.1651 0.77177Z"
                                            fill="#888888"
                                        />
                                        <path
                                            d="M9.28114 3.78116L7.21767 1.71723L0.669813 8.26542L0 10.9993L2.73329 10.3293L9.28114 3.78116Z"
                                            fill="#888888"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4924_7597">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <svg
                                    width="11"
                                    height="11"
                                    viewBox="0 0 11 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.70011 2.2H9.35011C9.65387 2.2 9.90011 2.44624 9.90011 2.75L9.90012 3.3H1.1001L1.10011 2.75C1.10011 2.44624 1.34636 2.2 1.65011 2.2H3.30011V0.825C3.30011 0.369365 3.66948 0 4.12511 0H6.87511C7.33075 0 7.70011 0.369365 7.70011 0.825V2.2ZM4.40011 1.1V2.2H6.60011V1.1H4.40011Z"
                                        fill="#888888"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M1.65011 4.4H9.35011V10.175C9.35011 10.6306 8.98075 11 8.52511 11H2.47511C2.01948 11 1.65011 10.6306 1.65011 10.175V4.4ZM3.8501 6.05H2.7501V9.35H3.8501V6.05ZM6.0501 6.05H4.9501V9.35H6.0501V6.05ZM7.1501 6.05H8.2501V9.35H7.1501V6.05Z"
                                        fill="#888888"
                                    />
                                </svg>
                            </div> */}
          </div>
        </div>

        {showModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <section className="room-type-model-container ">
                <h4>Are You Sure?</h4>
                {/* <p style={{ marginBottom: "0" }}>Do You Want To Delete lead?</p> */}
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {/* <div className="reason-input-button">Enter Reason</div> */}
                    {/* <div className="reason-input-button"> */}
                    {/* {reason?.reason?.trim()?.length}/{wordLimit} */}
                    {/* </div> */}
                  </div>
                  {/* <div style={{ width: "100%", marginBottom: "10px" }}>
                    <VerticalTextAreaCustom
                      name={"deleteReason"}
                      className="textfield"
                      focused={false}
                      type="text"
                      value={deleteReason}
                      autoComplete="off"
                      onChange={(e) => setdeleteReason(e.target.value)}
                      cols={9}
                    />
                  </div> */}
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                  >
                    Discard
                  </Button>
                  <Button
                    onClick={() => cancelBookingHandle(data?.id)}
                    destructive
                    type={"Button"}
                  >
                    Yes, Delete
                  </Button>
                </div>
              </section>
            </div>
          </>
        )}

        {openCheckedInMoreOptionsPopup && (
          <div className="more_options_popup" ref={popupsRef}>
            <button onClick={() => handleNoShowData("no_show", data?.id)}>
              No Show
            </button>
            <p>Add Add-on</p>
          </div>
        )}
        {/* {openPrintPopup && (
                    <div className="print_popup" ref={popupsRef}>
                        <p>Vouchers</p>
                        <p>GRC</p>
                        <p>Bill</p>
                    </div>
                )} */}

        {deleteWithReasonPopup && (
          <DeletePopupWithReasonBooking
            title={"Booking"}
            textAreaName={"reason_of_cancelation"}
            onDeleteWithReason={handleDeleteWithReason}
            wordLimit={50}
            deleteWithReasonPopup={deleteWithReasonPopup}
            setDeleteWithReasonPopup={setDeleteWithReasonPopup}
            deleteFunction={(data) => handleCancelStatusButton(data)}
            id={deleteKotId}
            btnText={"Yes, Delete"}
            type={"Button"}
            cancelText={"You Want To Delete This Booking?"}
          />
        )}
        {showAssignRoomPopup && (
          // <div className="bg-backdrop">
          //   <div
          //     style={{
          //       width: "500px",
          //       marginBottom: "20px",
          //       // height: "500px",
          //       // overflowY: "scroll",
          //     }}
          //     className="room-plan-add-model-container  overflow_x_scroll_y_hidden"
          //   >
          //     <GoBackButtonCustom
          //       buttonNextText={`Assign Rooms for Booking ID : ${clickedBookingId}`}
          //       onClick={() => [
          //         setShowAssignRoomPopup(false),
          //         setSelectedRoom(""),
          //       ]}
          //     />
          //     <div
          //       style={{
          //         display: "flex",
          //         justifyContent: "center",
          //         flexWrap: "wrap",
          //         flexDirection: "column",
          //       }}
          //     >
          //       {selectedData && selectedData.length > 0 ? (
          //         selectedData.map((data, index) => (
          //           <div
          //             key={index}
          //             className={`room-box ${
          //               selectedRoom === data.name ? "selected" : ""
          //             }`}
          //           >
          //             <div className="availableRoomsSelectBoxContainer">
          //               <div
          //                 className="availableRoomsSelectBox"
          //                 onClick={() =>
          //                   handleRoomClick(data.name, data.maxRooms)
          //                 }
          //               >
          //                 <div
          //                   style={{
          //                     display: "flex",
          //                     justifyContent: "space-between",
          //                     alignItems: "center",
          //                   }}
          //                 >
          //                   <span>{data.name}</span>
          //                   <span>Rooms:({data.maxRooms})</span>
          //                   <span
          //                     style={{
          //                       height: "20px",
          //                       width: "20px",
          //                     }}
          //                   >
          //                     <svg
          //                       viewBox="0 0 20 20"
          //                       class="Polaris-Icon__Svg"
          //                       focusable="false"
          //                       aria-hidden="true"
          //                     >
          //                       <path
          //                         fillRule="evenodd"
          //                         d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z"
          //                       ></path>
          //                     </svg>
          //                   </span>
          //                 </div>
          //               </div>
          //               {selectedRoom === data.name && (
          //                 <div className="checkbox-container">
          //                   {assignRoomsData
          //                     .find((room) => room.room_name === data.name)
          //                     .sorted_rooms.map((roomNo) => (
          //                       <div
          //                         className="flex flex-gap-10"
          //                         key={roomNo.id}
          //                       >
          //                         <input
          //                           style={{ marginRight: "5px" }}
          //                           type="checkbox"
          //                           checked={data.selectedRoooms.includes(
          //                             roomNo.room_no
          //                           )}
          //                           id={`room-${roomNo.room_no}`}
          //                           value={roomNo.room_no}
          //                           disabled={
          //                             roomNo.status === "occupied" ||
          //                             roomNo.status === "dirty" ||
          //                             roomNo.status === "out_of_order"
          //                           }
          //                           onChange={() =>
          //                             handleCheckboxChange(
          //                               roomNo.room_id,
          //                               data.name,
          //                               roomNo.id,
          //                               roomNo.room_no
          //                             )
          //                           }
          //                         />
          //                         <label htmlFor={`room-${roomNo.room_no}`}>
          //                           {`Room ${
          //                             roomNo.room_no
          //                           } (${roomNo.status.replace(/_/g, " ")})`}
          //                         </label>
          //                       </div>
          //                     ))}
          //                 </div>
          //               )}
          //             </div>
          //           </div>
          //         ))
          //       ) : (
          //         <>
          //           <div>No Rooms </div>
          //         </>
          //       )}
          //     </div>

          //     <div
          //       style={{
          //         display: "flex",
          //         justifyContent: "flex-start",
          //         alignItems: "center",
          //         gap: "10px",
          //       }}
          //     >
          //       <BgThemeButton
          //         onClick={(e) => assignRoomsHandle(e, data?.id)}
          //         children={"Assign"}
          //         type={"button"}
          //       />

          //       <BgOutlineThemeButton
          //         onClick={() => [
          //           setShowAssignRoomPopup(false),
          //           setSelectedRoom(""),
          //         ]}
          //         children={"Discard"}
          //       />
          //     </div>
          //   </div>
          // </div>
          <AssignRoomPopupNew
          mainData={data}
          setShowAssignRoomPopup={setShowAssignRoomPopup}
          // getSingleBookingDataHandler={getSingleBookingDataHandler}
          
          />
        )}

        {iframeContent && showIframePopup && (
          <>
            <div className="bg-backdrop">
              <section
                className="communication_iframe_container"
                style={{ width: "795px", overflow: "hidden" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "15px",
                    padding: "0 25px",
                  }}
                >
                  <div
                    className="add-ota-model-container-heading"
                    style={{ display: "flex", gap: "10px" }}
                  ></div>
                  <div
                    onClick={() => setShowIframePopup(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>
                {/* <iframe
                title="Preview"
                src={iframeSrc}
                width="100%"
                height="100%"
              /> */}
                <iframe
                  srcDoc={iframeContent}
                  id="receipt"
                  // style={{ display: "none" }}
                  title="Receipt"
                />
                {/* <PreviewCommunicationVoucher uniquebookingid={bookingId} /> */}
              </section>
            </div>
          </>
        )}

        {/* {iframeContent && showIframePopup && (
          <BookingVoucher
            setShowIframePopup={setShowIframePopup}
            iframeContent={iframeContent}
          />
        )} */}

        {/* add payments */}
        {addPaymentPopup && (
          <div className="bg-backdrop">
            <form
              onSubmit={settlementHandleFolio}
              style={{ width: "600px", zIndex: "10" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Add Payments
                </div>
                <div
                  onClick={() => setAddPaymentPopup(!addPaymentPopup)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                  min={propertyCurrentDate}
                    required={true}
                    focused={false}
                    name="date"
                    type="date"
                    titleName="Date"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.date || currentDate}
                  />
                </div>
                <div className="form-container-div">
                  <VerticalInputSelectCustomCopy
                    options={paymentTypeOptions}
                    type="text"
                    required={true}
                    focused={false}
                    name="payment_type"
                    titleName="Payment Type"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.payment_type}
                  />
                </div>
                <div className="form-container-div">
                  {addChargesData.payment_type === "cash" && (
                    <VerticalInputSelectCustomCopy
                      options={paymentModeOptions}
                      type="text"
                      required={true}
                      focused={false}
                      name="payment_mode"
                      titleName="Payment Mode"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.payment_mode}
                    />
                  )}
                  {addChargesData.payment_type === "city_ledger" && (
                    <VerticalInputSelectCustomCopy
                      options={agentListOptions}
                      type="text"
                      required={true}
                      focused={false}
                      name="company_name"
                      titleName="Company Name"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.company_name}
                    />
                  )}
                </div>
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="notes"
                    titleName="Notes"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.notes}
                  />
                </div>
                <div className="form-container-div">
                  {agentCreditLimit < 1 &&
                  addChargesData.payment_type === "city_ledger" ? (
                    <VerticalInputFieldCustom
                      type="number"
                      required={true}
                      focused={false}
                      disabled={true}
                      name="amount"
                      titleName="Amount"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.amount}
                    />
                  ) : (
                    <VerticalInputFieldCustom
                      type="number"
                      required={true}
                      focused={false}
                      // disabled={true}
                      name="amount"
                      titleName="Amount"
                      onChange={setAddChargesHandleChange}
                      autoComplete="off"
                      value={addChargesData.amount}
                    />
                  )}
                </div>
              </div>
              {addChargesData.payment_type === "city_ledger" && (
                <div className="form-container-div-full">
                  <>
                    Balance Credit Limit:
                    <span>
                      {agentCreditLimit
                        ? formatIndianCurrency(agentCreditLimit)
                        : formatIndianCurrency(agentCreditLimit)}
                    </span>
                  </>
                </div>
              )}

              {/* <div className="form-container-div-full">
                <>
                  Pending Balance:
                  <span>
                    {formatIndianCurrency(selectedFolio?.balance_amount)}
                  </span>
                </>
              </div> */}

              <div
                className="flex flex_gap_10"
                style={{ marginLeft: "10px", zIndex: "100" }}
              >
                {/* {Number(selectedFolio?.balance_amount) ===
                Number(addChargesData.amount) ? ( */}
                {/* <BgThemeButton children={"Add"} type={"submit"} /> */}
                <BgThemeButton
                  style={{ zIndex: "100" }}
                  children={"Add"}
                  type={"submit"}
                />
                {/* ) : (
                  <BgThemeButtonDisabled children={"Add"} />
                )} */}

                <BgOutlineThemeButton
                  children={"Cancel"}
                  type={"button"}
                  onClick={() => setAddPaymentPopup(!addPaymentPopup)}
                />
              </div>
            </form>
          </div>
        )}

        {/* add charges */}
        {addChargesPopup && (
          <div className="bg-backdrop">
            <form
              onSubmit={handleAddCharges}
              style={{ width: "1000px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">
                  Add Charges
                </div>
                <div
                  onClick={() => setAddChargesPopup(!addChargesPopup)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    options={serviceOptions}
                    titleName="Service"
                    name="charge_type"
                    value={addChargesData.charge_type}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Description"
                    name="description"
                    type="text"
                    value={addChargesData.description}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Rate"
                    name="rate"
                    type="number"
                    value={addChargesData.rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Qty"
                    name="quantity"
                    type="number"
                    value={addChargesData.quantity}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Discount%"
                    name="discount_rate"
                    type="number"
                    value={addChargesData.discount_rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>

                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    options={gstTypeOptions}
                    required={true}
                    titleName="GST Type"
                    name="gst_type"
                    value={addChargesData.gst_type}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputSelectCustomCopy
                    required={true}
                    options={gstOptions}
                    titleName="GST%"
                    name="tax_rate"
                    value={addChargesData.tax_rate}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Subtotal"
                    name="sub_total"
                    disabled={true}
                    type="number"
                    value={addChargesData.subtotal}
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div>
                Subtotal :{" "}
                {addChargesData.gst_type === "inclusive" ? (
                  // <HandleNaN
                  //   value={addChargesData.subtotal - addChargesData.tax_amount}
                  // />
                  <HandleNaN value={addChargesData.subtotal} />
                ) : (
                  <HandleNaN value={addChargesData.subtotal} />
                )}
              </div>

              <div>
                Tax Amount : <HandleNaN value={addChargesData.tax_amount} />
              </div>
              <div className="form-container-div-full flex flex_gap_10">
                <div className="form-container-div" style={{ width: "20%" }}>
                  <VerticalInputFieldCustom
                    required={true}
                    titleName="Total Amount"
                    name="net_total"
                    type="number"
                    disabled={true}
                    value={
                      addChargesData.gst_type === "inclusive"
                        ? // ? addChargesData.net_total - addChargesData.tax_amount
                          addChargesData.net_total
                        : addChargesData.net_total
                    }
                    onChange={setAddChargesHandleChange}
                  />
                </div>
              </div>

              <div className="flex flex_gap_10">
                <BgThemeButton children={"Add"} type={"submit"} />
                <BgOutlineThemeButton
                  children={"Cancel"}
                  type={"button"}
                  onClick={() => setAddChargesPopup(!addChargesPopup)}
                />
              </div>
            </form>
          </div>
        )}

        <iframe
          srcDoc={iframeContent}
          id="receipt"
          style={{ display: "none" }}
          title="Receipt"
        />

        {/* update leads  */}

        {addLeadsPopUp && (
          <div className="bg-backdrop">
            <form
              onSubmit={(event) => hadnleSubmitUpdateLead(event, data.id)}
              style={{ width: "600px" }}
              className="stock-add-model-container"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="add-ota-model-container-heading">Edit Lead</div>
                {/* <div
                onClick={() => setAddLeadsPopUp(false)}
                style={{ cursor: "pointer" }}
              >
                <Icon source={CancelMajor} color="base" />
              </div> */}
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="name"
                    type="text"
                    titleName="Name"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.name}
                  />
                </div>
                {/* <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={agentListOptions}
                  type="text"
                  required={true}
                  focused={false}
                  name="query_company"
                  titleName="Company Name"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.query_company}
                />
              </div> */}
              </div>
              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    type="number"
                    required={true}
                    focused={false}
                    name="phone"
                    titleName="Phone"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.phone}
                  />
                </div>

                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    type="email"
                    required={true}
                    focused={false}
                    name="email"
                    titleName="Email"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.email}
                  />
                </div>
              </div>

              <div className="form-container-div-half">
                <div className="form-container-div">
                  <VerticalInputFieldCustom
                    required={false}
                    focused={false}
                    name="note"
                    titleName="Note"
                    onChange={setAddChargesHandleChange}
                    autoComplete="off"
                    value={addChargesData.note}
                  />
                </div>
                {/* <div className="form-container-div">
                <VerticalInputSelectCustomCopy
                  options={queryTypeOptions}
                  required={true}
                  focused={false}
                  name="query_type"
                  titleName="Query Type"
                  onChange={setAddChargesHandleChange}
                  autoComplete="off"
                  value={addChargesData.query_type}
                />
              </div> */}
              </div>

              <div className="flex flex_gap_10">
                <BgThemeButton children={"update"} type={"submit"} />

                <BgOutlineThemeButton
                  children={"Cancel"}
                  type={"button"}
                  onClick={() => setAddLeadsPopUp(false)}
                />
              </div>
            </form>
          </div>
        )}

        <div className="d_flex common_padding booking_details_options booking_details_options_bottom_container">
          {/* <BgThemeButton
                  children={"Modify Reservation"}
                  style={{ height: "35px" }}
                /> */}

          <BgOutlineThemeButton
            style={{ height: "35px", width: "100px" }}
            className="more_options_btn"
            onClick={() => setAddLeadsPopUp(!addLeadsPopUp)}
            children={"Edit"}
          >
            Edit Lead{" "}
          </BgOutlineThemeButton>

          <SettleBillButton
            onClick={handleCancelBooking}
            style={{ height: "35px", width: "100px" }}
          >
            Delete
          </SettleBillButton>
        </div>
      </div>
    </div>
  );
};
export default LeadDetailsSlider;
