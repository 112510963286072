import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatDate,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";
import { getDecryptedData } from "../../utils/encryptStorage";
const ReportPaymentReconciliation = ({
  departureReport,
  loader,
  settlementOrders,
  fullData,
  getSingleBookingDataHandler
}) => {
  const [informationPopup, setInformationPopup] = useState(false);

  const property_id = useSelector((state) => state.property.value);
  const userToken = getDecryptedData("encryptToken");
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const names = [
    {
      date: "Date",
      payment_mode: "Payment Mode",
      settlement_amount: "Settlement Amount",
    },
  ];

  const handleSinglePaymentClick = (date, payment_type) => {
    singlePaymentDetails(
      new Date(date).toISOString().split("T")[0],
      payment_type
    );
  };

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>
          {formatDate(new Date(order.date))}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <UnderscoreRemoveCapitalize text={order.payment_mode} />{" "}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <span
            onClick={() =>
              handleSinglePaymentClick(order.date, order.payment_mode)
            }
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {" "}
            {formatIndianCurrency(order.settlement_amount)}
          </span>
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  const [showPaymentPopup, setPaymentPopup] = useState(false);
  const [singlePaymentData, setSinglePaymentData] = useState();
  const singlePaymentHeadings = [
    "Date",
    "Booking ID",
    "Payment Type",
    "Settlement Amount (₹)",
  ];

  const singlePaymentDetails = async (date, payment_type) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/details-payment-reconciliation-report/${property_id.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            date: date,
            payment_type: payment_type,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        setSinglePaymentData(data.data);
        setPaymentPopup(true);
      } else {
        setSinglePaymentData(data.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div style={{ display: "flex", gap: "10px" }} id="room-types">
            <div
              style={{ width: "100%" }}
              className="room-types-full-container"
            >
              <div className="room-types-full-container-div">
                <div
                  className="flex flex_gap_5 "
                  style={{ alignItems: "unset" }}
                >
                  <div
                    className="flex flex_gap_5 "
                    style={{ alignItems: "unset" }}
                  >
                    <div className="room-type-heading">
                      Payment Reconciliation Report
                    </div>
                    <div
                      className="hoverCursor"
                      onClick={() => setInformationPopup(true)}
                      id="ignoreFromPrint"
                    >
                      <SvgGetInfo />
                    </div>
                  </div>
                </div>
                {departureReport?.length > 0 && (
                  <div
                    className="flex flex_gap_15 align_center"
                    id="ignoreFromPrint"
                  >
                    {/* <DownloadPdfTables
                      tableData={departureReport}
                      tableNames={names}
                    />
                    <DownloadCsvTables tableData={departureReport} names={names}/> */}
                    <ButtonToDownloadTablePDF
                      downloadFileName={"room_payment_ledger"}
                      itemToDownloadID={"room-types"}
                      ignoreFromPrint={"ignoreFromPrint"}
                    />
                    <DownloadCSVButton
                      downloadFileName={"room_payment_ledger"}
                      itemToDownloadID={"room-types"}
                    />
                  </div>
                )}
              </div>

              {departureReport && departureReport?.length > 0 ? (
                <>
                  <LegacyCard>
                    <CustomReportTable
                      headings={[
                        "Date",
                        "Payment Method",
                        "Settlement Amount (₹)",
                      ]}
                    >
                      {rowMarkup}

                      {fullData && (
                        <>
                          <CustomReportTableRow>
                            <CustomReportTableCell colspan={3}>
                              <b>Summery</b>
                            </CustomReportTableCell>
                          </CustomReportTableRow>
                          <CustomReportTableRow>
                            <CustomReportTableCell>
                              Bank Payment
                            </CustomReportTableCell>
                            <CustomReportTableCell colspan={2}>
                              {formatIndianCurrency(
                                fullData?.summary?.bank_payment
                              ) || "-"}
                            </CustomReportTableCell>
                          </CustomReportTableRow>
                          <CustomReportTableRow>
                            <CustomReportTableCell>Cash</CustomReportTableCell>
                            <CustomReportTableCell colspan={2}>
                              {formatIndianCurrency(fullData?.summary?.cash) ||
                                "-"}
                            </CustomReportTableCell>
                          </CustomReportTableRow>
                          <CustomReportTableRow>
                            <CustomReportTableCell>
                              Credit Card
                            </CustomReportTableCell>
                            <CustomReportTableCell colspan={2}>
                              {formatIndianCurrency(
                                fullData?.summary?.credit_card
                              ) || "-"}
                            </CustomReportTableCell>
                          </CustomReportTableRow>
                          <CustomReportTableRow>
                            <CustomReportTableCell>
                              Debit Card
                            </CustomReportTableCell>
                            <CustomReportTableCell colspan={2}>
                              {formatIndianCurrency(
                                fullData?.summary?.debit_card
                              ) || "-"}
                            </CustomReportTableCell>
                          </CustomReportTableRow>
                          <CustomReportTableRow>
                            <CustomReportTableCell>upi</CustomReportTableCell>
                            <CustomReportTableCell colspan={2}>
                              {formatIndianCurrency(fullData?.summary?.upi) ||
                                "-"}
                            </CustomReportTableCell>
                          </CustomReportTableRow>
                        </>
                      )}
                    </CustomReportTable>
                  </LegacyCard>
                </>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </>
      )}

      {showPaymentPopup && (
        <>
          <div
            className="global_popup_container_background"
            onClick={() => setPaymentPopup(false)}
          >
            <div
              className="global_popup_container"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="flex justify_content_between align_center"
                style={{ marginBottom: "10px" }}
              >
                <h4 className="room-type-heading"> Single Payment Details</h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  onClick={() => setPaymentPopup(false)}
                  style={{ margin: "0px 0 0 auto", cursor: "pointer" }}
                >
                  <path
                    d="M1 13.1501L13 0.850098"
                    stroke="#AAAAAA"
                    strokeWidth="1.2"
                  />
                  <path
                    d="M13 13.1501L0.999999 0.850147"
                    stroke="#AAAAAA"
                    strokeWidth="1.2"
                  />
                </svg>
              </div>

              <CustomReportTable
                headings={singlePaymentHeadings}
                headerStyles={{
                  padding: "10px 5px",
                  fontWeight: "bold",
                  backgroundColor: "#f2f2f2",
                  borderBottom: "1px solid #CCC",
                }}
              >
                {singlePaymentData.map((data, index) => (
                  <CustomReportTableRow key={index}>
                    <CustomReportTableCell>
                      {formatDate(new Date(data?.date))}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                     <span style={{color:"#3968ed"}} className="pointer" onClick={() =>{ setPaymentPopup(false); getSingleBookingDataHandler(data?.unique_booking_id)}}>
                     #{data?.unique_booking_id}
                     </span>
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {data?.payment_mode.replaceAll("_", " ")}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(data?.amount)}
                    </CustomReportTableCell>
                  </CustomReportTableRow>
                ))}

                <CustomReportTableRow>
                  <CustomReportTableCell colspan={3}>
                    Total
                  </CustomReportTableCell>
                  <CustomReportTableCell colspan={1}>
                   {formatIndianCurrency(calculateTotalWithReduce(singlePaymentData, "amount"))}
                  </CustomReportTableCell>
                </CustomReportTableRow>
              </CustomReportTable>
            </div>
          </div>
        </>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Payment Reconciliation Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Payment Reconciliation Report?",
                  answer: (
                    <p>
                      The <b>Payment Reconciliation Report</b> is a detailed
                      financial document that tracks all payments received over
                      a specific date range, categorized by payment methods such
                      as cash, bank transfers, UPI, credit card, debit card, and
                      city ledger. This report provides a consolidated view of
                      all payment transactions, making it easier to reconcile
                      finances.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Payment Reconciliation Report?",
                  answer: (
                    <div>
                      <p>The Payment Reconciliation Report is essential for:</p>

                      <ul>
                        <li>
                          <b>Financial Accuracy: </b>Ensures all payments are
                          accurately recorded and matched with corresponding
                          transactions, preventing discrepancies.
                        </li>
                        <li>
                          <b>Cash Flow Management: </b>Helps in tracking cash
                          inflows from different payment channels, providing a
                          clear picture of daily revenue.
                        </li>
                        <li>
                          <b>Audit Preparedness: </b> Provides a transparent
                          record of payment methods used, simplifying audits and
                          financial reporting.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>Helps in identifying
                          any pending or unmatched payments, ensuring smoother
                          financial operations.
                        </li>
                        <li>
                          <b>Fraud Prevention: </b>By reconciling payments
                          against bookings and invoices, this report helps
                          identify potential fraud or errors in financial
                          transactions.
                        </li>
                      </ul>
                      <p>
                        This report is a key tool for maintaining accurate and
                        organized financial records.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportPaymentReconciliation;
