import React, { useEffect, useState } from "react";
import { getDecryptedData } from "../../../utils/encryptStorage";
import styles from "./ApplicableOn.module.scss";
import ToggleSwitch from "../../../Components/NewCustomComp/Switch/ToggleSwitch";

const ApplyOn = ({ formData, setFormData, hideOtaOptions }) => {
  const userToken = getDecryptedData("encryptToken");
  const [otaList, setOtaList] = useState([]);

  const fetchOta = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/channels/list`,
        requestOptions
      );
      const data = await response.json();

      if (data.success) {
        setOtaList(data?.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userToken && !hideOtaOptions) {
      fetchOta();
    }
  }, [userToken, formData?.selectedChannels, hideOtaOptions]);

  const applyOnList = hideOtaOptions
    ? ["agents", "website"]
    : ["pms", "website", "agents"];

  const handleCheckboxChange = (type, value) => {
    setFormData((prev) => {
      // Handle OTA checkbox
      if (type === "selectedChannels" && value === "ota") {
        const isOtaSelected = prev[type].includes("ota");

        return {
          ...prev,
          [type]: isOtaSelected
            ? prev[type].filter((item) => item !== "ota")
            : [...prev[type], "ota"],
          selectedOTAs: isOtaSelected ? [] : otaList.map((ota) => ota.id),
        };
      }

      // Handle individual OTA selections
      if (type === "selectedOTAs") {
        const updatedOTAs = prev[type].includes(value)
          ? prev[type].filter((item) => item !== value)
          : [...prev[type], value];

        // Check if at least one OTA is selected
        const isAnyOtaSelected = updatedOTAs.length > 0;

        return {
          ...prev,
          [type]: updatedOTAs,
          selectedChannels: isAnyOtaSelected
            ? [...new Set([...prev.selectedChannels, "ota"])] // Ensure "ota" is added only once
            : prev.selectedChannels.filter((item) => item !== "ota"),
        };
      }

      // Handle other types
      const updatedList = prev[type].includes(value)
        ? prev[type].filter((item) => item !== value)
        : [...prev[type], value];

      return { ...prev, [type]: updatedList };
    });
  };

  const handleToggleChange = (checked) => {
    if (checked) {
      setFormData((prev) => ({
        ...prev,
        selectedChannels: [...applyOnList, ...(hideOtaOptions ? [] : "ota")],
        selectedOTAs: otaList.map((ota) => ota.id),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        selectedChannels: [],
        selectedOTAs: [],
      }));
    }
  };

  // console.log((formData?.selectedChannels?.length === applyOnList?.length +1) && (formData?.selectedOTAs?.length === otaList?.length) ,"hiiii")
  //  console.log(formData?.selectedChannels?.length , applyOnList.length ,"hiii")



  return (
    <div>
      <div className="flex align_items_center flex_gap_10 mt-10">
        <h3>All Channels</h3>
        <ToggleSwitch
          isOn={
            hideOtaOptions
              ? formData?.selectedChannels?.length === applyOnList.length
              : formData?.selectedChannels?.length ===
                  applyOnList?.length + 1 &&
                formData?.selectedOTAs?.length === otaList?.length
          }
          size="medium"
          handleToggle={handleToggleChange}
          name={"allChannels"}
        />
      </div>

      <div
        className={`${styles.mealPlanContainer} mt-10 ${
          !hideOtaOptions && "overflowContainer "
        }`}
        style={hideOtaOptions && { height: "auto" }}
      >
        {applyOnList.map((item) => (
          <div className="flex align_items_center flex_gap_10 mt-10" key={item}>
            <input
              type="checkbox"
              className={styles.checkbox}
              checked={
                formData?.selectedChannels &&
                formData?.selectedChannels?.includes(item)
              }
              onChange={() => handleCheckboxChange("selectedChannels", item)}
            />
            <h3>{item}</h3>
          </div>
        ))}

        {!hideOtaOptions && (
          <div className="flex align_items_center flex_gap_10 mt-10">
            <input
              type="checkbox"
              className={
                formData?.selectedOTAs &&
                formData?.selectedOTAs?.length > 0 &&
                formData?.selectedOTAs?.length < otaList?.length
                  ? styles.checkboxFull
                  : styles.checkbox
              }
              checked={formData.selectedChannels.includes("ota")}
              onChange={() => handleCheckboxChange("selectedChannels", "ota")}
            />
            <h3>OTA</h3>
          </div>
        )}

        <div className="ml-10">
          {otaList?.map((item) => (
            <div
              className="flex align_items_center flex_gap_10 mt-10"
              key={item.channel_id}
            >
              <input
                type="checkbox"
                className={styles.checkbox}
                checked={
                  formData?.selectedOTAs &&
                  formData?.selectedOTAs?.includes(item.id)
                }
                onChange={() => handleCheckboxChange("selectedOTAs", item.id)}
              />
              <h5>{item?.channel_name}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplyOn;
