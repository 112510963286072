import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  CheckWithoutBgUnderlineBtn,
} from "../UI/Buttons";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import { Button, Tooltip } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import ReactQuill from "react-quill";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import { getBaseUrl } from "../../utils/utils";
import { getDecryptedData } from "../../utils/encryptStorage";
import { SvgDelete, SvgEdit, SvgPlus } from "../../assets/svgIcons";
import {
  PrimaryButton,
  PrimaryIconButtonWithText,
  SecondaryButton,
} from "../NewCustomComp/Buttons/Buttons";
import { color } from "chart.js/helpers";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { PrimaryInput } from "../NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import ToggleSwitch from "../NewCustomComp/Switch/ToggleSwitch";
import { type } from "@testing-library/user-event/dist/type";
import ConfirmPopup from "../NewCustomComp/confirmPopup/ConfirmPopup";

const PrivacyPolicies = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const baseURL = getBaseUrl();
  const [addPolicyPopup, setAddPolicyPopup] = useState(false);
  const [editPrivacyPolicyPopup, setEditPrivacyPolicyPopup] = useState(false);
  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  const userToken = getDecryptedData("encryptToken");

  const property_id = useSelector((state) => state.property.value);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [selectedId, setSelectedId] = useState(true);

  const [orders, setOrders] = useState([]);

  const fetchInfo = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${baseURL}/api/v1/property_policy/list/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setOrders(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  const [penalty, setPenalty] = useState([]);
  const [editPenalty, setEditPenalty] = useState("");

  const fetchPlenties = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${baseURL}/api/v1/booking-engine/cancellation/penality/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setPenalty(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id && userToken) {
      fetchInfo(property_id?.id);
      fetchPlenties(property_id?.id);
    }
  }, [property_id, userToken]);

  const handleSubmitAddPrivacyPolicy = (event) => {
    event.preventDefault();
    fetch(`${baseURL}/api/v1/property_policy/add/${property_id?.id}`, {
      method: "POST",
      body: JSON.stringify({ ...privacyData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setAddPolicyPopup(false);
          fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };
  const handleSubmitUpdatePrivacyPolicy = (event) => {
    event.preventDefault();
    fetch(
      `${baseURL}/api/v1/property_policy/update/${selectedId}/${property_id?.id}`,
      {
        method: "POST",
        body: JSON.stringify({ ...privacyData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setEditPrivacyPolicyPopup(false);
          setPrivacyData({});
          fetchInfo(property_id?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // privacy policy

  const [editorHtml, setEditorHtml] = useState("");

  const [privacyData, setPrivacyData] = useState({
    policy_type: "",
    policy_data: "",
  });

  useEffect(() => {
    setPrivacyData((prevData) => ({
      ...prevData,
      policy_data: editorHtml,
    }));
  }, [editorHtml]);

  const handleValueChange = (event) => {
    setPrivacyData({
      ...privacyData,
      [event.target.name]: event.target.value,
    });
  };

  const options = [
    { label: "Travel", value: "Travel" },
    { label: "Adventure", value: "Adventure" },
    { label: "Gk", value: "Gk" },
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["align", "code-block"],
      ["link", "image"],
      ["undo", "redo"], // Add undo and redo options
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "align",
    "code-block",
    "link",
    "image",
  ];

  const handleTextChange = (value) => {
    setEditorHtml(value);
  };

  const getOneData = async (id) => {
    setSelectedId(id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/property_policy/${id}/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      setEditPrivacyPolicyPopup(true);
      setPrivacyData(data?.data);
      setEditorHtml(data?.data?.policy_data || "");
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const removeItemConfirm = async () => {
    const response = await fetch(
      `${baseURL}/api/v1/property_policy/delete/${selectedId}/${property_id?.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          fetchInfo(property_id?.id);
          setShowModelMenuActive(false);
          showSuccessToast(data.message);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "handleInputChange");
    setEditPenalty((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  console.log(editPenalty, "handleInputChange");

  const handleToggleChange = (value, name) => {
    setEditPenalty((prev) => ({
      ...prev,
      [name]: value ? 1 : 0,
    }));
  };
  const handleEditClick = (item) => {
    setEditPenalty({
      type: "edit",
      ...item,
    });
  };

  const handleAddPenalty = () => {
    setEditPenalty({
      type: "add",
      min_days_before_checkin: "",
      max_days_before_checkin: "",
      penality_amount: "",
      penality_type: "fixed",
      is_active: 1,
    });
  };

  const handleUpdatePenalty = async () => {
    if (
      !editPenalty.min_days_before_checkin ||
      !editPenalty.max_days_before_checkin ||
      !editPenalty.penality_amount
    ) {
      return showErrorToast("please enter all the fields");
    }
    if (
      +editPenalty.min_days_before_checkin >
      +editPenalty.max_days_before_checkin
    ) {
      return showErrorToast(
        "Min days before checkin should be less than max days before checkin"
      );
    }
    try {
      setLoader(true);

      // Create the headers
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      // Create the form data
      const formdata = new FormData();
      formdata.append(
        "min_days_before_checkin",
        editPenalty.min_days_before_checkin
      );
      formdata.append(
        "max_days_before_checkin",
        editPenalty.max_days_before_checkin
      );
      formdata.append("penality_amount", editPenalty.penality_amount);
      formdata.append("penality_type", editPenalty.penality_type);
      formdata.append("is_active", editPenalty.is_active);
      editPenalty.type === "edit" && formdata.append("id", editPenalty.id);

      // Fetch request options
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      // Make the API request
      const response = await fetch(
        `${baseURL}/api/v1/booking-engine/cancellation/penality/${property_id?.id}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result.success) {
        showSuccessToast(result.message);
        setEditPenalty("");
        fetchPlenties(property_id?.id);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  const [deleteId ,setDeleteId] = useState(null)
  const handleDeleteClick = (id) =>{
    setDeleteId(id)
  }

  const handleConfirmDelete = async () => {
    const raw = JSON.stringify({
      ids: [deleteId], // JSON body with the required "ids" field
    });
  
    try {
      setLoader(true); // Start the loader
  
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json"); // Add Content-Type header
  
      // Fetch request options
      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      // Make the API request
      const response = await fetch(
        `${baseURL}/api/v1/booking-engine/cancellation/penality/${property_id?.id}`,
        requestOptions
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
  
      if (result.success) {
        showSuccessToast(result.message); // Show success toast
        setEditPenalty(""); // Reset edit penalty state
        setDeleteId(null); // Clear delete ID
        fetchPlenties(property_id?.id); // Refetch penalties
      }
  
      setLoader(false); // Stop the loader
    } catch (error) {
      setLoader(false); // Ensure loader stops in case of an error
      console.error("Error deleting penalty:", error);
    }
  };
  
  

  return (
    <>
      <div className="">
        <div
          className="bg-white pt-10 pb-10 pl-10 pr-10"
          style={{ borderRadius: "6px" }}
        >
          <div className="flex justify_content_between align_items_center mb-10 ">
            <div className="room-type-heading">Cancellation Penalties</div>

            {permissions &&
            permissions.includes("full_access_property_settings") ? (
              <PrimaryIconButtonWithText
                style={{ backgroundColor: "#fff", color: "#3968ed" }}
                size={"small"}
                icon={<SvgPlus />}
                title={"Add"}
                onClick={handleAddPenalty}
              />
            ) : (
              <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
                <PrimaryIconButtonWithText
                  style={{
                    backgroundColor: "rgb(241 242 244)",
                    color: "#3968ed",
                  }}
                  size={"small"}
                  icon={<SvgPlus />}
                  title={"Add"}
                />
              </Tooltip>
            )}
          </div>

          <div>
            {penalty && penalty?.length > 0 ? (
              <CustomShopifyIndexTable
                headings={[
                  "Min days before check-in",
                  "Max days before check-in",
                  "Penalty Amt",
                  "Unit",
                  "Status",
                  "Action",
                ]}
              >
                {penalty?.map((item, index) => (
                  <CustomShopifyIndexTableRow  id={index} >
                    <CustomShopifyIndexTableCell>
                      {item?.min_days_before_checkin}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item?.max_days_before_checkin}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item?.penality_amount}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item?.penality_type}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item?.is_active ? "Active" : "Inactive"}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      <span className="flex flex_gap_10">
                        <span
                          className="pointer"
                          onClick={() => handleEditClick(item)}
                        >
                          <SvgEdit />
                        </span>
                        <span className="pointer" onClick={()=>handleDeleteClick(item?.id)}>
                          <SvgDelete />
                        </span>
                      </span>
                    </CustomShopifyIndexTableCell>
                  </CustomShopifyIndexTableRow>
                ))}
              </CustomShopifyIndexTable>
            ) : (
              <NoDataFoundSmall />
            )}

            {editPenalty && (
              <div
                className="card-3d mt-10 flex justify_content_between"
                style={{ padding: "10px" }}
              >
                <div className="flex flex_gap_5 align_items_center">
                  <label className="global_label">
                    Min days before check-in
                  </label>
                  <PrimaryInput
                    style={{ width: "50px" }}
                    size={"small"}
                    name={"min_days_before_checkin"}
                    value={editPenalty?.min_days_before_checkin}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex_gap_5 align_items_center">
                  <label className="global_label">
                    Max days before check-in
                  </label>
                  <PrimaryInput
                    style={{ width: "50px" }}
                    size={"small"}
                    name={"max_days_before_checkin"}
                    value={editPenalty?.max_days_before_checkin}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex_gap_5 align_items_center">
                  <label className="global_label">Penalty Amt</label>
                  <PrimaryInput
                    style={{ width: "150px" }}
                    size={"small"}
                    name={"penality_amount"}
                    value={editPenalty?.penality_amount}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex_gap_5 align_items_center">
                  <label className="global_label">Penalty Type</label>
                  <SelectBasicWithSelectTag
                    options={[
                      { label: "Fixed", value: "fixed" },
                      { label: "Percentage", value: "percentage" },
                    ]}
                    size={"small"}
                    name={"penality_type"}
                    value={editPenalty?.penality_type}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="flex flex_gap_5 align_items_center">
                  <label className="global_label">Status</label>
                  <ToggleSwitch
                    size="small"
                    name={"is_active"}
                    isOn={editPenalty?.is_active ? true : false}
                    handleToggle={handleToggleChange}
                  />
                </div>

                <div className="flex flex_gap_5 align_items_center">
                  <PrimaryButton
                    size={"small"}
                    title={editPenalty.type === "add" ? "Add" : "Update"}
                    onClick={handleUpdatePenalty}
                  />
                  <SecondaryButton
                    size={"small"}
                    title={"Cancel"}
                    onClick={() => setEditPenalty(null)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify_content_between align_items_center pr-10 pl-10 mb-10 mt-20">
          <div className="room-type-heading">Privacy Policies</div>

          {permissions &&
          permissions.includes("full_access_property_settings") ? (
            <PrimaryIconButtonWithText
              style={{ backgroundColor: "rgb(241 242 244)", color: "#3968ed" }}
              size={"small"}
              icon={<SvgPlus />}
              title={"Add"}
              onClick={() => setAddPolicyPopup(!addPolicyPopup)}
            />
          ) : (
            <Tooltip content="Please Contact Your Admin" dismissOnMouseOut>
              <PrimaryIconButtonWithText
                style={{
                  backgroundColor: "rgb(241 242 244)",
                  color: "#3968ed",
                }}
                size={"small"}
                icon={<SvgPlus />}
                title={"Add"}
              />
            </Tooltip>
          )}
        </div>
      </div>

      <div>
        {loader ? (
          <LoaderSmall />
        ) : (
          <div>
            <div
              className="bg_white pt-10 pb-10 pl-10 pr-10"
              style={{
                borderRadius: "6px",
              }}
            >
              {orders && orders.length > 0 ? (
                <>
                  {orders.map((policy) => {
                    return (
                      <div>
                        <div
                          style={{ marginBottom: "10px" }}
                          className="flex justify_content_between"
                        >
                          <div style={{ fontWeight: "bold" }}>
                            {policy?.policy_type}
                          </div>
                          <div className="flex flex_gap_10">
                            {permissions &&
                            permissions.includes(
                              "full_access_property_settings"
                            ) ? (
                              <>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "rgba(57, 104, 237, 1)",
                                  }}
                                  onClick={() => getOneData(policy.id)}
                                >
                                  Edit
                                </div>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "rgba(224, 56, 56, 1)",
                                  }}
                                  onClick={() => [
                                    setShowModelMenuActive(true),
                                    setSelectedId(policy.id),
                                  ]}
                                >
                                  Delete
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <div
                          style={{ paddingLeft: "20px" }}
                          dangerouslySetInnerHTML={{
                            __html: policy?.policy_data,
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <NoDataFoundSmall />
              )}
            </div>
          </div>
        )}
      </div>

      {addPolicyPopup && (
        <div className="bg-backdrop">
          <form
            onSubmit={handleSubmitAddPrivacyPolicy}
            style={{ width: "600px" }}
            className="stock-add-model-container"
          >
            <div>
              <GoBackButtonCustom
                buttonNextText={"Add Privacy Policy"}
                onClick={() => navigate("/websites/blogs")}
                showSvg={false}
              />
            </div>

            <div className="edit-rooms-form-wrapper-container">
              <div className="edit-rooms-form-container">
                <div className="form-container-div-full">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="policy_type"
                    titleName="Privacy Policy Type"
                    autoComplete="off"
                    onChange={handleValueChange}
                    value={privacyData.policy_type}
                  />
                </div>
                <div className="form-container-div-full">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="url"
                    titleName="Url"
                    autoComplete="off"
                    onChange={handleValueChange}
                    value={privacyData.url}
                  />
                </div>
              </div>
              <div className="editor_container">
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={editorHtml}
                  onChange={handleTextChange}
                  style={{ border: "1px solid #ccc" }}
                />
                {/* <CKEditor
                          editor={ClassicEditor}
                          data="<p>Hello World</p> "
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            handleEditorChange(data);
                          }}
                        /> */}
              </div>
            </div>
            <div className="flex flex_gap_10 mt-20">
              <BgThemeButton children={"Add"} type={"submit"} loader={loader} />
              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => [
                  setSelectedId(null),
                  setPrivacyData({}),
                  setEditorHtml(null),
                  setAddPolicyPopup(!addPolicyPopup),
                ]}
              />
            </div>
          </form>
        </div>
      )}
      {editPrivacyPolicyPopup && (
        <div className="bg-backdrop">
          <form
            onSubmit={handleSubmitUpdatePrivacyPolicy}
            style={{ width: "600px" }}
            className="stock-add-model-container"
          >
            <div>
              <GoBackButtonCustom
                buttonNextText={"Edit Privacy Policy"}
                onClick={() => navigate("/websites/blogs")}
                showSvg={false}
              />
            </div>

            <div className="edit-rooms-form-wrapper-container">
              <div className="edit-rooms-form-container">
                <div className="form-container-div-full">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="policy_type"
                    titleName="Privacy Policy Type"
                    autoComplete="off"
                    onChange={handleValueChange}
                    value={privacyData.policy_type}
                  />
                </div>
                <div className="form-container-div-full">
                  <VerticalInputFieldCustom
                    required={true}
                    focused={false}
                    name="url"
                    titleName="URL"
                    autoComplete="off"
                    onChange={handleValueChange}
                    value={privacyData.url}
                  />
                </div>
              </div>
              <div className="editor_container">
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={editorHtml}
                  onChange={handleTextChange}
                  style={{ border: "1px solid #ccc" }}
                />
              </div>
            </div>
            <div className="flex flex_gap_10 mt-20">
              <BgThemeButton children={"Update"} type={"submit"} />
              <BgOutlineThemeButton
                children={"Cancel"}
                type={"button"}
                onClick={() => [
                  setSelectedId(null),
                  setPrivacyData({}),
                  setEditorHtml(null),
                  setEditPrivacyPolicyPopup(false),
                ]}
              />
            </div>
          </form>
        </div>
      )}

      {showModelMenuActive && (
        <>
          <div className="bg-backdrop">
            <section className="room-type-model-container ">
              <h4>Are You Sure?</h4>
              <p>You Want To Remove?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  onClick={() => setShowModelMenuActive(!showModelMenuActive)}
                >
                  Discard
                </Button>
                <Button onClick={removeItemConfirm} destructive>
                  Yes, Remove
                </Button>
              </div>
            </section>
          </div>
        </>
      )}
      { 
      deleteId && <ConfirmPopup
       heading={"Are you sure?"}
       subHeading={"Please confirm your action."}
       setShowPopup={setDeleteId}
       handleConfirmClick={handleConfirmDelete}
      
      />

      }
    </>
  );
};
export default PrivacyPolicies;
