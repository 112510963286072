import React, { useEffect } from "react";
import styles from "./PromotionsPackage.module.scss";
import GoBackButtonCustom from "../../../Components/GoBackButtonCustom/GoBackButtonCustom";
import { PrimaryInput } from "../../../Components/NewCustomComp/Inputs/Inputs";
import SelectDateRange from "../../../Components/CustomComponents/SelectDateRange/SelectDateRange";
import DateRangePicker from "../../../Components/NewCustomComp/DateRangePicker/DateRangePicker";
import ReactQuillTextEditor from "../../../Components/NewCustomComp/ReactOuillTextEditor/ReactQuillTextEditor";
import { SvgAlert, SvgMinus } from "../../../assets/svgIcons";
import { SearchSelect } from "../../../Components/NewCustomComp/Search/Search";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { getBaseUrl } from "../../../utils/utils";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../../utils/encryptStorage";
import ApplyOn from "../OffersComponent/ApplyOn";
import ApplicableOn from "../OffersComponent/ApplicableOn";
import DateRangePickerNew from "../../../Components/CustomComponents/CreateBookingNewPage/DateRangePickerNew/DateRangePickerNew";
import { useNavigate, useParams } from "react-router-dom";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../Components/NewCustomComp/Buttons/Buttons";
import UploadFileComponent from "../../../Components/NewCustomComp/UploadFileComponent/UploadFileComponent";

const CreatePackageDiscount = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const property_id = useSelector((state) => state.property.value)?.id;
  const userToken = getDecryptedData("encryptToken");

  const [selectedRoomCategories, setSelectedRoomCategories] = React.useState(
    []
  );
  const [formData, setFormData] = React.useState({
    package_name: "",
    package_rate: "",
    start_date: "",
    end_date: "",
    long_description: "",
    addons: [],
    apply_on: [],
    selectedChannels: [],
    selectedRoomCategories: [],
  });

  // Api's

  const [addons, setAddons] = React.useState([]);

  const getAddons = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/addons/offers/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      await response.json().then((data) => {
        if (data.success) {
          // setLoader(false);
          setAddons(data?.data);
        } else {
          showErrorToast("Something went wrong...");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      getAddons();
    }
  }, [property_id]);

  // Handle Functions

  const handleFormDataChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "number" && +value < 0) {
      showErrorToast("Amount Should not be less than 0");
      return;

    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (startDate, endDate) => {
    if (startDate && endDate) {
      setFormData((prevData) => ({
        ...prevData,
        start_date: startDate,
        end_date: endDate,
      }));
    }
  };

  const handleTextEditorInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddOnsOptionChange = (data) => {
    const formattedData = {
      ...data,
      cost: (+data.price * +data.tax) / 100 + +data.price,
      no_of_nights: 1,
      quantity: 1,
    };

    setFormData((prev) => {
      const isDuplicate = prev.addons?.some(
        (item) => item.addon_name === data.addon_name
      );

      if (isDuplicate) {
        showErrorToast("Add-on already exists");
        return prev;
      }
      return {
        ...prev,
        addons: prev.addons ? [...prev.addons, formattedData] : [formattedData],
      };
    });
  };

  const handleRemoveAddOns = (index) => {
    setFormData((prev) => ({
      ...prev,
      addons: prev.addons.filter((_, i) => i !== index),
    }));
  };

  // const handleAddOnsInputsChange = (e, index) => {
  //   const { name, value } = e.target;
  //   if (value === "0" || value === "0.00") {
  //     return;
  //   }
  //   setFormData((prev) => ({
  //     ...prev,
  //     addons: prev.addons.map((addon, i) =>
  //       i === index ? { ...addon, [name]: value } : addon
  //     ),
  //   }));
  // };

  const handleCreatePackage = async () => {
    const entities = {
      entity_type: "meal_plans",
      entity_ids: selectedRoomCategories.flatMap((room) =>
        room.meal_plans.map((plan) => plan.meal_plan_id)
      ),
    };
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const raw = JSON.stringify({
      ...(id && { id: id }),
      package_name: formData.package_name,
      package_rate: formData.package_rate,
      long_description: formData.long_description,
      start_date: formData.start_date,
      end_date: formData.end_date,
      is_active: formData?.is_active ? formData?.is_active : true,
      applications: formData.selectedChannels.map((channel) => ({
        applies_to: channel,
        specific_ids: [""],
      })),
      entities: [entities],
      inclusions: formData.addons,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("formData", formData);

  // update

  useEffect(() => {
    if (property_id && id) {
      fetchPackage(property_id, id);
      fetchImage(property_id, id, "package", "package_featured_image");
    }
  }, [id, property_id]);

  const fetchPackage = async (property_id, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/${property_id}?id=${id}`,
        requestOptions
      );
      const data = await response.json();
      if (data?.success) {
        // setAddons(data?.data)
        setFormData({
          package_name: data.data.package_name,
          package_rate: data.data.package_rate,
          start_date: data.data.start_date,
          end_date: data.data.end_date,
          is_active: data?.data.is_active,
          long_description: data.data.long_description,
          //  applications:
          addons: data.data.inclusions,

          selectedMealPlans: data?.data?.entities.find(
            (item) => item?.entity_type === "meal_plans"
          )?.entity_ids,

          selectedChannels: data?.data?.applications?.map(
            (item) => item?.applies_to
          ),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // featured image

  const [image, setImage] = React.useState("");

  const handleUploadImage = async () => {
    // Create a FormData instance
    const formData = new FormData();
    formData.append("media_type", "package");
    formData.append("associated_id", id);
    formData.append("package_featured_image", image);

    try {
      // Configure request options
      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      // Perform the API call
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/media/${property_id}`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        showSuccessToast(result.message); // Show success toast
      } else {
        const errorText = await response.text(); // Get error details
        console.error("Failed to save addon:", errorText);
        showErrorToast("Failed to upload image. Please try again."); // Show error toast
      }
    } catch (error) {
      console.error("Error saving addon:", error.message);
      showErrorToast("An unexpected error occurred. Please try again."); // Show error toast for unexpected errors
    }
  };

  const fetchImage = async (property_id, id, media_type, responseTypeKey) => {
    // Create a FormData instance

    try {
      // Configure request options
      const requestOptions = {
        redirect: "follow",
      };

      // Perform the API call
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/media/${property_id}?media_type=${media_type}&associated_id=${id}`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        setImage(result.media[responseTypeKey].src);
      } else {
        const errorText = await response.text(); // Get error details
        console.error("Failed to save addon:", errorText);
      }
    } catch (error) {
      console.error("Error saving addon:", error.message);
    }
  };

  const handleFileChange = (data) => {
    setImage(data);
  };

  return (
    <div className={`${styles.CreatePackage_container} main_container`}>
      <div className="flex align_items_center flex_gap_5">
        <GoBackButtonCustom
          buttonNextText={id ? "Update Package" : "Create Package"}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className={`${styles.CreatePackage_main} flex flex_gap_15 mt-15`}>
        <div className={`${styles.CreatePackage_left}`}>
          {/* Basic Details */}
          <div className={styles.common_white_wrapper}>
            <h4 className={styles.smallHeadings}>Basic Details</h4>

            <div className="flex flex_gap_15 mt-5 mb-10">
              <div className="w-50">
                <PrimaryInput
                  onChange={handleFormDataChange}
                  name={"package_name"}
                  value={formData.package_name}
                  label={"Package Name"}
                  size={"medium"}
                />
              </div>
              <div className="w-50">
                <PrimaryInput
                  onChange={handleFormDataChange}
                  name={"package_rate"}
                  value={formData.package_rate}
                  label={"Package Price"}
                  type={"number"}
                  size={"medium"}
                />
              </div>
            </div>
            <div>
              <label className="global_label">Package Date/Range</label>
              {/* <SelectDateRange  label={"Package Date/Range"} size={"medium"} /> */}
              <DateRangePicker
                onChange={handleDateChange}
                minDate={new Date()}
                defaultDate={{
                  startDate: formData.start_date,
                  endDate: formData.end_date,
                }}
              />
            </div>
          </div>
          {/* End Basic Details */}

          {/* Inclusions */}
          <div className={`${styles.common_white_wrapper} mt-15`}>
            <h4 className={styles.smallHeadings}>Inclusions</h4>

            <div className="w-100 mt-10">
              <SearchSelect
                handleOptionChange={handleAddOnsOptionChange}
                size={"medium"}
                options={addons}
                optionKeyName={"addon_name"}
              />
              {formData?.addons && formData?.addons?.length > 0 ? (
                <>
                  <table className={`${styles.table} mt-10`}>
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Addon</th>
                        <th>Price</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.addons.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.addon_name}</td>
                          <td>{data.price}</td>
                          <td>
                            <span
                              className="cursor-pointer"
                              onClick={() => handleRemoveAddOns(index)}
                            >
                              <SvgMinus />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className={styles.noAddonsContainer}>
                  <div className={styles.noAddonsMessage}>
                    <SvgAlert />
                    <p>
                      No addons are selected . Please select an addon from the
                      list above.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* End Inclusions */}

          {/* long_description */}
          <div className={`${styles.common_white_wrapper} mt-15`}>
            <h4 className={`${styles.smallHeadings} mb-10`}>Description</h4>

            <ReactQuillTextEditor
              handleTextChange={handleTextEditorInputChange}
              name={"long_description"}
              editorHtml={formData.long_description}
              // label={"Short long_description"}
              labelSize={"medium"}
            />
          </div>
          {/* End long_description */}

          {/* Featured Image */}
          {id && (
            <div className={`${styles.common_white_wrapper} mt-15`}>
              <label className="global_label">
                <b>Featured Image</b>
              </label>

              <div className="mt-5">
                <UploadFileComponent
                  fileType="image"
                  width={"100%"}
                  onFileChange={handleFileChange}
                  defaultFile={image}
                />
              </div>
              <PrimaryButton
                title={"Upload"}
                size="small"
                onClick={handleUploadImage}
              />
            </div>
          )}
          {/* End Featured Image */}

          <div className="flex flex_gap_15 mt-15">
            <PrimaryButton
              size={"medium"}
              title={"Save"}
              onClick={handleCreatePackage}
            />

            <SecondaryButton
              size={"medium"}
              title={"Discard"}
              onClick={() => navigate(-1)}
            />
          </div>
        </div>

        <div className={`${styles.CreatePackage_right} w-50`}>
          {/* Apply On */}

          <div className={`${styles.common_white_wrapper}`}>
            <h4 className={`${styles.smallHeadings} mb-10`}>Apply On</h4>
            <ApplyOn
              formData={formData}
              hideOtaOptions={true}
              setFormData={setFormData}
            />
          </div>
          {/* End Apply On */}

          {/* Applicable On */}
          <div className={`${styles.common_white_wrapper} mt-15`}>
            <h4 className={`${styles.smallHeadings} mb-10`}>Applicable On</h4>
            <ApplicableOn
              selectedRoomCategories={selectedRoomCategories}
              setSelectedRoomCategories={setSelectedRoomCategories}
              selectMealPlansArray={formData.selectedMealPlans}
              property_id={property_id}
            />
          </div>
          {/* End Applicable On */}
        </div>
      </div>
    </div>
  );
};

export default CreatePackageDiscount;
