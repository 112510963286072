import React, { useEffect, useRef } from "react";
import styles from "../HousekeepingStyles.module.scss";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { getBaseUrl, getDateAndMonth } from "../../../utils/utils";
import { SvgCancel, SvgCleaning, SvgMiniBar } from "../../../assets/svgIcons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";
import { SelectBasicWithSelectTag } from "../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { PrimaryButton } from "../../../Components/NewCustomComp/Buttons/Buttons";
import Loader from "../../../Components/UI/Loader/Loader";

const HousekeepingRoomView = () => {
  // All Hooks
  const baseUrl = getBaseUrl();
  const property_id = useSelector((state) => state.property.value)?.id;
  const userToken = getDecryptedData("encryptToken");
  const toolbarRef = useRef(null);
  // All Hooks End

  // All States
  const [orders, setOrders] = React.useState([]);
  const [count, setCount] = React.useState([]);
  const [popupType, setPopupType] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);
  const [toolBar, setToolBar] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState({});
  const [switchStatus, setSwitchStatus] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  // All States End

  // All Api

  // Listing Mini Bar Api
  const fetchRoomView = async (propertyId) => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-assign/${propertyId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setCount(data?.StatusCounts);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  // Listing Mini Bar Api End

  // Assign Mini Bar Api
  const assignMiniBar = async (roomId, roomNo) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      room_no: roomNo,
      room_id: roomId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-assign/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Mini Bar Assigned Successfully");
        fetchRoomView(property_id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Assign Mini Bar Api End

  // Unassign Mini Bar Api
  const unassignMiniBar = async (roomId, roomNo) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      room_no: roomNo,
      room_id: roomId,
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-assign/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Mini Bar Unassigned Successfully");
        fetchRoomView(property_id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Unassign Mini Bar Api End

  // Request Cleaning Api
  const requestCleaning = async (roomId, roomNo, id, status, floorNo) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const raw = JSON.stringify({
      room_no: roomNo,
      room_id: roomId,
      property_id: property_id,
      status: status,
      floor_no: floorNo,
      request_for_clean: true,
    });
    const requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/assign/rooms/update/${id}/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Cleaning Requested Successfully");
        fetchRoomView(property_id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Request Cleaning Api End

  // Status Change Api
  const switchStatusChange = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const raw = JSON.stringify({
      room_no: selectedData?.RoomNo,
      room_id: selectedData?.RoomId,
      property_id: property_id,
      status: switchStatus,
      floor_no: selectedData?.FloorNo,
    });
    const requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/assign/rooms/update/${selectedData?.id}/${property_id}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Status Updated Successfully");
        fetchRoomView(property_id);
        setShowPopup(false);
        setSwitchStatus("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Status Change Api End

  // All Api's End

  // All Functions
  useEffect(() => {
    if (property_id) {
      fetchRoomView(property_id);
    }
  }, [property_id]);

  const handleShowToolBar = (uniqueValue) => {
    if (toolBar === uniqueValue) {
      setToolBar(false);
    } else {
      setToolBar(uniqueValue);
    }
  };
  const handleClickOutside = (event) => {
    if (toolbarRef.current && !toolbarRef.current.contains(event.target)) {
      setToolBar(null); // Close the toolbar if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedDataFilter = orders.find((item) => {
    console.log(item, "items");
    return item.Rooms.filter(
      (room) =>
        room.RoomId === selectedData.RoomId &&
        room.RoomNo === selectedData.RoomNo
    );
  });

  const handleDisableClick = () => {
    showErrorToast("Cleaning Request Already Sent");
  };

  const handleChangeStatus = (e) => {
    const { value } = e.target;
    setSwitchStatus(value);
  };
  // console.log("selectedDataFilter", selectedDataFilter);
  // All Functions End

  // Console.logs
  console.log("orders", orders);
  console.log("status", switchStatus);
  console.log("selectedData", selectedData);
  // console.log("toolBar", toolBar);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="main_container">
          <div className={`${styles.roomViewHeading_wrapper} flex align_items_center flex_gap_10`}>
            <div className={`${styles.roomViewHeading_card}`}>
              <span className="weight_700">
              {count?.total}
              </span>
              All
            </div>
            <div className={`${styles.roomViewHeading_card}`}>
              <span className="weight_700">
              {count?.occupied}
              </span>
              Occupied
            </div>
            <div className={`${styles.roomViewHeading_card}`}>
              <span className="weight_700">
              {count?.clean}
              </span>
              Clean
            </div>
            <div className={`${styles.roomViewHeading_card}`}>
              <span className="weight_700">
              {count?.dirty}
              </span>
              Dirty
            </div>
          </div>

          <div className={`${styles.white_wrapper} mt-10`}>
            <div className={styles.room_card_wrapper}>
              {orders &&
                orders.length > 0 &&
                orders.map((items, index) => (
                  <div className={styles.row_child} key={index}>
                    <div className="flex align_items_center justify_content_between">
                      <h4>{items?.Category}</h4>
                    </div>

                    {/* Room Cards Wrapper */}
                    <div className={`flex flex_gap_10 mt-10 flex_wrap`}>
                      {items?.Rooms &&
                        items?.Rooms.map((item, innerIndex) => (
                          // Room Card
                          <div
                            key={innerIndex}
                            onClick={() =>
                              handleShowToolBar(
                                items?.Category +
                                  "-" +
                                  item.RoomId +
                                  "-" +
                                  item.RoomNo
                              )
                            }
                            className={`${styles.room_view_card} 
                        ${item?.RoomStatus === "dirty" && styles.dirtyRoom} 
                        ${
                          item?.RoomStatus === "occupied" && styles.occupiedRoom
                        } 
                        ${
                          item?.RoomStatus === "clean" && styles.cleanRoom
                        } pointer`}
                          >
                            {/* Room Card Header */}
                            <div className="flex mb-5 justify_content_between">
                              {item?.RoomNo}
                              <div className="flex align_items_center flex_gap_5">
                                {item?.MiniBarAssigned && (
                                  <span>
                                    <SvgMiniBar />
                                  </span>
                                )}
                                {item?.RequestForClean && (
                                  <span>
                                    <SvgCleaning />
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* Room Card Header End */}

                            {/* Room Card Body */}
                            <div className="">
                              <div className="flex flex_gap_5">
                                <h6>
                                  {item.ReservationDetails &&
                                    item.ReservationDetails.length > 0 &&
                                    getDateAndMonth(
                                      item.ReservationDetails[0]?.CheckIn
                                    )}
                                </h6>
                                {item.ReservationDetails &&
                                  item.ReservationDetails.length > 0 && (
                                    <span>-</span>
                                  )}
                                <h6>
                                  {item.ReservationDetails &&
                                    item.ReservationDetails.length > 0 &&
                                    getDateAndMonth(
                                      item.ReservationDetails[0]?.CheckOut
                                    )}
                                </h6>
                              </div>

                              <h6>
                                {item.ReservationDetails &&
                                  item.ReservationDetails.length > 0 &&
                                  item.ReservationDetails[0]?.GuestName}
                              </h6>
                            </div>
                            {/* Room Card Body End */}

                            {/* Room Card Footer (ToolBar) */}
                            {toolBar ===
                              items?.Category +
                                "-" +
                                item.RoomId +
                                "-" +
                                item.RoomNo && (
                              <div
                                ref={toolbarRef}
                                className={`${styles.room_view_card_footer} ${
                                  item?.RoomStatus !== "occupied" &&
                                  styles.noDetails
                                }`}
                              >
                                {item?.RoomStatus === "occupied" && (
                                  <div
                                    onClick={() => {
                                      setPopupType("view-details");
                                      setShowPopup(true);
                                      setSelectedData(item);
                                    }}
                                    className={`${styles.room_view_card_footer_rows}`}
                                  >
                                    View Details
                                  </div>
                                )}
                                {item?.RoomStatus === "occupied" &&
                                  !item?.RequestForClean && (
                                    <div
                                      onClick={() => {
                                        requestCleaning(
                                          item?.RoomId,
                                          item?.RoomNo,
                                          item?.id,
                                          item?.RoomStatus,
                                          item?.FloorNo
                                        );
                                      }}
                                      className={`${
                                        styles.room_view_card_footer_rows
                                      } ${
                                        item?.RequestForClean &&
                                        styles.requested
                                      }`}
                                    >
                                      Request Cleaning
                                    </div>
                                  )}
                                {item?.RoomStatus === "occupied" &&
                                  item?.RequestForClean && (
                                    <div
                                      onClick={handleDisableClick}
                                      className={`${
                                        styles.room_view_card_footer_rows
                                      } ${
                                        item?.RequestForClean &&
                                        styles.requested
                                      }`}
                                    >
                                      Request Cleaning
                                    </div>
                                  )}
                                {item?.RoomStatus !== "occupied" && (
                                  <div
                                    onClick={() => {
                                      setPopupType("change-status");
                                      setShowPopup(true);
                                      setSelectedData(item);
                                    }}
                                    className={`${styles.room_view_card_footer_rows} ${styles.disabledField}`}
                                  >
                                    Change Status
                                  </div>
                                )}
                                <div
                                  className={styles.room_view_card_footer_rows}
                                  onClick={() =>
                                    item?.MiniBarAssigned
                                      ? unassignMiniBar(
                                          item?.RoomId,
                                          item?.RoomNo
                                        )
                                      : assignMiniBar(
                                          item?.RoomId,
                                          item?.RoomNo
                                        )
                                  }
                                >
                                  {item?.MiniBarAssigned
                                    ? "Remove Mini Bar"
                                    : "Add Mini Bar"}
                                </div>
                              </div>
                            )}
                            {/* Room Card Footer (ToolBar) End */}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {/* Popup */}

      {showPopup && popupType === "view-details" && (
        <div className="bg-backdrop">
          <div style={{ minWidth: "350px" }} className="white_wrapper">
            <div className="flex align_items_center justify_content_between">
              <h4>Room Details</h4>

              <span
                onClick={() => setShowPopup(false)}
                className="flex align_items_center pointer"
              >
                <SvgCancel width="18" height="18" color={"#AAA"} />
              </span>
            </div>

            <div className="flex justify_content_between border_bottom mt-10 pb-10">
              <h6>Room Category</h6>
              <span>{selectedDataFilter?.Category}</span>
            </div>

            <div className="flex justify_content_between border_bottom mt-10 pb-10">
              <h6>Room No</h6>
              <span>{selectedData?.RoomNo}</span>
            </div>

            <div className="flex justify_content_between border_bottom mt-10 pb-10">
              <h6>Guest Name</h6>
              <span>
                {selectedData?.ReservationDetails[0]?.GuestName ?? "N/A"}
              </span>
            </div>

            <div className="flex justify_content_between mt-10 pb-10">
              <h6>No. of Guest</h6>
              <span>{selectedData?.GuestCount}</span>
            </div>
          </div>
        </div>
      )}

      {showPopup && popupType === "change-status" && (
        <div className="bg-backdrop">
          <div style={{ minWidth: "350px" }} className="white_wrapper">
            <div className="flex align_items_center justify_content_between">
              <h4>Change Room Status</h4>
              <span
                onClick={() => setShowPopup(false)}
                className="flex align_items_center pointer"
              >
                <SvgCancel width="18" height="18" color={"#AAA"} />
              </span>
            </div>
            <div className="mt-10">
              <SelectBasicWithSelectTag
                size={"medium"}
                options={[
                  { label: "Select", value: "" },
                  { label: "Clean", value: "clean" },
                  { label: "Dirty", value: "dirty" },
                ]}
                value={switchStatus}
                onChange={handleChangeStatus}
              />
            </div>
            <div className="mt-10">
              <PrimaryButton
                onClick={switchStatusChange}
                size="medium"
                title="Update"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HousekeepingRoomView;
