import React from "react";
import NavTabs from "../../Components/NewCustomComp/NavTabs/NavTabs";
import { Outlet } from "react-router-dom";

const OffersPromotionsPackages = () => {
  const links = [
    { label: "Promotions", to: "discounts" },
    { label: "Packages", to: "packages" },
    { label: "Add-ons", to: "add-ons" },
  ];

  return (
    <div className="main_container">
      <NavTabs mainData={links} />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default OffersPromotionsPackages;
