import React, { useEffect } from "react";
// import styles from './PromotionsAddons.module.scss'
import styles from "../PromotionsAddons/PromotionsAddons.module.scss";

import {
  PrimaryButton,
  SecondaryButton,
} from "../../../Components/NewCustomComp/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { getBaseUrl } from "../../../utils/utils";
import { SvgDelete, SvgEdit } from "../../../assets/svgIcons";
import { showSuccessToast } from "../../../assets/toastUtils";
import ConfirmPopup from "../../../Components/NewCustomComp/confirmPopup/ConfirmPopup";
import NoDataFound from "../../../Components/CustomComponents/NoDataFound";
import Pagination from "../../../Components/NewCustomComp/pagination/Pagination";
import { useSelector } from "react-redux";

const PromotionsPackagePage = () => {
  // Global Variables
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const propertyId = useSelector((state) => state.property.value)?.id;
  // const propertyId = 1;
  // End Global Variables

  // States
  const [addons, setAddons] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [deletePopup, setDeletePopup] = React.useState(false);
  // End States

  // All Api's

  const fetchAddons = async (page) => {
    setLoader(true);
    const url = page
      ? `${baseUrl}/api/v1/booking-engine/packages/${propertyId}?page=${page}`
      : `${baseUrl}/api/v1/booking-engine/packages/${propertyId}`;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data?.success) {
        setAddons(data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const DeleteAddons = async (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      ids: [id],
    });
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/${propertyId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        fetchAddons();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // End All Api's

  useEffect(() => {
    if (propertyId) {
      fetchAddons();
    }
  }, [propertyId]);

  const [deleteId, setDeleteId] = React.useState(null);

  const handleDelete = () => {
    setDeletePopup(false);
    DeleteAddons(deleteId);
  };

  // console.log("addons", addons)

  const handelPageChange = (page) => {
    fetchAddons(page);
  };

  return (
    <div>
      <div className="flex justify_content_end mt-10">
        <PrimaryButton
          title={"Create Package"}
          size="medium"
          onClick={() => navigate("/promotions/packages/create")}
        />
      </div>

      {addons && addons.data.length > 0 ? (
        <CustomShopifyIndexTable
          style={{ marginTop: "10px" }}
          headings={[
            "Name",
            "Price",
            "Start Date",
            "End Date",
            "Status",
            "Action",
          ]}
        >
          {addons &&
            addons.data.length > 0 &&
            addons.data.map((item, index) => {
              // console.log(item)
              return (
                <>
                  <CustomShopifyIndexTableRow key={index}>
                    <CustomShopifyIndexTableCell>
                      {item?.package_name}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item?.package_rate}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item?.start_date}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item?.end_date}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      <div
                        className={`${
                          item.is_active ? styles.active : styles.inactive
                        } flex align_items_center justify_content_center`}
                      >
                        {item?.is_active ? "Active" : "Inactive"}
                      </div>
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      <div className="flex align_items_center flex_gap_15">
                        <span
                          onClick={() =>
                            navigate(`/promotions/packages/edit/${item?.id}`)
                          }
                          className="flex align_items_center pointer"
                        >
                          <SvgEdit />
                        </span>
                        <span
                          onClick={() => {
                            setDeletePopup(true);
                            setDeleteId(item?.id);
                          }}
                          className="flex align_items_center pointer"
                        >
                          <SvgDelete />
                        </span>
                      </div>
                    </CustomShopifyIndexTableCell>
                  </CustomShopifyIndexTableRow>

                  <div>
                    <Pagination
                      type={"advance"}
                      currentPage={addons?.current_page}
                      totalPages={addons?.last_page}
                      onPageChange={handelPageChange}
                    />
                  </div>
                </>
              );
            })}
        </CustomShopifyIndexTable>
      ) : (
        <NoDataFound />
      )}

      {/* {addOffers && (
        <div className='bg_backdrop'> Add Offers Popup</div>
      )} */}

      {deletePopup && (
        <ConfirmPopup
          showPopup={deletePopup}
          heading={"Delete Addon"}
          subHeading={"Are you sure you want to delete this addon?"}
          setShowPopup={setDeletePopup}
          handleConfirmClick={handleDelete}
        />
      )}
    </div>
  );
};

export default PromotionsPackagePage;
