import React from "react";
import styles from "./ConditionBuilder.module.scss";
import { PrimaryInput } from "../../../Components/NewCustomComp/Inputs/Inputs";
import { SvgMinus } from "../../../assets/svgIcons";
import { SelectBasicWithSelectTag } from "../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { PrimaryButton } from "../../../Components/NewCustomComp/Buttons/Buttons";



const ConditionBuilder = ({ conditions =[], setConditions, deletePromotionEntities }) => {
  const handleAddCondition = () => {
    if(conditions.length === 0){
      setConditions([
        {
          field: "booking_date",
          operator: ">=",
          value: "",
          logical_operator: "AND",
        }
       
      ]);   
    }else{
      setConditions([
        { id:"" ,field: "", operator: "", value: "", logical_operator: "AND" },
        ...conditions, // Add new condition at the top
      ]);
      
    }
  };

  const handleRemoveCondition = (index ,condition) => {
    if (condition.id) {
      deletePromotionEntities("condition", condition.id)
    }
    setConditions(conditions.filter((_, i) => i !== index));
  };

  const handleChange = (index, key, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][key] = value;
    if (index === conditions.length - 1 && key === "logical_operator" && conditions.length > 1) {
      updatedConditions[index].logical_operator = value || null; // Allow user input or null
    }
    setConditions(updatedConditions);
  };
  const fieldOptions = [
    { value: "booking_date", label: "Booking Date" },
    { value: "check_in_date", label: "Check-In Date" },
    { value: "LOS", label: "LOS" },
    { value: "stay_free_rule", label: "Stay Free Rule" },
    { value: "tour_start_date", label: "Tour Start Date" },
  ];

  const operatorOptions = [
    { label: "equal to", value: "=" },
    { label: "not equal to", value: "!=" },
    { label: "greater than", value: ">" },
    { label: "less than", value: "<" },
    { label: "greater than or equal to", value: ">=" },
    { label: "less than or equal to", value: "<=" },
  ];

  const logicalOperatorOptions = [
    { label: "AND", value: "AND" },
    { label: "OR", value: "OR" },
  ];

  const getInputType = (field) => {
    if (
      field === "booking_date" ||
      field === "check_in_date" ||
      field === "tour_start_date"
    ) {
      return "date";
    }
    if (field === "LOS") {
      return "number";
    }
    return "text";
  };



  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Field</th>
            <th>Operator</th>
            <th>Value</th>
            <th>Log.Operator</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {conditions.map((condition, index) => (
            <tr key={index}>
              <td>
                <SelectBasicWithSelectTag
                  options={fieldOptions}
                  size={"medium"}
                  onChange={(e) => handleChange(index, "field", e.target.value)}
                  value={condition.field}
                />
              </td>
              <td>
                <SelectBasicWithSelectTag
                  options={operatorOptions}
                  size={"medium"}
                  onChange={(e) => handleChange(index, "operator", e.target.value)}
                  value={condition.operator}
                />
              </td>
              <td>
                <PrimaryInput
                  size={"medium"}
                  type={getInputType(condition.field)}
                  value={condition.value}
                  onChange={(e) => handleChange(index, "value", e.target.value)}
                />
              </td>
              <td>
                <SelectBasicWithSelectTag
                  options={logicalOperatorOptions}
                  size={"medium"}
                  onChange={(e) =>
                    handleChange(index, "logical_operator", e.target.value)
                  }
                  value={condition.logical_operator}
                />
              </td>
              <td>
                {/* {conditions.length  > 1 && index >0 && ( */}
                  <span onClick={() => handleRemoveCondition(index , condition)} className="pointer">
                    <SvgMinus />
                  </span>
                {/* )} */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-10 mb-10" style={{width:"fit-content" ,margin:"10px 0 10px auto"}}>
        {/* <button onClick={handleAddCondition} className={styles.addButton}>
          <SvgPlus /> Add Condition
        </button> */}

        <PrimaryButton
        onClick={handleAddCondition}
         title={"Add"}
         size={"small"}  
          
        
        />
      </div>
    </div>
  );
};

export default ConditionBuilder;
