import React, { useEffect, useState } from "react";
import AddonTypeSelectionBox from "./addonOffersComponents/AddonTypeSelectionBox";
import { PrimaryInput } from "../../../../Components/NewCustomComp/Inputs/Inputs";
import ToggleSwitch from "../../../../Components/NewCustomComp/Switch/ToggleSwitch";
import UploadFileComponent from "../../../../Components/NewCustomComp/UploadFileComponent/UploadFileComponent";
import { PrimaryButton } from "../../../../Components/NewCustomComp/Buttons/Buttons";
import { useSelector } from "react-redux";
import { SelectBasicWithSelectTag } from "../../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import ApplyOn from "../../OffersComponent/ApplyOn";
import ApplicableOn from "../../OffersComponent/ApplicableOn";
import DateRangePicker from "../../../../Components/NewCustomComp/DateRangePicker/DateRangePicker";
import { useNavigate, useParams } from "react-router-dom";
import GoBackButtonCustom from "../../../../Components/GoBackButtonCustom/GoBackButtonCustom";
import { getBaseUrl } from "../../../../utils/utils";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";

const AddonCreateUpdateAndApplyDiscount = () => {
  const baseUrl = getBaseUrl();
  const navigate = useNavigate();
  const { id } = useParams();
  const property_id = useSelector((state) => state.property.value)?.id;

  const [formData, setFormData] = useState({
    price_type: "fixed",
    addon_name: "",
    addon_tax: "",
    addon_description: "",
    addon_type: "",
    is_discount_active: true,
    status: "active",

    // discount
    discount_type: "percentage",
    discount: "",
    start_date: "",
    end_date: "",
    selectedChannels: [],
    selectedRoomCategories: [],
  });

  const [selectedRoomCategories, setSelectedRoomCategories] = useState([]);

  const handleFromDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "addon_type" ) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        price: "",
        adult_price: "",
        child_price: "",
      }));
      return;
    }

    if(name==="price" || name === "addon_tax" || name === "adult_price" || name === "child_price"){
      if(+value<0){
        return
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addonTypeOptions = [
    { label: "select", value: "" },
    {
      label: "Per Person per day per night",
      value: "per_person_per_day_per_night",
    }, //  2
    { label: "Per Person only once", value: "per_person_only_once" }, //  2
    { label: "Per Room per day / night ", value: "per_room_per_day_per_night" },
    { label: "Per Room only once", value: "per_room_only_once" },
  ];

  const handleDiscountToggle = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleStatusToggle = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value ? "active" : "inactive",
    }));
  };

  const handleDateChange = (start, end) => {
    if (start && end) {
      setFormData((prev) => ({
        ...prev,
        start_date: start,
        end_date: end,
      }));
    }
  };

  // update addon

  useEffect(() => {
    if (property_id && id) {
      fetchAddons(property_id, id);
      fetchImage(property_id, id, "addon", "addon_featured_image");
    }
  }, [id, property_id]);

  const fetchAddons = async (property_id, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/addons/offers/${property_id}?id=${id}`,
        requestOptions
      );
      const data = await response.json();
      if (data?.success) {
        // setAddons(data?.data)
        setFormData({
          price_type: data.data.price_type,
          addon_name: data.data.addon_name,
          addon_tax: data.data.addon_tax,
          addon_description: data.data.addon_description,
          is_discount_active: data.data.is_discount_active,
          status: data.data.status,

          ...(data.data.price_type === "fixed" && {
            price: data.data.price,
          }),
          ...(data.data.price_type === "adaptive" &&
            (data.data.addon_type === "per_person_per_day_per_night" ||
              data.data.addon_type === "per_person_only_once") && {
              adult_price: data.data.adult_price,
              child_price: data.data.child_price,
              addon_type: data.data.addon_type,
            }),
          ...(data.data.price_type === "adaptive" &&
            (data.data.addon_type === "per_room_per_day_per_night" ||
              data.data.addon_type === "per_room_only_once") && {
              price: data.data.price,
              addon_type: data.data.addon_type,
            }),

          ...(data.data.is_discount_active === true && {
            discount_type: data.data.discount_type,
            discount: data.data.discount,
            start_date: data.data.start_date,
            end_date: data.data.end_date,

            selectedChannels: data?.data?.applications?.map(
              (item) => item?.applies_to
            ),

            selectedMealPlans: data?.data?.entities.find(
              (item) => item?.entity_type === "meal_plans"
            )?.entity_ids,
            triggers: data?.data?.trigger,
          }),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  function validateFormData(formData) {
    const errors = [];

    // Common validations
    if (!formData.price_type) errors.push("Price type is required.");
    if (!formData.addon_name) errors.push("Addon name is required.");
    if (!formData.status) errors.push("Status is required.");

    // Validation for fixed price type
    if (formData.price_type === "fixed" && !formData.price) {
      errors.push("Price is required for fixed price type.");
    }

    // Validation for adaptive price type
    if (formData.price_type === "adaptive") {
      if (
        (formData.addon_type === "per_person_per_day_per_night" ||
          formData.addon_type === "per_person_only_once") &&
        (!formData.adult_price || !formData.child_price)
      ) {
        errors.push(
          "Adult and child prices are required for selected addon type."
        );
      }
      if (
        (formData.addon_type === "per_room_per_day_per_night" ||
          formData.addon_type === "per_room_only_once") &&
        !formData.price
      ) {
        errors.push("Price is required for the selected addon type.");
      }
      if (!formData.addon_type) {
        errors.push("Addon type is required for adaptive price type.");
      }
    }

    // Validation for discounts
    if (formData.is_discount_active) {
      if (!formData.discount_type) errors.push("Discount type is required.");
      if (!formData.discount) errors.push("Discount value is required.");
      if (!formData.start_date || !formData.end_date) {
        errors.push("Start date and end date are required for discounts.");
      }
      if (
        !formData.selectedChannels ||
        formData.selectedChannels.length === 0
      ) {
        errors.push(
          "At least one channel must be selected for discount application."
        );
      }
    }

    return errors;
  }

  const handleSaveAddon = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const validationErrors = validateFormData(formData);
    if (validationErrors.length > 0) {
      validationErrors.map((item) => showErrorToast(item));
      return;
    }

    const entities = {
      entity_type: "meal_plans",
      entity_ids: selectedRoomCategories.flatMap((room) =>
        room.meal_plans.map((plan) => plan.meal_plan_id)
      ),
    };
    try {
      const raw = JSON.stringify({
        ...(id && { id: id }),
        price_type: formData.price_type,
        addon_name: formData.addon_name,
        addon_tax: formData.addon_tax,
        addon_description: formData.addon_description,
        is_discount_active: formData.is_discount_active ? 1 : 0,
        status: formData.status,

        ...(formData.price_type === "fixed" && {
          price: formData.price,
        }),
        ...(formData.price_type === "adaptive" &&
          (formData.addon_type === "per_person_per_day_per_night" ||
            formData.addon_type === "per_person_only_once") && {
            adult_price: formData.adult_price,
            child_price: formData.child_price,
            addon_type: formData.addon_type,
          }),
        ...(formData.price_type === "adaptive" &&
          (formData.addon_type === "per_room_per_day_per_night" ||
            formData.addon_type === "per_room_only_once") && {
            price: formData.price,
            addon_type: formData.addon_type,
          }),

        ...(formData.is_discount_active === true && {
          discount_type: formData.discount_type,
          discount: formData.discount,
          start_date: formData.start_date,
          end_date: formData.end_date,
          entities: [entities],
          applications: formData.selectedChannels.map((application) => ({
            applies_to: application,
            specific_ids: [""],
          })),
        }),
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/addons/offers/${property_id}`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        showSuccessToast(result.message);
        navigate(-1);
      } else {
        console.error("Failed to save addon:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving addon:", error);
    }
  };

  const handleDiscountTypeChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      discount: "",
    }));

    
  };

  const handleDiscountChange = (e) => {
    const { name, value } = e.target;
    if (formData.discount_type === "percentage" && +value > 100) {
      return;
    }
    if (name === "discount") {
      if(value<0){
        return
      }
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // featured image

  const [image, setImage] = useState("");

  const handleUploadImage = async () => {
    // Create a FormData instance
    const formData = new FormData();
    formData.append("media_type", "addon");
    formData.append("associated_id", id);
    formData.append("addon_featured_image", image);

    try {
      // Configure request options
      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      // Perform the API call
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/addons/offers/media/${property_id}`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        showSuccessToast(result.message); // Show success toast
      } else {
        const errorText = await response.text(); // Get error details
        console.error("Failed to save addon:", errorText);
        showErrorToast("Failed to upload image. Please try again."); // Show error toast
      }
    } catch (error) {
      console.error("Error saving addon:", error.message);
      showErrorToast("An unexpected error occurred. Please try again."); // Show error toast for unexpected errors
    }
  };

  const fetchImage = async (property_id, id, media_type, responseTypeKey) => {
    // Create a FormData instance

    try {
      // Configure request options
      const requestOptions = {
        redirect: "follow",
      };

      // Perform the API call
      const response = await fetch(
        `${baseUrl}/api/v1/booking-engine/packages/media/${property_id}?media_type=${media_type}&associated_id=${id}`,
        requestOptions
      );

      if (response.ok) {
        const result = await response.json();
        setImage(result.media[responseTypeKey].src);
      } else {
        const errorText = await response.text(); // Get error details
        console.error("Failed to save addon:", errorText);
       
      }
    } catch (error) {
      console.error("Error saving addon:", error.message);
      
    }
  };

  const handleFileChange = (data) => {
    setImage(data);
  };

  console.log(formData ,"fromData")
  return (
    <div className="main_container">
      <div className="mb-20">
        <GoBackButtonCustom
          buttonNextText={id ? "Update Addon " : "Create Addon"}
          onClick={() => navigate(-1)}
        />
      </div>

      <div className="flex justify_content_between flex_gap_20">
        <div className="w-50">
          <AddonTypeSelectionBox
            formData={formData}
            setFormData={setFormData}
          />

          <div className="card-3d mt-10">
            <h2 className="card-3d-title">Basic Details</h2>

            <div className="flex flex_gap_10">
              <div className={"w-100"}>
                <PrimaryInput
                  size={"medium"}
                  label={"Add-on Name"}
                  name={"addon_name"}
                  onChange={handleFromDataChange}
                  value={formData.addon_name}
                />
              </div>
            </div>

            <div className="mt-10">
              <PrimaryInput
                size={"medium"}
                label={"Description"}
                name={"addon_description"}
                value={formData.addon_description}
                onChange={handleFromDataChange}
              />
            </div>

            <hr className="mt-20 mb-20" />

            <div className="flex flex_gap_20 w-100">
              {formData.price_type === "fixed" && (
                <>
                  <div className="flex flex_gap_10">
                    <div className="flex flex_gap_5 align_items_center">
                      <label>Price</label>
                      <PrimaryInput
                        size={"medium"}
                        value={formData.price}
                        name={"price"}
                        type={"number"}
                        onChange={handleFromDataChange}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="flex flex_gap_5 align_items_center">
                <label>Tax Rate</label>
                <PrimaryInput
                  size={"medium"}
                  style={{ width: "60px" }}
                  name={"addon_tax"}
                  value={formData.addon_tax}
                  onChange={handleFromDataChange}
                />
              </div>
            </div>
          </div>

          <div className="card-3d mt-10">
            {formData.price_type === "adaptive" && (
              <div>
                <SelectBasicWithSelectTag
                  size={"medium"}
                  value={formData.addon_type}
                  label={"Add-on Type"}
                  options={addonTypeOptions}
                  name={"addon_type"}
                  onChange={handleFromDataChange}
                />
              </div>
            )}

            {formData.price_type === "adaptive" &&
              (formData.addon_type === "per_person_only_once" ||
                formData.addon_type === "per_person_per_day_per_night") && (
                <div className="flex flex_gap_10 mt-10">
                  <div className="w-50">
                    <PrimaryInput
                      label={"Adult Cost"}
                      size={"medium"}
                      name="adult_price"
                      value={formData?.adult_price}
                      onChange={handleFromDataChange}
                      type={"number"}
                    />
                  </div>

                  <div className="w-50">
                    <PrimaryInput
                      label={"Child Cost"}
                      size={"medium"}
                      name="child_price"
                      value={formData?.child_price}
                      onChange={handleFromDataChange}
                      type={"number"}
                    />
                  </div>
                </div>
              )}

            {formData.price_type === "adaptive" &&
              (formData.addon_type === "per_room_per_day_per_night" ||
                formData.addon_type === "per_room_only_once") && (
                <div className="flex flex_gap_10 mt-10">
                  <div className="w-50">
                    <PrimaryInput
                      label={"Price"}
                      size={"medium"}
                      value={formData.price}
                      name={"price"}
                      onChange={handleFromDataChange}
                    />
                  </div>
                </div>
              )}

            {id && (
              <div className="mt-10">
                <label className="global_label">
                  <b>Featured Image</b>
                </label>

                <div className="mt-5">
                  <UploadFileComponent
                    fileType="image"
                    width={"100%"}
                    onFileChange={handleFileChange}
                    defaultFile={image}
                  />
                </div>
                <PrimaryButton
                  title={"Upload"}
                  size="small"
                  onClick={handleUploadImage}
                />
              </div>
            )}
          </div>

          <div className="flex flex_gap_10 mt-20">
            <PrimaryButton
              title={"Save"}
              size="medium"
              onClick={handleSaveAddon}
            />
          </div>
        </div>

        <div className="w-50">
          <div className="card-3d">
            <div className="flex justify_content_between ">
              <h2 className="card-3d-title">Status</h2>
              <ToggleSwitch
                size="large"
                isOn={formData.status === "active" ? true : false}
                handleToggle={handleStatusToggle}
                name={"status"}
              />
            </div>
            <div className="flex justify_content_between mt-10">
              <h2 className="card-3d-title">Discount</h2>
              <ToggleSwitch
                size="large"
                isOn={formData.is_discount_active ? true : false}
                handleToggle={handleDiscountToggle}
                name="is_discount_active"
              />
            </div>
            {formData.is_discount_active && (
              <div>
                <div className="flex flex_gap_10 mt-10">
                  <div className="w-50">
                    <SelectBasicWithSelectTag
                      label={"Discount Type"}
                      size={"medium"}
                      options={[
                        { label: "Percentage", value: "percentage" },
                        { label: "Fixed", value: "fixed" },
                      ]}
                      name={"discount_type"}
                      value={formData.discount_type}
                      onChange={handleDiscountTypeChange}
                    />
                  </div>
                  <div className="w-50">
                    <PrimaryInput
                      value={formData.discount}
                      name={"discount"}
                      label={"Discount"}
                      size={"medium"}
                      onChange={handleDiscountChange}
                      type={"number"}
                    />
                  </div>
                </div>

                <div className="flex flex_gap_10 mt-10">
                  <div className="w-100">
                    <DateRangePicker
                      size={"medium"}
                      label={"Offer Date/Range"}
                      onChange={handleDateChange}
                      minDate={new Date()}
                      defaultDate={{
                        startDate: formData.start_date,
                        endDate: formData.end_date,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {formData.is_discount_active && (
            <div>
              <div className="card-3d mt-10">
                <div className="card-3d-title mb-10"> Apply On </div>
                <ApplyOn
                  formData={formData}
                  setFormData={setFormData}
                  hideOtaOptions={true}
                />
              </div>

              <div className="card-3d mt-10">
              <div className="card-3d-title mb-10"> Applicable On </div>
                <ApplicableOn
                  selectedRoomCategories={selectedRoomCategories}
                  setSelectedRoomCategories={setSelectedRoomCategories}
                  property_id={property_id}
                  selectMealPlansArray={formData.selectedMealPlans}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddonCreateUpdateAndApplyDiscount;