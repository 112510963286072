import React, { useEffect, useState } from "react";
import { PrimaryInput } from "../../../Components/NewCustomComp/Inputs/Inputs";
import { SelectBasicWithSelectTag } from "../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../../utils/encryptStorage";
import { calculateTotalArrayObjectKey, getBaseUrl } from "../../../utils/utils";
import { PrimaryButton } from "../../../Components/NewCustomComp/Buttons/Buttons";
import { SvgCancel } from "../../../assets/svgIcons";
import { showErrorToast, showSuccessToast } from "../../../assets/toastUtils";

const HouseKeepingMinibarAddItemToRoomPopup = ({
  setShow,
  data = [],
  reservationDetails,
  RoomId,
  propertyCurrentDate,
  fetchMiniBarDetails,
  oldItemId
}) => {

    console.log(data ,"data")
  const baseUrl = getBaseUrl();
  const userToken = getDecryptedData("encryptToken");
  const property_id = useSelector((state) => state.property.value)?.id;
  const [items, setItems] = useState([]);

  const [addItem, setAddItem] = useState({
    mini_bar_item_id: "",
    item_name: "",
    quantity: "",
    rate: "",
  });

  const fetchItemListing = async (id) => {
    const url = id
      ? `${baseUrl}/api/v1/house-keeping/mini-bar-item/${property_id}?id=${id}`
      : `${baseUrl}/api/v1/house-keeping/mini-bar-item/${property_id}`;

    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        if (id) {
          setAddItem(data.data);
        } else {
          setItems(data.data);
        }
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchItemListing();
    }
  }, [property_id]);

  const itemsOptions = [
    { label: "select", value: "" },
    ...items.map((item) => ({ label: item.item_name, value: item.id })),
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mini_bar_item_id") {
      setAddItem((prevData) => ({
        ...prevData,
        [name]: value,
        item_name: items.find((item) => +item.id === +value)?.item_name,
        rate: items.find((item) => +item.id === +value)?.rate,
        item_category: items.find((item) => +item.id === +value)?.category,
      }));
    } else {
      setAddItem((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleAddButtonClick = async () => {
    if (!addItem.mini_bar_item_id   || !addItem.quantity) {
      return showErrorToast("Please select item");
    }

    const updatedData = data.map((item) => {
        if (item?.mini_bar_item_id == addItem?.mini_bar_item_id) {
          return {
            ...item,
            quantity: parseInt(item.quantity) + parseInt(addItem.quantity),
          };
        }
        return item;
      });
      const itemsArray = [...updatedData, 
        ...(!data.some((item) => +item.mini_bar_item_id == +addItem.mini_bar_item_id) ? [addItem] : [])
      ];
      
      const subTotal = itemsArray.reduce(
        (acc, item) => acc + parseFloat(item.rate) * parseFloat(item.quantity),
        0
      );
    const tax_rate = 18;
    const tax_amount = (subTotal * tax_rate) / 100;
    const grand_total = subTotal + tax_amount;

    const postData = {
      id: oldItemId,
      room_id: RoomId,
      room_no: reservationDetails.RoomNo,
      booking_id: reservationDetails.BookingID,
      guest_id: reservationDetails.GuestID,
      items: itemsArray,
      sub_total: subTotal,
      total_discount_amount: 0,
      total_tax_amount: tax_amount,
      grand_total: grand_total,
      date: propertyCurrentDate,
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/mini-bar-assign-items/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();

      if (data.success) {
        fetchMiniBarDetails(
          reservationDetails.BookingID,
          RoomId,
          reservationDetails.RoomNo
        );
        
        showSuccessToast(data.message);
      }
    } catch (error) {
    } finally {
        setShow(false);
    }
  };

  console.log(data ,"reservationDetails")
  console.log(addItem ,"reservationDetails")

  return (
    <div className="global_popup_container_background ">
      <div className="global_popup_container">
        <div className="flex justify_content_between align_items_center">
          <h3 className="global_popup_container_heading">Add Item</h3>

          <span onClick={() => setShow(false)} className="pointer">
            <SvgCancel color={"#888888"} />
          </span>
        </div>

        <div className="mt-10">
          <SelectBasicWithSelectTag
            size={"medium"}
            options={itemsOptions}
            value={addItem.mini_bar_item_id}
            name="mini_bar_item_id"
            onChange={handleInputChange}
          />
        </div>
        <div className="flex flex_gap_10 mt-10">
          <div className="w-33">
            <PrimaryInput
              size={"medium"}
              label={"Category"}
              value={addItem.item_category}
              disabled={true}
            />
          </div>
          <div className="w-33">
            <PrimaryInput
              size={"medium"}
              label={"Rate"}
              value={addItem.rate}
              disabled={true}
            />
          </div>

          <div className="w-33">
            <PrimaryInput
              size={"medium"}
              label={"Quantity"}
              value={addItem.quantity}
              onChange={handleInputChange}
              name={"quantity"}
              type={"number"}
            />
          </div>
        </div>

        <div className="mt-10">
          <PrimaryButton
            style={{ width: "100%" }}
            size="medium"
            title="Add"
            onClick={handleAddButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default HouseKeepingMinibarAddItemToRoomPopup;
