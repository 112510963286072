import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import { formatIndianCurrency, sortReportData } from "../../utils/utils";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";

import { calculateTotalWithReduce } from "../../utils/utils";
import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import { SelectBasicWithSelectTag } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";

const ReportByMealPlan = ({ regionReportsData }) => {
  const [sortType, setSortType] = useState("");

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      meal_plan: "Meal Plan Name",
      booking_count: "Total Booking",
      total_sale: "Total Sale",
    },
  ];

  const sortedData = sortReportData(regionReportsData, sortType);
  const rowMarkup =
    regionReportsData &&
    sortedData?.map(
      ({ booking_count, meal_plan, total_sale, state }, index) => {
        return (
          <CustomReportTableRow id={index} key={index} position={meal_plan}>
            <CustomReportTableCell>
              {meal_plan ? meal_plan : "N/A"}
            </CustomReportTableCell>
            <CustomReportTableCell>
              {booking_count ? booking_count : "0"}
            </CustomReportTableCell>
            <CustomReportTableCell>
              {total_sale ? formatIndianCurrency(total_sale) : "0"}
            </CustomReportTableCell>
          </CustomReportTableRow>
        );
      }
    );

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Totals"}</CustomReportTableCell>

      <CustomReportTableCell>
        {calculateTotalWithReduce(regionReportsData, "booking_count")}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(regionReportsData, "total_sale")
        )}
      </CustomReportTableCell>
    </CustomReportTableRow>
  );



  const handleSortChange = (e) => {
    setSortType(e.target.value);
  };

  const sortingOptions = [
    { label: "select", value: "" },
    { label: "booking count(high to low)", value: "booking_count_high_to_low" },
    { label: "booking count(low to high)", value: "booking_count_low_to_high" },
    {
      label: "booking amount(high to low)",
      value: "booking_amount_high_to_low",
    },
    {
      label: "booking amount(low to high)",
      value: "booking_amount_low_to_high",
    },
  ];

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">Reservation By Meal Plans</div>

            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>

          {regionReportsData?.length > 0 && (
            <div className="flex flex_gap_15 align_items_end" id="pdf">
              <div
                className="flex flex_gap_5 align_items_center"
                style={{ width: "150px" }}
              >
                <span
                  className="w-30"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Sort By
                </span>
                <div className="w-70">
                  <SelectBasicWithSelectTag
                    size={"small"}
                    options={sortingOptions}
                    value={sortType}
                    onChange={handleSortChange}
                  />
                </div>
              </div>
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"Reservation By Meal Plans"}
                ignoreFromPrint={"pdf"}
                // pdfStyles={

                // }
              />
              <DownloadCSVButton
                itemToDownloadID={"receipt"}
                downloadFileName={"Reservation By Meal Plans"}
              />
            </div>
          )}
        </div>

        {regionReportsData && regionReportsData.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={regionReportsData.length}
                selectable={false}
                headings={[{ title: "Plan Name" }, { title: "Booking Count" }, {title:"Total Sale(₹)"}]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={["Meal Plans", "Booking Count", "Total Sale"]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "Purchased",
              //   "Purchased",
              //   "Issued",
              //   "Issued",
              //   "Stock",
              //   "Stock",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Reservation by Meal Plan Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is Reservation by Meal Plan Report?",
                  answer: (
                    <p>
                      The <b>Reservation by Meal Plan Report</b> provides an
                      analysis of guest bookings based on different meal plans
                      offered by the hotel. This report categorizes bookings
                      into various meal plan types like CP (Continental Plan),
                      EP (European Plan), MAP (Modified American Plan), and
                      others, detailing the total booking count and associated
                      revenue for each plan.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Reservation by Meal Plan Report?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                          <b>Guest Preferences: </b>Identifies which meal plans
                          guests prefer, helping hotels tailor their services
                          accordingly.
                        </li>
                        <li>
                          <b>Revenue Insights:</b> Shows which meal plans
                          generate the most revenue, aiding in strategic pricing
                          and promotions.
                        </li>
                        <li>
                          <b>Operational Planning:</b> Helps in optimizing
                          kitchen and F&B operations based on plan popularity.
                        </li>
                        <li>
                          <b> Marketing Focus:</b> Enables targeted offers and
                          promotions for popular or underutilized meal plans to
                          boost bookings and guest satisfaction.
                        </li>
                        {/* <li>
                          <b>Strategic Decision-Making: </b>The report informs
                          decisions on marketing strategies, commission
                          structures, and partnerships to enhance business
                          growth.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b> Understanding booking
                          trends through agents helps optimize inventory
                          management and resource allocation.
                        </li> */}
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportByMealPlan;
