import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import Profile from "./profile";
// import Dashboard from "./Dashboard";
import RoomsPage from "./RoomsPage";
import RoomsTypePage from "./RoomsTypePage";
import RoomTypeAddPage from "./RoomTypeAddPage";
import RoomsPlanPage from "./RoomsPlanPage";
import RoomsRatePlanPage from "./RoomsRatePlanPage";
import CompetitorsPage from "./CompetitorsPage";
import CompetitorsManagePage from "./CompetitorsManagePage";
import RoomTypeEditPage from "./RoomTypeEditPage";
import AgentPage from "./AgentPage";
import AgentEditProfilePage from "./AgentEditProfilePage";
import AgentAddProfilePage from "./AgentAddProfilePage";
import SegmentPage from "./SegmentPage";
import DiscountsPage from "./DiscountsPage";
import CreateSegmentPage from "./CreateSegmentPage";
import UpgradePage from "./UpgradePage";
import UpgradePlanPage from "./UpgradePlanPage";
import AddDiscountPage from "./AddDiscountPage";
import BookingPage from "./BookingPage";
import OtasPage from "./OtasPage";
import SuppliersPage from "./SuppliersPage";
import ItemPage from "./ItemPage";
import PurchasePage from "./PurchasePage";
import InventoryPage from "./InventoryPage";
import CompetitiveAnalysisPage from "./CompetitiveAnalysisPage";
import CustomersPage from "./CustomersPage";
import LowInventoryRatesPage from "./LowInventoryRatesPage";
import CreateBookingPage from "./CreateBookingPage";
import AddPropertyPage from "./AddPropertyPage";
import PropertyPage from "./PropertyPage";
import Sidebar from "../Components/Sidebar";
import PreviewBillPage from "./PreviewBillPage";
import SettingsPage from "./Settings/SettingsPage";
// import SettingsPage from "./SettingsPage";
import NoPropertyPage from "./NoPropertyPage";
import { useEffect, useState } from "react";
import ProtectedRoutes from "../Components/Auth/ProtectedRoutes";
import { useSelector } from "react-redux";
import AnalyticsPage from "./AnalyticsPage";
import AgentSinglePage from "./AgentSinglePage";
import Websites from "./Websites/Websites";
import AddPage from "./Websites/AddPage";
import AddBlog from "./Websites/AddBlog";
import WebsitesPages from "./Websites/WebsitesPages";
import WebsitesBlogs from "./Websites/WebsitesBlogs";
import NoInternetConnection from "../Components/CustomComponents/NoInternetConnection";
import PreviewVoucherPage from "./PreviewVoucher/PreviewVoucherPage";
import CustomerSinglePage from "./CustomerSinglePage";
import EditBlog from "./Websites/EditBlog";
import EditPage from "./Websites/EditPage";
import ExpensesPage from "./ExpensePage";
import PmsChannelsPage from "./PmsChannelsPage";
import FbKotListPage from "./FbKotListPage";
import FbMenuPage from "./FbMenuPage";
import FbFoodSalePage from "./FbFoodSalePage";
import RateInventory from "../Components/RateInventory";
import FoodSaleBillPage from "./FoodSaleBillPage";
import PurchaseIssueItemPage from "./PurchaseIssueItemPage";
import KotBillPage from "./KotBillPage";
import StaffPermissions from "../Components/StaffPermissions";
import RateChannelInventory from "../Components/RateChannelInventory";
import EventsPage from "./EventsPageTest";
import BanquetHallPage from "./BanquetHallPage";
import MeetingHallPage from "./MeetingHallPage";
import AddEventPage from "./AddEventPage";
import EditEventPage from "./EditEventPage";
import DashboardPage from "./DashboardPage";
import BanquetFoodMenuPage from "./BanquetFoodMenuPage";
import ReportsFrontsOfficePage from "./ReportsPages/ReportsFrontsOfficePage";
import EventsBillPage from "./EventsBillPage";
import EventVoucherPage from "./EventVoucherPage";
import ReportsProductionPage from "./ReportsPages/ReportsProductionPage";
import FinanceReportsPage from "./ReportsPages/FinanceReportsPage";
import PropertyTargetPage from "./PropertyTargetPage";
import CommunicationPage from "./CommunicationPage/CommunicationPage";
import PreviewCommunicationVoucherPage from "./CommunicationPage/PreviewCommunicationVoucherPage";
import PreviewCommunicationBillPage from "./CommunicationPage/PreviewCommunicationBillPage";
import EventCommunicationVoucherPage from "./CommunicationPage/EventCommunicationVoucherPage";
import EventsCommunicationBillPage from "./CommunicationPage/EventsCommunicationBillPage";
import CompetitorsAnalysisReportsPage from "./ReportsPages/CompetitorsAnalysisReportsPage";
import PaymentReceiptCommunicationPage from "./CommunicationPage/PaymentReceiptCommunicationPage";
import PaymentReceiptEventCommunicationPage from "./CommunicationPage/PaymentReceiptEventCommunicationPage";
import FoodKotList from "../Components/Dashboard/FoodDashboard/FoodKotList";
import HousekeepingStaffPage from "./HousekeepingStaffPage";
import WaiterDashboardCreateTableKot from "../Components/Dashboard/FoodDashboard/WaiterDashboard/WaiterDashboardCreateTableKot";
import WaiterDashboardCreateRoomKot from "../Components/Dashboard/FoodDashboard/WaiterDashboard/WaiterDashboardCreateRoomKot";
import KotBillDashboard from "../Components/KotBillDashboard";
import AgentAccountCrud from "../Components/AgentAccountCrud";
import WebsiteMenus from "./Websites/WebsiteMenus";
import WebsiteSubMenus from "./Websites/WebsiteSubMenus";
import FoodCreateKotManager from "../Components/FoodCreateKotManager";
import NoDataFound from "../Components/CustomComponents/NoDataFound";
import StorePurchasePage from "../Components/Dashboard/StoreDashboard/StorePurchasePage";
import StoreOutofStock from "../Components/Dashboard/StoreDashboard/StoreOutofStock";
import StoreIssuedItemsPage from "../Components/Dashboard/StoreDashboard/StoreIssuedItemsPage";
import PropertyTargetSettingsPage from "./PropertyTargetSettingsPage";
import SettingsContent from "../Components/Settings/SettingsContent";
import ProfileSetting from "../Components/Settings/ProfileSetting";
import LocationSetting from "../Components/Settings/LocationSetting";
import RolesCrudComponent from "../Components/Settings/RolesCrudComponent";
import TaxSettingsCrud from "../Components/Settings/TaxSettingsCrud";
import RolesPermissions from "../Components/Settings/RolesPermissions";
import ActivityLogs from "../Components/Settings/ActivityLogs";
import OtaPriceMatching from "../Components/Settings/OtaPriceMatching";
import RoomBillPage from "./Bills/RoomBill/RoomBillPage";
import AddRoomBillPage from "./Bills/AddRoomBill/AddRoomBillPage";
import BillListingPage from "../Components/Bills/BillListingPage";
import NightAuditPage from "./NightAuditPage";
import EditDiscountsPage from "./EditDiscountPage";
import FolioPage from "./FolioPage";
import FolioSeries from "../Components/Settings/FolioSeries";
import CustomChatBot from "../Components/CustomChatbot/CustomChatBot";
import StayViewPage from "./StayViewPage";
import ReservationView from "../Components/StayView/ReservationView";
import FolioGuestDetailsPage from "./FolioGuestDetailsPage";
import FolioChargesPage from "./FolioChargesPage";
import FolioPaymentsPage from "./FolioPaymentsPage";
import FolioLogsPage from "./FolioLogsPage";
import RoomsViewPage from "./RoomsViewPage";
import ReservationViewPage from "./ReservationViewpage";
import BookingVoidPage from "./BookingVoidPage";
import FolioBillPage from "./FolioBillPage";
import AgentPaymentsPage from "./AgentPaymentsPage";
import LeadPage from "./LeadPage";
import AddPropertyNew from "../Components/Setup/AddPropertyNew";
import ChefDashBoardNewUi from "../Components/Dashboard/ChefDashBoardNewUi/ChefDashBoardNewUi";
import KotViewChefDashBoard from "../Components/Dashboard/ChefDashBoardNewUi/KotViewChefDashBoard";
import TableViewChefDashBoard from "../Components/Dashboard/ChefDashBoardNewUi/TableViewChefDashBoard";
import RoomViewChefDashBoard from "../Components/Dashboard/ChefDashBoardNewUi/RoomViewChefDashBoard";
import TableKoT from "../Components/Dashboard/FoodDashboard/FandB-KoT/TableKoT";
import CreateBookingPageNew from "./CreateBookingPageNew";
import CreateKotNew from "./CreateKotNew";
import PaymentGateways from "../Components/Settings/PaymentGateways";
import PrivacyPolicies from "../Components/Settings/PrivacyPolicies";
import PropertyDomains from "../Components/Settings/PropertyDomains";
import KotRoomTableView from "../Components/Dashboard/ChefDashBoardNewUi/KotRoomTableView";
import ProfileSettingsNew from "../Components/Settings/ProfileSettingsNew";
import BrandSettings from "../Components/Settings/BrandSettings";
import DistanceSettings from "../Components/Settings/DistanceSettings";
import WebsiteMenusLocations from "./Websites/WebsiteMenusLocations";
import FolioAuditLogs from "../Components/Reports/FolioAuditLogs";
import NightAuditLogsPage from "./NightAuditLogsPage";
import CreateBookingPageNewEdit from "./CreateBookingPageNewEdit";
import AddCompanyCredits from "../Components/UI/AddCompanyCredits";
import AddAdvancePayments from "../Components/UI/AddAdvancePayments";
import AgentBookings from "../Components/UI/AgentBookings";
import AdOnsSetting from "../Components/Settings/AdOnsSetting";
import ModifyReservation from "../Pages/ModifyReservation/ModifyReservation";

import RoomViewNewUi from "./RoomViewNewUi/RoomViewNewUI";

import AgentCreditFinanceLogs from "../Components/UI/AgentCreditFinanceLogs";
import AgentCityLedgerReport from "../Components/UI/AgentCityLedgerReport";
import CreateMessagePage from "./CreateMessagePage";
import SentMessagePage from "./SentMessagePage";
import CommunicationCreateNewPage from "../Components/CustomComponents/CommunicationCreateMessage/CommunicationCreateNewPage";
import CreateBookingNew from "../Components/CreateBookingNew";
import StoreRequestedItemsPage from "./Store/StoreRequestedItemsPage";
import AddPurchases from "./AddPurchase";
import EditPurchase from "./EditPurchase";
import IssueItemsPage from "./IssueItemsPage";
import CommunicationUpdatePage from "../Components/CustomComponents/CommunicationCreateMessage/CommunicationUpdateMessage";
import PurchasedIssueItemList from "../Components/PurchasedIssueItemList";
import AgentCreateBooking from "../Components/CustomComponents/AgentCreateBooking/AgentCreateBooking";
import RequestItemListPage from "./RequestItemListPage";
import RequestItemsAddPage from "../Pages/RequestItemsAddPage";
import PurchasedRequestedItemList from "../Components/PurchasedRequestedItemList";
import AddIssueRequestedItems from "./AddIssueRequestedItems";
import StockStatusPageNew from "./StockStatusPageNew";
import ReportsStorePurchasesPages from "./ReportsPages/ReportsStorePurchasesPages";
import CustomCheckTable from "../Components/CustomComponents/customCheckTable/customCheckTable";
import HoldRoomPlans from "./HoldRoomPlans";
import HoldRoomAddPage from "./HoldRoomAddPage";
import CustomComponents from "./NewDesignSystem";
import NewDesignSystem from "./NewDesignSystem";
import HoldRoomsCreateAgentPage from "./HoldRoomsCreateAgentPage";
import AgentHoldRoomsCreateBooking from "../Components/CustomComponents/AgentHoldRooms/agentCreateBooking/AgentHoldRoomsCreateBooking";
import HoldRoomsCreateContractPage from "./HoldRoomsCreateContractPage";
import AgentContractsCreateBooking from "../Components/CustomComponents/AgentCreateBooking/AgentContractsCreateBooking";
import AgentAdvancePaymentPage from "./AgentAdvancePayment/AgentAdvancePaymentPage";
import AdvancePayments from "./AgentAdvancePayment/outlets/AdvancePayments";
import UserCredits from "./AgentAdvancePayment/outlets/UserCredits";
import AdvancePaymentsReport from "./AgentAdvancePayment/outlets/AdvancePaymentReport";
import BookingEngineInventoryPage from "../Components/BookingEngine/BookingEngineInventoryPage";
import SelectAppOptions from "../Components/BookingEngine/SelectAppOptions";
import RoomPlansCommon from "../Components/RoomPlansCommon";
import { useNavigate } from "react-router-dom";
import InventoryRatesPage from "./InventoryRatesPage";
import RateInventoryBulkUpdate from "../Components/RateInventpryBulkUpdate";
import ChannelManagerDashboard from "../Components/Dashboard/channelManagerDashboard/ChannelManagerDashboard";
import ChannelManagerSettingsPage from "./ChannelManagerSettingsPage";
import ChannelListing from "../Components/ChannelManagerSettings/ChannelListing";
import WebsitesDiscounts from "./Websites/WebsitesDiscounts";
import WebsiteOffers from "./Websites/WebsiteOffers";
import PropertyTargetsPage from "./PropertyTargetsPage";
import PropertyTargets from "../Components/PropertyTargets/PropertyTargets";
import CompetitorsTargets from "../Components/PropertyTargets/CompetitorsTargets";
import FlexiPaySetting from "../Components/Settings/FlexiPaySetting";
import WebsitesCreateEditOffers from "./Websites/WebsitesCreateEditOffers";
import WebsitePageNavigation from "./Websites/WebsitePageNavigation";
import FAndBReports from "../Components/Reports/FAndBReports";
import FAndBReportsPage from "./ReportsPages/FAndBReportsPage";
import Customers from "../Components/Customers";
import SingleAgentDetails from "../Components/Agents/SingleAgentDetails";
import HotelierKot from "./HotelierKot";
import BillingTemplates from "../Components/Settings/BillingTemplates";
import OffersPromotionsPackages from "./OffersPromotionsPackages/OffersPromotionsPackages";
import PromotionsDiscounts from "./OffersPromotionsPackages/PromotionsDiscounts";
import CreatePromotionDiscounts from "./OffersPromotionsPackages/CreatePromotionaDiscounts";
import AgentsCustomersBulkRateUpdate from "../Components/AgentsCustomesCategories/AgentsCustomersBulkRateUpdate";
import PromotionsAddonsPage from "./OffersPromotionsPackages/PromotionsAddons/PromotionsAddonsPage";
import PromotionsPackagePage from "./OffersPromotionsPackages/PromotionsPackages/PromotionsPackagePage";
import CreatePackageDiscount from "./OffersPromotionsPackages/PromotionsPackages/CreatePackageDiscount";
import ChannelManagerSettings from "../Components/Settings/ChannelManagerSettings";
import AddonCreateUpdateAndApplyDiscount from "./OffersPromotionsPackages/PromotionsAddons/BookingEngineAddons/AddonCreateUpdateAndApplyDiscount";
import HousekeepingServicesPage from "./HousekeepingPages/HousekeepingServices/HousekeepingServicesPage";
import HousekeepingLaundry from "./HousekeepingPages/HousekeepingServices/HousekeepingLaundry/HousekeepingLaundry";
import HousekeepingLostFound from "./HousekeepingPages/HousekeepingServices/housekeepingLostAndFound/HousekeepingLostFound";
import HousekeepingMiniBar from "./HousekeepingPages/HousekeepingServices/HousekeepingMiniBar";
import HousekeepingLaundryCreateUpdate from "./HousekeepingPages/HousekeepingServices/HousekeepingLaundry/HousekeepingLaundryCreateUpdate";
import HousekeepingLaundryRates from "./HousekeepingPages/HousekeepingServices/HousekeepingLaundry/HousekeepingLaundryRates";
import CreateLostFoundService from "./HousekeepingPages/HousekeepingServices/housekeepingLostAndFound/CreateLostFoundService";
import HousekeepingRoomView from "./HousekeepingPages/HousekeepingRoomView/HousekeepingRoomView";
import MiniBarItems from "./HousekeepingPages/HousekeepingServices/MiniBarItems";
import HousekeepingStocksPage from "./HousekeepingPages/HousekeepingStocks/HousekeepingStocksPage";

const Layout = () => {
  const navigate = useNavigate();
  const authenticated =
    useSelector((state) => state.isAuthenticated.authenticated) ||
    localStorage.getItem("authenticated");

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const checkOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };
    const intervalId = setInterval(checkOnlineStatus, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const activeApp =
    useSelector((state) => state.activeApp.activeApp) ||
    localStorage.getItem("activeApp");

  useEffect(() => {
    if (!activeApp) {
      navigate("/");
    }
  }, [activeApp, navigate]);

  const location = useLocation();

  useEffect(() => {
    const app = localStorage.getItem("activeApp");
    const location = window.location;

    if (app === "booking_engine" && location.pathname === "/") {
      navigate("/booking-engine/bookings");
    } else if (app === "channel_manager" && location.pathname === "/") {
      navigate("/channel-manager");
    } else if (app === "pms") {
      // navigate("/");
    }
  }, []);

  return (
    <>
      <Navbar />
      <div>
        {isOnline ? (
          <>
            {!activeApp && authenticated && (
              <Routes>
                <Route path="/" element={<SelectAppOptions />} />
              </Routes>
            )}

            {activeApp === "pms" && (
              <>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/nopropertypage"
                      element={<NoPropertyPage />}
                    />
                    <Route path={"/profile"} element={<Profile />} />
                    <Route path={"/dashboard"} element={<DashboardPage />} />
                    {/* Waiter */}
                    <Route
                      path={"/table/create-kot"}
                      element={<WaiterDashboardCreateTableKot />}
                    />
                    <Route path={"/bookings"} element={<BookingPage />} />
                    <Route
                      path={"/room/create-kot"}
                      element={<WaiterDashboardCreateRoomKot />}
                    />
                    <Route
                      path={"/reports"}
                      element={<ReportsFrontsOfficePage />}
                    />
                    <Route
                      path={"/reports/store-and-purchases"}
                      element={<ReportsStorePurchasesPages />}
                    />
                    <Route
                      path={"/reports/production"}
                      element={<ReportsProductionPage />}
                    />
                    <Route
                      path={"/reports/finance"}
                      element={<FinanceReportsPage />}
                    />{" "}
                    <Route
                      path={"/reports/f&b"}
                      element={<FAndBReportsPage />}
                    />
                    <Route
                      path={`/agents/credits/:agentid`}
                      element={<AddCompanyCredits />}
                    />
                    <Route
                      path={`/agents/advance/payments/:agentid`}
                      element={<AddAdvancePayments />}
                    />
                    <Route
                      path={`/agents/bookings/:agentid`}
                      element={<AgentBookings />}
                    />
                    <Route
                      path={`/agents/credit/Logs/:agentid`}
                      element={<AgentCreditFinanceLogs />}
                    />
                    <Route
                      path={`/agents/city/Ledger/:agentid`}
                      element={<AgentCityLedgerReport />}
                    />
                    <Route
                      path={"/reports/competitors-analysis"}
                      element={<CompetitorsAnalysisReportsPage />}
                    />
                    <Route path={"/"} element={<DashboardPage />} />
                    <Route path={"/rooms"} element={<RoomsPage />} />
                    <Route path={"/room-view"} element={<RoomViewNewUi />} />
                    <Route
                      path={"/room-view-new"}
                      element={<RoomViewNewUi />}
                    />
                    <Route path={"/channels"} element={<PmsChannelsPage />} />
                    <Route path="/roomstype" element={<RoomsTypePage />} />
                    <Route
                      path="/propertytarget"
                      element={<PropertyTargetPage />}
                    />
                    <Route
                      path="/propertytarget/settings"
                      element={<PropertyTargetSettingsPage />}
                    />
                    <Route
                      path="/roomstype/edit/:roomtypeid"
                      element={<RoomTypeEditPage />}
                    />
                    <Route
                      path="/roomstype/add"
                      element={<RoomTypeAddPage />}
                    />
                    <Route path="/holdroom/add" element={<HoldRoomAddPage />} />
                    <Route path={"/roomsplans"} element={<RoomsPlanPage />} />
                    <Route
                      path={"/roomsrateplans"}
                      element={<RoomsRatePlanPage />}
                    />
                    <Route
                      path={"/competitors"}
                      element={<CompetitorsPage />}
                    />
                    <Route
                      path={"/competitors/manage"}
                      element={<CompetitorsManagePage />}
                    />
                    <Route
                      path={"/agents/finance"}
                      element={<AgentAccountCrud />}
                    />
                    {/* =========== customers routes start =============*/}
                    {/* customers nested routes  */}
                    <Route path="" element={<CustomersPage />}>
                      <Route path={"customers"} element={<Customers />} />
                      <Route
                        path={`customers/rates-update/:categoryId`}
                        element={<AgentsCustomersBulkRateUpdate />}
                      />
                      <Route
                        path={`agents/rates-update/:categoryId`}
                        element={<AgentsCustomersBulkRateUpdate />}
                      />

                      <Route path={"agents"} element={<AgentPage />} />
                      <Route
                        path={"agent-hold-rooms"}
                        element={<HoldRoomPlans />}
                      />
                      <Route
                        path="agent-contracts"
                        element={<HoldRoomsCreateAgentPage />}
                      />
                    </Route>
                    {/* customers nested routes end */}
                    <Route
                      path="agent/details/:ID"
                      element={<SingleAgentDetails />}
                    />
                    <Route
                      path={"/agent-hold-rooms/create-booking/:ID"}
                      element={<AgentHoldRoomsCreateBooking />}
                    />
                    <Route
                      path={"/agent-contracts/create-booking/:ID"}
                      element={<AgentContractsCreateBooking />}
                    />
                    <Route
                      path="/agent-contracts/create"
                      element={<HoldRoomsCreateContractPage />}
                    />
                    <Route
                      path={"/singlecustomer/:customerid"}
                      element={<CustomerSinglePage />}
                    />
                    <Route
                      path={"/credit/:agentid"}
                      element={<AgentEditProfilePage />}
                    />
                    <Route
                      path={"/transfer/:agentid"}
                      element={<AgentPaymentsPage />}
                    />
                    <Route path={"/discounts"} element={<DiscountsPage />} />
                    <Route
                      path={"/agent/addprofile"}
                      element={<AgentAddProfilePage />}
                    />
                    <Route
                      path={"/agent/editprofile/:agentid"}
                      element={<AgentEditProfilePage />}
                    />
                    <Route
                      path={"/singleagent/:agentid"}
                      element={<AgentSinglePage />}
                    />
                    <Route path={"/segments"} element={<SegmentPage />} />
                    <Route
                      path={"/segments/createsegment"}
                      element={<CreateSegmentPage />}
                    />
                    <Route path={"/discounts"} element={<DiscountsPage />} />
                    <Route
                      path="/discounts/edit/:discountid"
                      element={<EditDiscountsPage />}
                    />
                    <Route
                      path={"/discounts/adddiscounts"}
                      element={<AddDiscountPage />}
                    />
                    <Route
                      path="/discounts/edit/:discountid"
                      element={<EditDiscountsPage />}
                    />
                    <Route path={"/upgrade"} element={<UpgradePage />} />
                    <Route
                      path={"/upgrade/plans"}
                      element={<UpgradePlanPage />}
                    />
                    <Route path={"/inventory"} element={<InventoryPage />} />
                    {/* agent/create-booking/9 */}
                    <Route
                      path={"/agent/create-booking/:ID"}
                      element={<AgentCreateBooking />}
                    />
                    <Route
                      path={"/rateinventory"}
                      element={<RateInventory />}
                    />
                    <Route path={"/channels"} element={<PmsChannelsPage />} />
                    <Route
                      path={"/ratechannelinventory"}
                      element={<RateChannelInventory />}
                    />
                    <Route
                      path={"/lowinventory"}
                      element={<LowInventoryRatesPage />}
                    />
                    <Route path={"/bookings"} element={<BookingPage />} />
                    <Route
                      path={"/bookings/createbooking"}
                      element={<CreateBookingPageNew />}
                      // element={<CreateBookingNew />}
                    />
                    <Route
                      path={"/bookings/createbooking/new"}
                      element={<CreateBookingPageNew />}
                    />
                    <Route
                      path={"/bookings/createbooking/edit/:bookingID"}
                      element={<CreateBookingPageNewEdit />}
                    />
                    <Route
                      path={"/modifyReservation"}
                      element={<ModifyReservation />}
                    />
                    <Route
                      path={`/bookings/previewbill/:uniquebookingid`}
                      element={<PreviewBillPage />}
                    />
                    <Route
                      path={`/bookings/communication/previewbill/:uniquebookingid`}
                      element={<PreviewCommunicationBillPage />}
                    />
                    <Route
                      path={`/bookings/communication/paymentreceipt/:uniquebookingid`}
                      element={<PaymentReceiptCommunicationPage />}
                    />
                    <Route
                      path={`/bookings/communication/event/paymentreceipt/:uniquebookingid`}
                      element={<PaymentReceiptEventCommunicationPage />}
                    />
                    <Route
                      path={`/bookings/voucher/:uniquebookingid`}
                      element={<PreviewVoucherPage />}
                    />
                    <Route
                      path={`/bookings/communication/voucher/:uniquebookingid`}
                      element={<PreviewCommunicationVoucherPage />}
                    />
                    <Route
                      path={`/event/voucher/:eventid`}
                      element={<EventVoucherPage />}
                    />
                    <Route
                      path={`/event/communication/voucher/:eventid`}
                      element={<EventCommunicationVoucherPage />}
                    />
                    <Route path={"/analytics"} element={<AnalyticsPage />} />
                    <Route path="/property" element={<PropertyPage />} />
                    <Route
                      path={"/property/addproperty"}
                      element={<AddPropertyPage />}
                    />
                    <Route path={"/hotelier/otas"} element={<OtasPage />} />
                    <Route
                      path={"/hotelier/suppliers"}
                      element={<SuppliersPage />}
                    />
                    <Route path={"/hotelier/items"} element={<ItemPage />} />
                    <Route
                      path={"/hotelier/purchase"}
                      element={<PurchasePage />}
                    />
                    <Route
                      path={"/hotelier/expenses"}
                      element={<ExpensesPage />}
                    />
                    <Route
                      path={"competitiveanalysis"}
                      element={<CompetitiveAnalysisPage />}
                    />
                    
                    {/* edit pages from websites out of scope */}
                    <Route
                      path="websites/submenus/:id"
                      element={<WebsiteSubMenus />}
                    />
                    <Route
                      path="/websites/offers/edit/:offerID"
                      element={<WebsitesCreateEditOffers />}
                    />
                    <Route
                      path="/websites/offers/create/"
                      element={<WebsitesCreateEditOffers />}
                    />
                    {/* end edit pages from websites out of scope */}
                    <Route
                      path={"/staffpermissions/:staffid"}
                      element={<StaffPermissions />}
                    />
                    {/* Housekeeping Routes */}
                    <Route
                      path={"/housekeeping/staff"}
                      element={<HousekeepingStaffPage />}
                    />
                    <Route path="/housekeeping/room-view" element={<HousekeepingRoomView />} />
                    <Route path="/housekeeping/stocks" element={<HousekeepingStocksPage />} />
                   
                    <Route
                      path="/housekeeping/services"
                      element={<HousekeepingServicesPage />}
                    >
                      <Route path="laundry" element={<HousekeepingLaundry />} />
                      <Route
                        path="lost-found"
                        element={<HousekeepingLostFound />}
                      />
                      <Route
                        path="mini-bar"
                        element={<HousekeepingMiniBar />}
                      />
                      <Route
                        path="mini-bar/items"
                        element={<MiniBarItems/>}
                      />
                    </Route>
                    <Route
                      path="/housekeeping/services/lost-found/create"
                      element={<CreateLostFoundService />}
                    />
                    <Route
                      path="/housekeeping/services/lost-found/edit/:id"
                      element={<CreateLostFoundService />}
                    />
                    <Route
                      path="/housekeeping/services/laundry/create"
                      element={<HousekeepingLaundryCreateUpdate />}
                    />
                    <Route
                      path="/housekeeping/services/laundry/view/:details"
                      element={<HousekeepingLaundryCreateUpdate />}
                    />
                    <Route
                      path="/housekeeping/services/laundry/edit/:id"
                      element={<HousekeepingLaundryCreateUpdate />}
                    />
                    <Route
                      path="/housekeeping/services/laundry/rates"
                      element={<HousekeepingLaundryRates />}
                    />
                    {/* Housekeeping Routes End */}
                    <Route
                      path={"/websites/pages/editpage/:pageid"}
                      element={<EditPage />}
                    />
                    {/* <Route
                      path={"/hotelier/kotlisting"}
                      element={<FbKotListPage />}
                    /> */}
                    {/* <Route
                      path={"/kot/create-kot-manager"}
                      element={<CreateKotNew />}
                    /> */}
                    {/* <Route path={"/hotelier/menus"} element={<FbMenuPage />} /> */}
                    <Route
                      path={"/hotelier/foodsale"}
                      element={<FbFoodSalePage />}
                    />
                    <Route
                      path={"/hotelier/purchase/issueitems"}
                      element={<PurchaseIssueItemPage />}
                    />
                    <Route
                      path={"/hotelier/requests"}
                      element={<RequestItemListPage />}
                    />
                    <Route
                      path={"/hotelier/stock/status"}
                      element={<StockStatusPageNew />}
                    />
                    {/* Bills sections */}
                    <Route
                      path={"/bill/listing"}
                      // path={"/bill/room/:roomid"}
                      element={<BillListingPage />}
                    />
                    <Route
                      path={"/bill/room"}
                      // path={"/bill/room/:roomid"}
                      element={<RoomBillPage />}
                    />
                    <Route
                      path={"/add/bill/room/:id"}
                      // path={"/bill/room/:roomid"}
                      element={<AddRoomBillPage />}
                    />
                    <Route
                      path={"/hotelier/foodsale/bill/:foodsaleid"}
                      element={<FoodSaleBillPage />}
                    />
                    <Route path={"/foodkotlisting"} element={<FoodKotList />} />
                    <Route
                      path={"/hotelier/kotlisting/bill/:kotid"}
                      element={<KotBillPage />}
                    />
                    <Route
                      path={"/kot/bill/:kotid"}
                      element={<KotBillDashboard />}
                    />
                    <Route
                      path={"/event/bill/:eventid"}
                      element={<EventsBillPage />}
                    />
                    <Route
                      path={"/event/communication/bill/:eventid"}
                      element={<EventsCommunicationBillPage />}
                    />
                    {/* Banquet */}
                    <Route path={"/events"} element={<EventsPage />} />
                    <Route path={"/events/add"} element={<AddEventPage />} />
                    <Route
                      path={"/events/edit/:eventId"}
                      element={<EditEventPage />}
                    />
                    <Route
                      path={"/banquethall"}
                      element={<BanquetHallPage />}
                    />
                    <Route
                      path={"/banquetmenus"}
                      element={<BanquetFoodMenuPage />}
                    />
                    <Route
                      path={"/meetinghall"}
                      element={<MeetingHallPage />}
                    />
                    {/* COMMUNICATION */}
                    <Route
                      path={"/communication"}
                      element={<CommunicationPage />}
                    />
                    {/* testing */}
                    <Route
                      path={"/custom/table"}
                      element={<CustomCheckTable />}
                    />
                    <Route
                      path={"/store/purchase"}
                      element={<StorePurchasePage />}
                    />
                    <Route
                      path={"/hotelier/purchase/add"}
                      element={<AddPurchases />}
                    />
                    <Route
                      path={"/hotelier/issueditems/add"}
                      element={<IssueItemsPage />}
                    />
                    <Route
                      path={"/hotelier/issue/requested/items/:requestId"}
                      element={<AddIssueRequestedItems />}
                    />
                    <Route
                      path={"/hotelier/requestItems/add"}
                      element={<RequestItemsAddPage />}
                    />
                    <Route
                      path={"/hotelier/purchase/edit/:id"}
                      element={<EditPurchase />}
                    />
                    <Route
                      path={"/hotelier/issued/items/:id"}
                      element={<PurchasedIssueItemList />}
                    />
                    <Route
                      path={"/hotelier/requested/items/:id"}
                      element={<PurchasedRequestedItemList />}
                    />
                    <Route
                      path={"/store/requested-items"}
                      element={<StoreRequestedItemsPage />}
                    />
                    <Route
                      path={"/store/out-of-stock"}
                      element={<StoreOutofStock />}
                    />
                    <Route
                      path={"/store/issueditems"}
                      element={<StoreIssuedItemsPage />}
                    />
                    {/* Night Audit */}
                    <Route path={"/night/audit"} element={<NightAuditPage />} />
                  </Route>

                  {/* settings */}

                  <Route path="/settings" element={<SettingsPage />}>
                    <Route
                      path="/settings/profile"
                      element={<ProfileSettingsNew />}
                    />

                    <Route
                      path="/settings/profile/new"
                      element={<ProfileSettingsNew />}
                    />
                    <Route path="/settings/brand" element={<BrandSettings />} />
                    <Route
                      path="/settings/distance"
                      element={<DistanceSettings />}
                    />

                    <Route
                      path="/settings/location"
                      element={<LocationSetting />}
                    />
                    <Route path="/settings/tax" element={<TaxSettingsCrud />} />
                    <Route
                      path="/settings/folio-gateways"
                      element={<PaymentGateways />}
                    />
                    <Route
                      path="/settings/property-domain"
                      element={<PropertyDomains />}
                    />
                    <Route
                      path="/settings/roles-and-permission"
                      element={<RolesCrudComponent />}
                    />
                    <Route
                      path="/settings/staffs"
                      element={<RolesPermissions />}
                    />
                    <Route
                      path="/settings/activity-logs"
                      element={<ActivityLogs />}
                    />
                    <Route
                      path="/settings/ota-mapping"
                      element={<OtaPriceMatching />}
                    />
                    <Route
                      path="/settings/folio-series"
                      element={<FolioSeries />}
                    />
                    <Route
                      path="/settings/privacy-policy"
                      element={<PrivacyPolicies />}
                    />
                    <Route
                      path="/settings/add-ons"
                      element={<AdOnsSetting />}
                    />

                    <Route
                      path="/settings/flexi-pay"
                      element={<FlexiPaySetting />}
                    />
                    <Route
                      path="billing-templates"
                      element={<BillingTemplates />}
                    />
                    <Route
                      path="channel-manager"
                      element={<ChannelManagerSettings />}
                    />
                  </Route>

                  {/* ======  settings end ===== */}


                  {/* ==== nested routes for websites ==== */}
                  <Route path="/websites" element={<WebsitePageNavigation />}>
                      {/* Nested Routes under /websites */}
                      <Route path="pages" element={<WebsitesPages />} />
                      {/* <Route path="discounts" element={<WebsitesDiscounts />} /> */}
                      <Route
                      path="promotions"
                      element={<PromotionsDiscounts />}
                    />
                    <Route
                      path="packages"
                      element={<PromotionsPackagePage />}
                    />
                    <Route path="add-ons" element={<PromotionsAddonsPage />} />
                      <Route path="offers" element={<WebsiteOffers />} />
                      <Route
                        path="offers/create"
                        element={<WebsitesCreateEditOffers />}
                      />

                      <Route path="blogs" element={<WebsitesBlogs />} />
                      <Route
                        path="menuLocation"
                        element={<WebsiteMenusLocations />}
                      />
                      <Route path="menus" element={<WebsiteMenus />} />

                      <Route path="addpage" element={<AddPage />} />
                    </Route>
                    <Route
                      path="/websites/blogs/editblog/:blogid"
                      element={<EditBlog />}
                    />
                    <Route path="/websites/addblog" element={<AddBlog />} />
                    {/* ==== nested routes for websites end ==== */}

                  {/* ====== offers ,packages and add-on offers (create and edit) */}
                  <Route
                    path="/promotions/add-ons/create"
                    element={<AddonCreateUpdateAndApplyDiscount />}
                  />
                  <Route
                    path="/promotions/add-ons/edit/:id"
                    element={<AddonCreateUpdateAndApplyDiscount />}
                  />

                  <Route
                    path="/promotions/packages/create"
                    element={<CreatePackageDiscount />}
                  />
                  <Route
                    path="/promotions/packages/edit/:id"
                    element={<CreatePackageDiscount />}
                  />
                  <Route
                    path="/promotions/discounts/create-promotion"
                    element={<CreatePromotionDiscounts />}
                  />
                  <Route
                    path="/promotions/discounts/edit-promotion/:id"
                    element={<CreatePromotionDiscounts />}
                  />

                  {/* ====== offers ,packages and add-on offers */}

                  <Route
                    path="/"
                    element={
                      authenticated ? <DashboardPage /> : <DashboardPage />
                    }
                  />

                  <Route
                    path="/bookings/folio/:folioId/:folioUniqueId?"
                    element={<FolioGuestDetailsPage />}
                  />
                  <Route
                    path="/bookings/guest-details/:folioId/:folioUniqueId?"
                    element={<FolioGuestDetailsPage />}
                  />
                  <Route
                    path="/bookings/charges/:folioId/:folioUniqueId?"
                    element={<FolioChargesPage />}
                  />
                  <Route
                    path="/bookings/payment/:folioId/:folioUniqueId?"
                    element={<FolioPaymentsPage />}
                  />
                  <Route
                    path="/bookings/folio-logs/:folioId/:folioUniqueId?"
                    element={<FolioLogsPage />}
                  />
                  <Route
                    path="/bookings/folio-bills/:folioId/:folioUniqueId?"
                    element={<FolioBillPage />}
                  />
                  <Route path="/void/bookings" element={<BookingVoidPage />} />

                  <Route path="/stay-view" element={<StayViewPage />} />
                  <Route
                    path="/reservation-view"
                    element={<ReservationViewPage />}
                  />

                  {/* LEADS */}
                  <Route path="/leads" element={<LeadPage />} />
                  <Route path="/communication" element={<BookingPage />} />
                  <Route
                    path="/leads/createmessage/:leadId"
                    element={<CreateMessagePage />}
                  />
                  <Route
                    path="/leads/sentmessage/:messageId"
                    element={<SentMessagePage />}
                  />
                  {/* <Route
                    path="/communication/createmessage"
                    element={<CommunicationCreateNewPage />}
                  /> */}

                  <Route
                    path="/leads/createmessage/:leadId"
                    element={<CreateMessagePage />}
                  />
                  <Route
                    path="/leads/sentmessage/:messageId"
                    element={<SentMessagePage />}
                  />
                  <Route
                    path="/communication/createmessage"
                    element={<CommunicationCreateNewPage />}
                  />
                  <Route
                    path="/leads/communicationUpdatemessage/:comId"
                    element={<CommunicationUpdatePage />}
                  />
                  <Route
                    path="/leads/createmessage/:leadId"
                    element={<CreateMessagePage />}
                  />
                  <Route
                    path="/leads/sentmessage/:messageId"
                    element={<SentMessagePage />}
                  />
                  <Route
                    path="/communication/createmessage"
                    element={<CommunicationCreateNewPage />}
                  />
                  <Route
                    path="/leads/communicationUpdatemessage/:comId/:bookingId?"
                    element={<CommunicationUpdatePage />}
                  />
                  <Route path="*" element={<NoDataFound />} />

                  {/* ChefDashboard */}
                  <Route
                    path="/chef-dashboard"
                    element={<ChefDashBoardNewUi />}
                  />
                  <Route
                    path="/chef-dashboard/kot-view"
                    element={<KotViewChefDashBoard />}
                  />
                  <Route
                    path="/chef-dashboard/table-view"
                    element={<TableViewChefDashBoard />}
                  />
                  <Route
                    path="/kot-room-table-view"
                    element={<KotRoomTableView />}
                  />
                  <Route
                    path="/chef-dashboard/room-view"
                    element={<RoomViewChefDashBoard />}
                  />

                  {/* folio audit logs */}
                  <Route
                    path="/night/audit/logs"
                    element={<NightAuditLogsPage />}
                  />
                  {/* .............Meal Plan........... */}

                  <Route
                    path="/custom-components"
                    element={<NewDesignSystem />}
                  />
                  {/* <Route path="/meal-plan/:filter" element={<MealPlanNewUi />} /> */}

                  {/* agent advance payments route group */}

                  <Route
                    path="/agents/:ID/"
                    element={<AgentAdvancePaymentPage />}
                  >
                    <Route
                      index
                      path="advance-payments"
                      element={<AdvancePayments />}
                    />
                    <Route path="used-advance" element={<UserCredits />} />
                    <Route
                      path="advance-payments-report"
                      element={<AdvancePaymentsReport />}
                    />
                  </Route>
                  {/* end agent advance payments */}

                  {/* Property Targets */}
                  <Route path="" element={<PropertyTargetsPage />}>
                    <Route
                      path="property-targets"
                      element={<PropertyTargets />}
                    />
                    <Route
                      path="competitors-targets"
                      element={<CompetitorsTargets />}
                    />
                  </Route>
                  {/* Kot / restaurant */}
                  <Route path="hotelier" element={<HotelierKot />}>
                    <Route path={"kotlisting"} element={<FbKotListPage />} />
                    <Route
                      path={"create-kot-manager"}
                      element={<CreateKotNew />}
                    />
                    <Route
                      path="kot-room-table-view"
                      element={<KotRoomTableView />}
                    />
                    <Route path={"menus"} element={<FbMenuPage />} />
                  </Route>
                </Routes>
              </>
            )}

            {activeApp === "booking_engine" && (
              <>
                <Routes>
                  <Route path="*" element={<NoDataFound />} />
                  <Route path={"/booking-engine"} element={<ProtectedRoutes />}>
                    <Route path="nopropertypage" element={<NoPropertyPage />} />
                    <Route index element={<BookingPage />} />
                    <Route
                      path={"inventory/bulk-update"}
                      element={<RateInventoryBulkUpdate />}
                    />
                    <Route
                      path={"inventory/low-inventory"}
                      element={<LowInventoryRatesPage />}
                    />
                    <Route path={"bookings"} element={<BookingPage />} />

                    <Route
                      path={`bookings/previewbill/:uniquebookingid`}
                      element={<PreviewBillPage />}
                    />

                    <Route
                      path={`bookings/voucher/:uniquebookingid`}
                      element={<PreviewVoucherPage />}
                    />
                    <Route
                      path={`bookings/communication/voucher/:uniquebookingid`}
                      element={<PreviewCommunicationVoucherPage />}
                    />

                    {/* ==== nested routes for websites ==== */}

                    <Route path="websites" element={<WebsitePageNavigation />}>
                      {/* Nested Routes under /websites */}
                      <Route path="pages" element={<WebsitesPages />} />
                      <Route path="discounts" element={<WebsitesDiscounts />} />
                      <Route path="offers" element={<WebsiteOffers />} />

                      <Route path="blogs" element={<WebsitesBlogs />} />
                      <Route
                        path="menuLocation"
                        element={<WebsiteMenusLocations />}
                      />
                      <Route path="menus" element={<WebsiteMenus />} />

                      <Route path="addpage" element={<AddPage />} />
                      <Route path="addblog" element={<AddBlog />} />
                      <Route
                        path="blogs/editblog/:blogid"
                        element={<EditBlog />}
                      />
                    </Route>
                    {/* ==== nested routes for websites end ==== */}

                    {/* edit pages from websites out of scope */}
                    <Route path="submenus/:id" element={<WebsiteSubMenus />} />
                    <Route
                      path="websites/offers/edit/:offerID"
                      element={<WebsitesCreateEditOffers />}
                    />
                    <Route
                      path="offers/create"
                      element={<WebsitesCreateEditOffers />}
                    />
                    {/* end edit pages from websites out of scope */}

                    <Route
                      path={"housekeeping/staff"}
                      element={<HousekeepingStaffPage />}
                    />

                    <Route
                      path={"websites/pages/editpage/:pageid"}
                      element={<EditPage />}
                    />

                    {/* common routes for booking engine inventory */}
                    <Route element={<BookingEngineInventoryPage />}>
                      <Route path="inventory" element={<InventoryPage />} />
                      <Route
                        path="inventory-rates"
                        element={<InventoryRatesPage />}
                      />
                      <Route path="room-types" element={<RoomsTypePage />} />
                      <Route path="rooms" element={<RoomViewNewUi />} />
                      <Route path="meal-plans" element={<RoomPlansCommon />} />
                      <Route
                        path="room-rate-plans"
                        element={<RoomsRatePlanPage />}
                      />
                    </Route>
                    {/* end common routes for booking engine inventory */}
                    <Route
                      path="room-types/edit/:roomtypeid"
                      element={<RoomTypeEditPage />}
                    />
                    <Route
                      path="room-types/add"
                      element={<RoomTypeAddPage />}
                    />
                    <Route
                      path="room-types/edit/:roomtypeid"
                      element={<RoomTypeEditPage />}
                    />

                    {/* ======discounts ======= */}

                    <Route path={"discounts"} element={<DiscountsPage />}>
                      <Route
                        path="edit/:discountid"
                        element={<EditDiscountsPage />}
                      />
                      <Route path={"add"} element={<AddDiscountPage />} />
                    </Route>

                    {/* ======discounts end ======= */}

                    {/* ====== settings group routes ======= */}

                    <Route path="settings" element={<SettingsPage />}>
                      <Route path="profile" element={<ProfileSettingsNew />} />
                      <Route path="brand" element={<BrandSettings />} />
                    </Route>

                    {/* ====== end settings group routes ======= */}
                  </Route>

                  <Route
                    path="/"
                    element={
                      authenticated ? <DashboardPage /> : <DashboardPage />
                    }
                  />
                </Routes>
              </>
            )}

            {activeApp === "channel_manager" && (
              <>
                {/* channel-manager/bookings */}
                <Routes>
                  <Route path="*" element={<NoDataFound />} />
                  <Route
                    path={"/channel-manager"}
                    element={<ProtectedRoutes />}
                  >
                    <Route index element={<ChannelManagerDashboard />} />
                    <Route path="nopropertypage" element={<NoPropertyPage />} />
                    <Route
                      path={"inventory/rate-inventory"}
                      element={<RateInventory />}
                    />
                    <Route
                      path={"inventory/low-inventory"}
                      element={<LowInventoryRatesPage />}
                    />
                    <Route path={"bookings"} element={<BookingPage />} />

                    <Route
                      path={`bookings/previewbill/:uniquebookingid`}
                      element={<PreviewBillPage />}
                    />

                    <Route
                      path={`bookings/voucher/:uniquebookingid`}
                      element={<PreviewVoucherPage />}
                    />
                    <Route
                      path={`bookings/communication/voucher/:uniquebookingid`}
                      element={<PreviewCommunicationVoucherPage />}
                    />
                    {/* ==== nested routes for websites ==== */}

                    {/* <Route path="websites" element={<WebsitePageNavigation />}>
                      <Route path="pages" element={<WebsitesPages />} />
                      <Route path="discounts" element={<WebsitesDiscounts />} />
                      <Route path="offers" element={<WebsiteOffers />} />
                      <Route
                        path="offers/create"
                        element={<WebsitesCreateEditOffers />}
                      />

                      <Route path="blogs" element={<WebsitesBlogs />} />
                      <Route
                        path="menuLocation"
                        element={<WebsiteMenusLocations />}
                      />
                      <Route path="menus" element={<WebsiteMenus />} />

                      <Route path="addpage" element={<AddPage />} />
                      <Route path="addblog" element={<AddBlog />} />
                      <Route
                        path="blogs/editblog/:blogid"
                        element={<EditBlog />}
                      />
                    </Route> */}
                    {/* ==== nested routes for websites end ==== */}

                    {/* edit pages from websites out of scope */}
                    {/* <Route path="submenus/:id" element={<WebsiteSubMenus />} />
                    <Route
                      path="websites/offers/edit/:offerID"
                      element={<WebsitesCreateEditOffers />}
                    /> */}
                    {/* end edit pages from websites out of scope */}

                    {/* nested routes for  inventory */}
                    <Route element={<BookingEngineInventoryPage />}>
                      <Route path="inventory" element={<InventoryPage />} />
                      <Route
                        path="inventory-rates"
                        element={<InventoryRatesPage />}
                      />
                      <Route path="room-types" element={<RoomsTypePage />} />
                      <Route path="rooms" element={<RoomViewNewUi />} />
                      <Route path="meal-plans" element={<RoomPlansCommon />} />
                      <Route
                        path="room-rate-plans"
                        element={<RoomsRatePlanPage />}
                      />
                    </Route>
                    <Route
                      path="room-types/edit/:roomtypeid"
                      element={<RoomTypeEditPage />}
                    />
                    {/* end common routes for booking engine inventory */}

                    {/* nested routes for settings */}
                    <Route
                      path="settings"
                      element={<ChannelManagerSettingsPage />}
                    >
                      <Route path="profile" element={<ProfileSettingsNew />} />
                      <Route path="brand" element={<BrandSettings />} />
                      <Route path="distance" element={<DistanceSettings />} />
                      <Route
                        path="privacy-policy"
                        element={<PrivacyPolicies />}
                      />
                      <Route path="channel" element={<ChannelListing />} />
                    </Route>
                    {/* end nested routes for settings */}

                    <Route
                      path="room-types/add"
                      element={<RoomTypeAddPage />}
                    />
                    <Route
                      path="room-types/edit/:roomtypeid"
                      element={<RoomTypeEditPage />}
                    />
                  </Route>
                </Routes>
              </>
            )}
          </>
        ) : (
          <NoInternetConnection />
        )}
      </div>
      {/* <Sidebar /> */}
    </>
  );
};
export default Layout;
