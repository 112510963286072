import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import { useState, useEffect } from "react";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/svgIcons";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
const ReportDepartureTable = ({
  departureReport,
  loader,
  getSingleBookingDataHandler,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const extractedData = departureReport?.map((item) => ({
    unique_booking_id: item?.unique_booking_id,
    guest_name: item?.guest[0]?.guest_name,
    check_in: item?.check_in,
    check_out: item?.check_out,
    room_type_name:
      item?.items.length > 0 ? item?.items[0]?.room_type_name : "N/A",
    no_nights: item?.items[0]?.no_nights,
    no_adult: item?.items[0]?.no_adult,
    assigned_room: item?.assignedrooms[0]?.assigned_room,
    status: item?.status?.replace(/_/g, " "),
  }));

  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Name",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      room_type_name: "Room Category",
      no_nights: "No of Nights",
      no_adult: "No. of Guests",
      assigned_room: "Assigned Rooms",
      status: "Status",
    },
  ];

  const rowMarkup = departureReport?.map((order) => (
    <IndexTable.Row id={order.id} key={order.id} position={order.index}>
      <IndexTable.Cell>
        {" "}
        <div
          className="pointer"
          style={{
            color: "rgba(57, 104, 237, 1)",
          }}
          onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
        >
          #{order.unique_booking_id || ""}
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>{order.guest[0].guest_name}</IndexTable.Cell>
      <IndexTable.Cell>
        {" "}
        <TextDateFormatter
          as={"span"}
          showYear={true}
          date={order.created_at}
        />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.items.map((item) => (
          <div>{item.room_type_name}</div>
        ))}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.items.map((item) => item.no_nights)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {order?.items.map((item) => (
          <div>{item.no_adult}</div>
        ))}
      </IndexTable.Cell>

      <IndexTable.Cell>
        <>
          {order && order.assignedrooms && order.assignedrooms.length > 0 ? (
            order.assignedrooms.map((room) => {
              return <div>{room.assigned_room}</div>;
            })
          ) : (
            <div>N/A</div>
          )}
        </>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusUpdate status={order.status} /> {}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const rowMarkupOne = departureReport?.map((order) => (
    <CustomReportTableRow
      id={order.id}
      key={order.id}
      // selected={selectedResources.includes(order.id)}
      // position={order.index}
    >
      <CustomReportTableCell>
        {" "}
        <div
          className="pointer"
          style={{
            color: "rgba(57, 104, 237, 1)",
          }}
          onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
        >
          #{order.unique_booking_id || ""}
        </div>
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest && order?.guest?.length > 0 && order?.guest[0].guest_name}
      </CustomReportTableCell>
      <CustomReportTableCell>
      <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.created_at}
          />
      </CustomReportTableCell>
      <CustomReportTableCell>
    
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest &&
          order?.guest?.length > 0 &&
          order?.items.map((item, index) => (
            <span key={index}>
              {item.room_type_name}
              {index < order.items.length - 1 && ","} &nbsp;
            </span>
          ))}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest && order?.guest?.length > 0 && order?.items[0]?.no_nights}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.guest && order?.guest?.length > 0 && order?.items[0]?.no_adult}
      </CustomReportTableCell>
      <CustomReportTableCell>₹{order?.balance_amount}</CustomReportTableCell>
      <CustomReportTableCell>
        <StatusUpdate status={order?.status} /> {}
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}>{"Totals"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "balance_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );

  return (
    <div id="receipt">
      <div className="room-types-full-container reports-new-background">
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">Detailed Departure Report</div>
            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
              id="ignoreFromPrint"
            >
              <SvgGetInfo />
            </div>
          </div>
          {departureReport?.length > 0 && (
            <div
              className="flex flex_gap_15 align_center"
              id={"ignoreFromPrint"}
            >
          
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"departure_report"}
                pdfOrientation={"landscape"}
                ignoreFromPrint={"ignoreFromPrint"}
                longPdf={true}
              />
              <DownloadCSVButton
                itemToDownloadID={"receipt"}
                downloadFileName={"departure_report"}
              />
            </div>
          )}
        </div>
        {loader && <div>Loader</div>}

        {departureReport && departureReport.length > 0 ? (
          <>
            <CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Booking Date",
                "Check In Date",
                "Check Out Date",
                "Room Type",
                "No Of Nights",
                "	No Of Guests",
                "Balance",
                "Status",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "Quantity",
              //   "Quantity",
              //   "Quantity",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                textAlign: "center",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {/* {rowMarkup} */}
              {rowMarkupOne}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Detailed Departure Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is the Departure Report?",
                  answer: (
                    <p>
                      The <b>Departure Report</b> offers a detailed summary of
                      bookings that have already arrived on previous dates,
                      providing key insights into either <b>checked-in</b>
                      or <b>checked-out</b> statuses. Whether you're monitoring
                      current in-house guests or reviewing completed stays, this
                      report serves as a vital tool for tracking guest activity
                      and operational efficiency.
                    </p>
                  ),
                },
                {
                  question: "Why is the Departure Report Important?",
                  answer: (
                    <div>
                      <p>
                        The Departure Report is essential for optimizing daily
                        operations and understanding guest flow. Here’s why it
                        matters:
                      </p>

                      <ul>
                        <li>
                          <b>Efficient Housekeeping Management: </b>Ensure rooms
                          are promptly cleaned and prepared for new arrivals by
                          tracking checked-out guests.
                        </li>
                        <li>
                          <b>Enhanced Guest Experience: </b>Identify guests who
                          are still checked in and cater to their ongoing needs.
                        </li>
                        <li>
                          <b>Accurate Billing and Reporting: </b>Easily
                          cross-check guest departure status to ensure accurate
                          billing and timely record updates.
                        </li>
                        <li>
                          <b>Operational Planning: </b>Monitor room turnover
                          rates and plan resources more effectively.
                        </li>
                        <li>
                          <b>Revenue Tracking</b>: Understand how room
                          categories are being utilized to optimize pricing
                          strategies and revenue generation.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </div>
  );
};
export default ReportDepartureTable;
