import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import { calculateTotalWithReduce, formatIndianCurrency } from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import InformationCustomPopup from "../InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import { SvgGetInfo } from "../../assets/svgIcons";
import { CustomReportTable, CustomReportTableCell, CustomReportTableRow } from "../CustomComponents/CustomReportComponent/CustomReportComponent";

const ReportPaymentCommission = ({ departureReport, loader  ,getSingleBookingDataHandler}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  
  const names = [
    {
      agent_name: "Agent Name",
      commission_amount: "Commission Amount",
      commission_percentage: "Commission Percentage",
      room_total: "Room Total",
    },
  ];

  const [informationPopup, setInformationPopup] = useState(false);

  const rowMarkup =
  departureReport &&
  departureReport.length > 0 &&
  departureReport.map((order) => (
    <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
      <CustomReportTableCell>{order.agent_name}</CustomReportTableCell>
      <CustomReportTableCell>
        <span>{order.booking_id}</span>
        </CustomReportTableCell>
      <CustomReportTableCell>
        {order.guest_name}
        </CustomReportTableCell>
      <CustomReportTableCell>{order.booking_date}</CustomReportTableCell>
      <CustomReportTableCell>{order.check_in_date}</CustomReportTableCell>
      <CustomReportTableCell>{order.check_out_date}</CustomReportTableCell>
      <CustomReportTableCell>{order.room_type}</CustomReportTableCell>
      <CustomReportTableCell>{order.nights || "0"}</CustomReportTableCell>
      <CustomReportTableCell>{order.guests || "0"}</CustomReportTableCell>
      <CustomReportTableCell>
        {order.grand_total ? formatIndianCurrency(order.grand_total) : "0"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.commission_percentage
          ? order.commission_percentage + "%"
          : "0"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.commission_amount
          ? formatIndianCurrency(order.commission_amount)
          : "0"}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order.hotel_earning
          ? formatIndianCurrency(order.hotel_earning)
          : "0"}
      </CustomReportTableCell>
      <CustomReportTableCell>{order.status}</CustomReportTableCell>
    </CustomReportTableRow>
  ));


    const calculateTotals = (
      <CustomReportTableRow
        CustomReportTableRow
        id={"id"}
        key={"id"}
        bodyStyles={{
          borderTop: "1px solid #CCC",
          backgroundColor: "lightgray",
        }}
      >
        <CustomReportTableCell styles={{textAlign:"left"}} colSpan={4}>{"Totals"}</CustomReportTableCell>
        <CustomReportTableCell styles={{textAlign:"left"}}>
          {/* {formatIndianCurrency(
            calculateTotalWithReduce(departureReport, "commission_amount")
          )} */}
        </CustomReportTableCell>
  
     <CustomReportTableCell styles={{textAlign:"left"}}>
  
        </CustomReportTableCell >
        <CustomReportTableCell styles={{textAlign:"left"}}>
          {/* {formatIndianCurrency(
            calculateTotalWithReduce(departureReport, "room_total")
          )} */}
        </CustomReportTableCell>
      
  
      </CustomReportTableRow>
    );
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div
          id="receipt"
          className="room-types-full-container reports-new-background"
        >
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">Commission Report </div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
              >
               <SvgGetInfo />
              </div>
            </div>

            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15" id="ignoreFromPrint">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"receipt"}
                  downloadFileName={"Commission Report"}
                  ignoreFromPrint={"ignoreFromPrint"}
                  pageFormat={"A3"}
                />
                <DownloadCsvTables tableData={departureReport} names={names} />
              </div>
            )}
          </div>

          {departureReport && departureReport.length > 0 ? (
            <>
              <LegacyCard>
                <CustomReportTable
                  headings={["Agent/ OTA Name" ,"Booking ID" , "Guest Name" , "Booking Date" ,"Check In Date" ,"Check Out Date" ,"Room Type" ,"No. of Nights" ,"No. of Guests" ,"Grand Total" , "Commission %" , "Commission Amount" , "Hotel Earning (Grand Total- Commission)" ,"Status"]}
                >
                  {rowMarkup}
                 
                </CustomReportTable>
              </LegacyCard>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>

        // </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Commission Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Commission Report?",
                  answer: (
                    <p>
                     A Payout Commission Report tracks the commissions owed to agents or online travel agencies (OTAs) for bookings made through their platforms. It includes details like booking IDs, guest names, booking and stay dates, the total amount for each booking, commission percentages, the commission amounts, and the remaining earnings for the hotel after deducting commissions.
                    </p>
                  ),
                },
                {
                  question: "What is the Importance of the Commission Report?",
                  answer: (
                    <div>
                      <p>
                        The Commission Report is important for several reasons:
                      </p>

                      <ul>
                        <li>
                          <b>Financial Accuracy: </b>Ensures transparency in tracking commissions paid to agents/OTAs and helps in reconciling payouts.

                        </li>
                        <li>
                          <b>Profit Calculation: </b>Displays the net earnings for the hotel after deducting the commission, enabling accurate financial analysis.
                        </li>
                        <li>
                          <b>Performance Tracking: </b>Assists hotels in analyzing which agents/OTAs generate the most business, helping make informed decisions on partnerships.
                        </li>
                        {/* <li>
                          <b>Strategic Decision-Making: </b>The report informs
                          decisions on marketing strategies, commission
                          structures, and partnerships to enhance business
                          growth.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b> Understanding booking
                          trends through agents helps optimize inventory
                          management and resource allocation.
                        </li> */}
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportPaymentCommission;
