import React, { useEffect } from "react";
import GoBackButtonCustom from "../../../../Components/GoBackButtonCustom/GoBackButtonCustom";
import { SelectBasicWithSelectTag } from "../../../../Components/NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDecryptedData } from "../../../../utils/encryptStorage";
import {
  calculateTotalArrayObjectKey,
  formatIndianCurrency,
  getBaseUrl,
  getDateByMonth,
  performRounding,
} from "../../../../utils/utils";
import { PrimaryInput } from "../../../../Components/NewCustomComp/Inputs/Inputs";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../../../../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import { SvgCancel, SvgDelete, SvgPlus } from "../../../../assets/svgIcons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../../assets/toastUtils";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../Components/NewCustomComp/Buttons/Buttons";
import { TextArea } from "../../../../Components/NewCustomComp/TextArea/TextArea";
import HouseKeepingStatusUpdateSwitch from "../housekeepingLostAndFound/HouseKeepingStatusUpdateSwitch";
import { color } from "chart.js/helpers";
import GenerateBillComponent from "./GenerateBillComponent";

const HousekeepingLaundryCreateUpdate = () => {
  // Global
  const property_id = useSelector((state) => state.property.value)?.id;
  const navigate = useNavigate();
  const userToken = getDecryptedData("encryptToken");
  const baseUrl = getBaseUrl();
  const { id } = useParams();
  const { details } = useParams();

  // Global End

  // All States
  const [orders, setOrders] = React.useState([]);
  const [roomsData, setRoomsData] = React.useState([]);
  const [staffs, setStaffs] = React.useState([]);
  const [itemsList, setItemsList] = React.useState([]);
  const [serviceDetails, setServiceDetails] = React.useState({});
  const [generateBill, setGenerateBill] = React.useState(false);
  const [formdata, setFormdata] = React.useState({
    room_id: null,
    room_no: null,
    assign_name: "",
    assign_id: "",
    sub_total: null,
    total_discount_amount: null,
    total_tax_amount: null,
    grand_total: null,
    note: "",
    status: "no_action",
    items: [],
    is_complimentary: false,
    reason_complimentary: "",
    payment_status: "paid",
    paid_amount: 0,
    round_off: "",
    folio_id: "",
  });

  const [selectedData, setSelectedData] = React.useState({
    item_id: null,
    plan: "",
    item_name: "",
    service_type: "",
    gender: "",
    quantity: null,
    rate: null,
    sub_total: null,
    discount_rate: null,
    discount_amount: null,
    tax_rate: null,
    tax_amount: null,
    grand_total: null,
    express_rate: "",
    discount_type: "percentage",
  });

  // All States End

  // All Api's
  const fetchInfo = async (propertyId) => {
    // if (!propertyId) {
    // navigate("/login");
    // }
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/assign/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.rooms);
    } catch (error) {
      console.log(error);
    }
  };

  // Items Listing Api
  const fetchItemsList = async (propertyId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/laundry/rates/${propertyId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setItemsList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Items Listing Api End

  // Assign Rooms Api
  const fetchRoomsData = async (propertyId) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/assign/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setRoomsData(data?.assignedRoomsList || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Assign Rooms Api End

  // Listing Staff
  const fetchStaff = async (propertyId) => {
    try {
      const response = await fetch(
        // /api/v1/account/get-house-keeping/10
        `${baseUrl}/api/v1/account/get-house-keeping/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setStaffs(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Listing Staff End

  // Get Folio Id
const getFolioId = async (roomNo) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${baseUrl}/api/v1/get/room_details/${roomNo}/${property_id}`,
      requestOptions
    );
    const data = await response.json();
    if (data.success) {
      setFormdata({ ...formdata, folio_id: data?.guestDetail?.folio_id });
    }
  } catch (error) {
    console.log("error", error);
  }
}
  // Get Folio Id End

  //  Add Service
  const handleAddService = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      room_id: formdata?.room_id,
      room_no: formdata?.room_no,
      assign_name: formdata?.assign_name,
      assign_id: formdata?.assign_id,
      note: formdata?.note,
      sub_total: calculateTotalArrayObjectKey(formdata?.items, "sub_total"),
      total_discount_amount: calculateTotalArrayObjectKey(
        formdata?.items,
        "discount_amount"
      ),
      total_tax_amount: calculateTotalArrayObjectKey(
        formdata?.items,
        "tax_amount"
      ),
      grand_total: calculateTotalArrayObjectKey(formdata?.items, "grand_total"),
      status: "no_action",
      is_bill_generated: false,
      is_complimentary: formdata.is_complimentary,
      payment_status: formdata.payment_status,
      paid_amount: formdata.paid_amount,
      items: formdata?.items,
      folio_id: formdata.folio_id
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/service/${property_id}`,
        requestOptions
      );
      const data = await response.json();

      if (data.success) {
        showSuccessToast("Added Successfully");
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Add Service End

  // Get Single Service
  const getSingleService = async (propertyId, serviceId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/api/v1/house-keeping/service/${propertyId}?id=${serviceId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        setServiceDetails(data.data);
        setFormdata((prev) => ({
          ...prev,
          room_id: data.data.room_id,
          room_no: data.data.room_no,
          assign_name: data.data.assign_name,
          assign_id: data.data.assign_id,
          note: data.data.note,
          sub_total: data.data.sub_total,
          total_discount_amount: data.data.total_discount_amount,
          total_tax_amount: data.data.total_tax_amount,
          grand_total: data.data.grand_total,
          status: data.data.status,
          is_bill_generated: data.data.is_bill_generated,
          is_complimentary: data.data.is_complimentary,
          payment_status: data.data.payment_status,
          paid_amount: data.data.paid_amount,
          items: data.data.items,
          folio_id: data.data.folio_id
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Update Service Api
  const updateService = async (data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      room_id: formdata.room_id,
      room_no: formdata.room_no,
      // plan: formdata.plan,
      assign_name: formdata.assign_name,
      assign_id: formdata.assign_id,
      note: formdata.note,
      sub_total: calculateTotalArrayObjectKey(formdata?.items, "sub_total"),
      total_discount_amount: calculateTotalArrayObjectKey(
        formdata?.items,
        "discount_amount"
      ),
      total_tax_amount: calculateTotalArrayObjectKey(
        formdata?.items,
        "tax_amount"
      ),
      grand_total: calculateTotalArrayObjectKey(formdata?.items, "grand_total"),
      status: data?.status || "no_action",
      is_complimentary: formdata.is_complimentary,
      reason_complimentary: formdata.reason_complimentary,
      payment_status: formdata.payment_status,
      paid_amount: formdata.paid_amount,
      items: formdata.items,
      folio_id: formdata.folio_id
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const url = details
      ? `${baseUrl}/api/v1/house-keeping/service/${property_id}?id=${details}`
      : `${baseUrl}/api/v1/house-keeping/service/${property_id}?id=${id}`;
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      if (data.success) {
        showSuccessToast("Updated Successfully");
        navigate(-1);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Update Service Api End

  // All Api's End

  // Functions
  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id);
      fetchItemsList(property_id);
      fetchStaff(property_id);
      fetchRoomsData(property_id);

      if(formdata?.room_id){
        getFolioId(formdata?.room_no)
      }

      if (id) {
        getSingleService(property_id, id);
      }
      if (details) {
        getSingleService(property_id, details);
      }
    }
  }, [property_id, id,details,formdata?.room_id]);

  const selectedItemData = itemsList.find(
    (item) => +item.id === +selectedData?.item_id
  );

  const itemNameOptions = [
    { label: "Select", value: "" },
    ...itemsList
      .filter((item) => item.category === selectedData?.gender)
      .map((item) => ({
        label: item.item_name,
        value: item.id,
      })),
  ];

  const handleOptionsChange = (e) => {
    const { value, name } = e.target;

    if (name === "service_type") {
      const filterRateValue = Object.keys(selectedItemData).find(
        (key) => key === e.target.value
      );

      const rateValue = filterRateValue
        ? selectedItemData[filterRateValue]
        : null;
      setSelectedData((prev) => ({
        ...prev,
        [name]: e.target.options[e.target.selectedIndex].text,
        rate: rateValue,
      }));
    }

    if (name === "item_id") {
      const itemName = itemsList.find((item) => item.id === +value)?.item_name;
      setSelectedData((prev) => ({
        ...prev,
        [name]: value,
        item_name: itemName,
      }));
    }

    if (name === "room_id") {
      setFormdata((prev) => ({
        ...prev,
        [name]: value,
        room_no: roomsData.find((room) => room.id === +value)?.room_no,
      }));
    }

    if (name === "note") {
      setFormdata((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (name === "assign_id") {
      const getAssignedName = staffs.find((staff) => staff.id === +value)?.name;
      setFormdata((prev) => ({
        ...prev,
        [name]: value,
        assign_name: getAssignedName,
      }));
    }

    // if (name === "plan") {
    //   setFormdata((prev) => ({
    //     ...prev,
    //     [name]: value,
    //   }));
    // }

    setSelectedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddItem = () => {
    const updatedItems = [...formdata.items, selectedData];
    setFormdata((prev) => ({
      ...prev,
      items: updatedItems,
    }));

    setSelectedData({
      item_id: "",
      plan: "",
      item_name: "",
      service_type: "",
      gender: "",
      quantity: "",
      rate: "",
      sub_total: "",
      discount_rate: "",
      discount_amount: "",
      tax_rate: "",
      tax_amount: "",
      grand_total: "",
      express_rate: "",
      discount_type: "percentage",
    });
  };

  const handleDeleteItem = (index) => {
    const newData = [...formdata.items];
    newData.splice(index, 1);
    setFormdata((prev) => ({
      ...prev,
      items: newData,
    }));
  };

  const assignedToOptions = [
    { label: "Select", value: "" },
    ...staffs.map((staff) => ({
      label: staff.name,
      value: staff.id,
    })),
  ];

  const roomsOptions = [
    { label: "Select", value: "" },
    ...roomsData
      .filter((room) => room.status === "occupied")
      .map((room) => ({
        label: room.room_no, // Map to label and value
        value: room.id,
      })),
  ];

  const handleStatusChange = (data) => {
    updateService(data);
  };

  const handleGenerateBill = () => {
    setGenerateBill(true);
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;

    if (name === "round_off") {
      const roundedGrandTotal = performRounding(
        serviceDetails?.grand_total,
        value
      );
      console.log(roundedGrandTotal, "roundedGrandTotal");
    }
    setFormdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    // alert("called")
    const quantity = +selectedData.quantity;
    const discountRate = +selectedData.discount_rate;
    // const discountType = +selectedData.discountType ? selectedData.discountType : "percentage"

    let rate = +selectedData.rate;

    // let expressAmount = 0;
    if (selectedData?.plan === "express") {
      const expressRate = rate + rate * (selectedItemData?.express_rate / 100);
      rate = expressRate;
      // console.log(expressRate, "expressRate");
    }

    const tax = +selectedData.tax_rate;
    const discountAmount = rate * (discountRate / 100);

    const discountedRate = rate - discountAmount;

    const subTotal = discountedRate * quantity;
    const taxAmount = subTotal * (tax / 100);

    const grandTotal = subTotal + taxAmount;

    const itemName = selectedItemData?.item_name;

    // const totalSubTotal = formdata.items.reduce(
    //   (acc, item) => acc + Number(item.sub_total),
    //   0
    // );

    // const totalGrandTotal = formdata.items.reduce(
    //   (acc, item) => acc + Number(item.grand_total),
    //   0
    // );

    // const totalTaxAmount = formdata.items.reduce((acc, item) => {
    //   // Convert tax_amount from percentage to actual value
    //   const taxValue = (Number(item.tax_amount) / 100) * Number(item.sub_total);
    //   return acc + taxValue;
    // }, 0);

    // const totalDiscountAmount = formdata.items.reduce((acc, item) => {
    //   // Convert discount_amount from percentage to actual value
    //   const discountValue =
    //     (Number(item.discount_amount) / 100) * Number(item.sub_total);
    //   return acc + discountValue;
    // }, 0);

    setSelectedData((prev) => ({
      ...prev,
      tax_amount: taxAmount.toFixed(2),
      discount_amount: (discountAmount * quantity).toFixed(2),
      sub_total: subTotal.toFixed(2),
      grand_total: grandTotal.toFixed(2),
      item_name: itemName,
    }));

    setFormdata((prev) => ({
      ...prev,
      grand_total: "",
    }));

    // setFormdata((prev) => ({
    //   ...prev,
    //   sub_total: totalSubTotal,
    // grand_total: totalGrandTotal,
    // total_tax_amount: totalTaxAmount.toFixed(2),
    // total_discount_amount: totalDiscountAmount.toFixed(2),
    // }));
  }, [
    selectedData.quantity,
    selectedData.discount_rate,
    selectedData.rate,
    selectedData.tax_rate,
    selectedItemData?.item_name,
    formdata.items,
  ]);

  // Functions End

  // Console Log

  // console.log("orders", orders);
  console.log("formdata", formdata);
  // console.log("serviceDetails", serviceDetails);
  // console.log("roomsData", roomsData);
  console.log("selectedData", selectedData);
  // console.log("selectedItemData", selectedItemData);
  // console.log("staffs", staffs);
  // console.log("itemNameOptions", itemNameOptions);

  // Console Log End

  return (
    <div className="main_container">
      <div className="flex justify_content_between">
        <GoBackButtonCustom
          onClick={() => navigate(-1)}
          buttonNextText={
            details
              ? "Service Details"
              : id
              ? "Update Services"
              : "Create Services"
          }
        />
        {details && (
          <div className="flex flex_gap_10 align_items_center">
            <div>
              Service Id :{" "}
              <span className="weight_700">
                #{serviceDetails?.unique_service_id}
              </span>
            </div>
            <div>
              Assign To :{" "}
              <span className="weight_700">{serviceDetails?.assign_name}</span>
            </div>
            <div>
              Service Date :{" "}
              <span className="weight_700">
                {getDateByMonth(serviceDetails?.created_at)}
              </span>
            </div>
            <div>
              <HouseKeepingStatusUpdateSwitch
                currentStatus={serviceDetails?.status}
                onStatusChange={handleStatusChange}
                apiUrl={"service"}
                options={["no action", "in progress", "delivered", "canceled"]}
              />
            </div>
          </div>
        )}
      </div>

      <div className="white_wrapper mt-10">
        {/* Create Services */}
        <div>
          <div className="flex flex_gap_10 pb-20 border_bottom">
            <SelectBasicWithSelectTag
              options={roomsOptions}
              value={formdata.room_id}
              name={"room_id"}
              onChange={handleOptionsChange}
              size={"medium"}
              label={"Room No."}
            />
            {/* <SelectBasicWithSelectTag
              size={"medium"}
              options={[
                { label: "Select", value: "" },
                { label: "Regular", value: "regular" },
                { label: "Express", value: "express" },
              ]}
              name={"plan"}
              value={formdata.plan}
              label={"Plan"}
              onChange={handleOptionsChange}
            /> */}
            <SelectBasicWithSelectTag
              options={assignedToOptions}
              onChange={handleOptionsChange}
              name={"assign_id"}
              value={formdata.assign_id}
              size={"medium"}
              label={"Assign To"}
            />
          </div>

          <div className="flex flex_gap_10">
            <SelectBasicWithSelectTag
              size={"medium"}
              name={"gender"}
              value={selectedData?.gender}
              onChange={handleOptionsChange}
              options={[
                { label: "Select", value: "" },
                { label: "Men", value: "men" },
                { label: "Women", value: "women" },
                { label: "Kids", value: "kids" },
                { label: "Others", value: "others" },
              ]}
              label={"Category"}
            />

            <SelectBasicWithSelectTag
              options={itemNameOptions}
              name={"item_id"}
              value={selectedData?.item_id}
              onChange={handleOptionsChange}
              size={"medium"}
              label={"Item Name"}
            />

            <SelectBasicWithSelectTag
              options={[
                { label: "Select", value: "" },
                {
                  label: "Dry Cleaning",
                  value: "dry_cleaning",
                },
                { label: "Ironing", value: "ironing" },
                { label: "Washing", value: "washing" },
                {
                  label: "Washing & Ironing",
                  value: "wash_iron",
                },
              ]}
              name={"service_type"}
              value={selectedData?.service_type}
              onChange={handleOptionsChange}
              size={"medium"}
              label={"Type"}
            />

            <SelectBasicWithSelectTag
              size={"medium"}
              options={[
                { label: "Select", value: "" },
                { label: "Regular", value: "regular" },
                { label: "Express", value: "express" },
              ]}
              name={"plan"}
              value={selectedData.plan}
              label={"Plan"}
              onChange={handleOptionsChange}
            />

            {selectedData.plan === "express" && (
              <PrimaryInput
                label={"Express Rate %"}
                size={"medium"}
                disabled={"true"}
                value={selectedItemData?.express_rate}
              />
            )}

            <PrimaryInput
              label={"Rate"}
              size={"medium"}
              disabled={true}
              // name={"rate"}
              value={selectedData?.rate}
            />

            <PrimaryInput
              type={"number"}
              label={"Quantity"}
              name={"quantity"}
              value={selectedData?.quantity}
              onChange={handleOptionsChange}
              size={"medium"}
            />

            <PrimaryInput
              type={"number"}
              label={"Subtotal"}
              value={selectedData?.sub_total || 0}
              disabled={true}
              size={"medium"}
            />

            <PrimaryInput
              type={"number"}
              label={"Disc.%"}
              name={"discount_rate"}
              value={selectedData?.discount_rate}
              onChange={handleOptionsChange}
              size={"medium"}
            />

            <PrimaryInput
              type={"number"}
              size={"medium"}
              label={"Tax Rate%"}
              onChange={handleOptionsChange}
              name={"tax_rate"}
              value={selectedData?.tax_rate}
            />

            <PrimaryInput
              type={"number"}
              label={"Total Amount"}
              value={selectedData?.grand_total || 0}
              size={"medium"}
              disabled={true}
            />

            {!details && (
              <span
                onClick={handleAddItem}
                className="flex align_items_end pointer"
              >
                <SvgPlus />
              </span>
            )}
          </div>
        </div>

        {/* Items list table */}

        {formdata?.items && formdata?.items?.length > 0 && (
          <div className="mt-15">
            <CustomShopifyIndexTable
              headings={[
                "Gender",
                "Item Name",
                "Service Type",
                "Rate",
                "Quantity",
                "Subtotal",
                "Disc.%",
                "Tax %",
                "Total Amount",
              ]}
            >
              {formdata?.items &&
                formdata?.items?.length > 0 &&
                formdata?.items?.map((item, index) => (
                  <CustomShopifyIndexTableRow key={index}>
                    <CustomShopifyIndexTableCell>
                      {item.gender}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item.item_name}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item.service_type}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item.rate}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item.quantity}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item.sub_total}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item.discount_amount ? item.discount_amount : 0}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item.tax_amount ? item.tax_amount : 0}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {item.grand_total}
                    </CustomShopifyIndexTableCell>
                    <CustomShopifyIndexTableCell>
                      {!details && (
                        <span
                          onClick={() => handleDeleteItem(index)}
                          className="pointer flex align_items_center"
                        >
                          <SvgDelete />
                        </span>
                      )}
                    </CustomShopifyIndexTableCell>
                  </CustomShopifyIndexTableRow>
                ))}
            </CustomShopifyIndexTable>
          </div>
        )}

        {/* Create Services End */}
      </div>

      <div className="mt-10 ">
        <div className="flex flex_gap_10 justify_content_end">
          <div>
            Subtotal:{" "}
            <span className="weight_700">
              {formatIndianCurrency(
                calculateTotalArrayObjectKey(formdata?.items, "sub_total")
              )}
            </span>{" "}
          </div>
          <div>
            Discounted Amt. :{" "}
            <span className="weight_700">
              {formatIndianCurrency(
                calculateTotalArrayObjectKey(formdata?.items, "discount_amount")
              )}
            </span>
          </div>
          <div>
            Tax Amt. :{" "}
            <span className="weight_700">
              {formatIndianCurrency(
                calculateTotalArrayObjectKey(formdata?.items, "tax_amount")
              )}
            </span>{" "}
          </div>
          <div>
            Total Amt. :{" "}
            <span className="weight_700">
              {formatIndianCurrency(
                calculateTotalArrayObjectKey(formdata?.items, "grand_total")
              )}
            </span>{" "}
          </div>
        </div>
        <div className="flex flex_gap_10 justify_content_end mt-10">
          <label className="global_label">Note :</label>
          <TextArea
            size={"medium"}
            name={"note"}
            value={formdata?.note}
            onChange={handleOptionsChange}
          />
        </div>

        {details ? (
          <div className="flex flex_gap_10 justify_content_end mt-10">
            {serviceDetails?.status === "no_action" && (
              <SecondaryButton
                title={"Cancel Service"}
                size="medium"
                style={{ borderColor: "#E03838", color: "#E03838" }}
              />
            )}
            {serviceDetails?.status === "delivered" && (
              <>
                {serviceDetails?.payment_status === "paid" ?(
                  <>
                  <PrimaryButton
                  size="medium"
                  title={"View Bill"}
                  />  
                  
                  <PrimaryButton 
                   size="medium"
                   title={"Print Bill"}
                  />
                  </>
                ) :(
                  <PrimaryButton
                    size="medium"
                    title={"Generate Bill"}
                    onClick={handleGenerateBill}
                  />
                )}
              </>
            )}
          </div>
        ) : (
          <div className="flex flex_gap_10 justify_content_end mt-10">
            <SecondaryButton
              onClick={() => navigate(-1)}
              title={"Discard"}
              size="medium"
            />
            {formdata.is_complimentary ? (
              <PrimaryButton
                onClick={() =>
                  setFormdata((prev) => ({
                    ...prev,
                    is_complimentary: !prev.is_complimentary,
                  }))
                }
                title={"Un-mark From Complementary"}
                size="medium"
              />
            ) : (
              <SecondaryButton
                onClick={() =>
                  setFormdata((prev) => ({
                    ...prev,
                    is_complimentary: !prev.is_complimentary,
                  }))
                }
                title={"Mark As Complementary"}
                size="medium"
              />
            )}
            <PrimaryButton
              onClick={id ? updateService : handleAddService}
              title={id ? "Update" : "Create"}
              size="medium"
            />
          </div>
        )}
      </div>

      {generateBill && (
        <div className="bg-backdrop">
          <GenerateBillComponent
          refreshApi={getSingleService}
            details={serviceDetails}
            closePopup={setGenerateBill}
          />
        </div>
      )}
    </div>
  );
};

export default HousekeepingLaundryCreateUpdate;
