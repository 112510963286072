import React from 'react';
import './CreateBookingSelectBox.css';
const CreateBookingSelectBox = ({ label, value, onChange, options ,disabled ,error,optionDisabled}) => {
    return (
        <div className='create_booking_selectBox_container'>
            <label className="Create_booking_label">{label}</label>
            <select value={value} onChange={onChange}  disabled={disabled}  
             style={{
                border: error ? "1px solid #E03838" : "1px solid #ddd",
                backgroundColor: error ? "#FFEAEA" : "",
               cursor:disabled ? "not-allowed" : "pointer"
              }}
            className={error ? "create_booking_error-border Create_booking_selectBox" : "Create_booking_selectBox"}>
                <option value="" id=" " >Select</option>
                {options?.length>0 &&options?.map((option, index) => (
                    <option key={index}  value={option.value} disabled={optionDisabled}  >
                        {option.room_name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CreateBookingSelectBox;