import React from "react";
import styles from "./selectionBoxExtendStayReward.module.scss";
import { SvgCampaign, SvgMoon } from "../../../assets/svgIcons";

const SelectionBoxExtendStayReward = ({ setFormData, formData }) => {
  const handleTypeChange = (newType) => {
    setFormData((prevState) => {
      const updatedConditions = {
        ...prevState.promotion_wise_conditions,
        extend_stay_rewards: {
          type: newType,
          ...(newType === "discount"
            ? { min_los: 3 } // Update fields for discount
            : newType === "free_nights"
            ? {
                stay_for_nights: 5, // Update fields for free nights
                pay_for_nights: 4,
              }
            : {}),
        },
      };

      return {
        ...prevState,
        promotion_wise_conditions: updatedConditions,
      };
    });
  };

  const selectedOption =
    formData?.promotion_wise_conditions?.extend_stay_rewards?.type;

  return (
    <div className={`${styles.container}`}>
      {/* Offer Discount Box */}
      <div
        className={`${styles.box} ${
          selectedOption === "discount" ? styles.selected : ""
        } pointer`}
        onClick={() => handleTypeChange("discount")}
      >
        <div className={styles.content}>
          <div className={styles.checkbox}>
            <input
              type="radio"
              id="discount"
              className="pointer"
              checked={selectedOption === "discount"}
              readOnly
            />
            <span className={styles.customCheckbox}></span>
          </div>
          <label htmlFor="discount" className="pointer">
            Offer Discount
          </label>
          <div
            className={`${styles.icon} ${
              selectedOption === "discount" ? styles.selected : ""
            }`}
          >
            <SvgCampaign
              color={selectedOption === "discount" ? "#3968ed" : ""}
            />
          </div>
        </div>
      </div>

      {/* Offer Free Nights Box */}
      <div
        className={`${styles.box} ${
          selectedOption === "free_nights" ? styles.selected : ""
        } pointer`}
        onClick={() => handleTypeChange("free_nights")}
      >
        <div className={styles.content}>
          <div className={styles.checkbox}>
            <input
              type="radio"
              id="free-nights"
              className="pointer"
              checked={selectedOption === "free_nights"}
              readOnly
            />
            <span className={styles.customCheckbox}></span>
          </div>
          <label htmlFor="free-nights" className="pointer">
            Offer Free Nights
          </label>
          <div
            className={`${styles.icon} ${
              selectedOption === "free_nights" ? styles.selected : ""
            }`}
          >
            <SvgMoon color={selectedOption === "free_nights" ? "#3968ed" : ""} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionBoxExtendStayReward;
