import React, { useEffect, useState } from "react";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import {
  BgOutlineThemeButton,
  BgThemeButton,
} from "../../Components/UI/Buttons";
import { PrimaryInput } from "../../Components/NewCustomComp/Inputs/Inputs";
import { getBaseUrl } from "../../utils/utils";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SvgAlert,
  SvgCancel,
  SvgDelete,
  SvgEdit,
  SvgGoBack,
  SvgMinus,
  SvgPlus,
} from "../../assets/svgIcons";
// import styles from "./websiteEditOffers.module.scss";
import styles from "../../Pages/Websites/websiteEditOffers.module.scss";
import Loader from "../../Components/UI/Loader/Loader";
import { getDecryptedData } from "../../utils/encryptStorage";
import { SelectBasicWithSelectTag } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import {
  PrimaryButton,
  SecondaryButton,
} from "../NewCustomComp/Buttons/Buttons";
import GoBackButtonCustom from "../GoBackButtonCustom/GoBackButtonCustom";
import ConfirmPopup from "../NewCustomComp/confirmPopup/ConfirmPopup";

const AgentCustomerCategories = ({
  setShow,
  show,
  categoriesData,
  setCategoriesData,
  type,
}) => {
  const apiEndPoint = type === "customer" ? "customer" : "agent";
  const categoriesOption = [
    { label: "select", value: "" },
    ...categoriesData
      .filter((item) => {
        if (type === "agent") {
          return item.agent_cat_discount_type === "manual-rates";
        }
        return true; // For "customer", include all categories
      })
      .map((item) => ({
        label: type === "customer" ? item.category_name : item.agent_cat_name,
        value: item.id,
      })),
  ];

  const [loader, setLoader] = useState(false);
  const userToken = getDecryptedData("encryptToken");
  const navigate = useNavigate();
  const baseUrl = getBaseUrl();
  const property_id = useSelector((state) => state.property.value)?.id;

  const [userDetails, setUserDetails] = useState({
    category_name: "",
    category_description: "",
    targeted_room_count: 0,
    targeted_booking_amount: 0,
    category_id: "",
    reduce_amount: "",
    relative_rates: "manual",
    amount_type: "fixed",
    ...(type === "agent" && {
      category_discount_type: "tac",
      category_discount: 0,
    }),
  });

  const [rooms, setRooms] = useState([]);
  const [ratePlans, setRatePlans] = useState([]);
  const [ratesData, setRatesData] = useState([]);
  const [fromData, setFromData] = useState({
    room_id: "",
    roomName: "",
    mealplan_id: "",
    rate_plan_type: "",
    rate_bar_price: "",
    extra_adult: "",
    extra_child: "",
    extra_bed: "",
    breakfast: "",
    lunch: "",
    dinner: "",
  });

  const handleClear = () => {
    setFromData({
      room_id: "",
      roomName: "",
      mealplan_id: "",
      rate_plan_type: "",
      rate_bar_price: "",
      extra_adult: "",
      extra_child: "",
      extra_bed: "",
      breakfast: "",
      lunch: "",
      dinner: "",
    });
    setRatePlans([]);
    setShow(false);
  };

  const fetchRooms = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(`${baseUrl}/api/v1/rooms/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });

      const data = await response.json();

      if (data.success) {
        setRooms(data.rooms); // Set rooms if successful
        setFromData((prev) => ({
          ...prev,
          room_id: "",
          roomName: "",
          mealplan_id: "",
          rate_plan_type: "",
        }));
      } else {
        showErrorToast("Something went wrong...");
      }
    } catch (error) {
      console.log(error);
      showErrorToast("Failed to fetch rooms.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchRooms(property_id);
    }
  }, [property_id]);


  const handleRoomCategoryChange = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${baseUrl}/api/v1/get/rooms/rates/${id}/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setRatePlans(data.roomRates);
        setFromData((prev) => ({
          ...prev,
          room_id: id,
          room_name: rooms.find((room) => +room.id === +id).room_name,
          breakfast: "",
          lunch: "",
          dinner: "",
          mealplan_id: "",
          rate_plan_type: "",
          rate_plan_name: "",
          rate_plan_id: "",
        }));
      } else {
        showErrorToast("Failed to fetch room rates.");
      }
    } catch (error) {
      showErrorToast("Failed to fetch meal plans.");
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleMealPlanChange = (id) => {
    const selectedPlan = ratePlans.find((plan) => +plan.id === +id);

    const reduceAmount = +userDetails.reduce_amount;
    if (userDetails?.relative_rates === "relative" && !reduceAmount) {
      return showErrorToast(
        "Please enter reduce amount to calculate relative rates"
      );
    }

    if (selectedPlan) {
      const matchingRoom = relativeRates?.find(
        (room) => +room.room_id === +fromData.room_id
      );
      const matchingRatePlan = matchingRoom?.room_rates?.find(
        (ratePlan) => +ratePlan.rate_plan_id === +id
      );

      const rates = matchingRatePlan?.rates[0];

      const reduceValue = (value) => {
        if (!value) return value;
        let reducedValue;
        if (userDetails?.amount_type === "percentage") {
          reducedValue = (parseFloat(value) * (100 - reduceAmount)) / 100;
        } else if (userDetails?.amount_type === "fixed") {
          reducedValue = parseFloat(value) - reduceAmount;
        } else {
          return value;
        }

        return reducedValue < 0 ? 0 : reducedValue;
      };

      const shouldReduce = Boolean(rates);

      setFromData((prev) => ({
        ...prev,
        rate_plan_id: id,
        rate_plan_type: selectedPlan.rate_plan_type,
        rate_rack_price: shouldReduce
          ? reduceValue(rates?.rate_rack_price)
          : selectedPlan.rate_rack_price,
        rate_plan_name: selectedPlan.rate_plan_name,
        rate_bar_price: shouldReduce
          ? reduceValue(rates?.rate_bar_price)
          : selectedPlan.rate_bar_price,
        extra_adult: shouldReduce
          ? reduceValue(rates?.extra_adult)
          : selectedPlan.extra_adult,
        extra_child: shouldReduce
          ? reduceValue(rates?.extra_child)
          : selectedPlan.extra_child,
        extra_bed: shouldReduce
          ? reduceValue(rates?.extra_bed)
          : selectedPlan.extra_bed,
        breakfast: shouldReduce
          ? reduceValue(rates?.breakfast)
          : selectedPlan.breakfast,
        lunch: shouldReduce ? reduceValue(rates?.lunch) : selectedPlan.lunch,
        dinner: shouldReduce ? reduceValue(rates?.dinner) : selectedPlan.dinner,
        mealplan_id: selectedPlan.mealplan_id,
      }));
    }
  };

  const handleInputRatesChange = (e) => {
    const { name, value } = e.target;
    setFromData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRemoveOffer = (index) => {
    setRatesData((prev) => prev.filter((_, i) => i !== index));
  };

  const handleToOfferClick = () => {
    const isDuplicate = ratesData.some(
      (item) =>
        item.room_id === fromData.room_id &&
        item.mealplan_id === fromData.mealplan_id
    );

    if (isDuplicate) {
      showErrorToast("This room and meal plan combination already exists!");
      return;
    }

    setRatesData((prev) => [...prev, { ...fromData }]);
    setFromData({
      room_id: "",
      roomName: "",
      mealplan_id: "",
      rate_plan_type: "",
      rate_bar_price: "",
      extra_adult: "",
      extra_child: "",
      extra_bed: "",
      breakfast: "",
      lunch: "",
      dinner: "",
      room_name: "",
      rate_plan_name: "",
      rate_plan_id: "",
      rate_rack_price: "",
    });
  };

  const roomCategoriesOption = [
    { label: "select", value: "" },
    ...rooms.map((room) => ({
      label: room.room_name,
      value: room.id,
    })),
  ];

  const mealPlansOption = [
    { label: "select", value: "" },
    ...ratePlans.map((rate) => ({
      label: rate.rate_plan_name,
      value: rate.id,
    })),
  ];

  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const [deleteId ,setDeleteId] = useState(null)

  const handleDeletedCategory = async (id) => {
    showErrorToast("Are you sure you want to delete this category?")
    setDeleteId(id)
  };

  const handleDeletedCategoryConfirm = async () => {
    const postData = { ids: [deleteId] };

    try {
      setLoader(true);
      const response = await fetch(
        `${baseUrl}/api/v1/${apiEndPoint}/category/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "DELETE",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        setDeleteId(null)
        setCategoriesData(categoriesData.filter((item) => +item.id !== +deleteId));
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  const [editData, setEditData] = useState("");


  const handleEditClick = async (item) => {
    setEditData(item);
  };
  

  const handleUpdateCategory = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/api/v1/${apiEndPoint}/category/${property_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            id: editData.id,
            category_name:
              type === "customer"
                ? editData.category_name
                : editData.agent_cat_name,
            category_description:
              type === "customer"
                ? editData.category_description
                : editData.agent_cat_description,
            targeted_room_count: editData.targeted_room_count,
            targeted_booking_amount: editData.targeted_booking_amount,
            ...(type === "agent" && {
              category_discount_type: editData.agent_cat_discount_type,
              category_discount: editData.agent_cat_discount,
            }),
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        showSuccessToast(data.message);
        setCategoriesData((prev) =>
          prev.map((item) =>
            +item.id === +editData.id ? { ...editData } : item
          )
        );
     

        setEditData("");
      } else {
        showErrorToast("Something went wrong...");
      }
    } catch (error) {
      console.log(error);
      showErrorToast("Failed to fetch rooms.");
    } finally {
      setLoader(false);
    }
  };

  const handleEditCategoryChange = (e) => {
    const { name, value } = e.target;
    setEditData((prev) => ({ ...prev, [name]: value }));
  };

  // console.log(editData ,"editData");

  const [section, setShowSection] = useState("categories");

  const handleAddCategory = () => {
    setShowSection("addCategory");
  };

  const [relativeRates, setRelativeRates] = useState([]);

  useEffect(() => {
    if (userDetails.category_id) {
      const fetchCategoryData = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", ` Bearer  ${userToken}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/${apiEndPoint}/category/${property_id}?with_rates=${userDetails.category_id}&id=${userDetails.category_id}`,
            requestOptions
          );
          const result = await response.json();
          setRelativeRates(type === "customer" ? result.data.rates : result.data.agent_rates);
        } catch (error) {
          console.error(error);
        }
      };
      fetchCategoryData();
    } else {
      setRelativeRates([]);
    }
  }, [userDetails.category_id]);

  // console.log(relativeRates, "relativeRates");
  // console.log(fromData, "fromData");

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    if (userDetails.amount_type === "percentage" && +value >= 100) {
      showErrorToast("Percentage cannot be greater than 100");
      return;
    } else {
      setUserDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const tableHeadings =
    type === "customer"
      ? ["Name", "No of Room Nights", "Bookings Amt.", "Action"]
      : [
          "Name",
          "No of Room Nights",
          "Bookings Amt.",
          "Discount Type",
          "Action",
        ];

  const agentCategoryDiscountTypeOptions = [
    { label: "Tac", value: "tac" },
    { label: "Manual", value: "manual-rates" },
  ];
  // console.log(userDetails, "userDetails");

  // create button click functions for agent and customer

  const handleCreateCategoryCustomers = async () => {
    const postData = {
      category_name: userDetails.category_name,
      category_description: userDetails.category_description,
      targeted_room_count: userDetails.targeted_room_count,
      targeted_booking_amount: userDetails.targeted_booking_amount,
      customer_rates: ratesData,
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${baseUrl}/api/v1/${apiEndPoint}/category/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        handleClear();
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  const handleCreateCategoryAgents = async () => {
    const postData = {
      category_name: userDetails.category_name,
      property_id: property_id,
      category_description: userDetails.category_description,
      targeted_room_count: userDetails.targeted_room_count,
      targeted_booking_amount: userDetails.targeted_booking_amount,
      category_discount_type: userDetails.category_discount_type,
      ...(userDetails.category_discount_type === "tac" && {
        category_discount: userDetails.category_discount,
      }),
      ...(userDetails.category_discount_type === "manual-rates" && {
        agent_rates: ratesData,
      }),
    };

    try {
      setLoader(true);
      const response = await fetch(
        `${baseUrl}/api/v1/${apiEndPoint}/category/${property_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          method: "POST",
          body: JSON.stringify(postData),
        }
      );

      const data = await response.json();
      if (data.success) {
        showSuccessToast(data.message);
        handleClear();
      } else {
        showErrorToast(data.message || "Failed to update stay restrictions.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showErrorToast("An error occurred while updating stay restrictions.");
    } finally {
      setLoader(false);
    }
  };

  const handleBulkUpdate = (item)=>{
    navigate(`rates-update/${item.id}`)
  }
  // console.log(relativeRates ,"relativeRates")

  
  return (
    <>
      <div>
        {loader ? (
          <div className="w-100 h-100 flex justify_content_center align_items_center">
            <Loader />
          </div>
        ) : (
          <>
            {section === "categories" && (
              <>
                <div>
                  <div className="mb-10 flex justify_content_between">
                    <GoBackButtonCustom
                      buttonNextText={"Categories"}
                      onClick={() => setShow(false)}
                    />

                    <PrimaryButton
                      size="small"
                      title={"Add Category"}
                      onClick={handleAddCategory}
                    />
                  </div>

                  <CustomShopifyIndexTable headings={tableHeadings}>
                    {categoriesData.map((item, index) => (
                      <CustomShopifyIndexTableRow key={item.id}>
                        <CustomShopifyIndexTableCell>
                          {type === "customer"
                            ? item.category_name
                            : item.agent_cat_name}
                        </CustomShopifyIndexTableCell>
                        <CustomShopifyIndexTableCell>
                          {item.targeted_room_count}
                        </CustomShopifyIndexTableCell>
                        <CustomShopifyIndexTableCell>
                          {item.targeted_booking_amount}
                        </CustomShopifyIndexTableCell>

                        {type === "agent" && (
                          <CustomShopifyIndexTableCell>
                            {item.agent_cat_discount_type}
                          </CustomShopifyIndexTableCell>
                        )}
                        <CustomShopifyIndexTableCell>
                          <span className="flex flex_gap_10">
                          <span >
                              {/* <SvgEdit /> Bulk */}
                              <SecondaryButton
                              disabled={(type === "agent" && item.agent_cat_discount_type !== "manual-rates")}
                              onClick={() =>  handleBulkUpdate(item)}
                              size="small"
                              title={"Manage Rates"}
                              
                              />
                            </span>
                            <span
                              onClick={() =>handleEditClick(item)}
                            >
                              <SvgEdit/>
                            </span>
                           
                            <span
                              onClick={() => index > 0 &&  handleDeletedCategory(item.id)}
                            >
                              <SvgDelete color = {index === 0 ? "grey" : ""} />
                            </span>
                          </span>
                        </CustomShopifyIndexTableCell>
                      </CustomShopifyIndexTableRow>
                    ))}
                  </CustomShopifyIndexTable>
                </div>

                {editData && (
                  <div
                    className="flex align_items_center justify_content_between mt-10 card-3d"
                    style={{ padding: "10px" }}
                  >
                    <div className="flex flex_gap_10 align_items_center">
                      <span>
                        Category <sup>*</sup>
                      </span>
                      <PrimaryInput
                        size={"small"}
                        style={{ width: "100px" }}
                        value={
                          type === "customer"
                            ? editData.category_name
                            : editData.agent_cat_name
                        }
                        name={
                          type === "customer"
                            ? "category_name"
                            : "agent_cat_name"
                        }
                        onChange={handleEditCategoryChange}
                      />
                    </div>

                    <div className="flex flex_gap_10 align_items_center">
                      <span>
                        Room/Night <sup>*</sup>
                      </span>
                      <PrimaryInput
                        size={"small"}
                        style={{ width: "50px" }}
                        value={editData.targeted_room_count}
                        name={"targeted_room_count"}
                        onChange={handleEditCategoryChange}
                      />
                    </div>

                    <div className="flex flex_gap_10 align_items_center">
                      <span>
                        Booking Amt <sup>*</sup>
                      </span>

                      <PrimaryInput
                        size={"small"}
                        style={{ width: "100px" }}
                        value={editData.targeted_booking_amount}
                        name={"targeted_booking_amount"}
                        onChange={handleEditCategoryChange}
                      />
                    </div>

                    {type === "agent" &&
                      editData?.agent_cat_discount_type === "tac" && (
                        <div className="flex flex_gap_10 align_items_center">
                          <span>
                            Tac % <sup>*</sup>
                          </span>
                          <PrimaryInput
                            size={"small"}
                            style={{ width: "50px" }}
                            value={editData.agent_cat_discount}
                            name={"agent_cat_discount"}
                            onChange={handleEditCategoryChange}
                          />
                        </div>
                      )}
                    <div className="flex flex_gap_10 align_items_center">
                      <PrimaryButton
                        size="small"
                        title={"Update"}
                        onClick={handleUpdateCategory}
                      />

                      <SecondaryButton
                        size="small"
                        title={"Cancel"}
                        onClick={() => setEditData("")}
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {section === "addCategory" && type === "customer" && (
              <div>
                <div>
                  <div className="flex justify_content_between mb-10">
                    <GoBackButtonCustom
                      buttonNextText={"Add Category"}
                      onClick={() => setShowSection("categories")}
                    />
                  </div>
                  <div>
                    <div className="flex flex_gap_10">
                      <div className="w-50">
                        <PrimaryInput
                          label={"Category Name"}
                          size={"medium"}
                          name={"category_name"}
                          required={true}
                          value={userDetails.category_name}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                      <div className="w-50">
                        <PrimaryInput
                          label={"Category Description"}
                          size={"medium"}
                          name={"category_description"}
                          required={true}
                          value={userDetails.category_description}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                    </div>

                    <div className="flex flex_gap_10 mt-10">
                      <div className="w-33">
                        <PrimaryInput
                          name={"targeted_room_count"}
                          label={"Targeted Room Count"}
                          size={"medium"}
                          required={true}
                          value={userDetails.targeted_room_count}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                      <div className="w-33">
                        <PrimaryInput
                          label={"Targeted Booking Amount"}
                          name={"targeted_booking_amount"}
                          size={"medium"}
                          required={true}
                          value={userDetails.targeted_booking_amount}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                      <div className="w-33">
                        <SelectBasicWithSelectTag
                          label={"Relative Rates"}
                          name={"relative_rates"}
                          options={[
                            { label: "select", value: "" },
                            { label: "manual", value: "manual" },
                            { label: "Relative", value: "relative" },
                          ]}
                          size={"medium"}
                          required={true}
                          value={userDetails?.relative_rates}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                    </div>

                    {userDetails?.relative_rates === "relative" && (
                      <div className="flex flex_gap_10 mt-10">
                        <div className="w-33">
                          <SelectBasicWithSelectTag
                            label={"Categories"}
                            name={"category_id"}
                            options={categoriesOption}
                            size={"medium"}
                            required={true}
                            value={userDetails?.category_id}
                            onChange={handleUserDetailsChange}
                            disabled={ratesData.length > 0}
                          />
                        </div>

                        <div className="w-33">
                          <SelectBasicWithSelectTag
                            label={"Amount Type"}
                            name={"amount_type"}
                            options={[
                              { label: "select", value: "" },
                              { label: "Fixed", value: "fixed" },
                              { label: "Percentage", value: "percentage" },
                            ]}
                            size={"medium"}
                            required={true}
                            value={userDetails?.amount_type}
                            onChange={handleUserDetailsChange}
                            disabled={ratesData.length > 0}
                          />
                        </div>

                        <div className="w-33">
                          <PrimaryInput
                            label={"Reduce Amount"}
                            name={"reduce_amount"}
                            size={"medium"}
                            required={true}
                            value={userDetails?.reduce_amount}
                            onChange={handleAmountChange}
                            disabled={ratesData.length > 0}
                            type="number"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex flex_gap_10 mt-10">
                    <div className="w-50">
                      <SelectBasicWithSelectTag
                        label="Select Room"
                        size={"medium"}
                        options={roomCategoriesOption}
                        disabled={!rooms?.length}
                        onChange={(e) =>
                          handleRoomCategoryChange(e.target.value)
                        }
                        value={fromData?.room_id}
                      />
                    </div>

                    <div className="w-50">
                      <SelectBasicWithSelectTag
                        label="Select Meal Plan"
                        size={"medium"}
                        options={mealPlansOption}
                        disabled={!rooms?.length}
                        onChange={(e) => handleMealPlanChange(e.target.value)}
                        value={fromData?.rate_plan_id || ""}
                      />
                    </div>
                  </div>

                  {fromData.room_id && fromData.rate_plan_id && (
                    <>
                      <table className={`{styles.table} mt-10`}>
                        <tbody>
                          <tr>
                            <td>
                              <PrimaryInput
                                size={"small"}
                                type="number"
                                label={"Room Rate"}
                                name="rate_bar_price"
                                value={fromData.rate_bar_price}
                                onChange={handleInputRatesChange}
                              />
                            </td>
                            <td>
                              <PrimaryInput
                                size={"small"}
                                type="number"
                                name={"extra_adult"}
                                label={"Extra Adult"}
                                value={fromData.extra_adult}
                                onChange={handleInputRatesChange}
                              />
                            </td>
                            <td>
                              <PrimaryInput
                                size={"small"}
                                type="number"
                                name={"extra_bed"}
                                label={"Extra Bed"}
                                value={fromData.extra_bed}
                                onChange={handleInputRatesChange}
                              />
                            </td>

                            <td>
                              <PrimaryInput
                                size={"small"}
                                type="number"
                                name={"extra_child"}
                                label={"Extra Child"}
                                value={fromData.extra_child}
                                onChange={handleInputRatesChange}
                              />
                            </td>

                            <td>
                              <PrimaryInput
                                size={"small"}
                                type="number"
                                label={"Breakfast"}
                                name={"breakfast"}
                                value={fromData.breakfast}
                                onChange={handleInputRatesChange}
                              />
                            </td>
                            <td>
                              <PrimaryInput
                                size={"small"}
                                type="number"
                                label={"Lunch"}
                                value={fromData.lunch}
                                name={"lunch"}
                                onChange={handleInputRatesChange}
                              />
                            </td>
                            <td>
                              <PrimaryInput
                                size={"small"}
                                type="number"
                                label={"dinner"}
                                value={fromData.dinner}
                                name={"dinner"}
                                onChange={handleInputRatesChange}
                              />
                            </td>

                            <td style={{ verticalAlign: "bottom" }}>
                              <span
                                className="cursor-pointer w-100 h-100 flex align_items_end"
                                onClick={handleToOfferClick}
                              >
                                <SvgPlus />
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                  <hr className="mt-20 mb-20" />
                  <h2 className={styles.headings}></h2>
                  <div className={styles.offer_table}>
                    {ratesData?.length > 0 ? (
                      <>
                        <table className={styles.table}>
                          <thead>
                            <tr>
                              <th>Room Name</th>
                              <th>Rate Plan Type</th>
                              <th>BAR Price</th>
                              <th>Extra Adult</th>
                              <th>Extra Child</th>
                              <th>Extra Bed</th>
                              <th>Breakfast </th>
                              <th>Lunch</th>
                              <th>Dinner</th>

                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ratesData.map((item, index) => (
                              <tr key={index}>
                                <td>{item.room_name || "N/A"}</td>
                                <td>{item.rate_plan_type || "N/A"}</td>
                                <td>{item.rate_bar_price || "N/A"}</td>
                                <td>{item.extra_adult || "N/A"}</td>
                                <td>{item.extra_child || "N/A"}</td>
                                <td>{item.extra_bed || "N/A"}</td>
                                <td>{item.breakfast || "N/A"}</td>
                                <td>{item.lunch || "N/A"}</td>
                                <td>{item.dinner || "N/A"}</td>

                                <td>
                                  <span
                                    onClick={() => handleRemoveOffer(index)}
                                  >
                                    <SvgMinus />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className={styles.noAddonsContainer}>
                        <div className={styles.noAddonsMessage}>
                          <SvgAlert />
                          <p>
                            Please add rooms, and rate plans to view the
                            details.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className={` flex align_items_center justify_content_between mt-20`}
                  >
                    <div className="flex align_items_center flex_gap_10 ">
                      <BgThemeButton
                        disabled={ratesData?.length === 0}
                        onClick={
                          type === "customer"
                            ? handleCreateCategoryCustomers
                            : handleCreateCategoryAgents
                        }
                        children={"Create"}
                      />

                      <BgOutlineThemeButton onClick={() => handleClear()}>
                        Cancel
                      </BgOutlineThemeButton>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Type Agent   */}

            {section === "addCategory" && type === "agent" && (
              <div>
                <div>
                  <div className="flex justify_content_between mb-10">
                    <GoBackButtonCustom
                      buttonNextText={"Add Category"}
                      onClick={() => setShowSection("categories")}
                    />
                  </div>
                  <div>
                    <div className="flex flex_gap_10">
                      <div className="w-50">
                        <PrimaryInput
                          label={"Category Name"}
                          size={"medium"}
                          name={"category_name"}
                          required={true}
                          value={userDetails.category_name}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                      <div className="w-50">
                        <PrimaryInput
                          label={"Category Description"}
                          size={"medium"}
                          name={"category_description"}
                          required={true}
                          value={userDetails.category_description}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                    </div>

                    <div className="flex flex_gap_10 mt-10">
                      <div className="w-25">
                        <PrimaryInput
                          name={"targeted_room_count"}
                          label={"Targeted Room Count"}
                          size={"medium"}
                          required={true}
                          value={userDetails.targeted_room_count}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                      <div className="w-25">
                        <PrimaryInput
                          label={"Targeted Booking Amount"}
                          name={"targeted_booking_amount"}
                          size={"medium"}
                          required={true}
                          value={userDetails.targeted_booking_amount}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                      <div className="w-25">
                        <SelectBasicWithSelectTag
                          label={"Category Discount Type"}
                          name={"category_discount_type"}
                          options={agentCategoryDiscountTypeOptions}
                          size={"medium"}
                          required={true}
                          value={userDetails?.category_discount_type}
                          onChange={handleUserDetailsChange}
                          disabled={ratesData.length > 0}
                        />
                      </div>
                      <div className="w-25">
                        {userDetails?.category_discount_type === "tac" ? (
                          <PrimaryInput
                            label={"Tac (%)"}
                            name={"category_discount"}
                            size={"medium"}
                            required={true}
                            value={userDetails.category_discount}
                            onChange={handleUserDetailsChange}
                            disabled={ratesData.length > 0}
                            type={"number"}
                          />
                        ) : (
                          <SelectBasicWithSelectTag
                            label={"Relative Rates"}
                            name={"relative_rates"}
                            options={[
                              { label: "select", value: "" },
                              { label: "manual", value: "manual" },
                              { label: "Relative", value: "relative" },
                            ]}
                            size={"medium"}
                            required={true}
                            value={userDetails?.relative_rates}
                            onChange={handleUserDetailsChange}
                            disabled={ratesData.length > 0}
                          />
                        )}
                      </div>
                    </div>

                    {userDetails?.relative_rates === "relative" &&
                      userDetails.category_discount_type !== "tac" && (
                        <div className="flex flex_gap_10 mt-10">
                          <div className="w-33">
                            <SelectBasicWithSelectTag
                              label={"Categories"}
                              name={"category_id"}
                              options={categoriesOption}
                              size={"medium"}
                              required={true}
                              value={userDetails?.category_id}
                              onChange={handleUserDetailsChange}
                              disabled={ratesData.length > 0}
                            />
                          </div>

                          <div className="w-33">
                            <SelectBasicWithSelectTag
                              label={"Amount Type"}
                              name={"amount_type"}
                              options={[
                                { label: "select", value: "" },
                                { label: "Fixed", value: "fixed" },
                                { label: "Percentage", value: "percentage" },
                              ]}
                              size={"medium"}
                              required={true}
                              value={userDetails?.amount_type}
                              onChange={handleUserDetailsChange}
                              disabled={ratesData.length > 0}
                            />
                          </div>

                          <div className="w-33">
                            <PrimaryInput
                              label={"Reduce Amount"}
                              name={"reduce_amount"}
                              size={"medium"}
                              required={true}
                              value={userDetails?.reduce_amount}
                              onChange={handleAmountChange}
                              disabled={ratesData.length > 0}
                              type="number"
                            />
                          </div>
                        </div>
                      )}
                  </div>

                  {userDetails.category_discount_type !== "tac" && (
                    <>
                      <div className="flex flex_gap_10 mt-10">
                        <div className="w-50">
                          <SelectBasicWithSelectTag
                            label="Select Room"
                            size={"medium"}
                            options={roomCategoriesOption}
                            disabled={!rooms?.length}
                            onChange={(e) =>
                              handleRoomCategoryChange(e.target.value)
                            }
                            value={fromData?.room_id}
                          />
                        </div>

                        <div className="w-50">
                          <SelectBasicWithSelectTag
                            label="Select Meal Plan"
                            size={"medium"}
                            options={mealPlansOption}
                            disabled={!rooms?.length}
                            onChange={(e) =>
                              handleMealPlanChange(e.target.value)
                            }
                            value={fromData?.rate_plan_id || ""}
                          />
                        </div>
                      </div>

                      {fromData.room_id && fromData.rate_plan_id && (
                        <>
                          <table className={`{styles.table} mt-10`}>
                            <tbody>
                              <tr>
                                <td>
                                  <PrimaryInput
                                    size={"small"}
                                    type="number"
                                    label={"Room Rate"}
                                    name="rate_bar_price"
                                    value={fromData.rate_bar_price}
                                    onChange={handleInputRatesChange}
                                  />
                                </td>
                                <td>
                                  <PrimaryInput
                                    size={"small"}
                                    type="number"
                                    name={"extra_adult"}
                                    label={"Extra Adult"}
                                    value={fromData.extra_adult}
                                    onChange={handleInputRatesChange}
                                  />
                                </td>
                                <td>
                                  <PrimaryInput
                                    size={"small"}
                                    type="number"
                                    name={"extra_bed"}
                                    label={"Extra Bed"}
                                    value={fromData.extra_bed}
                                    onChange={handleInputRatesChange}
                                  />
                                </td>

                                <td>
                                  <PrimaryInput
                                    size={"small"}
                                    type="number"
                                    name={"extra_child"}
                                    label={"Extra Child"}
                                    value={fromData.extra_child}
                                    onChange={handleInputRatesChange}
                                  />
                                </td>

                                <td>
                                  <PrimaryInput
                                    size={"small"}
                                    type="number"
                                    label={"Breakfast"}
                                    name={"breakfast"}
                                    value={fromData.breakfast}
                                    onChange={handleInputRatesChange}
                                  />
                                </td>
                                <td>
                                  <PrimaryInput
                                    size={"small"}
                                    type="number"
                                    label={"Lunch"}
                                    value={fromData.lunch}
                                    name={"lunch"}
                                    onChange={handleInputRatesChange}
                                  />
                                </td>
                                <td>
                                  <PrimaryInput
                                    size={"small"}
                                    type="number"
                                    label={"dinner"}
                                    value={fromData.dinner}
                                    name={"dinner"}
                                    onChange={handleInputRatesChange}
                                  />
                                </td>

                                <td style={{ verticalAlign: "bottom" }}>
                                  <span
                                    className="cursor-pointer w-100 h-100 flex align_items_end"
                                    onClick={handleToOfferClick}
                                  >
                                    <SvgPlus />
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                      <hr className="mt-20 mb-20" />
                      <h2 className={styles.headings}></h2>
                      <div className={styles.offer_table}>
                        {ratesData?.length > 0 ? (
                          <>
                            <table className={styles.table}>
                              <thead>
                                <tr>
                                  <th>Room Name</th>
                                  <th>Rate Plan Type</th>
                                  <th>BAR Price</th>
                                  <th>Extra Adult</th>
                                  <th>Extra Child</th>
                                  <th>Extra Bed</th>
                                  <th>Breakfast </th>
                                  <th>Lunch</th>
                                  <th>Dinner</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ratesData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.room_name || "N/A"}</td>
                                    <td>{item.rate_plan_type || "N/A"}</td>
                                    <td>{item.rate_bar_price || "N/A"}</td>
                                    <td>{item.extra_adult || "N/A"}</td>
                                    <td>{item.extra_child || "N/A"}</td>
                                    <td>{item.extra_bed || "N/A"}</td>
                                    <td>{item.breakfast || "N/A"}</td>
                                    <td>{item.lunch || "N/A"}</td>
                                    <td>{item.dinner || "N/A"}</td>

                                    <td>
                                      <span
                                        onClick={() => handleRemoveOffer(index)}
                                      >
                                        <SvgMinus />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <div className={styles.noAddonsContainer}>
                            <div className={styles.noAddonsMessage}>
                              <SvgAlert />
                              <p>
                                Please add rooms, and rate plans to view the
                                details.
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  <div
                    className={` flex align_items_center justify_content_between mt-20`}
                  >
                    <div className="flex align_items_center flex_gap_10 ">
                      <BgThemeButton
                        // disabled={ratesData?.length === 0}
                        onClick={
                          type === "customer"
                            ? handleCreateCategoryCustomers
                            : handleCreateCategoryAgents
                        }
                        children={"Create"}
                      />

                      <BgOutlineThemeButton onClick={() => handleClear()}>
                        Cancel
                      </BgOutlineThemeButton>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {
              deleteId && (
                <ConfirmPopup
                 setShowPopup={setDeleteId}
                 heading={"Are you sure?"}
                 subHeading={"Are you sure you want to delete this category?"}
                 handleConfirmClick={handleDeletedCategoryConfirm}
                />
              )
            }
          </>
        )}
      </div>
    </>
  );
};

export default AgentCustomerCategories;
